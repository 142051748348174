import styled from "@emotion/styled";
import { FC } from "react";
import { MergedTheme } from "../../types/emotion";
import { StyledFC } from "../../types/styledFC";
import { IconProps } from "../icons/types";

type LinkButtonColor = "primary" | "gray" | "white";

type ButtonSize = "sm" | "2xl";

interface LinkButtonProps {
  color: LinkButtonColor;
  size: ButtonSize;
  leadingIcon?: FC<IconProps> | undefined;
  trailingIcon?: FC<IconProps> | undefined;
  label?: string | undefined;
  onClick: () => void;
  disabled?: boolean | undefined;
}

function getDefaultColorStyle(
  theme: MergedTheme,
  color: LinkButtonColor,
  error?: boolean | undefined
) {
  switch (color) {
    case "primary":
      return {
        cursor: "pointer",
        backgroundColor: "inherit",
        color: theme.salesleg.palette.primary[600],
        ":hover": {
          color: theme.salesleg.palette.primary[700],
        },
        ":disabled": {
          color: theme.salesleg.palette.neutral[300],
          cursor: "not-allowed",
        },
      };

    case "gray":
      return {
        cursor: "pointer",
        backgroundColor: "inherit",
        color: theme.salesleg.palette.neutral[500],
        ":hover": {
          color: theme.salesleg.palette.neutral[600],
        },
        ":disabled": {
          color: theme.salesleg.palette.neutral[100],
          cursor: "not-allowed",
        },
      };
    case "white":
      return {
        cursor: "pointer",
        backgroundColor: "inherit",
        color: "rgba(255, 255, 255, 0.7)",
        ":hover": {
          color: "rgba(255, 255, 255, 0.8)",
        },
        ":disabled": {
          color: "rgba(255, 255, 255, 0.25)",
          cursor: "not-allowed",
        },
      };
  }
}

function getSizeStyle(size: ButtonSize) {
  switch (size) {
    case "sm":
      return {
        gap: 8,
        svg: {
          height: 20,
          width: 20,
        },
      };
    case "2xl":
      return {
        gap: 12,
        svg: {
          height: 24,
          width: 24,
        },
      };
  }
}

export const RootContainer = styled.button<{
  color: LinkButtonColor;
  size: ButtonSize;
}>(({ color, size, theme }) => ({
  display: "flex",
  alignItems: "center",
  ...getDefaultColorStyle(theme, color),
  ...getSizeStyle(size),
}));

const Label = styled.span<{ size: ButtonSize }>(({ size, theme }) => {
  switch (size) {
    case "sm":
      return {
        ...theme.salesleg.typography.paragraph.sm.medium,
        whiteSpace: "nowrap",
      };
    default:
      return {
        ...theme.salesleg.typography.paragraph.lg.medium,
        whiteSpace: "nowrap",
      };
  }
});

const LinkButton: StyledFC<LinkButtonProps> = ({
  size,
  color,
  leadingIcon,
  trailingIcon,
  label,
  onClick,
  style,
  disabled,
}) => {
  const LeadingIcon = leadingIcon;
  const TrailingIcon = trailingIcon;
  return (
    <RootContainer
      color={color}
      size={size}
      onClick={onClick}
      disabled={disabled}
      style={style}
    >
      {LeadingIcon && <LeadingIcon color="inherit" />}
      {label && <Label size={size}>{label}</Label>}
      {TrailingIcon && <TrailingIcon color="inherit" />}
    </RootContainer>
  );
};

export default LinkButton;
