import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { FC } from "react";
import { IconComponent } from "../icons/types";

const Container = styled.div(({ theme }) => ({
  display: "inline-flex",
  padding: "4px 6px",
  alignItems: "center",
  borderRadius: 4,
  border: `1px solid ${theme.salesleg.palette.neutral[100]}`,
  background: "#FFFFFF",
  gap: 4,
}));

interface TagProps {
  label: string;
  leadingIcon?: IconComponent | undefined;
  trailingIcon?: IconComponent | undefined;
}

const Label = styled.div(({ theme }) => ({
  ...theme.salesleg.typography.body.xs.regular,
  color: theme.salesleg.palette.neutral[500],
}));

const Tag: FC<TagProps> = ({ label, leadingIcon, trailingIcon }) => {
  const theme = useTheme();
  const LeadingIcon = leadingIcon;
  const TrailingIcon = trailingIcon;
  return (
    <Container>
      {LeadingIcon && (
        <LeadingIcon size={14} color={theme.salesleg.palette.neutral[500]} />
      )}
      <Label>{label}</Label>
      {TrailingIcon && (
        <TrailingIcon size={10} color={theme.salesleg.palette.neutral[500]} />
      )}
    </Container>
  );
};

export default Tag;
