import styled from "@emotion/styled";
import { ActivityStatusV2 } from "@hshrimal/salesleg";
import capitalize from "capitalize";
import { FC } from "react";

interface VisitStatusProps {
  status: ActivityStatusV2;
}
const VisitStatus = styled.div(({ theme }) => ({
  textTransform: "capitalize",
  color: theme.salesleg.palette.neutral[400],
}));
const VisitStatusCell: FC<VisitStatusProps> = ({ status }) => {
  let label;
  if (status === "scheduled") {
    label = "Pending";
  } else if (status === "rescheduled") {
    label = "Canceled";
  } else {
    label = capitalize(status);
  }

  return <VisitStatus>{label}</VisitStatus>;
};

export default VisitStatusCell;
