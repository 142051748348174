import {
  Employee,
  EmployeeFilter,
  EmployeeStatus,
  ExtendedEmployee,
  WithMetadata,
  deserializeExtendedEmployees,
} from "@hshrimal/salesleg";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const employeesApi = createApi({
  reducerPath: "employeesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_SALESLEG_API_BASE_URL}/api`,
    prepareHeaders: (headers) => headers.append("client", "web"),
    credentials: "include",
  }),
  tagTypes: ["employees"],
  endpoints: (builder) => ({
    getEmployees: builder.query<
      WithMetadata<ExtendedEmployee>[],
      EmployeeFilter
    >({
      query: (filter) => ({
        method: "POST",
        url: "/employees/query",
        body: filter,
      }),
      transformResponse: (data) => deserializeExtendedEmployees(data),
      providesTags: ["employees"],
    }),
    createEmployee: builder.mutation<{ id: string }, Employee>({
      query: (input) => ({
        url: `/employees`,
        method: "POST",
        body: input,
      }),
      invalidatesTags: ["employees"],
    }),
    updateEmployee: builder.mutation<void, Employee & { id: string }>({
      query: (input) => ({
        url: `/employees/${input.id}`,
        method: "PUT",
        body: input,
      }),
      invalidatesTags: ["employees"],
    }),
    updateEmployeeStatus: builder.mutation<
      void,
      { id: string; status: EmployeeStatus }
    >({
      query: (input) => ({
        url: `/employees/${input.id}/status`,
        method: "PUT",
        body: input.status,
      }),
      invalidatesTags: ["employees"],
    }),
  }),
});

export const {
  useGetEmployeesQuery,
  useCreateEmployeeMutation,
  useUpdateEmployeeMutation,
  useUpdateEmployeeStatusMutation,
} = employeesApi;
