import styled from "@emotion/styled";
import { Switch } from "@mui/material";
import { FC } from "react";

interface AllFilterProps {
  checked: boolean;
  handleSelectAllChange: () => void;
}

const SwitchAll = styled.div<{ checked: boolean }>(({ checked, theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "10px 14px",
  backgroundColor: checked ? theme.salesleg.palette.primary[25] : "#ffffff",
}));

const Text = styled.div(({ theme }) => ({
  ...theme.salesleg.typography.paragraph.md.medium,
}));

const AllFilter: FC<AllFilterProps> = ({ checked, handleSelectAllChange }) => {
  return (
    <SwitchAll checked={checked}>
      <Text>All</Text>
      <Switch checked={checked} onChange={handleSelectAllChange} size="small" />
    </SwitchAll>
  );
};

export default AllFilter;
