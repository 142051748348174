import {
  LeadsQualityReportRequest,
  SummaryV2,
  deserializeSummaryV2,
} from "@hshrimal/salesleg";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const summaryApi = createApi({
  reducerPath: "summaryApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_SALESLEG_API_BASE_URL}/api`,
    prepareHeaders: (headers) => headers.append("client", "web"),
    credentials: "include",
  }),
  tagTypes: ["summary"],
  endpoints: (builder) => ({
    getSummary: builder.query<SummaryV2[], LeadsQualityReportRequest>({
      query: (leadFilter) => ({
        method: "Post",
        url: "/leads/reports/summary/query",
        leadFilter,
        body: leadFilter,
      }),
      transformResponse: (data) => deserializeSummaryV2(data),
      providesTags: ["summary"],
    }),
  }),
});

export const { useGetSummaryQuery } = summaryApi;
