import { FilterUrlParams } from "../../constants/filters";
import { NextFollowUpDisplayMap } from "../../utils/nextFollowUpDisplayHelper";
import { Filter } from "../Filter";
import { MenuItem } from "../Menu/MenuItem";
import CalendarIcon from "../icons/CalenderIcon";

const nextFollowUps = ["past_due", "today", "tomorrow", "upcoming"];

const NextFollowUpFilter = () => {
  return (
    <Filter
      icon={CalendarIcon}
      name="Next Follow Up"
      urlParamName={FilterUrlParams.FOLLOWUP}
      multiple={false}
    >
      {nextFollowUps.map((nextFollowUp) => (
        <MenuItem
          key={nextFollowUp}
          variant="default"
          value={nextFollowUp}
          label={NextFollowUpDisplayMap.get(nextFollowUp)}
        />
      ))}
    </Filter>
  );
};

export default NextFollowUpFilter;
