import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import * as React from "react";
import Badge from "../Badge";
import VerticalDivider from "../VerticalDivider";
import CloseIcon from "../icons/CloseIcon";
import DropdownOpenIcon from "../icons/DropdownOpenIcon";
import { IconComponent } from "../icons/types";

type ValueDisplayType = "text" | "tags";
type Size = "sm" | "md";

interface FilterButtonProps {
  size: Size;
  leadingIcon?: IconComponent;
  label?: string | undefined;
  valueDisplayType?: ValueDisplayType | undefined;
  valueText?: string | undefined;
  valueTags?: string[] | undefined;
  onClick?: React.MouseEventHandler<HTMLElement>;
  width?: number | string | undefined;
  onTagRemoveClick?: (tag: string) => void;
  onFilterRemoveClick?: () => void;
  disableClearButton?: boolean | undefined;
}

const getButtonSizeStyle = (size: Size) => {
  switch (size) {
    case "sm":
      return {
        padding: "6px 10px",
      };
    case "md":
      return {
        padding: "8px 16px",
      };
  }
};

const Container = styled.button<{
  size: Size;
  width: number | string;
}>(({ theme, size, width }) => ({
  width: width,
  backgroundColor: "#ffffff",
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderRadius: 20,
  gap: 6,
  cursor: "pointer",
  boxShadow: `${theme.salesleg.shadow.sm}`,
  border: `1px solid ${theme.salesleg.palette.neutral[25]}`,
  ...getButtonSizeStyle(size),
  ":hover": {
    border: `1px solid ${theme.salesleg.palette.neutral[50]}`,
    boxShadow: `${theme.salesleg.shadow.lg}`,
  },
  ":focus-visible": {
    border: `1px solid ${theme.salesleg.palette.primary[100]}`,
    boxShadow: `0px 0px 0px 4px ${theme.salesleg.palette.primary[25]}, 0px 1px 2px 0px rgba(16, 24, 40, 0.05)`,
  },
}));

const LabelText = styled.div<{ color: string }>(({ theme, color }) => ({
  ...theme.salesleg.typography.body.sm.regular,
  color: color,
}));

const Text = styled.div(({ theme }) => ({
  ...theme.salesleg.typography.body.sm.regular,
  color: theme.salesleg.palette.neutral[900],
}));

const IconWrap = styled.div(() => ({
  display: "flex",
  gap: 5,
}));

const CloseIconWrapper = styled.span(() => ({
  display: "flex",
  alignItems: "center",
}));

export const FilterButton = React.forwardRef<
  HTMLButtonElement,
  FilterButtonProps
>(
  (
    {
      leadingIcon,
      label,
      valueText,
      valueTags,
      onClick,
      size,
      valueDisplayType = "text",
      width = "auto",
      onTagRemoveClick,
      onFilterRemoveClick,
      disableClearButton,
    },
    ref
  ) => {
    const theme = useTheme();
    const LeadingIcon = leadingIcon;
    return (
      <Container onClick={onClick} size={size} ref={ref} width={width}>
        {LeadingIcon && (
          <LeadingIcon color={theme.salesleg.palette.neutral[700]} size={16} />
        )}
        {label && (
          <LabelText
            color={
              valueText || valueTags
                ? theme.salesleg.palette.neutral[400]
                : theme.salesleg.palette.neutral[900]
            }
          >
            {label}
          </LabelText>
        )}
        {valueDisplayType === "text" && valueText && <Text>{valueText}</Text>}
        {valueDisplayType === "tags" && valueTags && valueTags.length > 0 && (
          <>
            <VerticalDivider height={12} width={0} />
            {valueTags.slice(0, 2).map((valueTag) => (
              <Badge
                key={valueTag}
                color="primary"
                label={valueTag}
                size="sm"
                showCloseIcon={!disableClearButton}
                onClose={(e) => {
                  e.stopPropagation();
                  onTagRemoveClick && onTagRemoveClick(valueTag);
                }}
              />
            ))}
            {valueTags.length > 2 && (
              <Badge
                color="primary"
                label={`+${valueTags.length - 2}`}
                size="sm"
              />
            )}
          </>
        )}

        <IconWrap>
          {!disableClearButton && valueTags && valueTags?.length > 0 && (
            <CloseIconWrapper
              onClick={(e) => {
                e.stopPropagation();
                onFilterRemoveClick && onFilterRemoveClick();
              }}
            >
              <CloseIcon
                color={theme.salesleg.palette.neutral[700]}
                size={14}
              />
            </CloseIconWrapper>
          )}

          <DropdownOpenIcon
            size={16}
            color={theme.salesleg.palette.neutral[700]}
          />
        </IconWrap>
      </Container>
    );
  }
);
