import { ReactComponent as DropdownOpenSvgIcon } from "./../../../assets/icons/dropdown-open.svg";
import { IconComponent } from "../types";

const DropdownOpenIcon: IconComponent = ({ size, color, style }) => {
  return (
    <DropdownOpenSvgIcon
      width={size}
      height={size}
      style={style}
      color={color}
    />
  );
};

export default DropdownOpenIcon;
