import { LeadStage } from "@hshrimal/salesleg";
import { FilterUrlParams } from "../../constants/filters";
import { LeadStageDisplayMap } from "../../utils/LeadStageDisplayHelper";
import { FilterMenu, FilterMenuProps } from "../FilterMenu";
import { MenuItem } from "../Menu/MenuItem";

const Stages: LeadStage[] = [
  "new",
  "contacted",
  "interested",
  "visit_planned",
  "visit_done",
  "negotiation",
  "booking",
];

const StageMenu = ({
  open,
  onClose,
  anchorEl,
  placement,
  multiple,
}: Omit<FilterMenuProps, "children" | "urlParamName">) => {
  return (
    <FilterMenu
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      placement={placement}
      multiple={multiple}
      urlParamName={FilterUrlParams.STAGE}
    >
      {Stages.map((stage) => (
        <MenuItem
          key={stage}
          variant="default"
          value={stage}
          label={LeadStageDisplayMap.get(stage)}
        />
      ))}
    </FilterMenu>
  );
};

export default StageMenu;
