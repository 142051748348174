import {
  ExtendedEmployee,
  TeamReference,
  WithMetadata,
} from "@hshrimal/salesleg";
import { FC } from "react";

import TeamCollapse from "./TeamCollapse";

interface SelectUserByTeamProps {
  onAddUsers: (users: WithMetadata<ExtendedEmployee>[]) => void;
  onRemoveUsers: (users: WithMetadata<ExtendedEmployee>[]) => void;
  teams: TeamReference[];
  users: WithMetadata<ExtendedEmployee>[];
}

const SelectUserByTeam: FC<SelectUserByTeamProps> = ({
  onAddUsers,
  onRemoveUsers,
  teams,
  users,
}) => {
  return (
    <>
      {teams.map((team) => {
        return (
          <TeamCollapse
            key={team.id}
            onAddUsers={onAddUsers}
            onRemoveUsers={onRemoveUsers}
            filteredUsers={users}
            team={team}
          />
        );
      })}
    </>
  );
};

export default SelectUserByTeam;
