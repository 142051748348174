import { useTheme } from "@emotion/react";
import { StyledFC } from "../../types/styledFC";

interface UserTeamNameCellProps {
  team: string;
}

const UserTeamNameCell: StyledFC<UserTeamNameCellProps> = ({ team }) => {
  const theme = useTheme();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 8,
        ...theme.salesleg.typography.body.sm.regular,
      }}
    >
      <span style={{ color: theme.salesleg.palette.neutral[700] }}>{team}</span>
    </div>
  );
};

export default UserTeamNameCell;
