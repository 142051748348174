import styled from "@emotion/styled";
import AttachmentCard from "./AttachmentCard";

const RootContainer = styled.div(() => ({
  padding: "24px 32px 0px 24px",
}));

const CardContainer = styled.div(() => ({
  display: "flex",
  flexDirection: "row",
  gap: 24,
  flexWrap: "wrap",
}));

interface AttachmentContentProps {
  attachments: { name: string }[];
}

const AttachmentContent = ({ attachments }: AttachmentContentProps) => {
  return (
    <RootContainer>
      <CardContainer>
        {attachments.map((item) => (
          <AttachmentCard fileName={item.name} fileSize="200 KB" />
        ))}
      </CardContainer>
    </RootContainer>
  );
};

export default AttachmentContent;
