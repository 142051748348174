import styled from "@emotion/styled";
import { City } from "@hshrimal/salesleg";
import { FC } from "react";
import MapPinIcon from "../../components/icons/MapPinIcon";
import theme from "../../theme/light/theme";

const RootContainer = styled.div(() => ({
  display: "flex",
  alignItems: "center",
  gap: 24,
}));

const Field = styled.div(() => ({
  display: "flex",
  alignItems: "center",
  gap: 12,
}));

const FieldName = styled.div(({ theme }) => ({
  ...theme.salesleg.typography.paragraph.sm.medium,
  color: theme.salesleg.palette.neutral[400],
}));

const FieldValue = styled.div(({ theme }) => ({
  ...theme.salesleg.typography.body.sm.regular,
  color: theme.salesleg.palette.neutral[700],
  textTransform: "capitalize",
}));

const LocationIconText = styled.div(() => ({
  display: "flex",
  gap: 4,
}));

interface LocationHeaderProps {
  prospectAgeInDays: number;
  location: City;
}

const LocationHeader: FC<LocationHeaderProps> = ({
  prospectAgeInDays,
  location,
}) => {
  return (
    <RootContainer>
      <Field>
        <FieldName>Aging</FieldName>
        <FieldValue>
          {prospectAgeInDays === 1
            ? `${prospectAgeInDays} Day`
            : `${prospectAgeInDays} Days`}
        </FieldValue>
      </Field>

      <Field>
        <FieldName>City</FieldName>
        <LocationIconText>
          <MapPinIcon size={16} color={theme.palette.neutral[400]} />
          <FieldValue>{location}</FieldValue>
        </LocationIconText>
      </Field>
    </RootContainer>
  );
};

export default LocationHeader;
