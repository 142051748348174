import styled from "@emotion/styled";
import { FC } from "react";

interface InputLabelProps {
  label?: string | undefined;
  required: boolean;
}

const Label = styled.div(({ theme }) => ({
  ...theme.salesleg.typography.paragraph.sm.medium,
  color: theme.salesleg.palette.neutral[700],
  textTransform: "capitalize",
  gap: 3,
  display: "flex",
}));

const RequiredStyle = styled.span(() => ({
  color: "Red",
}));

const InputLabel: FC<InputLabelProps> = ({ label, required }) => {
  return (
    <Label>
      {label}
      {required && <RequiredStyle>*</RequiredStyle>}
    </Label>
  );
};

export default InputLabel;
