import { FC } from "react";
import Badge from "../../components/Badge";

interface StatusCellProps {
  status: string | undefined;
}

const StatusCell: FC<StatusCellProps> = ({ status }) => {
  return (
    <>
      {status === undefined || status === "active" ? (
        <Badge color="success" label="Active" size="sm" />
      ) : (
        <Badge color="error" label="Inactive" size="sm" />
      )}
    </>
  );
};

export default StatusCell;
