import styled from "@emotion/styled";
import { useEffect, useRef, useState } from "react";
import { StyledFC } from "../types/styledFC";
const OliviaRhyeImg = require("../assets/olivia-rhye.png");

export interface AvatarProps {
  size: number;
  isActive?: boolean | undefined;
}

const Dot = styled.div(({ theme }) => ({
  height: 10,
  width: 10,
  borderRadius: 5,
  background: theme.salesleg.palette.success[500],
}));

const Avatar: StyledFC<AvatarProps> = ({ size, isActive }) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const [coordinates, setCordinates] = useState<DOMRect>();

  useEffect(() => {
    const rect = ref.current?.getBoundingClientRect();
    if (rect === undefined) return;
    setCordinates(rect);
  }, []);

  return (
    <div style={{ display: "flex", gap: 8, alignItems: "center" }} ref={ref}>
      <img
        src={OliviaRhyeImg}
        height={size}
        width={size}
        style={{ borderRadius: size / 2 }}
        alt="Project Owner Pic"
      />
      {isActive && coordinates && (
        <div
          style={{
            position: "absolute",
            width: coordinates.width,
            height: coordinates.height,
          }}
        >
          <div
            style={{
              position: "relative",
              width: 0,
              left: coordinates.width - 8,
              top: coordinates.height - 8,
            }}
          >
            <Dot />
          </div>
        </div>
      )}
    </div>
  );
};

export default Avatar;
