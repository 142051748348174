import { LeadStage } from "@hshrimal/salesleg";
import { ClientStatusDisplayMap } from "../utils/clientStatusDisplayHelper";
import Badge, { BadgeColor } from "./Badge";

interface ClientStageBadgeProps {
  stage: LeadStage;
}

function getBadgeColor(stage: LeadStage): BadgeColor {
  switch (stage) {
    case "new":
      return "neutral";
    case "contacted":
      return "violet";
    case "interested":
      return "primary";
    case "visit_planned":
      return "rose";
    case "visit_done":
      return "rose";
    case "negotiation":
      return "orange";
    case "booking":
      return "success";
    case "dump":
      return "error";
    default:
      throw new Error("Unrecognized lead stage value");
  }
}

const ClientStageBadge = ({ stage: status }: ClientStageBadgeProps) => {
  return (
    <Badge
      label={ClientStatusDisplayMap.get(status) || ""}
      color={getBadgeColor(status)}
      size="sm"
    />
  );
};

export default ClientStageBadge;
