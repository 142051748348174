import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  deserializeDevelopersWithMetadata,
  Developer,
  WithMetadata,
} from "@hshrimal/salesleg";

export const developersApi = createApi({
  reducerPath: "developersApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_SALESLEG_API_BASE_URL}/api`,
    prepareHeaders: (headers) => headers.append("client", "web"),
    credentials: "include",
  }),
  tagTypes: ["developers"],
  endpoints: (builder) => ({
    getDevelopers: builder.query<WithMetadata<Developer>[], void>({
      query: () => ({ url: "/developers" }),
      transformResponse: (data) => deserializeDevelopersWithMetadata(data),
      providesTags: ["developers"],
    }),
    createDeveloper: builder.mutation<void, { name: string }>({
      query: (input) => ({
        url: `/developers`,
        method: "POST",
        body: input,
      }),
      invalidatesTags: ["developers"],
    }),
  }),
});

export const { useGetDevelopersQuery, useCreateDeveloperMutation } =
  developersApi;
