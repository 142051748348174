import { FC } from "react";
import styled from "styled-components";
import PhoneCallIcon from "../../components/icons/PhoneCallIcon";
import theme from "../../theme/light/theme";
interface ClientPhoneNumberCellProps {
  phoneNumber?: string | undefined;
}
const Container = styled.div(() => ({
  ...theme.typography.body.sm.regular,
  display: "flex",
  flexDirection: "column",
  gap: 8,
}));

const ContactContainer = styled.div(() => ({
  display: "flex",
  gap: 4,
}));

const Phone = styled.div(() => ({
  color: theme.palette.neutral[400],
}));

const ClientPhoneNumberCell: FC<ClientPhoneNumberCellProps> = ({
  phoneNumber,
}) => {
  return (
    <Container>
      <ContactContainer>
        <PhoneCallIcon color={theme.palette.neutral[400]} size={16} />
        <Phone>{phoneNumber}</Phone>
      </ContactContainer>
    </Container>
  );
};

export default ClientPhoneNumberCell;
