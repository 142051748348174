import styled from "@emotion/styled";
import Logo from "../../components/Logo";

const RootContainer = styled.div({
  padding: "20px 56px",
  display: "flex",
  alignItems: "center",
});

const BrandContainer = styled.div({ display: "flex", gap: 12 });

const BrandName = styled.span({
  fontFamily: "PlusJakartaSans",
  fontWeight: 600,
  lineHeight: "30px",
  color: "#3B4A6B",
  fontSize: 20,
  display: "inline-flex",
  alignItems: "center",
});

export function Header() {
  return (
    <RootContainer>
      <BrandContainer>
        <Logo size={40} colored={true} />
        <BrandName>SalesLeg</BrandName>
      </BrandContainer>
    </RootContainer>
  );
}
