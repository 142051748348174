import styled from "@emotion/styled";
import { useEffect, useRef, useState } from "react";
import Button from "../../components/Button/Button";
import { FilterButton } from "../../components/FilterButton";
import { Menu } from "../../components/Menu";
import { MenuItem } from "../../components/Menu/MenuItem";
import { ProgressIndicator } from "../../components/ProgressIndicator";
import { useNavigateWithTeam } from "../../hooks/useNavigationWithTeam";
import {
  useGetTenantEmployeesQuery,
  useGetTenantsQuery,
} from "../../redux/features/api/tenants";
import { loginAs } from "../../redux/features/auth";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import theme from "../../theme/light/theme";
import { defined } from "../../utils/assert";

const RootContainer = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: 30,
  gap: 20,
  flex: 1,
}));

const FilterContainer = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: 10,
}));

const HedingContainer = styled.div(() => ({
  ...theme.typography.heading.sm.medium,
  display: "flex",
  flexDirection: "row",
  gap: 2,
}));

const LoginAs = () => {
  const navigate = useNavigateWithTeam();
  const [dirty, setDirty] = useState(false);
  const { loginAsPending, loginAsError, loginAsUser } = useAppSelector(
    (state) => ({
      loginAsPending: state.auth.loginAsPending,
      loginAsFailed: state.auth.loginAsFailed,
      loginAsError: state.auth.loginAsError,
      loginAsUser: state.auth.loginAsUser,
    })
  );
  const [tenantId, setTenantId] = useState<string>("");
  const [userId, setUserId] = useState<string>("");
  const dispatch = useAppDispatch();

  const { data: tenants, isLoading: isLoadingTenants } = useGetTenantsQuery();

  const {
    data: tenantEmployees,
    isLoading: isLoadingTenantEmployees,
    isFetching: isFetchingTenantEmployees,
  } = useGetTenantEmployeesQuery(
    {
      tenantId: tenantId,
    },
    { skip: tenantId === "" }
  );

  useEffect(() => {
    if (dirty && !loginAsPending && loginAsUser) {
      navigate("/");
    }
  }, [dirty, loginAsPending, loginAsUser, navigate]);

  function handleLoginAs() {
    setDirty(true);
    dispatch(loginAs({ tenantId: defined(tenantId), userId: defined(userId) }));
  }

  const [tenantMenu, setTenantMenu] = useState<boolean>(false);
  const ref = useRef<HTMLButtonElement | null>(null);

  const [employeMenu, setEmployeMenu] = useState<boolean>(false);
  const refEmploye = useRef<HTMLButtonElement | null>(null);

  const selectedTenant =
    tenants && tenants.find((tenant) => tenant.id === tenantId);

  const selectedEmploye =
    tenantEmployees &&
    tenantEmployees.find((employee) => employee.id === userId);

  return (
    <>
      <RootContainer>
        <HedingContainer>
          <h1>
            {loginAsUser
              ? "Currently logged in as:"
              : "Not logged in as anyone"}
          </h1>
          {loginAsUser && (
            <h1>
              {`${loginAsUser.name} (Tenant: ${
                tenants?.find((it) => it.id === loginAsUser.tenantId)?.name ||
                "N/A"
              })`}
            </h1>
          )}
        </HedingContainer>
        <FilterContainer>
          <div>
            <FilterButton
              size="md"
              label={
                selectedTenant !== undefined
                  ? selectedTenant.name
                  : "Select Tenant"
              }
              onClick={() => {
                setTenantMenu((toggle) => !toggle);
              }}
              ref={ref}
              width={180}
            />
            <Menu
              open={tenantMenu}
              onClose={() => setTenantMenu(false)}
              enableSearch={true}
              anchorEl={ref.current || undefined}
              value={tenantId}
              onChange={(value) => {
                setTenantId(value as string);
              }}
              disableCloseOnSelection={false}
            >
              {tenants &&
                tenants.map((tenant) => (
                  <MenuItem
                    key={tenant.id}
                    value={tenant.id}
                    label={tenant.name}
                    variant="default"
                  />
                ))}
            </Menu>
          </div>
          <div>
            <FilterButton
              size="md"
              label={
                selectedEmploye !== undefined
                  ? selectedEmploye.name
                  : "Select Employe"
              }
              onClick={() => {
                setEmployeMenu((menuToggle) => !menuToggle);
              }}
              ref={refEmploye}
              width={180}
            />
            <Menu
              open={employeMenu}
              onClose={() => setEmployeMenu(false)}
              enableSearch={true}
              anchorEl={refEmploye.current || undefined}
              value={userId}
              onChange={(value) => {
                setUserId(value as string);
              }}
            >
              {tenantEmployees &&
                tenantEmployees.map((emp) => (
                  <MenuItem
                    key={emp.id}
                    value={emp.id}
                    label={emp.name}
                    variant="default"
                  />
                ))}
            </Menu>
          </div>
          <Button
            size="lg"
            disabled={!tenantId || !userId}
            onClick={handleLoginAs}
            label="Login As"
            color="primary"
          />
        </FilterContainer>
      </RootContainer>
      {isLoadingTenants && <ProgressIndicator open />}
      {(isLoadingTenantEmployees || isFetchingTenantEmployees) && (
        <ProgressIndicator open />
      )}
      {loginAsPending && <ProgressIndicator open />}
      {loginAsError && (
        <div>Oh oh! Looks like there was an problem while logging in</div>
      )}
    </>
  );
};

export default LoginAs;
