import styled from "@emotion/styled";
import { FC, ReactNode } from "react";

interface ModalBodyProps {
  children: ReactNode;
}

const Container = styled.div(() => ({
  padding: "0px 24px",
  display: "flex",
  flexDirection: "column",
  gap: 20,
}));

const ModalBody: FC<ModalBodyProps> = ({ children }) => {
  return <Container>{children} </Container>;
};

export default ModalBody;
