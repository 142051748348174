import styled from "@emotion/styled";
import { TeamReference } from "@hshrimal/salesleg";
import Avatar from "../../components/Avatar";
import theme from "../../theme/light/theme";
import { StyledFC } from "../../types/styledFC";

const RootContainer = styled.div(() => ({
  width: 312,
  padding: "20px 12px 20px 20px",
  display: "flex",
  borderRadius: 6,
  cursor: "pointer",
  boxshadow: theme.shadow.xs,
  border: `1px solid ${theme.palette.neutral[100]}`,
  background: "#ffffff",
}));
const Content = styled.div(() => ({
  display: "flex",
  gap: 12,
  flexDirection: "column",
}));

const UserDetailContainer = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: 4,
}));
const TeamName = styled.p(() => ({
  color: theme.palette.neutral[800],
  ...theme.typography.body.sm.regular,
}));

const Location = styled.div(() => ({
  color: theme.palette.neutral[400],
  ...theme.typography.body.sm.regular,
  textTransform: "capitalize",
}));
const AvatarContainer = styled.div(() => ({
  display: "flex",
  gap: 8,
  alignItems: "center",
}));
const Name = styled.div(() => ({
  color: theme.palette.neutral[500],
  ...theme.typography.body.sm.regular,
}));
interface UserCardProps {
  team: TeamReference;
}

const UserCard: StyledFC<UserCardProps> = ({ team }) => {
  return (
    <RootContainer>
      <Content>
        <UserDetailContainer>
          <TeamName>{team.name}</TeamName>
          <Location>{team.city}</Location>
        </UserDetailContainer>
        <AvatarContainer>
          <Avatar size={24} />
          <Name>{team.owner.id}</Name>
        </AvatarContainer>
      </Content>
    </RootContainer>
  );
};

export default UserCard;
