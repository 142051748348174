import styled from "@emotion/styled";
import { CSSProperties, FC, ForwardedRef, forwardRef } from "react";
import { MergedTheme } from "../../types/emotion";
import { IconProps } from "../icons/types";

type IconButtonColor =
  | "primary"
  | "secondary"
  | "secondary-gray"
  | "tertiary-gray";

type ButtonSize = "sm" | "md" | "2xl";

// TODO: convert this to StyledFC component for style attribute
interface IconButtonProps {
  color: IconButtonColor;
  size: ButtonSize;
  icon: FC<IconProps>;
  onClick: () => void;
  disabled?: boolean | undefined;
  style?: CSSProperties | undefined;
}

function getDefaultColorStyle(
  theme: MergedTheme,
  color: IconButtonColor,
  error?: boolean | undefined
) {
  switch (color) {
    case "primary":
      return {
        backgroundColor: theme.salesleg.palette.primary[600],
        color: "#ffffff",
        boxShadow: theme.salesleg.shadow.xs,
        ":hover": {
          backgroundColor: theme.salesleg.palette.primary[700],
        },
        ":disabled": {
          backgroundColor: theme.salesleg.palette.primary[50],
        },
        ":focused": {
          boxShadow:
            "0px 0px 0px 4px #EBF1F8, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);",
        },
      };

    case "secondary":
      return {
        backgroundColor: theme.salesleg.palette.primary[50],
        color: theme.salesleg.palette.primary[900],
        boxShadow: theme.salesleg.shadow.xs,
        ":hover": {
          backgroundColor: theme.salesleg.palette.primary[100],
          color: theme.salesleg.palette.primary[900],
        },
        ":disabled": {
          backgroundColor: theme.salesleg.palette.primary[25],
          color: theme.salesleg.palette.neutral[100],
        },
        ":focused": {
          boxShadow:
            "0px 0px 0px 4px #EAFBFF, 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
        },
      };

    case "secondary-gray":
      return {
        backgroundColor: "#ffffff",
        border: `1px solid ${theme.salesleg.palette.neutral[100]}`,
        color: theme.salesleg.palette.neutral[700],
        boxShadow: theme.salesleg.shadow.xs,
        ":hover": {
          backgroundColor: theme.salesleg.palette.neutral[25],
          color: theme.salesleg.palette.neutral[800],
        },
        ":disabled": {
          border: `1px solid ${theme.salesleg.palette.neutral[50]}`,
          boxShadow:
            "0px 0px 0px 4px #F5F7F8, 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
          color: theme.salesleg.palette.neutral[100],
        },
      };

    case "tertiary-gray":
      return {
        backgroundColor: "inherit",
        color: theme.salesleg.palette.neutral[500],
        ":hover": {
          backgroundColor: theme.salesleg.palette.neutral[25],
          color: theme.salesleg.palette.neutral[600],
        },
        ":disabled": {
          color: theme.salesleg.palette.neutral[100],
        },
      };
  }
}

function getSizeStyle(size: ButtonSize) {
  switch (size) {
    case "sm":
      return {
        padding: 8,
        borderRadius: 4,
        svg: {
          height: 20,
          width: 20,
        },
      };
    case "md":
      return {
        padding: 10,
        borderRadius: 4,
        svg: {
          height: 20,
          width: 20,
        },
      };
    case "2xl":
      return {
        padding: 12,
        borderRadius: 8,
        svg: {
          height: 24,
          width: 24,
        },
      };
  }
}

export const RootContainer = styled.button<{
  color: IconButtonColor;
  size: ButtonSize;
}>(({ color, size, theme }) => ({
  display: "flex",
  alignItems: "center",
  ...getDefaultColorStyle(theme, color),
  ...getSizeStyle(size),
  cursor: "pointer",
}));

export type Ref = ForwardedRef<HTMLButtonElement>;

const IconButton = (
  { size, color, icon, onClick, disabled, style }: IconButtonProps,
  ref: Ref
) => {
  const Icon = icon;
  return (
    <RootContainer
      color={color}
      size={size}
      onClick={onClick}
      disabled={disabled}
      ref={ref}
      style={style}
    >
      <Icon color="inherit" />
    </RootContainer>
  );
};

export default forwardRef(IconButton);
