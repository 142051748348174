import styled from "@emotion/styled";
import { ExtendedLead, WithMetadata } from "@hshrimal/salesleg";
import { FC, useState } from "react";
import Badge from "../../components/Badge";
import Button from "../../components/Button/Button";
import ExportIcon from "../../components/icons/ExportIcon";
import PlusIcon from "../../components/icons/PlusIcon";
import { useLoggedInViewer } from "../../hooks/getLoggedInViewer";
import AddClient from "./AddClient";
import { downloadLeads } from "./utils/export";

const ClientsHeader = styled.div(() => ({
  backgroundColor: "#ffffff",
  padding: "24px 32px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const ClientTextGroup = styled.div(() => ({
  display: "flex",
  alignItems: "center",
  gap: 8,
}));

const ClientText = styled.div(({ theme }) => ({
  ...theme.salesleg.typography.heading.md.semiBold,
  color: theme.salesleg.palette.neutral[900],
}));

const ButtonGroup = styled.div(() => ({
  display: "flex",
  gap: 16,
}));
interface HeaderProps {
  leads: WithMetadata<ExtendedLead>[];
  count: number;
}

const Header: FC<HeaderProps> = ({ leads, count }) => {
  const viewer = useLoggedInViewer();

  const [open, setOpen] = useState<boolean>(false);

  const toggle = () => {
    setOpen((open) => !open);
  };
  return (
    <ClientsHeader>
      <ClientTextGroup>
        <ClientText>Leads</ClientText>
        <Badge size="sm" color="primary" label={count.toString()} />
      </ClientTextGroup>
      {(viewer.role === "ops" ||
        viewer.role === "marketing" ||
        viewer.role === "sales_head") && (
        <ButtonGroup>
          <Button
            size="sm"
            color="secondary-gray"
            onClick={() => {
              downloadLeads(leads);
            }}
            label="Export Clients"
            leadingIcon={ExportIcon}
          />
          <Button
            size="sm"
            color="primary"
            label="Add Client"
            leadingIcon={PlusIcon}
            onClick={toggle}
          />
          <AddClient open={open} toggle={toggle} />
        </ButtonGroup>
      )}
    </ClientsHeader>
  );
};

export default Header;
