import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { ExtendedProject, WithMetadata } from "@hshrimal/salesleg";
import IconButton from "../../components/Button/IconButton";
import ArrowRightIcon from "../../components/icons/ArrowRightIcon";
import { useNavigateWithTeam } from "../../hooks/useNavigationWithTeam";
import { useSelectedCity } from "../../hooks/useSelectedCity";
import { useGetProjectsQuery } from "../../redux/features/api/projects";
import { BgBlurSvg } from "./BgBlurSvg";
import { BgSvg } from "./BgSvg";
import { IconSquareFourSvg } from "./IconSquareFourSvg";
import { MaskGroupSvg } from "./MaskGroupSvg";

interface ProjectsCardProps {
  userId: string;
}

const RootContainer = styled.div(({ theme }) => ({
  width: 312,
  height: "100%",
  background: "white",
  borderRadius: 8,
  border: `solid 1px ${theme.salesleg.palette.neutral[50]}}`,
  boxShadow:
    "0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)",
}));

const Header = styled.div(({ theme }) => ({
  padding: "24px 20px 0px 20px",
  display: "flex",
  alignItems: "center",
  gap: 10,
}));

const IconContainer = styled.div(({ theme }) => ({
  height: 52,
  width: 52,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const Title = styled.span(({ theme }) => ({
  ...theme.salesleg.typography.heading.md.medium,
  color: theme.salesleg.palette.neutral[900],
}));

function ProjectCard({ project }: { project: WithMetadata<ExtendedProject> }) {
  const theme = useTheme();
  const navigate = useNavigateWithTeam();
  return (
    <div
      style={{
        padding: "0px 20px",
        display: "flex",
        gap: 16,
        alignItems: "center",
      }}
    >
      <span
        style={{
          flexGrow: 1,
          ...theme.salesleg.typography.body.sm.regular,
          color: theme.salesleg.palette.neutral[600],
        }}
      >
        {project.name}
      </span>
      <IconButton
        icon={ArrowRightIcon}
        onClick={() => {
          navigate(`/projects/${project.id}`);
        }}
        color="tertiary-gray"
        size="sm"
      />
    </div>
  );
}

const ProjectsContainer = styled.div(({ theme }) => ({
  height: "100%",
  overflowY: "scroll",
  zIndex: 1,
}));

export function ProjectsCard({ userId }: ProjectsCardProps) {
  const theme = useTheme();

  const selectedCity = useSelectedCity();
  const { data: projects, isLoading } = useGetProjectsQuery({
    city: selectedCity,
  });

  if (isLoading || !projects) {
    return <></>;
  }

  const filteredProjects = projects.filter(
    (project) =>
      project.distributionQueue.find(
        (item) => item.salesExecutive.id === userId
      ) !== undefined
  );

  return (
    <RootContainer
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        gap: 16,
      }}
    >
      <MaskGroupSvg style={{ position: "absolute", top: 0, left: 0 }} />
      <BgBlurSvg
        style={{ position: "absolute", top: 0, left: 0, borderRadius: 7 }}
      />
      <Header>
        <IconContainer style={{ position: "relative" }}>
          <BgSvg
            style={{ position: "absolute", top: 0, left: 0, zIndex: -1 }}
          />
          <IconSquareFourSvg
            size={24}
            color={theme.salesleg.palette.primary[600]}
          />
        </IconContainer>
        <Title>Projects</Title>
      </Header>
      <ProjectsContainer>
        {filteredProjects.map((project) => (
          <ProjectCard key={project.id} project={project} />
        ))}
      </ProjectsContainer>
    </RootContainer>
  );
}
