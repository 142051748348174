import styled from "@emotion/styled";
import { ExtendedEmployee, WithMetadata } from "@hshrimal/salesleg";
import { FC } from "react";
import Avatar from "../../components/Avatar";
import { UserRoleBadge } from "../../components/UserRoleBadge";
import SectionHeader from "./SectionHeader";

const Container = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 16,
}));

const FieldsContainer = styled.div(({ theme }) => ({
  width: 648,
  display: "flex",
  flexWrap: "wrap",
  alignContent: "flex-start",
  gap: 24,
}));

const EmployDetailContainer = styled.div(() => ({
  width: 312,
  height: 64,
}));

const Title = styled.div(({ theme }) => ({
  ...theme.salesleg.typography.paragraph.sm.medium,
  color: theme.salesleg.palette.neutral[700],
}));

const Value = styled.div(({ theme }) => ({
  ...theme.salesleg.typography.paragraph.md.regular,
  color: theme.salesleg.palette.neutral[500],
  paddingTop: 10,
  paddingRight: 14,
  paddingBottom: 10,
}));

const BadgeValue = styled.div(() => ({
  padding: "12px 0px",
}));

const ValueContainer = styled.div(() => ({
  display: "flex",
  gap: 8,
  padding: "10px 0px",
}));

const Name = styled.div(({ theme }) => ({
  ...theme.salesleg.typography.paragraph.md.regular,
  color: theme.salesleg.palette.neutral[700],
}));

interface EmploymentDetailsSectionProps {
  user: WithMetadata<ExtendedEmployee>;
  manager?: WithMetadata<ExtendedEmployee> | undefined;
}

const EmploymentDetailsSection: FC<EmploymentDetailsSectionProps> = ({
  user,
  manager,
}) => {
  return (
    <Container>
      <SectionHeader title="Employment Details" />
      <FieldsContainer>
        <EmployDetailContainer>
          <Title>Employee Code</Title>
          <Value>{user?.alias}</Value>
        </EmployDetailContainer>
        <EmployDetailContainer>
          <Title>Designation</Title>
          <Value>{user?.title}</Value>
        </EmployDetailContainer>
        <EmployDetailContainer>
          <Title>Role</Title>
          <BadgeValue>
            <UserRoleBadge role={user?.role} />
          </BadgeValue>
        </EmployDetailContainer>
        {(user.role === "sales_lead" || user.role === "sales_associate") && (
          <>
            <EmployDetailContainer>
              <Title>Team</Title>
              <ValueContainer>
                <Name>{user?.teams[0]?.name}</Name>
              </ValueContainer>
            </EmployDetailContainer>
            {manager?.name && (
              <EmployDetailContainer>
                <Title>Manager</Title>
                <ValueContainer>
                  <Avatar size={24} />
                  <Name>{manager?.name}</Name>
                </ValueContainer>
              </EmployDetailContainer>
            )}
          </>
        )}
      </FieldsContainer>
    </Container>
  );
};

export default EmploymentDetailsSection;
