import styled from "@emotion/styled";
import { ExtendedLead, WithMetadata } from "@hshrimal/salesleg";
import dayjs from "dayjs";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import LinkButton from "../../components/Button/LinkButton";
import ArrowLeftIcon from "../../components/icons/ArrowLeftIcon";
import CalendarIcon from "../../components/icons/CalenderIcon";
import EmailIcon from "../../components/icons/EmailIcon";
import GenderMaleIcon from "../../components/icons/GenderMaleIcon";
import PhoneIcon from "../../components/icons/PhoneIcon";
import theme from "../../theme/light/theme";
import ActionButton from "./ActionButton";
import ClientStagePipeline from "./ClientStagePipeline";
import DumpLeadStagePipeline from "./DumpLeadStagePipeline";
import LocationHeader from "./LocationHeader";
import ProjectInfo from "./ProjectInfo";

const RootContainer = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  paddingTop: 24,
  paddingLeft: 32,
  paddingRight: 32,
  paddingBottom: 16,
  gap: 8,
}));

const DetailsContainer = styled.div(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const ClientName = styled.div(({ theme }) => ({
  ...theme.salesleg.typography.heading.sm.medium,
  paddingTop: 10,
  paddingBottom: 10,
  paddingRight: 14,
}));

const InfoColumn = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: 8,
}));

const FieldValue = styled.div(({ theme }) => ({
  ...theme.salesleg.typography.body.sm.regular,
  color: theme.salesleg.palette.neutral[400],
}));

const IconTextContainer = styled.div(() => ({
  display: "flex",
  alignItems: "center",
  gap: 6,
}));

const InfoContainer = styled.div(() => ({
  display: "flex",
  gap: 24,
}));

const PipeLineContainer = styled.div((styled) => ({
  display: "flex",
  flexDirection: "column",
  gap: 12,
  borderBottom: `1px solid ${theme.palette.neutral[100]}`,
  paddingRight: 32,
  paddingLeft: 32,
  paddingBottom: 16,
}));

interface HeaderProps {
  lead: WithMetadata<ExtendedLead>;
}

const getProspectAge = (leadCreationTime: Date) => {
  const currentTime = dayjs();
  const creationTime = dayjs(leadCreationTime);
  return currentTime.diff(creationTime, "day");
};

const Header: FC<HeaderProps> = ({ lead }) => {
  const navigate = useNavigate();

  return (
    <>
      <RootContainer>
        <LinkButton
          size="sm"
          color="gray"
          label="Back"
          leadingIcon={ArrowLeftIcon}
          onClick={() => {
            navigate(-1);
          }}
          style={{ width: 61 }}
        />

        <DetailsContainer>
          <ClientName>{lead.client.name}</ClientName>
          <InfoContainer>
            <InfoColumn>
              <IconTextContainer>
                <CalendarIcon color={theme.palette.neutral[400]} size={16} />
                <FieldValue>-</FieldValue>
              </IconTextContainer>
              <IconTextContainer>
                <GenderMaleIcon color={theme.palette.neutral[400]} size={16} />
                <FieldValue>Male</FieldValue>
              </IconTextContainer>
            </InfoColumn>
            <InfoColumn>
              <IconTextContainer>
                <PhoneIcon color={theme.palette.neutral[400]} size={16} />
                <FieldValue>{lead.client.phone}</FieldValue>
              </IconTextContainer>
              <IconTextContainer>
                <EmailIcon color={theme.palette.neutral[400]} size={16} />
                <FieldValue>{lead.client.email}</FieldValue>
              </IconTextContainer>
            </InfoColumn>
            <ActionButton />
          </InfoContainer>
        </DetailsContainer>
        <LocationHeader
          prospectAgeInDays={getProspectAge(lead.created_at)}
          location={lead.project.city}
        />
      </RootContainer>
      <PipeLineContainer>
        {lead.stage !== "dump" && (
          <ClientStagePipeline activeStage={lead.stage} />
        )}
        {lead.stage === "dump" && (
          <DumpLeadStagePipeline
            lastActiveStage={
              lead.activities.completed.length > 1
                ? lead.activities.completed[
                    lead.activities.completed.length - 2
                  ].result
                : "new"
            }
          />
        )}
        <ProjectInfo
          projectName={lead.project.name}
          developerName={lead.developer.name}
          source={lead.source}
        />
      </PipeLineContainer>
    </>
  );
};

export default Header;
