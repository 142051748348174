import { LeadSources } from "@hshrimal/salesleg";
import { FilterUrlParams } from "../../constants/filters";
import { LeadSourceDisplayMap } from "../../utils/leadSourceDisplayHelper";
import { Filter } from "../Filter";
import { MenuItem } from "../Menu/MenuItem";
import UserIcon from "../icons/UserIcon";

const LeadSourceFilter = () => {
  return (
    <Filter
      icon={UserIcon}
      name="Lead Source"
      urlParamName={FilterUrlParams.LEAD_SOURCE}
      multiple={true}
    >
      {LeadSources.map((lead) => (
        <MenuItem
          key={lead}
          variant="default"
          value={lead}
          label={LeadSourceDisplayMap.get(lead)}
        />
      )) || []}
    </Filter>
  );
};

export default LeadSourceFilter;
