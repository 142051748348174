import { Role } from "@hshrimal/salesleg";
import dayjs from "dayjs";
import { FC } from "react";
import AddressBookIcon from "../components/icons/AddressBookIcon";
import BuildingIcon from "../components/icons/BuildingIcon";
import SquaresFourIcon from "../components/icons/SquaresFourIcon";
import TagIcon from "../components/icons/TagIcon";
import UserIcon from "../components/icons/UserIcon";
import UserListIcon from "../components/icons/UserListIcon";
import { IconProps } from "../components/icons/types";
import { FilterUrlParams } from "../constants/filters";

interface Route {}

interface TopLevelRoute extends Route {
  icon: FC<IconProps>;
  label: string;
  route: string;
  authorizedRoles: Role[];
}

const now = dayjs();
const startOfThisMonth = now.startOf("month");
const endofThisMonth = now.endOf("month").startOf("day").add(1, "day");

const startOfThisMonthStr = startOfThisMonth.toISOString();
const endOfThisMonthStr = endofThisMonth.toISOString();
const currentMonthDateRangeStr = startOfThisMonthStr + "_" + endOfThisMonthStr;

export const PrimaryRoutes: TopLevelRoute[] = [
  {
    route: `/clients?${FilterUrlParams.CREATION_DATE}=${currentMonthDateRangeStr}`,
    icon: AddressBookIcon,
    label: "Clients",
    authorizedRoles: [
      "business_head",
      "sales_head",
      "sales_lead",
      "sales_associate",
      "marketing",
      "ops",
    ],
  },
  {
    route: `/allleads?${FilterUrlParams.CREATION_DATE}=${currentMonthDateRangeStr}&group_by=manager`,
    icon: TagIcon,
    label: "Reports",
    authorizedRoles: [
      "business_head",
      "sales_head",
      "sales_lead",
      "sales_associate",
      "marketing",
      "ops",
    ],
  },
  {
    route: `/visits?${FilterUrlParams.VISIT_DATE}=${currentMonthDateRangeStr}`,
    icon: BuildingIcon,
    label: "Site Visits",
    authorizedRoles: [
      "business_head",
      "sales_head",
      "sales_lead",
      "sales_associate",
      "marketing",
      "ops",
    ],
  },
  {
    route: "/projects",
    icon: SquaresFourIcon,
    label: "Projects",
    authorizedRoles: ["marketing", "ops"],
  },
  {
    icon: UserListIcon,
    label: "Users",
    route: "/users",
    authorizedRoles: ["ops", "it"],
  },
  {
    route: `/loginas`,
    icon: UserIcon,
    label: "Login As",
    authorizedRoles: ["god"],
  },
];
