import { PaletteColor } from "../../types";

const color: PaletteColor = {
  25: "#EAFBFF",
  50: "#D0F4FF",
  100: "#ACEFFF",
  200: "#73E8FF",
  300: "#31D7FF",
  400: "#02B7FF",
  500: "#0091FF",
  600: "#0077FF",
  700: "#0063DB",
  800: "#0057AB",
  900: "#053B72",
};

export default color;
