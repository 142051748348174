import { Role } from "@hshrimal/salesleg";
import { FilterUrlParams } from "../../constants/filters";
import { RoleDisplayMap } from "../../utils/RoleDisplayHelper";
import { Filter } from "../Filter";
import { MenuItem } from "../Menu/MenuItem";
import RadioButtonIcon from "../icons/RadioButtonIcon";

const UsedRoles: Role[] = [
  "sales_head",
  "sales_lead",
  "sales_associate",
  "marketing",
  "ops",
];

const RoleFilter = () => {
  return (
    <Filter
      icon={RadioButtonIcon}
      name="Role"
      urlParamName={FilterUrlParams.ROLE}
      multiple={true}
    >
      {UsedRoles.map((role) => (
        <MenuItem
          key={role}
          variant="default"
          value={role}
          label={RoleDisplayMap.get(role)}
        />
      ))}
    </Filter>
  );
};

export default RoleFilter;
