import { FC } from "react";
import { ReactComponent as RightTriangleSvg } from "./../../assets/right-triangle.svg";

interface RightTriangleIconProps {
  color: string;
}

const RightTriangle: FC<RightTriangleIconProps> = ({ color }) => {
  return <RightTriangleSvg color={color} />;
};

export default RightTriangle;
