import { PaletteColor } from "../../types";

const color: PaletteColor = {
  25: "#F5F7F8",
  50: "#F1F5F9",
  100: "#E2E8F0",
  200: "#CBD5E1",
  300: "#94A3B8",
  400: "#64748B",
  500: "#475569",
  600: "#334155",
  700: "#1E293B",
  800: "#0F172A",
  900: "#020617",
};

export default color;
