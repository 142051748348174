import { useTheme } from "@emotion/react";
import Avatar from "../../components/Avatar";
import { StyledFC } from "../../types/styledFC";

interface UserManagerCellProps {
  manager?: string | undefined;
}

const UserManagerCell: StyledFC<UserManagerCellProps> = ({ manager }) => {
  const theme = useTheme();

  if (!manager) {
    return <></>;
  }

  return (
    <div style={{ display: "flex", alignItems: "center", gap: 12 }}>
      <Avatar size={40} />
      <div
        style={{
          ...theme.salesleg.typography.paragraph.sm.regular,
          color: theme.salesleg.palette.neutral[700],
        }}
      >
        {manager}
      </div>
    </div>
  );
};

export default UserManagerCell;
