import styled from "@emotion/styled";

const RootContainer = styled.div({ padding: "32px 56px" });

const CopyrightText = styled.span(({ theme }) => ({
  ...theme.salesleg.typography.paragraph.sm.regular,
}));

export function Footer() {
  return (
    <RootContainer>
      <CopyrightText>{"\u00A9 SalesLeg 2023"}</CopyrightText>
    </RootContainer>
  );
}
