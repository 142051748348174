import { LeadStage } from "@hshrimal/salesleg";

export const ClientStatusDisplayMap = new Map<LeadStage, string>([
  ["new", "New"],
  ["contacted", "Contacted"],
  ["interested", "Interested"],
  ["visit_planned", "Visit Planned"],
  ["visit_done", "Visit Done"],
  ["negotiation", "Negotiation"],
  ["booking", "Booking"],
  ["dump", "Dump"],
]);
