import { SiteVisit } from "@hshrimal/salesleg";
import capitalize from "capitalize";
import { LeadSourceDisplayMap } from "../../../utils/LeasesourceDisplayHelper";
import { downloadCsv } from "../../../utils/csvUtils";

function toCSV(visit: SiteVisit) {
  let visitStatus;
  if (!visit.status) {
    visitStatus = "Pending";
  } else if (visit.status === "rescheduled") {
    visitStatus = "Canceled";
  } else {
    visitStatus = capitalize(visit.status);
  }

  const leadAttributes = [
    visit.lead.client.name,
    visit.lead.client.phone,
    visit.date,
    visitStatus,
    visit.assignedTo.name,
    visit.teamLeader.name,
    `${visit.lead.developer.name} ${visit.lead.project.name}`,
    `"${visit.feedback || ""}"`,
    LeadSourceDisplayMap.get(visit.lead.source),
  ];
  return leadAttributes.join(",");
}

export function downloadSiteVisits(visits: SiteVisit[]) {
  const columnNames = [
    "Client Name,Client Phone Number,Visit Date,Visit Status,Executive,Team Leader,Project,Feedback,Lead Source",
  ];
  const headerRow = columnNames.join(",");
  const dataRows = visits.map((visit) => toCSV(visit));
  const csv = [headerRow, ...dataRows].join("\n");
  downloadCsv(csv, `Visits`);
}
