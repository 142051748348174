import { ExtendedLead, WithMetadata } from "@hshrimal/salesleg";
import { LeadStageDisplayMap } from "../../../utils/LeadStageDisplayHelper";
import { ConstructionStatusDisplayMap } from "../../../utils/constructionStatusDisplayHelper";
import { downloadCsv } from "../../../utils/csvUtils";
import { SKUDisplayMap } from "../../../utils/skuDisplayHelper";

function toCSV(lead: WithMetadata<ExtendedLead>) {
  const firstVisitDone = lead.activities.completed.find(
    (activity) =>
      activity.type === "site_visit" &&
      activity.scheduled_at !== undefined &&
      activity.siteVisitStatus === "completed"
  );

  const firstVisitPlanned = lead.activities.scheduled.find(
    (activity) =>
      activity.type === "site_visit" && activity.scheduled_at !== undefined
  );

  const leadAttributes = [
    lead.id,
    lead.created_at.toDateString(),
    lead.source,
    lead.teamLeader.name,
    lead.relationshipManager.name,
    lead.client.name,
    lead.client.email,
    lead.client.phone,
    lead.requirement?.budget,
    lead.requirement?.sku ? SKUDisplayMap.get(lead.requirement.sku) : "",
    lead.requirement?.location,
    lead.requirement?.constructionStatus
      ? ConstructionStatusDisplayMap.get(lead.requirement.constructionStatus)
      : "",
    `${lead.developer.name} ${lead.project.name}`,
    LeadStageDisplayMap.get(lead.stage),
    lead.activities.completed
      .find((activity) => activity.convertedToProspect === true)
      ?.completed_at.toDateString(),
    (firstVisitPlanned || firstVisitDone)?.scheduled_at?.toDateString(),
    firstVisitDone?.completed_at?.toDateString(),
    `"${
      lead.activities.completed.length > 0
        ? lead.activities.completed[lead.activities.completed.length - 1]
            .details
        : ""
    }"`,
    lead.nextFollowUp?.toDateString() || "",
    lead.marketer?.name || "",
  ];
  return leadAttributes.join(",");
}

export function downloadLeads(leads: WithMetadata<ExtendedLead>[]) {
  const columnNames = [
    "Id,Generated On,Source,TL,RM,Client Name,Client Email,Client Phone Number,Budget,SKU,Location,Construction Status,Project,Stage,Prospect Date,VP Date, VD Date,Feedback,Next Followup,Marketer",
  ];
  const headerRow = columnNames.join(",");
  const dataRows = leads.map((lead) => toCSV(lead));
  const csv = [headerRow, ...dataRows].join("\n");
  downloadCsv(csv, `Active_Leads`);
}
