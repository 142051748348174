import { City } from "@hshrimal/salesleg";
import { useTeamSelector } from "../context/teamContext";
import { defined } from "../utils/assert";
import { useLoggedInViewer } from "./getLoggedInViewer";

export function useSelectedCity(): City | undefined {
  const viewer = useLoggedInViewer();
  const { selectedTeamId } = useTeamSelector();

  if (selectedTeamId) {
    return defined(
      viewer.teams.find((team) => team.id === selectedTeamId)?.city
    );
  } else if (viewer.teams.length > 0) {
    return viewer.teams[0].city;
  }

  return undefined;
}
