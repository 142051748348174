import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { ExtendedProject, WithMetadata } from "@hshrimal/salesleg";
import capitalize from "capitalize";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import IconButton from "../../components/Button/IconButton";
import LinkButton from "../../components/Button/LinkButton";
import Divider from "../../components/SideBar/Divider";
import ArrowLeftIcon from "../../components/icons/ArrowLeftIcon";
import BuildingIcon from "../../components/icons/BuildingIcon";
import HouseLineIcon from "../../components/icons/HouseLineIcon";
import MapPinIcon from "../../components/icons/MapPinIcon";
import PencilSimpleIcon from "../../components/icons/PencilSimpleIcon";
import SimpleLinkIcon from "../../components/icons/SimpleLinkIcon";
import ThreeDotIcon from "../../components/icons/ThreeDotIcon";
import { useLoggedInViewer } from "../../hooks/getLoggedInViewer";
import { useNavigateWithTeam } from "../../hooks/useNavigationWithTeam";

const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div(() => ({
  paddingTop: 24,
  paddingLeft: 32,
  paddingRight: 32,
  backgroundColor: "#ffffff",
  display: "flex",
  flexDirection: "column",
  gap: 8,
}));

const ProjectContent = styled.div(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const ProjectName = styled.div(() => ({
  display: "flex",
  alignItems: "center",
  gap: 8,
}));

const HouseIconStyle = styled.div(({ theme }) => ({
  height: 32,
  width: 32,
  borderRadius: 16,
  backgroundColor: theme.salesleg.palette.neutral[50],
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const ProjectText = styled.div(({ theme }) => ({
  ...theme.salesleg.typography.heading.sm.medium,
  paddingTop: 10,
  paddingBottom: 10,
  paddingRight: 14,
}));

const LinkButtonGroup = styled.div(() => ({
  display: "flex",
  alignItems: "center",
  gap: 24,
}));

const LinkName = styled.div(({ theme }) => ({
  ...theme.salesleg.typography.paragraph.sm.medium,
  color: theme.salesleg.palette.neutral[400],
}));

const LinkGroup = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: 4,
}));

// const AvatarUserContainer = styled.div(() => ({
//   display: "flex",
//   gap: 10,
// }));

// const TextGroup = styled.div(() => ({
//   display: "flex",
//   flexDirection: "column",
//   gap: 2,
// }));

// const UserName = styled.div(({ theme }) => ({
//   ...theme.salesleg.typography.body.sm.medium,
// }));

// const SubText = styled.div(({ theme }) => ({
//   ...theme.salesleg.typography.body.xs.regular,
//   color: theme.salesleg.palette.neutral[400],
// }));

const ButtonGroup = styled.div(() => ({
  display: "flex",
  alignItems: "center",
  gap: 12,
}));

const IconTextGroup = styled.div(() => ({
  display: "flex",
  alignItems: "center",
  gap: 4,
  cursor: "pointer",
}));

const Text = styled.div(({ theme }) => ({
  ...theme.salesleg.typography.body.sm.regular,
  color: theme.salesleg.palette.neutral[700],
}));

const LocationContainer = styled.div(() => ({
  display: "flex",
  alignItems: "center",
  paddingTop: 4,
  paddingBottom: 8,
  gap: 24,
}));

interface HeaderProps {
  project: WithMetadata<ExtendedProject>;
}

const Header = ({ project }: HeaderProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const navigateWithTeam = useNavigateWithTeam();
  const viewer = useLoggedInViewer();

  const facebookUrl = `${process.env.REACT_APP_SALESLEG_API_BASE_URL}/api/projects/${project.id}/leads?source=facebook&tenantId=${viewer.tenantId}&marketerId=${viewer.id}`;
  const googleUrl = `${process.env.REACT_APP_SALESLEG_API_BASE_URL}/api/projects/${project.id}/leads?source=google&tenantId=${viewer.tenantId}&marketerId=${viewer.id}`;

  return (
    <RootContainer>
      <HeaderContainer>
        <LinkButton
          size="sm"
          color="gray"
          label="Back"
          leadingIcon={ArrowLeftIcon}
          onClick={() => {
            navigate(-1);
          }}
          style={{ width: 61 }}
        />

        <ProjectContent>
          <ProjectName>
            <HouseIconStyle>
              <HouseLineIcon
                size={16}
                color={theme.salesleg.palette.neutral[600]}
              />
            </HouseIconStyle>
            <ProjectText>{project.name}</ProjectText>
          </ProjectName>
          <LinkButtonGroup>
            <LinkGroup>
              <LinkName>Google</LinkName>
              <LinkButton
                color="primary"
                onClick={() => navigator.clipboard.writeText(googleUrl)}
                size="sm"
                leadingIcon={SimpleLinkIcon}
                label="Copy Link"
              />
            </LinkGroup>
            <LinkGroup>
              <LinkName>Facebook</LinkName>
              <LinkButton
                color="primary"
                onClick={() => navigator.clipboard.writeText(facebookUrl)}
                size="sm"
                leadingIcon={SimpleLinkIcon}
                label="Copy Link"
              />
            </LinkGroup>
            {/* <AvatarUserContainer>
              <Avatar size={32} />
              <TextGroup>
                <UserName>{user.name}</UserName>
                <SubText>Spartans A</SubText>
              </TextGroup>
            </AvatarUserContainer> */}
            <ButtonGroup>
              <Button
                color="secondary-gray"
                size="sm"
                label="Edit Project"
                leadingIcon={PencilSimpleIcon}
                onClick={() => {
                  navigateWithTeam(`/projects/${project.id}/update`);
                }}
              />
              <IconButton
                color="tertiary-gray"
                onClick={() => {}}
                size="sm"
                icon={ThreeDotIcon}
              />
            </ButtonGroup>
          </LinkButtonGroup>
        </ProjectContent>

        <LocationContainer>
          <IconTextGroup>
            <BuildingIcon
              color={theme.salesleg.palette.neutral[400]}
              size={16}
            />
            <Text>{project.developer.name}</Text>
          </IconTextGroup>
          <IconTextGroup>
            <MapPinIcon size={16} color={theme.salesleg.palette.neutral[400]} />
            <Text>{capitalize(project.city)}</Text>
          </IconTextGroup>
        </LocationContainer>
      </HeaderContainer>
      <Divider width="100%" color={theme.salesleg.palette.neutral[100]} />
    </RootContainer>
  );
};

export default Header;
