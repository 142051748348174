import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { MouseEvent } from "react";
import { MergedTheme } from "../types/emotion";
import { StyledFC } from "../types/styledFC";
import ClearIcon from "./icons/ClearIcon";

export type BadgeColor =
  | "primary"
  | "rose"
  | "neutral"
  | "pink"
  | "violet"
  | "orange"
  | "success"
  | "error"
  | "warning"
  | "gray";

interface BadgeProps {
  size: "sm" | "md" | "lg";
  color: BadgeColor;
  label: string;
  showCloseIcon?: boolean | undefined;
  onClose?: (e: MouseEvent) => void;
}

function getColorStyle(color: BadgeColor, theme: MergedTheme) {
  switch (color) {
    case "primary": {
      return {
        backgroundColor: theme.salesleg.palette.primary[50],
        color: theme.salesleg.palette.primary[700],
      };
    }

    case "rose": {
      return {
        backgroundColor: theme.salesleg.palette.custom.rose[50],
        color: theme.salesleg.palette.custom.rose[700],
      };
    }

    case "neutral": {
      return {
        backgroundColor: theme.salesleg.palette.neutral[50],
        color: theme.salesleg.palette.neutral[500],
      };
    }

    case "pink": {
      return {
        backgroundColor: theme.salesleg.palette.custom.pink[50],
        color: theme.salesleg.palette.custom.pink[700],
      };
    }

    case "violet": {
      return {
        backgroundColor: theme.salesleg.palette.custom.violet[25],
        color: theme.salesleg.palette.custom.violet[700],
      };
    }

    case "orange": {
      return {
        backgroundColor: theme.salesleg.palette.custom.orange[100],
        color: theme.salesleg.palette.custom.orange[700],
      };
    }

    case "success": {
      return {
        backgroundColor: theme.salesleg.palette.success[50],
        color: theme.salesleg.palette.success[700],
      };
    }

    case "error": {
      return {
        backgroundColor: theme.salesleg.palette.error[50],
        color: theme.salesleg.palette.error[700],
      };
    }

    case "warning": {
      return {
        backgroundColor: theme.salesleg.palette.warning[50],
        color: theme.salesleg.palette.warning[700],
      };
    }
    case "gray": {
      return {
        backgroundColor: theme.salesleg.palette.neutral[50],
        color: theme.salesleg.palette.neutral[500],
      };
    }
  }
}

function getSize(size: "sm" | "md" | "lg"): { height: number } {
  switch (size) {
    case "sm":
      return { height: 20 };
    case "md":
      return { height: 20 };
    case "lg":
      return { height: 24 };
  }
}

const BadgeText = styled.span(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  ...theme.salesleg.typography.body.xs.medium,
}));

const CloseIconWrapper = styled.span(() => ({
  display: "flex",
  alignItems: "center",
}));

const Badge: StyledFC<BadgeProps> = ({
  size,
  label,
  color,
  style,
  showCloseIcon = false,
  onClose,
}) => {
  const theme = useTheme();
  return (
    <div
      style={{
        ...style,
        borderRadius: 16,
        padding: "2px 8px",
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        ...getSize(size),
        ...getColorStyle(color, theme),
        minWidth: 40,
        gap: 5,
      }}
    >
      <BadgeText>{label}</BadgeText>
      {showCloseIcon && (
        <CloseIconWrapper onClick={onClose}>
          <ClearIcon color={theme.salesleg.palette.neutral[700]} size={12} />
        </CloseIconWrapper>
      )}
    </div>
  );
};

export default Badge;
