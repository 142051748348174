import { City } from "@hshrimal/salesleg";
import { createContext, useContext } from "react";
import { defined } from "../utils/assert";

interface BranchContextState {
  selectedBranch: City | undefined;
  setSelectedBranch: (value: City | undefined) => void;
}

export const BranchContext = createContext<BranchContextState | undefined>(
  undefined
);

export function useBranchSelector() {
  const context = defined(useContext(BranchContext));
  return context;
}
