import styled from "@emotion/styled";
import { FC, useRef, useState } from "react";
import { useUrlQueryParam } from "../../hooks/useUrlQueryParam";
import getShortcutPageNumbers from "../../utils/pagination";
import Button from "../Button/Button";
import { Menu } from "../Menu";
import { MenuItem } from "../Menu/MenuItem";
import ArrowLeftIcon from "../icons/ArrowLeftIcon";
import ArrowRightIcon from "../icons/ArrowRightIcon";
import DropdownOpenIcon from "../icons/DropdownOpenIcon";

const RootContainer = styled.div(({ theme }) => ({
  display: "flex",
  height: 56,
  backgroundColor: "#ffffff",
  alignItems: "center",
  boxShadow: theme.salesleg.shadow.sm,
  padding: "12px 32px",
  justifyContent: "space-between",
  borderTop: `1px solid ${theme.salesleg.palette.neutral[100]}`,
}));

const Text = styled.div<{ isActive?: boolean }>(({ theme, isActive }) => ({
  ...theme.salesleg.typography.paragraph.sm.regular,
  color: isActive
    ? theme.salesleg.palette.neutral[700]
    : theme.salesleg.palette.neutral[500],
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: 24,
  width: 24,
  padding: 4,
  borderRadius: isActive ? 4 : "none",
  backgroundColor: isActive ? theme.salesleg.palette.neutral[50] : "none",
  cursor: "pointer",
}));

const ShowPageContainer = styled.div(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: 8,
  ...theme.salesleg.typography.body.sm.regular,
  color: theme.salesleg.palette.neutral[500],
}));

const PageDropDown = styled.div(({ theme }) => ({
  ...theme.salesleg.typography.body.sm.semiBold,
  color: theme.salesleg.palette.neutral[500],
  display: "flex",
  gap: 4,
  cursor: "pointer",
}));

const PageNumberContainer = styled.div(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: 2,
}));

const PaginationContainer = styled.div(() => ({
  display: "flex",
  alignItems: "center",
}));

interface PaginationProps {
  numRecords: number;
}

const Pagination: FC<PaginationProps> = ({ numRecords }) => {
  const pageDropdownRef = useRef<HTMLDivElement | null>(null);
  const [pageDropDownMenuOpen, setPageDropDownMenuOpen] = useState(false);

  const [page, setPage] = useUrlQueryParam<string>("page", "1");
  const [pageSize, setPageSize] = useUrlQueryParam<string>("page_size", "10");

  const intPage = page ? parseInt(page) : 1;
  const intPageSize = pageSize ? parseInt(pageSize) : 10;

  let { leftPages, rightPages, lastPage } = getShortcutPageNumbers(
    numRecords,
    intPage,
    intPageSize,
    2
  );

  if (numRecords <= (intPage - 1) * intPageSize) {
    setPage("1");
  }

  return (
    <RootContainer>
      <ShowPageContainer>
        Items Per Page:
        <PageDropDown
          ref={pageDropdownRef}
          onClick={() => setPageDropDownMenuOpen((open) => !open)}
        >
          <div>{pageSize}</div>
          <DropdownOpenIcon color="black" size={16} />
        </PageDropDown>
        <Menu
          open={pageDropDownMenuOpen}
          onClose={() => setPageDropDownMenuOpen((open) => !open)}
          anchorEl={pageDropdownRef.current || undefined}
          onChange={(selectedPageSize) => {
            if (selectedPageSize) {
              setPageSize(
                Array.isArray(selectedPageSize)
                  ? selectedPageSize[0]
                  : (selectedPageSize as string)
              );
            }
          }}
          multiple={false}
          value={pageSize}
        >
          <MenuItem value="10" variant="default" label="10" />
          <MenuItem value="25" variant="default" label="25" />
          <MenuItem value="50" variant="default" label="50" />
          <MenuItem value="100" variant="default" label="100" />
          <MenuItem value="250" variant="default" label="250" />
          <MenuItem value="500" variant="default" label="500" />
          <MenuItem value="1000" variant="default" label="1000" />
        </Menu>
      </ShowPageContainer>
      <ShowPageContainer>
        {`Showing ${intPageSize * (intPage - 1) + 1} - ${Math.min(
          intPageSize * intPage,
          numRecords
        )} of ${numRecords} items`}
      </ShowPageContainer>
      <PaginationContainer>
        <Button
          color="tertiary-gray"
          onClick={() => {
            const prePage = (intPage - 1).toString();
            setPage(prePage);
          }}
          size="sm"
          label="Previous"
          leadingIcon={ArrowLeftIcon}
          disabled={intPage === 1}
        />
        <PageNumberContainer>
          {leftPages.map((i) => (
            <Text
              key={i}
              onClick={() => setPage(i.toString())}
              isActive={intPage === i}
            >
              {i}
            </Text>
          ))}

          {rightPages.length > 0 && (
            <>
              <Text>...</Text>
              {rightPages.map((i) => (
                <Text
                  key={i}
                  onClick={() => setPage(i.toString())}
                  isActive={intPage === i}
                >
                  {i}
                </Text>
              ))}
            </>
          )}
        </PageNumberContainer>
        <Button
          color="tertiary-gray"
          onClick={() => {
            const nextPage = (intPage + 1).toString();
            setPage(nextPage);
          }}
          size="sm"
          label="Next"
          trailingIcon={ArrowRightIcon}
          disabled={intPage === lastPage}
        />
      </PaginationContainer>
    </RootContainer>
  );
};
export default Pagination;
