import styled from "@emotion/styled";
import EmailIcon from "../../components/icons/EmailIcon";
import PhoneCallIcon from "../../components/icons/PhoneCallIcon";
import theme from "../../theme/light/theme";
import { StyledFC } from "../../types/styledFC";

interface ContactDetailsCellProps {
  contact: { phone: string; email?: string | undefined };
}

const Container = styled.div(({ theme }) => ({
  ...theme.salesleg.typography.body.sm.regular,
  display: "flex",
  flexDirection: "column",
  gap: 8,
}));

const ContactContainer = styled.div(() => ({
  display: "flex",
  gap: 4,
}));

const Phone = styled.div(({ theme }) => ({
  color: theme.salesleg.palette.neutral[400],
}));

const Email = styled.div(({ theme }) => ({
  color: theme.salesleg.palette.neutral[400],
}));

const ContactDetailsCell: StyledFC<ContactDetailsCellProps> = ({ contact }) => {
  return (
    <Container>
      <ContactContainer>
        <PhoneCallIcon color={theme.palette.neutral[400]} size={16} />
        <Phone>{contact.phone}</Phone>
      </ContactContainer>
      {contact.email && (
        <ContactContainer>
          <EmailIcon color={theme.palette.neutral[400]} size={16} />
          <Email>{contact.email}</Email>
        </ContactContainer>
      )}
    </Container>
  );
};

export default ContactDetailsCell;
