import styled from "@emotion/styled";
import { useRef, useState } from "react";
import Button from "../../components/Button/Button";
import IconButton from "../../components/Button/IconButton";
import { Menu } from "../../components/Menu";
import { MenuItem } from "../../components/Menu/MenuItem";
import CaretDownIcon from "../../components/icons/CaretDownIcon";
import EmailIcon from "../../components/icons/EmailIcon";
import MinusCircleIcon from "../../components/icons/MinusCircleIcon";
import PencilSimpleIcon from "../../components/icons/PencilSimpleIcon";
import ThreeDotIcon from "../../components/icons/ThreeDotIcon";
import Whatsapp from "../../components/images/Whatsapp";

const ButtonGroup = styled.div(() => ({
  display: "flex",
  alignItems: "center",
  gap: 12,
}));

const ActionButton = () => {
  const [open, setOpen] = useState(false);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const toggle = () => {
    setOpen((open) => !open);
  };

  return (
    <ButtonGroup>
      <Button
        color="error"
        size="sm"
        label="Dump Client"
        leadingIcon={MinusCircleIcon}
        onClick={() => {}}
        disabled={true}
      />

      <Button
        color="secondary-gray"
        size="sm"
        label="Edit Client"
        leadingIcon={PencilSimpleIcon}
        onClick={() => {}}
        disabled={true}
      />

      <Button
        color="primary"
        size="sm"
        label="Contact"
        trailingIcon={CaretDownIcon}
        onClick={() => {
          toggle();
        }}
        ref={buttonRef}
        disabled={true}
      />
      <Menu
        open={open}
        placement="bottom"
        anchorEl={buttonRef.current || undefined}
      >
        <MenuItem
          label="Whatsapp"
          variant="icon"
          iconComponent={Whatsapp}
          onClick={() => {}}
          disableCheckMark={true}
        />
        <MenuItem
          label="Mail Client"
          variant="icon"
          iconComponent={EmailIcon}
          onClick={() => {}}
          disableCheckMark={true}
        />
      </Menu>

      <IconButton
        color="tertiary-gray"
        size="sm"
        icon={ThreeDotIcon}
        onClick={() => {}}
        disabled={true}
      />
    </ButtonGroup>
  );
};

export default ActionButton;
