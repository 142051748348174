import { useRef, useState } from "react";
import { FilterButton } from "./FilterButton";
import { Menu } from "./Menu";
import { MenuItem } from "./Menu/MenuItem";
import FunnelIcon from "./icons/FunnelIcon";
import { IconProps } from "./icons/types";

export interface FilterSelectorMenuItems {
  name: string;
  icon: React.FC<IconProps>;
  subMenuComponent: React.FC<{
    open: boolean;
    onClose: (clickAway: boolean) => void;
    anchorEl?: HTMLElement | undefined;
    placement: "bottom" | "right";
    disableModal?: boolean | false;
    disableCloseOnSelection?: boolean | undefined;
  }>;
}

interface FilterSelectorMenuProps {
  filters: FilterSelectorMenuItems[];
}

export function FilterSelector({ filters }: FilterSelectorMenuProps) {
  const [openMainMenu, setOpenMainMenu] = useState(false);
  const [suppressMainMenuClose, setSuppressMainMenuClose] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const ref = useRef<HTMLButtonElement>(null);
  const filterMenuItemRefs = useRef<(HTMLButtonElement | null)[]>(
    new Array(filters.length)
  );
  const [selectedFilterName, setSelectedFilterName] = useState<
    string | undefined
  >();

  const selectedFilterIndex = filters.findIndex(
    (filter) => filter.name === selectedFilterName
  );
  const SelectedFilterSubMenu = filters[selectedFilterIndex]?.subMenuComponent;
  const selectedFilterElement = filterMenuItemRefs.current[selectedFilterIndex];

  function handleSubMenuClose(clickAway: boolean) {
    setOpenSubMenu(false);
    if (!clickAway) {
      setSuppressMainMenuClose(true);
    }
  }

  function handleMainMenuClose() {
    if (openSubMenu) {
      return;
    }

    if (suppressMainMenuClose) {
      setSuppressMainMenuClose(false);
      return;
    }

    setOpenMainMenu(false);
    setSelectedFilterName(undefined);
    setSuppressMainMenuClose(false);
  }

  return (
    <>
      <FilterButton
        leadingIcon={FunnelIcon}
        size="sm"
        onClick={() => {
          setOpenMainMenu((openMainMenu) => !openMainMenu);
          setSelectedFilterName(undefined);
        }}
        ref={ref}
      />
      <Menu
        open={openMainMenu}
        onClose={handleMainMenuClose}
        anchorEl={ref.current || undefined}
        placement="bottom"
        value={selectedFilterName}
        disableCloseOnSelection={true}
        menuType="action"
      >
        {filters.map((filter, index) => (
          <MenuItem
            key={filter.name}
            value={filter.name}
            variant="icon"
            iconComponent={filter.icon}
            label={filter.name}
            disableCheckMark
            ref={(element) => {
              filterMenuItemRefs.current[index] = element;
            }}
            onClick={() => {
              setSelectedFilterName((selectedFilterName) =>
                selectedFilterName === filter.name ? undefined : filter.name
              );
              setOpenSubMenu(true);
            }}
          />
        ))}
      </Menu>
      {SelectedFilterSubMenu && (
        <SelectedFilterSubMenu
          open={openSubMenu}
          onClose={handleSubMenuClose}
          anchorEl={selectedFilterElement || undefined}
          placement="right"
          disableCloseOnSelection={true}
        />
      )}
    </>
  );
}
