import styled from "@emotion/styled";
import { LeadStage } from "@hshrimal/salesleg";
import { FC } from "react";
import { ClientStatusDisplayMap } from "../../utils/clientStatusDisplayHelper";
import Stage from "./Stage";

interface ClientStagePipelineProps {
  lastActiveStage: LeadStage;
}

const stages: LeadStage[] = [
  "new",
  "contacted",
  "interested",
  "visit_planned",
  "visit_done",
  "negotiation",
  "booking",
];

const RootContainer = styled.div(() => ({
  display: "flex",
}));

const DumpLeadStagePipeline: FC<ClientStagePipelineProps> = ({
  lastActiveStage,
}) => {
  const lastActiveStageIndex = stages.findIndex(
    (stage) => stage === lastActiveStage
  );

  return (
    <RootContainer>
      {stages.slice(0, lastActiveStageIndex + 1).map((stage, index) => {
        const isFirstStage = index === 0;
        const isCompleted = index <= lastActiveStageIndex;
        if (isCompleted) {
          return (
            <Stage
              key={index}
              isFirstStage={isFirstStage}
              isLastStage={false}
              isActive={isCompleted}
              label={ClientStatusDisplayMap.get(stage) || ""}
            />
          );
        }
        return null;
      })}

      <Stage
        isFirstStage={false}
        isLastStage={true}
        isActive={true}
        label={ClientStatusDisplayMap.get("dump") || ""}
        isDump={true}
      />
    </RootContainer>
  );
};

export default DumpLeadStagePipeline;
