import styled from "@emotion/styled";
import { ActivityType, ExtendedLead, WithMetadata } from "@hshrimal/salesleg";
import dayjs from "dayjs";
import { FC } from "react";
import IconButton from "../../components/Button/IconButton";
import CalendarIcon from "../../components/icons/CalenderIcon";
import CheckIcon from "../../components/icons/CheckIcon";
import ClockClockwiseIcon from "../../components/icons/ClockClockwiseIcon";
import EmailIcon from "../../components/icons/EmailIcon";
import PhoneCallIcon from "../../components/icons/PhoneCallIcon";
import theme from "../../theme/light/theme";

const RootContainer = styled.div(({ theme }) => ({
  backgroundColor: theme.salesleg.palette.neutral[100],
  borderTop: `1px solid ${theme.salesleg.palette.neutral[300]}`,
  borderBottom: `1px solid ${theme.salesleg.palette.neutral[300]}`,
  display: "flex",
  alignItems: "center",
  paddingTop: 16,
  paddingBottom: 16,
  paddingLeft: 24,
  paddingRight: 32,
  justifyContent: "space-between",
}));

const IconContainer = styled.div(({ theme }) => ({
  width: 40,
  height: 40,
  borderRadius: 20,
  backgroundColor: theme.salesleg.palette.neutral[200],
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const IconTextContainer = styled.div(() => ({
  display: "flex",
  gap: 12,
}));

const Container = styled.div(() => ({
  display: "flex",
  alignItems: "center",
}));

const TextContainer = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: 4,
}));

const Title = styled.div(({ theme }) => ({
  ...theme.salesleg.typography.paragraph.sm.regular,
  color: theme.salesleg.palette.neutral[700],
}));

const SubTitleContainer = styled.div(() => ({
  display: "flex",
  alignItems: "center",
  gap: 4,
}));

const SubTitle = styled.div(({ theme }) => ({
  ...theme.salesleg.typography.body.xs.regular,
  color: theme.salesleg.palette.neutral[600],
}));

const NextFollowupText = styled.div(({ theme }) => ({
  position: "absolute",
  ...theme.salesleg.typography.caption.sm.medium,
  borderRadius: 18,
  backgroundColor: theme.salesleg.palette.neutral[300],
  color: "#ffffff",
  padding: "2px 8px",
  top: -45,
  width: 115,
  textTransform: "uppercase",
}));

const TagContainer = styled.div(() => ({
  position: "relative",
}));

const ButtonContainer = styled.div(() => ({
  display: "flex",
  gap: 12,
}));

interface ScheduledFooterProps {
  lead: WithMetadata<ExtendedLead>;
}

const getIcon = (type: ActivityType) => {
  switch (type) {
    case "call":
      return <PhoneCallIcon color={theme.palette.neutral[600]} size={24} />;
    case "email":
      return <EmailIcon color={theme.palette.neutral[600]} size={24} />;
    case "meeting":
      return <PhoneCallIcon color={theme.palette.neutral[600]} size={24} />;
    case "message":
      return <PhoneCallIcon color={theme.palette.neutral[600]} size={24} />;
    case "site_visit":
      return <PhoneCallIcon color={theme.palette.neutral[600]} size={24} />;
  }
};

const ScheduledFooter: FC<ScheduledFooterProps> = ({ lead }) => {
  const date = dayjs(lead.activities.scheduled[0].scheduled_at).format(
    "MMM DD, YYYY"
  );
  return (
    <>
      <RootContainer>
        <Container>
          <TagContainer>
            <NextFollowupText>Next Followup</NextFollowupText>
          </TagContainer>
          <IconTextContainer>
            <IconContainer>
              {getIcon(lead.activities.scheduled[0].type)}
            </IconContainer>

            <TextContainer>
              <Title>{lead.activities.scheduled[0].type}</Title>
              <SubTitleContainer>
                <CalendarIcon color={theme.palette.neutral[300]} size={16} />
                <SubTitle>{date}</SubTitle>
              </SubTitleContainer>
            </TextContainer>
          </IconTextContainer>
        </Container>
        <ButtonContainer>
          <IconButton
            color="secondary-gray"
            icon={ClockClockwiseIcon}
            onClick={() => {}}
            size="sm"
          />
          <IconButton
            color="primary"
            icon={CheckIcon}
            onClick={() => {}}
            size="sm"
          />
        </ButtonContainer>
      </RootContainer>
    </>
  );
};

export default ScheduledFooter;
