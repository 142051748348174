import styled from "@emotion/styled";
import dayjs from "dayjs";
import theme from "../../theme/light/theme";
import { StyledFC } from "../../types/styledFC";

const VisitDateStyle = styled.div(() => ({
  color: theme.palette.neutral[400],
}));

interface LeadDateCellProps {
  date: Date;
}
const LeadDateCell: StyledFC<LeadDateCellProps> = ({ date }) => {
  return (
    <div style={{}}>
      <VisitDateStyle>{dayjs(date).format("DD MMM YYYY")}</VisitDateStyle>
      <VisitDateStyle>{dayjs(date).format("HH:mm a")}</VisitDateStyle>
    </div>
  );
};

export default LeadDateCell;
