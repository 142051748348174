import { FC } from "react";
import styled from "styled-components";
import { Employee } from ".";
import { FilterUrlParams } from "../../constants/filters";
import { useNavigateWithTeam } from "../../hooks/useNavigationWithTeam";
import { useUrlQueryParam } from "../../hooks/useUrlQueryParam";
import { getLeadsFilterUrl } from "../../utils/urlHelper";

interface ProductivityCellProps {
  count: number;
  employee?: Employee | undefined;
  isRoot?: boolean | undefined;
}

const RootContainer = styled.div(() => ({
  cursor: "pointer",
}));
const ProductivityCell: FC<ProductivityCellProps> = ({ count, employee, isRoot }) => {
  const navigate = useNavigateWithTeam();
  const [prospectDate] = useUrlQueryParam(FilterUrlParams.CREATION_DATE);
  const handleClick = () => {
    const queryString = getLeadsFilterUrl(
      undefined,
      [],
      undefined,
      employee,
      undefined,
      undefined,
      isRoot,
      prospectDate
    );
    navigate(`/clients?${queryString}`);
  };return <RootContainer onClick={handleClick}>{count}</RootContainer>;
};

export default ProductivityCell;
