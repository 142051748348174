import styled from "@emotion/styled";
import { FC, useContext } from "react";
import Button from "../../components/Button/Button";
import ExportIcon from "../../components/icons/ExportIcon";
import { FilterUrlParams } from "../../constants/filters";
import { useUrlQueryParam } from "../../hooks/useUrlQueryParam";
import { DownloadContext } from "./context/DownloadContext";
import { downloadActiveLeads } from "./utils/ActiveLeads";
import { downloadAllLeads } from "./utils/AllLeads";
import { downloadAllLeadsManager } from "./utils/AllLeadsManager";
import { downloadFolloup } from "./utils/Followup";
import { downloadProductivity } from "./utils/Productivity";

const LeadHeader = styled.div(() => ({
  backgroundColor: "#ffffff",
  padding: "24px 32px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const Text = styled.div(({ theme }) => ({
  ...theme.salesleg.typography.heading.md.semiBold,
  color: theme.salesleg.palette.neutral[900],
}));

interface HeaderProps {
  activeTab: string | undefined;
}

const Header: FC<HeaderProps> = ({ activeTab }) => {
  const downloadCtx = useContext(DownloadContext);
  const [groupBy] = useUrlQueryParam(FilterUrlParams.GROUP_BY);

  const onDownload = () => {
    if (!downloadCtx) return;
    if (activeTab === "1") {
      if (groupBy === "project") {
        const combine = [...downloadCtx.allLeadByProjectRows];
        if (downloadCtx.totalAllLeadByProjectRow)
          combine.push(downloadCtx.totalAllLeadByProjectRow);
        downloadAllLeads(combine);
      } else if (groupBy === "manager") {
        const combine = [...downloadCtx.allLeadByManagerRows];
        if (downloadCtx.totalAllLeadByManagerRow)
          combine.push(downloadCtx.totalAllLeadByManagerRow);
        downloadAllLeadsManager(combine);
      }
    } else if (activeTab === "2") {
      const combine = [...downloadCtx.activeLeadRows];
      if (downloadCtx.totalActiveLeadRow)
        combine.push(downloadCtx.totalActiveLeadRow);
      downloadActiveLeads(combine);
    } else if (activeTab === "3") {
      const combine = [...downloadCtx.followUpSummaryRows];
      if (downloadCtx.totalFollowUpSummaryRow)
        combine.push(downloadCtx.totalFollowUpSummaryRow);
      downloadFolloup(combine);
    } else if (activeTab === "4") {
      const combine = [...downloadCtx.productivityReportRows];
      if (downloadCtx.totalProductivityReportRow)
        combine.push(downloadCtx.totalProductivityReportRow);
      downloadProductivity(combine);
    }
  };

  return (
    <LeadHeader>
      <Text>Reports</Text>
      <Button
        size="sm"
        color="secondary-gray"
        onClick={onDownload}
        label="Export"
        leadingIcon={ExportIcon}
      />
    </LeadHeader>
  );
};

export default Header;
