import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { StyledFC } from "../../types/styledFC";
import Badge from "../Badge";
import { IconProps } from "../icons/types";

interface GridTabProps {
  icon: React.FC<IconProps> | undefined;
  title: string;
  badgeCount: string | undefined;
  isActive: boolean;
  onClick: () => void;
  id: string;
}

const Container = styled.div<{ isActive: boolean }>(({ isActive, theme }) => ({
  display: "flex",
  padding: "16px 8px",
  gap: 8,
  alignItems: "center",
  cursor: "pointer",
  ...(isActive && {
    borderBottom: `2px solid ${theme.salesleg.palette.primary[600]}`,
  }),
  color: isActive
    ? theme.salesleg.palette.primary[600]
    : theme.salesleg.palette.neutral[400],
}));

const Title = styled.span(({ theme }) => ({
  ...theme.salesleg.typography.body.sm.medium,
}));

const GridTab: StyledFC<GridTabProps> = ({
  icon,
  title,
  badgeCount,
  isActive,
  onClick,
}) => {
  const theme = useTheme();
  const Icon = icon;
  return (
    <Container isActive={isActive} onClick={onClick}>
      {Icon && (
        <Icon
          size={16}
          color={
            isActive
              ? theme.salesleg.palette.primary[600]
              : theme.salesleg.palette.neutral[400]
          }
        />
      )}
      <Title>{title}</Title>
      {badgeCount && (
        <Badge
          size="sm"
          color={isActive ? "primary" : "neutral"}
          label={badgeCount.toString()}
        />
      )}
    </Container>
  );
};

export default GridTab;
