import styled from "@emotion/styled";
import { ExtendedEmployee, WithMetadata } from "@hshrimal/salesleg";
import { FC } from "react";
import Avatar from "../../components/Avatar";
import Badge from "../../components/Badge";

const Card = styled.div<{ selected: boolean }>(({ selected, theme }) => ({
  width: 312,
  height: 120,
  paddingLeft: 20,
  paddingTop: 24,
  paddingRight: 12,
  paddingBottom: 24,
  display: "flex",
  gap: 16,
  backgroundColor: selected ? theme.salesleg.palette.primary[25] : "#ffffff",
  border: `1px solid ${
    selected
      ? theme.salesleg.palette.primary[500]
      : theme.salesleg.palette.neutral[50]
  }`,

  boxShadow: theme.salesleg.shadow.xs,
  borderRadius: 6,
  ":hover": {
    boxShadow: theme.salesleg.shadow.lg,
  },
}));

const AvatarUserContainer = styled.div(() => ({
  display: "flex",
  gap: 10,
  justifyContent: "center",
  alignItems: "center",
}));

const TextGroup = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: 2,
}));

const Text = styled.div(({ theme }) => ({
  ...theme.salesleg.typography.body.sm.medium,
}));

// const SubText = styled.div(({ theme }) => ({
//   ...theme.salesleg.typography.body.xs.regular,
//   color: theme.salesleg.palette.neutral[400],
// }));

const BadgeStyle = styled.div(() => ({
  display: "flex",
  alignItems: "center",
}));

const WeightGroup = styled.div(() => ({
  display: "flex",
  gap: 12,
}));

const WeightText = styled.div(({ theme }) => ({
  ...theme.salesleg.typography.body.sm.medium,
  color: theme.salesleg.palette.neutral[400],
}));

const WeightNumber = styled.div(({ theme }) => ({
  ...theme.salesleg.typography.body.sm.regular,
  color: theme.salesleg.palette.neutral[500],
}));

const UserInfoContainer = styled.div(({ theme }) => ({
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
  gap: 20,
}));

const AvatarContainer = styled.div(({ theme }) => ({
  display: "flex",
  gap: 6,
}));

interface UserCardProps {
  user: WithMetadata<ExtendedEmployee>;
  weight: number;
}

const UserCard: FC<UserCardProps> = ({ user, weight }: UserCardProps) => {
  // const [selected, setSelected] = useState(false);
  // const [isHovering, setIsHovering] = useState(false);

  return (
    <Card
      selected={false}
      // selected={selected}
      // onMouseOver={() => setIsHovering(true)}
      // onMouseOut={() => setIsHovering(false)}
    >
      {/* <CheckBox
        size="sm"
        checked={selected}
        onChange={(checked) => setSelected(checked)}
        style={{ marginTop: 10 }}
        visible={isHovering || selected}
      /> */}

      <UserInfoContainer>
        <AvatarContainer>
          <AvatarUserContainer>
            <Avatar size={32} />
            <TextGroup>
              <Text>{user.name}</Text>
              {/* Replace with team name */}
              {/* <SubText>Spartans A</SubText> */}
            </TextGroup>
          </AvatarUserContainer>
          <BadgeStyle>
            <Badge color="primary" size="sm" label="Sales Lead" />
          </BadgeStyle>
        </AvatarContainer>
        <WeightGroup>
          <WeightText>Weight</WeightText>
          <WeightNumber>{`${weight}x`}</WeightNumber>
        </WeightGroup>
      </UserInfoContainer>
      {/* {isHovering && !selected && (
        <IconButton
          icon={PencilSimpleIcon}
          color="tertiary-gray"
          onClick={() => {}}
          size="sm"
          style={{ height: 36, width: 36 }}
        />
      )} */}
    </Card>
  );
};

export default UserCard;
