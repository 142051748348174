import styled from "@emotion/styled";
import { FC } from "react";
import FileIcon from "../../components/icons/FileIcon";
import ThreeDotIcon from "../../components/icons/ThreeDotIcon";
import theme from "../../theme/light/theme";

const Card = styled.div(() => ({
  width: 312,
  padding: 20,
  display: "flex",
  gap: 16,
  borderRadius: 8,
  border: `1px solid ${theme.palette.neutral[100]}`,
  background: "#ffffff",
  boxShadow: theme.shadow.xl,
}));

const FileIconWrapper = styled.div(() => ({
  width: 32,
  height: 32,
  borderRadius: 16,
  padding: 8,
  backgroundColor: theme.palette.primary[25],
}));

const FileDetails = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: 4,
}));

const FileName = styled.div(() => ({
  ...theme.typography.body.sm.regular,
  color: theme.palette.neutral[700],
}));

const FileSize = styled.div(() => ({
  ...theme.typography.body.xs.regular,
  color: theme.palette.neutral[400],
}));

const Container = styled.div(() => ({
  display: "flex",
  flexGrow: 1,
  justifyContent: "space-between",
}));

const IconWrapper = styled.div(() => ({
  padding: "0px 8px 8px 8px",
  cursor: "pointer",
}));

interface AttachmentCardProps {
  fileName: string;
  fileSize: string;
}

const AttachmentCard: FC<AttachmentCardProps> = ({ fileName, fileSize }) => {
  return (
    <Card>
      <FileIconWrapper>
        <FileIcon color={theme.palette.primary[600]} size={16} />
      </FileIconWrapper>
      <Container>
        <FileDetails>
          <FileName>{fileName}</FileName>
          <FileSize>{fileSize}</FileSize>
        </FileDetails>
        <IconWrapper>
          <ThreeDotIcon color={theme.palette.neutral[500]} size={20} />
        </IconWrapper>
      </Container>
    </Card>
  );
};

export default AttachmentCard;
