import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { ExtendedEmployee, Project, WithMetadata } from "@hshrimal/salesleg";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { ErrorNotification } from "../../components/ErrorNotification";
import { ProgressIndicator } from "../../components/ProgressIndicator";
import Divider from "../../components/SideBar/Divider";
import { useNavigateWithTeam } from "../../hooks/useNavigationWithTeam";
import { useSelectedCity } from "../../hooks/useSelectedCity";
import { useGetEmployeesQuery } from "../../redux/features/api/employees";
import {
  useCreateProjectMutation,
  useGetProjectsQuery,
  useUpdateProjectMutation,
} from "../../redux/features/api/projects";
import { StyledFC } from "../../types/styledFC";
import AddUsers from "./AddUsers";
import CreateProjectHeader from "./CreateProjectHeader";
import SelectedUsers from "./SelectedUsers";

const RootContainer = styled.div({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
});

const HeaderContainer = styled.div(() => ({
  flex: "0 0 auto",
  height: 148,
  padding: "24px 32px",
  backgroundColor: "#ffffff",
  display: "flex",
  flexDirection: "column",
  gap: 8,
}));

const DetailContainer = styled.div(() => ({
  display: "flex",
  flexGrow: 1,
}));

export interface ProjectWithExtendedDistributionQueue extends Project {
  users: WithMetadata<ExtendedEmployee>[];
}

const CreateProject: StyledFC = () => {
  const [
    createProject,
    {
      isLoading: creationInProgress,
      status: creationStatus,
      error: createProjectError,
      data: createProjectResponse,
    },
  ] = useCreateProjectMutation();
  const [updateProject, { isLoading: updateInProgress, status: updateStatus }] =
    useUpdateProjectMutation();

  const projectId = useParams().projectId;
  const selectedCity = useSelectedCity();
  const { data: projects } = useGetProjectsQuery(
    { city: selectedCity },
    {
      skip: projectId === undefined,
    }
  );
  const { data: users } = useGetEmployeesQuery({});
  const form = useForm<ProjectWithExtendedDistributionQueue>({
    defaultValues: {
      distributionQueue: [],
      users: [],
    },
  });

  const navigate = useNavigateWithTeam();
  useEffect(() => {
    if (
      !creationInProgress &&
      creationStatus === "fulfilled" &&
      createProjectResponse
    ) {
      navigate(`/projects/${createProjectResponse.id}`);
    }
  }, [creationStatus, createProjectResponse, creationInProgress, navigate]);

  useEffect(() => {
    if (!updateInProgress && updateStatus === "fulfilled") {
      navigate(`/projects/${projectId}`);
    }
  }, [updateInProgress, updateStatus, navigate, projectId]);

  useEffect(() => {
    const project = projects?.find((project) => project.id === projectId);
    if (project && users) {
      const selectedUsers: WithMetadata<ExtendedEmployee>[] = [];
      project.distributionQueue.forEach((distRecord) => {
        const user = users.find(
          (user) => user.id === distRecord.salesExecutive.id
        );
        if (user) {
          selectedUsers.push(user);
        } else {
          console.log(`User ${distRecord.salesExecutive.name} not found`);
        }
      });

      form.setValue("name", project.name);
      form.setValue("developerId", project.developer.id);
      form.setValue("city", project.city);
      form.setValue("type", project.type);
      form.setValue("distributionQueue", project.distributionQueue);
      form.setValue("users", selectedUsers);
    }
  }, [form, projectId, projects, users]);

  const onSubmit = (values: ProjectWithExtendedDistributionQueue) => {
    const { users, ...projectData } = values;
    if (projectId) {
      updateProject({ id: projectId, ...projectData });
    } else {
      createProject(projectData);
    }
  };

  const theme = useTheme();
  return (
    <>
      <RootContainer>
        <FormProvider {...form}>
          <HeaderContainer>
            <CreateProjectHeader onSubmit={onSubmit} />
          </HeaderContainer>
          <Divider width="100%" color={theme.salesleg.palette.neutral[100]} />
          <DetailContainer>
            <AddUsers />
            <SelectedUsers />
          </DetailContainer>
        </FormProvider>
      </RootContainer>
      <ProgressIndicator open={updateInProgress || creationInProgress} />{" "}
      {createProjectError && (
        <ErrorNotification message={(createProjectError as any).data.message} />
      )}
    </>
  );
};

export default CreateProject;
