import { FC } from "react";
import DropDownV2, {
  DropDownItemParam,
} from "../../../components/DropDownV2/DropDownV2";
import { useGetDevelopersQuery } from "../../../redux/features/api/developers";
import AddDeveloperButton from "../AddDeveloperButton";

export interface IDeveloper {
  key: string;
  name: string;
}

interface SelectDeveloperProps {
  onChange: (developerId: string) => void;
  selectedDeveloperId: string;
}

const SelectDeveloper: FC<SelectDeveloperProps> = ({
  onChange,
  selectedDeveloperId,
}) => {
  const { data: developers } = useGetDevelopersQuery();

  if (!developers) return <></>;

  const onOptionChange = (value: string | string[] | undefined) => {
    onChange(value as string);
  };

  const dropDownDevelopers: DropDownItemParam[] = developers.map(
    (developer) => {
      return { value: developer.id, label: developer.name };
    }
  );

  return (
    <div>
      <DropDownV2
        width={351}
        height={44}
        items={dropDownDevelopers}
        placeholder="Select Developer"
        searchPlaceholder="Search Developer"
        quickAddComponent={<AddDeveloperButton />}
        onChange={(label) => onOptionChange(label)}
        enableSearch={true}
        value={selectedDeveloperId}
      />
    </div>
  );
};

export default SelectDeveloper;
