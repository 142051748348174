import { PaletteColor } from "../../types";

const color: PaletteColor = {
  25: "#FFF0F0",
  50: "#FFDEDE",
  100: "#FFC2C2",
  200: "#FF9898",
  300: "#FF5D5D",
  400: "#FF2A2A",
  500: "#F70B0B",
  600: "#E60505",
  700: "#AC0808",
  800: "#8E0E0E",
  900: "#4E0101",
};

export default color;
