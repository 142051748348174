import styled from "@emotion/styled";
import { ReactElement } from "react";
import { useLocation } from "react-router-dom";
import { useNavigateWithTeam } from "../hooks/useNavigationWithTeam";
import Badge from "./Badge";
import FilterChip from "./FilterChip";
import { FilterSelector, FilterSelectorMenuItems } from "./FilterSelector";
import { SearchInput } from "./SearchInput";
import VerticalDivider from "./VerticalDivider";

interface IDirectFilters {
  urlParamName: string;
  component: ReactElement;
  permanent: boolean;
}

interface FilterPanelProps {
  masterFilterMenuItems: FilterSelectorMenuItems[];
  directFilters: IDirectFilters[];
  searchable?: boolean | undefined;
}

const RootContainer = styled.div(({ theme }) => ({
  backgroundColor: theme.salesleg.palette.neutral[25],
  display: "flex",
  alignItems: "center",
  gap: 16,
  padding: "14px 32px",
}));

const FilterContainer = styled.div({
  flexGrow: 1,
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
  gap: 12,
});
const ClearButton = styled.div(() => ({
  cursor: "pointer",
}));

export function FilterPanel({
  masterFilterMenuItems,
  directFilters,
  searchable = true,
}: FilterPanelProps) {
  const location = useLocation();
  const navigate = useNavigateWithTeam();
  const directFilterComponents = directFilters.map((param) => {
    return (
      <FilterChip
        key={param.urlParamName}
        component={param.component}
        permanent={param.permanent}
        urlParamName={param.urlParamName}
      />
    );
  });

  const onClearAllClick = () => {
    const ignoreParams = ["tab", "group_by", "page", "page_size"];
    const existingSearchParams = new URLSearchParams(location.search);
    const searchParams = new URLSearchParams();
    for (let ignoreParam of ignoreParams) {
      if (existingSearchParams.has(ignoreParam)) {
        searchParams.append(
          ignoreParam,
          existingSearchParams.get(ignoreParam) || ""
        );
      }
    }
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  return (
    <RootContainer>
      {searchable && (
        <>
          <SearchInput /> <VerticalDivider width={1} height={16} />
        </>
      )}

      <FilterContainer>
        {masterFilterMenuItems.length > 0 && (
          <FilterSelector filters={masterFilterMenuItems} />
        )}
        {directFilterComponents}
        <ClearButton onClick={onClearAllClick}>
          <Badge color="gray" label="Clear" showCloseIcon={true} size="lg" />
        </ClearButton>
      </FilterContainer>
    </RootContainer>
  );
}
