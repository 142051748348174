import { FilterUrlParams } from "../../constants/filters";
import { FilterMenuProps } from "../FilterMenu";
import DateRangeMenu from "./DateRangeMenu";

const CreatedAtMenu = ({
  open,
  onClose,
  anchorEl,
  placement,
}: Omit<FilterMenuProps, "children" | "urlParamName">) => {
  return (
    <DateRangeMenu
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      placement={placement}
      urlParamName={FilterUrlParams.CREATION_DATE}
    />
  );
};
export default CreatedAtMenu;
