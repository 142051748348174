import { FollowUpSummary } from "@hshrimal/salesleg";
import { downloadCsv } from "../../../utils/csvUtils";

function toCSV(leadsQuality: FollowUpSummary) {
  const leadAttributes = [
    leadsQuality.employee.name,
    leadsQuality.new,
    leadsQuality.pastDue,
    leadsQuality.today,
    leadsQuality.tomorrow,
    leadsQuality.upcoming,
    leadsQuality.total,
  ];
  return leadAttributes.join(",");
}

export function downloadFolloup(leadsQuality: FollowUpSummary[]) {
  const columnNames = [
    "ASSIGNED TO,NEW,PAST DUE,TODAY,TOMORROW,UPCOMING,TOTAL",
  ];
  const headerRow = columnNames.join(",");
  const dataRows = leadsQuality.map((leads) => toCSV(leads));
  const csv = [headerRow, ...dataRows].join("\n");
  downloadCsv(csv, `Followup`);
}
