import { DateRange, ProductivityReportRequest } from "@hshrimal/salesleg";
import { useReportFilter } from "./useReportFilter";

export function useProductivityReportRequest(
  activitDateRange: DateRange,
  employeeId?: string | undefined
): ProductivityReportRequest {
  const reportFilter = useReportFilter(employeeId);
  const request: ProductivityReportRequest = {
    reportFilter,
    activityDate: activitDateRange,
  };

  return request;
}
