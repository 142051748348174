import { OrgFilter } from "@hshrimal/salesleg";
import { useBranchSelector } from "../../context/branchSelector";
import { useTeamSelector } from "../../context/teamContext";
import { useLoggedInViewer } from "../../hooks/getLoggedInViewer";

export function useOrgFilter(
  managerId?: string,
  assigneeId?: string
): OrgFilter {
  const orgFilter = useBaseOrgFilter();

  if (assigneeId) {
    orgFilter.employeeId = assigneeId;
  } else if (managerId) {
    orgFilter.managerId = managerId;
  }

  return orgFilter;
}

function useBaseOrgFilter() {
  const viewer = useLoggedInViewer();
  const { selectedTeamId } = useTeamSelector();
  const { selectedBranch } = useBranchSelector();
  switch (viewer.role) {
    case "business_head":
      return { city: selectedBranch };
    case "marketing":
    case "ops":
      return { teamId: selectedTeamId };
    case "sales_head":
      return { teamId: selectedTeamId, managerId: viewer.id };
    case "sales_lead":
      return { managerId: viewer.id };
    case "it":
      return {};
    default:
      return { employeeId: viewer.id };
  }
}
