import styled from "@emotion/styled";
import Badge from "../../components/Badge";
import Button from "../../components/Button/Button";
import { FilterPanel } from "../../components/FilterPanel";
import Pagination from "../../components/Pagination";
import { ProgressIndicator } from "../../components/ProgressIndicator";
import { DeveloperFilter } from "../../components/filters/DeveloperFilter";
import PlusIcon from "../../components/icons/PlusIcon";
import { FilterUrlParams } from "../../constants/filters";
import { useNavigateWithTeam } from "../../hooks/useNavigationWithTeam";
import { useSelectedCity } from "../../hooks/useSelectedCity";
import {
  useMultiSelectUrlQueryParam,
  useUrlQueryParam,
} from "../../hooks/useUrlQueryParam";
import { useGetProjectsQuery } from "../../redux/features/api/projects";
import { StyledFC } from "../../types/styledFC";
import ProjectCard from "./ProjectCard";

const RootContainer = styled.div<{ style: React.CSSProperties | undefined }>(
  ({ style }) => ({
    ...style,
    display: "flex",
    flexDirection: "column",
  })
);

const Header = styled.div({
  flex: "0 0 auto",
  height: 84,
  backgroundColor: "#ffffff",
  paddingLeft: 32,
  paddingRight: 32,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

const TitleContainer = styled.div({ display: "flex", alignItems: "center" });

const TitleText = styled.div(({ theme }) => ({
  ...theme.salesleg.typography.heading.md.semiBold,
  color: theme.salesleg.palette.neutral[600],
}));

const ProjectsCardGrid = styled.div(({ theme }) => ({
  backgroundColor: theme.salesleg.palette.neutral[25],
  flex: "1 0 0",
  display: "flex",
  flexWrap: "wrap",
  alignContent: "flex-start",
  rowGap: 24,
  columnGap: 24,
  padding: 32,
  overflowY: "auto",
}));

const ViewProjects: StyledFC = ({ style }) => {
  const navigate = useNavigateWithTeam();
  const selectedCity = useSelectedCity();
  const { data: projects, isFetching } = useGetProjectsQuery({
    city: selectedCity,
  });
  const [cityFilter] = useMultiSelectUrlQueryParam(FilterUrlParams.City);
  const [developerFilter] = useMultiSelectUrlQueryParam(
    FilterUrlParams.DEVELOPER
  );
  const [searchText] = useUrlQueryParam("search");
  const [page_size] = useUrlQueryParam("page_size", "10");
  const [page] = useUrlQueryParam("page", "1");

  let filteredProjects = projects?.filter(
    (project) =>
      (cityFilter.length === 0 || cityFilter.includes(project.city)) &&
      (developerFilter.length === 0 ||
        developerFilter.includes(project.developer.id)) &&
      (!searchText ||
        project.name.toLowerCase().includes(searchText.toLocaleLowerCase()))
  );

  let paginationProjects = filteredProjects;
  if (page_size && page) {
    const parsedPageSize = parseInt(page_size);

    const parsedPage = parseInt(page);
    const startRecordIndex = (parsedPage - 1) * parsedPageSize;
    const endRecordIndex = startRecordIndex + parsedPageSize;

    paginationProjects = filteredProjects?.slice(
      startRecordIndex,
      endRecordIndex
    );
  }

  return (
    <>
      <RootContainer style={style}>
        <Header>
          <TitleContainer>
            <TitleText>Projects</TitleText>
            <Badge
              style={{ marginLeft: 12 }}
              size="sm"
              color="primary"
              label={`${projects?.length || 0}`}
            />
          </TitleContainer>
          <Button
            size="sm"
            color="primary"
            label="Create Project"
            leadingIcon={PlusIcon}
            onClick={() => {
              navigate("/projects/create");
            }}
          />
        </Header>
        <FilterPanel
          masterFilterMenuItems={[]}
          directFilters={[
            {
              urlParamName: "developer_id",
              component: <DeveloperFilter key="developer" />,
              permanent: true,
            },
          ]}
        />
        {paginationProjects && (
          <ProjectsCardGrid>
            {paginationProjects?.map((project, i) => (
              <ProjectCard key={i} project={project} />
            ))}
          </ProjectsCardGrid>
        )}
        {filteredProjects && (
          <Pagination numRecords={filteredProjects?.length} />
        )}
      </RootContainer>

      <ProgressIndicator open={isFetching} />
    </>
  );
};

export default ViewProjects;
