import {
  GroupBy,
  LeadSource,
  LeadsQualityReportRequest,
} from "@hshrimal/salesleg";
import { useLeadFilter } from "../../ViewClients/useLeadFilter";
import { useReportFilter } from "./useReportFilter";

export function useLeadsQualityReportRequest(
  groupBy: GroupBy,
  employeeId?: string | undefined,
  createdAtStart?: Date | undefined,
  createdAtEnd?: Date | undefined,
  sources?: LeadSource[] | undefined
): LeadsQualityReportRequest {
  const reportFilter = useReportFilter(employeeId);
  const leadFilter = useLeadFilter(
    [],
    sources || [],
    [],
    createdAtStart,
    createdAtEnd
  );
  const request: LeadsQualityReportRequest = {
    groupBy,
    reportFilter,
    leadFilter,
  };

  return request;
}
