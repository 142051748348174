import styled from "@emotion/styled";
import { Cities, City, TeamReference } from "@hshrimal/salesleg";
import capitalize from "capitalize";
import { FC } from "react";
import { useLocation } from "react-router-dom";
import { useBranchSelector } from "../../context/branchSelector";
import { useTeamSelector } from "../../context/teamContext";
import {
  useLoggedInViewer,
  usePrincipleUser,
} from "../../hooks/getLoggedInViewer";
import { PrimaryRoutes } from "../../layout/routes";
import DropDownV2 from "../DropDownV2/DropDownV2";
import UserIcon from "../icons/UserIcon";
import LogoSection from "./LogoSection";
import Menu from "./Menu";
import UserProfileMenuItem from "./UserProfileMenuItem";

export interface ItemListParams {
  icon: FC<any>;
  label: string;
  route: string;
  disabled?: boolean | undefined;
}

const RootContainer = styled.div<{ open?: boolean }>(({ open, theme }) => ({
  display: "flex",
  gap: 30,
  padding: "4px 8px",
  alignItems: "center",
  backgroundColor: theme.salesleg.palette.neutral[900],
}));

interface ITeam {
  value: string;
  label: string;
}

const SideBar = () => {
  const viewer = useLoggedInViewer();

  const routesAvailableToViewer = PrimaryRoutes.filter((route) =>
    route.authorizedRoles.includes(viewer.role)
  );

  const principalUser = usePrincipleUser();
  if (principalUser.role === "god") {
    routesAvailableToViewer.push({
      route: `/loginas`,
      icon: UserIcon,
      label: "Login As",
      authorizedRoles: ["god"],
    });
  }
  const { selectedTeamId, setSelectedTeamId } = useTeamSelector();
  const { selectedBranch, setSelectedBranch } = useBranchSelector();

  const onTeamIdChange = (value: string | string[] | undefined) => {
    if (value !== undefined) setSelectedTeamId(value as string);
  };
  const onBranchChange = (value: City | undefined) => {
    if (value !== undefined) setSelectedBranch(value);
  };

  const teamOptions: ITeam[] = viewer.teams.map((team: TeamReference) => {
    return { value: team.id, label: team.name };
  });
  const branchOptions: ITeam[] = Cities.map((city) => {
    return { value: city, label: capitalize(city) };
  });
  const location = useLocation();

  return (
    <RootContainer>
      <div style={{ display: "flex", gap: 24, alignItems: "center" }}>
        <LogoSection />
        <Menu items={routesAvailableToViewer} />
      </div>
      <div
        style={{
          display: "flex",
          gap: 20,
          justifyContent: "flex-end",
          flex: 1,
        }}
      >
        {viewer.role === "business_head" &&
          (location.pathname === "/clients" ||
            location.pathname === "/allleads" ||
            location.pathname === "/allLeads" ||
            location.pathname === "/visits" ||
            location.pathname === "/projects" ||
            location.pathname === "/users") && (
            <DropDownV2
              width={248}
              height={44}
              enableSearch={false}
              items={branchOptions}
              onChange={(value) => onBranchChange(value as City)}
              value={selectedBranch}
              placeholder="Select Branch"
              themeColor="dark"
            />
          )}
        {viewer.role !== "business_head" &&
          viewer.teams.length > 1 &&
          (location.pathname === "/clients" ||
            location.pathname === "/allleads" ||
            location.pathname === "/allLeads" ||
            location.pathname === "/visits" ||
            location.pathname === "/projects" ||
            location.pathname === "/users") && (
            <DropDownV2
              width={248}
              height={44}
              enableSearch={false}
              items={teamOptions}
              onChange={(value) => onTeamIdChange(value)}
              value={selectedTeamId}
              placeholder="Select Team"
              themeColor="dark"
            />
          )}
        <UserProfileMenuItem />
      </div>
    </RootContainer>
  );
};

export default SideBar;
