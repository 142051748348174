import { PaletteColor } from "../../types";

const color: PaletteColor = {
  25: "#FBFEE8",
  50: "#F6FDC4",
  100: "#F3FC8C",
  200: "#F2FA4A",
  300: "#F4F619",
  400: "#E9E10C",
  500: "#C7B107",
  600: "#9E810A",
  700: "#836510",
  800: "#6F5214",
  900: "#412C07",
};

export default color;
