import { useTheme } from "@emotion/react";
import { useState } from "react";
import { useDebouncedCallback as useDebouncing } from "../hooks/useDebouncedCallback";
import { useUrlQueryParam } from "../hooks/useUrlQueryParam";
import InputField from "./InputField";
import SearchIcon from "./icons/SearchIcon";

export function SearchInput() {
  const [searchText, setSearchText] = useUrlQueryParam("search");
  const [inputText, setInputText] = useState<string | undefined>(searchText);
  const theme = useTheme();

  const debouncedSetSearchText = useDebouncing(setSearchText);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputText(event.target.value);
    debouncedSetSearchText(event.target.value);
  };

  return (
    <InputField
      startIcon={
        <SearchIcon size={24} color={theme.salesleg.palette.neutral[400]} />
      }
      height={36}
      width={214}
      value={inputText}
      onChange={handleChange}
      placeholder="Search"
    />
  );
}
