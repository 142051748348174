import styled from "@emotion/styled";
import { City } from "@hshrimal/salesleg";
import { useState } from "react";
import Button from "../../components/Button/Button";
import PlusIcon from "../../components/icons/PlusIcon";
import { useCreateTeamMutation } from "../../redux/features/api/teams";
import CreateTeamModal, { CreateTeamModalForm } from "./CreateTeamModal";

const ButtonGroup = styled.div(() => ({
  display: "flex",
  gap: 16,
}));

const CreateTeamButton = () => {
  const [open, setOpen] = useState(false);

  const [createTeam] = useCreateTeamMutation();

  const toggle = () => {
    setOpen((open) => !open);
  };

  return (
    <>
      <ButtonGroup>
        <Button
          size="sm"
          color="primary"
          label="Create Team"
          leadingIcon={PlusIcon}
          onClick={toggle}
        />
      </ButtonGroup>
      <CreateTeamModal
        open={open}
        toggle={toggle}
        onSubmit={(values: CreateTeamModalForm) => {
          createTeam({
            name: values.name,
            city: values.city as City,
            ownerId: values.ownerId,
          });
        }}
      />
    </>
  );
};

export default CreateTeamButton;
