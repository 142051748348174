import styled from "@emotion/styled";
import { ProgressIndicator } from "../../components/ProgressIndicator";
import { useAppSelector } from "../../redux/hooks";
import { Body } from "./Body";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { ScreenMockup } from "./ScreenMockup";

const RootContainer = styled.div({
  display: "flex",
  height: "100vh",
  flexDirection: "row",
  width: "100%",
  className: "root-login",
});

const LeftHalf = styled.div({
  flex: "1 0 0",
  display: "flex",
  flexDirection: "column",
});

const RightHalf = styled.div({
  flex: "1 0 0",
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  backgroundColor: "#F0D43B",
});

export function LoginPage() {
  const { loginPending } = useAppSelector((state) => state.auth);
  return (
    <>
      <RootContainer>
        <LeftHalf>
          <Header />
          <Body />
          <Footer />
        </LeftHalf>
        <RightHalf>
          <ScreenMockup />
        </RightHalf>
      </RootContainer>
      <ProgressIndicator open={loginPending} />
    </>
  );
}
