import { EmployeeFilter } from "@hshrimal/salesleg";
import { FilterUrlParams } from "../../constants/filters";
import { useOrgFilter } from "../../pages/ViewClients/useOrgFilter";
import { useGetEmployeesQuery } from "../../redux/features/api/employees";
import { Filter } from "../Filter";
import { MenuItem } from "../Menu/MenuItem";
import { ProgressIndicator } from "../ProgressIndicator";
import UserIcon from "../icons/UserIcon";

const ManagerFilter = () => {
  const orgFilter = useOrgFilter();
  const employeeFilter: EmployeeFilter = {
    ...orgFilter,
    role: ["sales_head", "sales_lead"],
    status: "active",
  };
  const { data: managers, isFetching } = useGetEmployeesQuery(employeeFilter);

  return (
    <>
      <Filter
        icon={UserIcon}
        name="Manager"
        urlParamName={FilterUrlParams.MANAGER}
        multiple={false}
        enableSearch
        searchPlaceholder="Search Manager"
      >
        {managers?.map((manager) => (
          <MenuItem
            key={manager.id}
            variant="default"
            value={manager.id}
            label={manager.name}
          />
        )) || []}
      </Filter>
      <ProgressIndicator open={isFetching} />
    </>
  );
};

export default ManagerFilter;
