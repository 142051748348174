import { LeadStage } from "@hshrimal/salesleg";
import { FilterUrlParams } from "../../constants/filters";
import { LeadStageDisplayMap } from "../../utils/LeadStageDisplayHelper";
import { Filter } from "../Filter";
import { MenuItem } from "../Menu/MenuItem";
import RadioButtonIcon from "../icons/RadioButtonIcon";

const Stages: LeadStage[] = [
  "new",
  "contacted",
  "interested",
  "visit_planned",
  "visit_done",
  "negotiation",
  "booking",
  "dump",
];

const StageFilter = () => {
  return (
    <Filter
      icon={RadioButtonIcon}
      name="Stage"
      urlParamName={FilterUrlParams.STAGE}
      multiple={true}
    >
      {Stages.map((stage) => (
        <MenuItem
          key={stage}
          variant="default"
          value={stage}
          label={LeadStageDisplayMap.get(stage)}
        />
      ))}
    </Filter>
  );
};

export default StageFilter;
