import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { ExtendedProject, WithMetadata } from "@hshrimal/salesleg";
import capitalize from "capitalize";
import HouseLineIcon from "../../components/icons/HouseLineIcon";
import MapPinIcon from "../../components/icons/MapPinIcon";
import { useNavigateWithTeam } from "../../hooks/useNavigationWithTeam";
import { StyledFC } from "../../types/styledFC";

const Card = styled.div<{ selected: boolean }>(({ selected, theme }) => ({
  width: 252,
  height: 122,
  paddingLeft: 20,
  paddingRight: 20,
  paddingTop: 24,
  display: "flex",
  gap: 16,
  backgroundColor: selected ? theme.salesleg.palette.primary[25] : "#ffffff",
  border: `1px solid ${theme.salesleg.palette.neutral[50]}`,
  boxShadow: theme.salesleg.shadow.xs,
  borderRadius: 6,
  ":hover": {
    boxShadow: theme.salesleg.shadow.lg,
  },
  cursor: "pointer",
}));

const Content = styled.div(() => ({ display: "flex", gap: 16 }));

interface ProjectCardProps {
  project: WithMetadata<ExtendedProject>;
}

const ProjectCard: StyledFC<ProjectCardProps> = ({
  project,
}: ProjectCardProps) => {
  //   const [selected, setSelected] = useState(false);
  //   const [isHovering, setIsHovering] = useState(false);
  const navigate = useNavigateWithTeam();

  const theme = useTheme();
  return (
    <Card
      selected={false}
      //   selected={selected}
      //   onMouseOver={() => setIsHovering(true)}
      //   onMouseOut={() => setIsHovering(false)}
      onClick={() => navigate(`/projects/${project.id}`)}
    >
      <Content>
        {/* <CheckBox
          size="sm"
          checked={selected}
          onChange={(checked) => setSelected(checked)}
          style={{ marginTop: 10 }}
          visible={isHovering || selected}
        /> */}
        <div
          style={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            gap: 20,
          }}
        >
          <div style={{ display: "flex", gap: 12 }}>
            <div
              style={{
                height: 32,
                width: 32,
                borderRadius: 16,
                backgroundColor: theme.salesleg.palette.neutral[50],
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <HouseLineIcon
                size={16}
                color={theme.salesleg.palette.neutral[600]}
              />
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: 4 }}>
              <div
                style={{
                  ...theme.salesleg.typography.body.sm.medium,
                  textOverflow: "ellipsis",
                }}
              >
                {project.name}
              </div>
              <div
                style={{
                  ...theme.salesleg.typography.body.sm.regular,
                  color: theme.salesleg.palette.neutral[400],
                }}
              >
                {project.developer.name}
              </div>
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: 12 }}>
            <div style={{ display: "flex", gap: 8 }}>
              <MapPinIcon
                size={16}
                color={theme.salesleg.palette.neutral[400]}
              />
              <div
                style={{
                  ...theme.salesleg.typography.body.sm.regular,
                  color: theme.salesleg.palette.neutral[500],
                }}
              >
                {capitalize(project.city)}
              </div>
            </div>
          </div>
        </div>
      </Content>
    </Card>
  );
};

export default ProjectCard;
