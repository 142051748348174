import styled from "@emotion/styled";
import { ExtendedEmployee, WithMetadata } from "@hshrimal/salesleg";
import { FC } from "react";
import SectionHeader from "./SectionHeader";
import UserCard from "./UserCard";

const Cards = styled.div(() => ({
  display: "flex",
  alignItems: "center",
  gap: 24,
  flexWrap: "wrap",
}));

const TeamContainer = styled.div(() => ({
  display: "flex",
  gap: 16,
  flexDirection: "column",
}));

interface OperationTeamProps {
  user: WithMetadata<ExtendedEmployee>;
}

const OperationTeam: FC<OperationTeamProps> = ({ user }) => {
  if (user.role === "marketing" || user.role === "ops") {
    return (
      <TeamContainer>
        <SectionHeader title="Operational Teams" />
        <Cards>
          {user.teams.map((team, index) => {
            return <UserCard team={team} key={index} />;
          })}
        </Cards>
      </TeamContainer>
    );
  }
  return null;
};

export default OperationTeam;
