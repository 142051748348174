import styled from "@emotion/styled";
import { ReactComponent as UsersAvatarSvg } from "./../../../assets/users-avatar.svg";

const EmptySelectedUserContainer = styled.div(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
  flexGrow: 1,
  backgroundColor: theme.salesleg.palette.neutral[25],
}));

const SelectedUserConatiner = styled.div(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
}));

const SelectedUserTitle = styled.div(({ theme }) => ({
  ...theme.salesleg.typography.heading.sm.medium,
}));

const SelectedUserSubtitle = styled.div(({ theme }) => ({
  ...theme.salesleg.typography.paragraph.md.regular,
  color: theme.salesleg.palette.neutral[500],
}));

const EmptySelectedUser = () => {
  return (
    <EmptySelectedUserContainer>
      <UsersAvatarSvg />
      <SelectedUserConatiner>
        <SelectedUserTitle>Add sales executives</SelectedUserTitle>
        <SelectedUserSubtitle>
          Added sales executives will be shown here.
        </SelectedUserSubtitle>
      </SelectedUserConatiner>
    </EmptySelectedUserContainer>
  );
};

export default EmptySelectedUser;
