import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import capitalize from "capitalize";
import { FC, useState } from "react";
import MapPinIcon from "../../../components/icons/MapPinIcon";
import LocationModal from "./LocationModal";

const LocationContainer = styled.div(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: 4,
  cursor: "pointer",
}));

const LocationText = styled.div(({ theme }) => ({
  ...theme.salesleg.typography.body.sm.regular,
}));

interface SelectLocationProps {
  onAddLocation: (city: string) => void;
  selectedLocation?: string | undefined;
}

const SelectLocation: FC<SelectLocationProps> = ({
  onAddLocation,
  selectedLocation,
}) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const toggle = () => {
    setOpen(!open);
  };

  return (
    <>
      <LocationContainer onClick={toggle}>
        <MapPinIcon size={16} color={theme.salesleg.palette.neutral[400]} />
        <LocationText>
          {selectedLocation !== undefined
            ? capitalize(selectedLocation)
            : "Select Location"}
        </LocationText>
      </LocationContainer>
      <LocationModal open={open} toggle={toggle} setCity={onAddLocation} />
    </>
  );
};

export default SelectLocation;
