import styled from "@emotion/styled";
import { FC, ReactNode, useRef, useState } from "react";
import theme from "../../theme/light/theme";
import { AvatarProps } from "../Avatar";
import ErrorText from "../ErrorText";
import InputLabel from "../InputLabel";
import { Menu } from "../Menu";
import { MenuItem, Variant } from "../Menu/MenuItem";
import DropdownOpenIcon from "../icons/DropdownOpenIcon";

type ThemeColor = "light" | "dark";

interface ColorScheme {
  container: {
    backgroundColor: string;
    color: string;
  };
  icon: {
    color: string;
  };
}

const lightTheme: ColorScheme = {
  container: {
    backgroundColor: "#ffffff",
    color: theme.palette.neutral[400],
  },
  icon: {
    color: theme.palette.neutral[400],
  },
};
const darkTheme: ColorScheme = {
  container: {
    backgroundColor: theme.palette.neutral[900],
    color: theme.palette.neutral[100],
  },
  icon: {
    color: theme.palette.neutral[100],
  },
};

const getColorScheme = (variant: ThemeColor) => {
  switch (variant) {
    case "light":
      return lightTheme;
    case "dark":
      return darkTheme;
  }
};

const InputContainer = styled.div<{
  width: number | string;
  height: number | string;
  colorScheme: ColorScheme;
}>(({ theme, width, height, colorScheme }) => {
  return {
    display: "flex",
    alignItems: "center",
    padding: "6px 14px",
    gap: 8,
    borderRadius: 8,
    width: width,
    height: height,
    boxShadow: theme.salesleg.shadow.xs,
    border: `1px solid ${theme.salesleg.palette.neutral[100]}`,
    cursor: "pointer",
    ...colorScheme.container,
  };
});

const RootContainer = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: 6,
}));

const DropDownSelect = styled.div<{ colorScheme: ColorScheme }>(
  ({ theme, colorScheme }) => ({
    ...theme.salesleg.typography.paragraph.md.regular,
    border: "none",
    outline: "none",
    width: "100%",
    ...colorScheme.container,
  })
);

const Placeholder = styled.span<{
  variant: string | undefined;
}>(({ variant }) => ({
  color: variant === "black" ? theme.palette.neutral[300] : undefined,
}));

interface DropDownV2Props {
  label?: string | undefined;
  required?: boolean | undefined;
  placeholder?: string;
  value?: string | undefined | string[];
  width: string | number;
  height: string | number;
  onChange?: (value: string | undefined) => void;
  error?: string | undefined;
  items: DropDownItemParam[];
  searchPlaceholder?: string | undefined;
  enableSearch?: boolean | undefined;
  quickAddComponent?: ReactNode | undefined;
  themeColor?: ThemeColor | undefined;
  menuItemVariant?: Variant | undefined;
  avatarComponent?: React.FC<AvatarProps> | undefined;
  menuHeight?: number | string | undefined;
}

export interface DropDownItemParam {
  value: string;
  label: string;
}

const DropDownV2: FC<DropDownV2Props> = ({
  height,
  width,
  error,
  label,
  onChange,
  placeholder,
  value,
  items,
  searchPlaceholder,
  enableSearch,
  quickAddComponent,
  themeColor = "light",
  required = false,
  avatarComponent,
  menuItemVariant,
  menuHeight,
}) => {
  const colorScheme = getColorScheme(themeColor);
  const [open, setOpen] = useState(false);
  const ref = useRef(null);

  const selectedItem = items.find((item) => item.value === value);

  const onSelectionChange = (value: string | undefined | string[]) => {
    onChange && onChange(value as string);
    setOpen(false);
  };

  return (
    <RootContainer>
      {label && <InputLabel label={label} required={required} />}
      <InputContainer
        height={height}
        width={width}
        colorScheme={colorScheme}
        ref={ref}
        onClick={() => setOpen((open) => !open)}
      >
        <DropDownSelect colorScheme={colorScheme}>
          {selectedItem !== undefined ? (
            selectedItem.label
          ) : (
            <Placeholder variant={themeColor}>{placeholder}</Placeholder>
          )}
        </DropDownSelect>
        <DropdownOpenIcon size={16} color={colorScheme.icon.color} />
      </InputContainer>
      {error && <ErrorText error={error} />}

      <Menu
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={ref.current || undefined}
        placement="bottom"
        value={value}
        disableCloseOnSelection={true}
        onChange={onSelectionChange}
        enableSearch={enableSearch}
        quickAddComponent={quickAddComponent}
        searchPlaceholder={searchPlaceholder}
        themeColor={themeColor}
        height={menuHeight}
        width={width}
      >
        {items.map((item, index) => {
          return (
            <MenuItem
              key={index}
              variant={menuItemVariant}
              value={item.value}
              label={item.label}
              avatarComponent={avatarComponent}
              themeColor={themeColor}
            />
          );
        })}
      </Menu>
    </RootContainer>
  );
};

export default DropDownV2;
