import styled from "@emotion/styled";
import { yupResolver } from "@hookform/resolvers/yup";
import { FC } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import Button from "../../components/Button/Button";
import DropDownV2, {
  DropDownItemParam,
} from "../../components/DropDownV2/DropDownV2";
import InputField from "../../components/InputField";
import Modal from "../../components/Modal";
import ModalBody from "../../components/Modal/ModalBody";
import ModalFooter from "../../components/Modal/ModalFooter";
import ModalHeader from "../../components/Modal/ModalHeader";
import { ProgressIndicator } from "../../components/ProgressIndicator";
import TextInput from "../../components/TextInput";
import ModelBlurBG from "../../components/icons/ModelBlurBG";
import ModelSqureBG from "../../components/icons/ModelBlurBGSqure";
import UsersThreeIcon from "../../components/icons/UsersThreeIcon";
import { useSelectedCity } from "../../hooks/useSelectedCity";
import { useCreateLeadMutation } from "../../redux/features/api/leads";
import { useGetProjectsQuery } from "../../redux/features/api/projects";
import theme from "../../theme/light/theme";
// import OwnerDropdown from "./OwnerDropdown";

interface AddClientProps {
  open: boolean;
  toggle: () => void;
}

const Background = styled.div(({ theme }) => ({
  position: "relative",
  height: 44,
  width: 44,
  background: "#ffffff",
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  boxShadow: theme.salesleg.shadow.sm,
}));

const BlurBackground = styled.div(() => ({
  display: "flex",
  position: "absolute",
  zIndex: -1,
}));

const BackgroundSqure = styled.div(() => ({
  display: "flex",
  position: "absolute",
  zIndex: -1,
}));

export interface CreateClientModalForm {
  name: string;
  phone: string;
  email: string | undefined;
  source: string;
  projectId: string;
}

const schema = yup.object({
  name: yup.string().required("Client name is required"),
  email: yup.string().email(),
  phone: yup
    .string()
    .required("Client phone number is required")
    .length(10, "Phone number must be exactly 10 digits"),
  source: yup.string().required("Lead source is required"),
  projectId: yup.string().required("Project is required"),
});

const sources: DropDownItemParam[] = [
  {
    value: "google",
    label: "Google",
  },
  {
    value: "facebook",
    label: "FaceBook",
  },
];

const AddClient: FC<AddClientProps> = ({ open, toggle }) => {
  const resolver = yupResolver(schema);

  const methods = useForm<CreateClientModalForm>({ resolver });

  const [createLead, { isLoading: isCreatingLead }] = useCreateLeadMutation();

  const {
    handleSubmit,
    reset,
    register,
    control,
    formState: { errors, isSubmitting },
  } = methods;

  const handleCancel = () => {
    reset();
  };

  const onFormSubmit = (values: CreateClientModalForm) => {
    createLead(values);
    toggle();
    reset();
  };

  const selectedCity = useSelectedCity();
  const { data: projects, isLoading: isProjectLoading } = useGetProjectsQuery({
    city: selectedCity,
  });

  const projectDropDownItems: DropDownItemParam[] =
    projects?.map((project) => {
      return {
        label: `${project.developer.name} ${project.name}`,
        value: project.id,
      };
    }) || [];

  return (
    <FormProvider {...methods}>
      <Modal isOpen={open}>
        <BlurBackground>
          <ModelBlurBG color={theme.palette.primary[500]} />
        </BlurBackground>
        <BackgroundSqure>
          <ModelSqureBG color={theme.palette.primary[500]} />
        </BackgroundSqure>
        <ModalHeader toggle={toggle}>
          <Background>
            <UsersThreeIcon size={24} color={theme.palette.primary[600]} />
          </Background>
        </ModalHeader>
        <ModalBody>
          <InputField
            required={true}
            label="client Name"
            placeholder="Enter Team Name"
            width={420}
            height={44}
            error={errors.name?.message}
            {...register("name")}
          />

          <Controller
            control={control}
            name="phone"
            render={({ field, fieldState }) => {
              const { error } = fieldState;
              const handleChangePhone = (phone: string) => {
                const regex = /^[0-9\b]+$/;
                if (phone === "" || regex.test(phone)) {
                  field.onChange(phone);
                }
              };
              return (
                <TextInput
                  label="Phone Number"
                  {...field}
                  value={field.value || ""}
                  onChange={handleChangePhone}
                  type="text"
                  maxLength={10}
                  prefix="+91"
                  error={error?.message}
                  required={true}
                  width="auto"
                />
              );
            }}
          />

          <InputField
            required={false}
            label="Email"
            placeholder="Enter Email"
            width={420}
            height={44}
            error={errors.email?.message}
            {...register("email")}
          />
          <Controller
            name="source"
            control={control}
            render={({ field, fieldState }) => {
              const { value, onChange } = field;
              const { error } = fieldState;
              return (
                <DropDownV2
                  label="Source"
                  required={true}
                  height={44}
                  width={420}
                  items={sources}
                  placeholder="Select source"
                  onChange={(value) => onChange(value as string)}
                  value={value as string}
                  error={error?.message}
                />
              );
            }}
          />
          <Controller
            name="projectId"
            control={control}
            render={({ field, fieldState }) => {
              const { value, onChange } = field;
              const { error } = fieldState;
              return (
                <DropDownV2
                  label="Project"
                  required={true}
                  height={44}
                  width={420}
                  items={projectDropDownItems}
                  placeholder="Select project"
                  onChange={(value) => onChange(value as string)}
                  value={value as string}
                  error={error?.message}
                  enableSearch={true}
                />
              );
            }}
          />
        </ModalBody>

        <ModalFooter>
          <Button
            style={{
              borderRadius: 6,
              height: 44,
              width: 122,
              boxShadow: theme.shadow.xs,
              justifyContent: "center",
            }}
            color="secondary-gray"
            onClick={handleCancel}
            size="sm"
            label="Reset"
          />
          <Button
            style={{ height: 44, width: 122, justifyContent: "center" }}
            color="primary"
            onClick={handleSubmit(onFormSubmit)}
            size="sm"
            label="Add Client"
          />
        </ModalFooter>
      </Modal>
      <ProgressIndicator
        open={isSubmitting || isProjectLoading || isCreatingLead}
      />
    </FormProvider>
  );
};

export default AddClient;
