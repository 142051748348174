import { ExtendedEmployee, WithMetadata } from "@hshrimal/salesleg";
import { downloadCsv } from "../../../utils/csvUtils";

function toCSV(employee: WithMetadata<ExtendedEmployee>) {
  const employeeAttributes = [
    employee.id,
    employee.alias,
    employee.name,
    employee.phoneNumber,
    employee.teams[0]?.name,
    employee.manager?.name,
    employee.role,
  ];
  return employeeAttributes.join(",");
}

export function downloadEmployees(employees: WithMetadata<ExtendedEmployee>[]) {
  const columnNames = [
    "Employee ID,Employee Code,Name,Phone Number,Team,Manager,Role",
  ];
  const headerRow = columnNames.join(",");
  const dataRows = employees.map((lead) => toCSV(lead));
  const csv = [headerRow, ...dataRows].join("\n");
  downloadCsv(csv, `Employees`);
}
