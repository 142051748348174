import styled from "@emotion/styled";
import { FC } from "react";
import { Employee } from ".";
import { FilterUrlParams } from "../../constants/filters";
import {
  useMultiSelectUrlQueryParam,
  useUrlQueryParam,
} from "../../hooks/useUrlQueryParam";
import { getLeadsFilterUrl } from "../../utils/urlHelper";
import { useNavigateWithTeam } from "../../hooks/useNavigationWithTeam";

interface VisitPlanedCellProps {
  visitPlaned: number;
  employee?: Employee | undefined;
  projectId?: string | undefined;
  isRoot?: boolean | undefined;
}
const RootContainer = styled.div(() => ({
  cursor: "pointer",
}));

const VisitPlanedCell: FC<VisitPlanedCellProps> = ({
  visitPlaned,
  employee,
  projectId,
  isRoot,
}) => {
  const navigate = useNavigateWithTeam();
  const [createdDate] = useUrlQueryParam(FilterUrlParams.CREATION_DATE);
  const [leadSource] = useMultiSelectUrlQueryParam(FilterUrlParams.LEAD_SOURCE);

  const handleClick = () => {
    const queryString = getLeadsFilterUrl(
      createdDate,
      leadSource,
      projectId,
      employee,
      "visit_planned",
      undefined,
      isRoot
    );
    navigate(`/clients?${queryString}`);
  };
  return <RootContainer onClick={handleClick}>{visitPlaned}</RootContainer>;
};

export default VisitPlanedCell;
