import styled from "@emotion/styled";
import { FC, useContext, useEffect } from "react";
import { FilterPanel } from "../../components/FilterPanel";
import Pagination from "../../components/Pagination";
import { ProgressIndicator } from "../../components/ProgressIndicator";
import {
  ITabContext,
  TabContext,
} from "../../components/Tabs/TabContextProvider";
import { CityFilter } from "../../components/filters/CityFilter";
import OwnerFilter from "../../components/filters/OwnerFilter";
import { FilterUrlParams } from "../../constants/filters";
import {
  useMultiSelectUrlQueryParam,
  useUrlQueryParam,
} from "../../hooks/useUrlQueryParam";
import { useGetTeamsQuery } from "../../redux/features/api/teams";
import TeamCard from "./TeamCard";

interface TeamTabContentProps {
  id: string;
}

const RootContainer = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
}));

const CardContainer = styled.div(({ theme }) => ({
  backgroundColor: theme.salesleg.palette.neutral[25],
  flexGrow: 1,
  padding: 32,
  flexBasis: 0,
  overflowY: "auto",
}));

const Cards = styled.div(({ theme }) => ({
  display: "flex",
  gap: 24,
  flexWrap: "wrap",
  backgroundColor: theme.salesleg.palette.neutral[25],
}));

const TeamTabContent: FC<TeamTabContentProps> = ({ id }) => {
  const { setBadgeValue } = useContext<ITabContext>(TabContext);
  const { data: teams, isFetching: isFetchingTeams } = useGetTeamsQuery();

  const [searchText] = useUrlQueryParam("search");
  const [ownerFilter] = useMultiSelectUrlQueryParam(FilterUrlParams.OWNER);
  const [cityFilter] = useMultiSelectUrlQueryParam(FilterUrlParams.City);
  const [page_size] = useUrlQueryParam("page_size", "10");
  const [page] = useUrlQueryParam("page", "1");

  useEffect(() => {
    if (teams) {
      setBadgeValue(id, teams.length.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teams]);

  let filteredTeams = teams?.filter(
    (team) =>
      (cityFilter.length === 0 || cityFilter.includes(team.city)) &&
      (ownerFilter.length === 0 || ownerFilter.includes(team.owner.id)) &&
      (!searchText ||
        team.name.toLowerCase().startsWith(searchText.toLocaleLowerCase()))
  );

  let currentPageTeams = teams;
  if (page_size && page) {
    const parsedPageSize = parseInt(page_size);
    const parsedPage = parseInt(page);
    const startRecordIndex = (parsedPage - 1) * parsedPageSize;
    const endRecordIndex = startRecordIndex + parsedPageSize;

    currentPageTeams = filteredTeams?.slice(startRecordIndex, endRecordIndex);
  }

  return (
    <RootContainer>
      <FilterPanel
        masterFilterMenuItems={[]}
        directFilters={[
          {
            urlParamName: FilterUrlParams.OWNER,
            component: <OwnerFilter key="owner" />,
            permanent: true,
          },
          {
            urlParamName: FilterUrlParams.City,
            component: <CityFilter key="city" />,
            permanent: true,
          },
        ]}
      />
      {filteredTeams && (
        <>
          <CardContainer>
            <Cards>
              {currentPageTeams?.map((team , index) => {
                return <TeamCard team={team} onEdit={() => {}} key={index} />;
              })}
            </Cards>
          </CardContainer>
          <Pagination numRecords={filteredTeams.length} />
        </>
      )}
      <ProgressIndicator open={isFetchingTeams} />
    </RootContainer>
  );
};

export default TeamTabContent;
