import {
  FollowUpSummary,
  LeadsQualityByManagerV2,
  LeadsQualityByProjectV2,
  LeadsQualityReportRequest,
  ProductivityReportByOwner,
  ProductivityReportRequest,
  deserializeFollowUpReport,
  deserializeLeadsQualityByManagerV2,
  deserializeLeadsQualityByProjectV2,
  deserializeProductivityReportByOwner,
} from "@hshrimal/salesleg";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const leadsQualityApi = createApi({
  reducerPath: "leadsQualityApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_SALESLEG_API_BASE_URL}/api`,
    prepareHeaders: (headers) => headers.append("client", "web"),
    credentials: "include",
  }),
  tagTypes: [
    "qualitybyproject",
    "qualitybymanager",
    "followupreport",
    "productivityreport",
  ],
  endpoints: (builder) => ({
    getLeadsQualityByProject: builder.query<
      LeadsQualityByProjectV2[],
      LeadsQualityReportRequest
    >({
      query: (request) => ({
        method: "Post",
        url: "/leads/reports/leadsquality/query",
        body: request,
      }),
      transformResponse: (data) => deserializeLeadsQualityByProjectV2(data),
      providesTags: ["qualitybyproject"],
    }),
    getLeadsQualityBySalesManager: builder.query<
      LeadsQualityByManagerV2[],
      LeadsQualityReportRequest
    >({
      query: (request) => ({
        method: "Post",
        url: "/leads/reports/leadsquality/query",
        body: request,
      }),
      transformResponse: (data) => deserializeLeadsQualityByManagerV2(data),
      providesTags: ["qualitybymanager"],
    }),
    getFollowUpReport: builder.query<
      FollowUpSummary[],
      LeadsQualityReportRequest
    >({
      query: (request) => ({
        method: "Post",
        url: "/leads/reports/followup/query",
        body: request,
      }),
      transformResponse: (data) => deserializeFollowUpReport(data),
      providesTags: ["followupreport"],
    }),
    getProductivityReport: builder.query<
      ProductivityReportByOwner[],
      ProductivityReportRequest
    >({
      query: (request) => ({
        method: "Post",
        url: "/leads/reports/productivity/query",
        body: request,
      }),
      transformResponse: (data) => deserializeProductivityReportByOwner(data),
      providesTags: ["productivityreport"],
    }),
  }),
});

export const {
  useGetLeadsQualityByProjectQuery,
  useGetLeadsQualityBySalesManagerQuery,
  useGetFollowUpReportQuery,
  useGetProductivityReportQuery,
} = leadsQualityApi;
