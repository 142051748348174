import { ProductivityReportByOwner } from "@hshrimal/salesleg";
import { downloadCsv } from "../../../utils/csvUtils";

function toCSV(leadsQuality: ProductivityReportByOwner) {
  const leadAttributes = [
    leadsQuality.employee.name,
    leadsQuality.prospectsGenerated.total,
    leadsQuality.siteVisitsPlanned.total,
    leadsQuality.siteVisitsDone.total,
    leadsQuality.revisitsDone.total,
    leadsQuality.bookings.total,
  ];
  return leadAttributes.join(",");
}

export function downloadProductivity(
  leadsQuality: ProductivityReportByOwner[]
) {
  const columnNames = [
    "ASSIGNED TO,PROSPECTS,VISITS PLANNED,VISITS DONE,RE-VISITS DONE,BOOKINGS",
  ];
  const headerRow = columnNames.join(",");
  const dataRows = leadsQuality.map((lead) => toCSV(lead));
  const csv = [headerRow, ...dataRows].join("\n");
  downloadCsv(csv, `Productivity`);
}
