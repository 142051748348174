import styled from "@emotion/styled";
import { Controller, useFormContext } from "react-hook-form";
import { CreateUserFormValues } from ".";
import TextInput from "../../components/TextInput";
import { StyledFC } from "../../types/styledFC";
import SectionHeader from "./SectionHeader";

const Container = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 16,
}));

const FieldsContainer = styled.div(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  alignContent: "flex-start",
  rowGap: 24,
  columnGap: 24,
}));

const ContactDetailsSection: StyledFC = () => {
  const { control } = useFormContext<CreateUserFormValues>();
  return (
    <Container>
      <SectionHeader title="Contact Details" />
      <FieldsContainer>
        <Controller
          control={control}
          name="phoneNumber"
          render={({ field }) => {
            const handleChangePhone = (phone: string) => {
              const regex = /^[0-9\b]+$/;
              if (phone === "" || regex.test(phone)) {
                field.onChange(phone);
              }
            };
            return (
              <TextInput
                label="Phone Number"
                {...field}
                value={field.value || ""}
                onChange={handleChangePhone}
                type="text"
                maxLength={10}
                prefix="+91"
              />
            );
          }}
        />

        <Controller
          control={control}
          name="email"
          render={({ field }) => <TextInput label="Email" {...field} />}
        />
      </FieldsContainer>
    </Container>
  );
};

export default ContactDetailsSection;
