import styled from "@emotion/styled";
import {
  ChangeEvent,
  KeyboardEvent,
  ReactNode,
  forwardRef,
  useState,
} from "react";
import { MergedTheme } from "../../types/emotion";
import ErrorText from "../ErrorText";
import InputLabel from "../InputLabel";

type Variant = "plain" | "outline";

type InputType = "text" | "number";

const getInputVariantStyle = (theme: MergedTheme, variant: Variant) => {
  if (variant === "plain") {
    return {};
  }
  return {
    boxShadow: theme.salesleg.shadow.xs,
    background: "#FFFFFF",
    border: `1px solid ${theme.salesleg.palette.neutral[100]}`,
  };
};

const getInputFocusStyle = (theme: MergedTheme, variant: Variant) => {
  if (variant === "plain") {
    return {};
  }
  return {
    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #EAFBFF",
    background: "#FFFFFF",
    border: `1px solid ${theme.salesleg.palette.primary[100]}`,
  };
};

const getDisabledStyle = (theme: MergedTheme, variant: Variant) => {
  if (variant === "plain") {
    return {};
  }

  return {
    boxShadow: theme.salesleg.shadow.xs,
    background: theme.salesleg.palette.neutral[25],
    border: `1px solid ${theme.salesleg.palette.neutral[100]}`,
  };
};

const InputContainer = styled.div<{
  width: number | string;
  height: number | string;
  isFocus: boolean;
  disabled?: boolean | undefined;
  variant: Variant;
}>(({ theme, width, height, isFocus, disabled, variant }) => {
  let inputFocusStyle = {};
  if (isFocus) {
    inputFocusStyle = getInputFocusStyle(theme, variant);
  }
  let inputDisabledStyle = {};
  if (disabled) {
    inputDisabledStyle = getDisabledStyle(theme, variant);
  }
  const inputVariantStyle = getInputVariantStyle(theme, variant);
  return {
    display: "flex",
    alignItems: "center",
    padding: "6px 14px",
    gap: 8,
    borderRadius: 8,
    width: width,
    height: height,
    ...inputVariantStyle,
    ...inputFocusStyle,
    ...inputDisabledStyle,
  };
});

const Input = styled.input<{}>(({ theme }) => ({
  ...theme.salesleg.typography.paragraph.md.regular,
  border: "none",
  outline: "none",
  width: "100%",
  color: theme.salesleg.palette.neutral[500],

  "::placeholder": {
    color: theme.salesleg.palette.neutral[500],
  },

  ":focus": {
    color: theme.salesleg.palette.neutral[900],
  },
  ":focus-visible": {
    color: theme.salesleg.palette.neutral[900],
  },
  ":disabled": {
    color: theme.salesleg.palette.neutral[400],
  },
}));

interface InputFieldProps {
  label?: string | undefined;
  required?: boolean | undefined;
  placeholder?: string;
  value?: string | number;
  width: string | number;
  height: string | number;
  endIcon?: ReactNode;
  startIcon?: ReactNode;
  disabled?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  variant?: Variant | undefined;
  name?: string | undefined;
  error?: string | undefined;
  type?: InputType | undefined;
  onKeyUp?: (e: KeyboardEvent<HTMLInputElement>) => void;
  autoFocus?: boolean | undefined;
  onFocus?: ((e: any) => void) | undefined;
  onBlur?: ((e: any) => void) | undefined;
}

const Container = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: 6,
}));

export type Ref = HTMLInputElement;

const InputField = forwardRef<Ref, InputFieldProps>(
  (
    {
      label,
      required = false,
      placeholder,
      value,
      width,
      height,
      endIcon,
      startIcon,
      disabled,
      onChange,
      variant = "outline",
      name,
      error,
      type = "text",
      onKeyUp,
      autoFocus,
      onFocus,
      onBlur,
    },
    ref
  ) => {
    const [isFocused, setFocused] = useState(false);

    function onFocusChange(e: any, focused: boolean) {
      setFocused(focused);
      if (focused) {
        onFocus && onFocus(e);
      } else {
        onBlur && onBlur(e);
      }
    }

    return (
      <Container>
        {label && <InputLabel label={label} required={required} />}
        <InputContainer
          height={height}
          width={width}
          isFocus={isFocused}
          disabled={disabled}
          variant={variant}
        >
          {startIcon && startIcon}
          <Input
            placeholder={placeholder}
            value={value}
            disabled={disabled}
            onChange={onChange}
            onFocus={(e) => onFocusChange(e, true)}
            onBlur={(e) => onFocusChange(e, false)}
            required={required}
            ref={ref}
            name={name}
            type={type}
            onKeyUp={onKeyUp}
            autoFocus={autoFocus}
          />
          {endIcon && endIcon}
        </InputContainer>
        {error && <ErrorText error={error} />}
      </Container>
    );
  }
);

export default InputField;
