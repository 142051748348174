import { StyledFC } from "../../types/styledFC";
import Logo from "../Logo";

const LogoSection: StyledFC = ({ style }) => {
  return (
    <div
      style={{
        ...style,
        height: 40,
        display: "flex",
        alignItems: "center",
      }}
    >
      <Logo size={40} colored />
    </div>
  );
};

export default LogoSection;
