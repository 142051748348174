import styled from "@emotion/styled";
import { useNavigateWithTeam } from "../../hooks/useNavigationWithTeam";
import { StyledFC } from "../../types/styledFC";
import { IconComponent } from "../icons/types";

interface MenuItemProps {
  isActive: boolean;
  icon: IconComponent;
  label: string;
  route: string;
  disabled?: boolean | undefined;
}

const Container = styled.div(({ theme }) => ({
  height: 58,
  flexGrow: 1,
  display: "flex",
  alignItems: "center",
  gap: 12,
  cursor: "pointer",
}));

const Label = styled.span(({ theme }) => ({
  ...theme.salesleg.typography.body.sm.regular,
  color: "#ffffff",
}));

const MenuItem: StyledFC<MenuItemProps> = ({
  isActive,
  icon,
  label,
  route,
}) => {
  const navigate = useNavigateWithTeam();
  const MenuItemIcon = icon;
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Container onClick={() => navigate(route)}>
        <MenuItemIcon size={20} color="#ffffff" />
        <Label>{label}</Label>
      </Container>
      {isActive && (
        <div
          style={{
            backgroundColor: "white",
            height: 3,
            marginBottom: -4,
            borderRadius: 1.5,
          }}
        />
      )}
    </div>
  );
};

export default MenuItem;
