import styled from "@emotion/styled";
import { FC } from "react";

const RootContainer = styled.div(() => ({}));

interface ProjectCellProps {
  id: string;
  name: string;
}

const ProjectCell: FC<ProjectCellProps> = ({ name }) => {
  return <RootContainer>{name}</RootContainer>;
};

export default ProjectCell;
