import styled from "@emotion/styled";
import { CSSProperties, FC, useContext } from "react";
import { TabParams, Variant } from ".";
import GridTab from "./GridTab";
import Tab from "./Tab";
import { ITabContext, TabContext } from "./TabContextProvider";

const getTab = (
  variant: Variant,
  tab: TabParams,
  activeTab: string,
  handleTabClick: (tab: TabParams) => void,
  countContext: Record<string, string>
) => {
  switch (variant) {
    case "grid":
      return (
        <GridTab
          key={tab.id}
          isActive={activeTab === tab.id}
          title={tab.label}
          icon={tab.icon}
          badgeCount={countContext[tab.id]}
          onClick={() => handleTabClick(tab)}
          id={tab.id}
        />
      );
    default:
      return (
        <Tab
          key={tab.id}
          active={activeTab === tab.id}
          label={tab.label}
          onClick={() => handleTabClick(tab)}
          id={tab.id}
        />
      );
  }
};

const TabContainer = styled.div(({ theme }) => ({
  display: "flex",
  gap: 6,
  borderBottom: theme.salesleg.palette.neutral[400],
  zIndex: 10,
}));

interface TabHeaderProps {
  tabs: TabParams[];
  variant: Variant;
  activeTab: string;
  handleTabClick: (tab: TabParams) => void;
  tabHeaderStyle?: CSSProperties | undefined;
}

const TabHeader: FC<TabHeaderProps> = ({
  activeTab,
  handleTabClick,
  tabs,
  variant,
  tabHeaderStyle,
}) => {
  const { counts } = useContext<ITabContext>(TabContext);
  return (
    <TabContainer style={tabHeaderStyle}>
      {tabs.map((tab) => {
        return getTab(variant, tab, activeTab, handleTabClick, counts);
      })}
    </TabContainer>
  );
};

export default TabHeader;
