import { FilterUrlParams } from "../../constants/filters";
import { useSelectedCity } from "../../hooks/useSelectedCity";
import { useGetProjectsQuery } from "../../redux/features/api/projects";
import { Filter } from "../Filter";
import { MenuItem } from "../Menu/MenuItem";
import { ProgressIndicator } from "../ProgressIndicator";
import SquaresFourIcon from "../icons/SquaresFourIcon";

const ProjectFilter = () => {
  const selectedCity = useSelectedCity();
  const { data: projects, isFetching: isFetchingProjects } =
    useGetProjectsQuery({ city: selectedCity });

  return (
    <>
      <Filter
        icon={SquaresFourIcon}
        name="Project"
        urlParamName={FilterUrlParams.PROJECT}
        multiple={true}
        enableSearch
        searchPlaceholder="Search Project"
      >
        {projects?.map((project) => (
          <MenuItem
            key={project.id}
            variant="default"
            value={project.id}
            label={`${project.developer.name} ${project.name}`}
          />
        )) || []}
      </Filter>
      <ProgressIndicator open={isFetchingProjects} />
    </>
  );
};

export default ProjectFilter;
