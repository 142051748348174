import styled from "@emotion/styled";
import { Modal } from "@mui/material";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { ReactComponent as BackgroundBlurSvg } from "../../assets/bg-blur-deactivate.svg";
import { ReactComponent as SquareSvg } from "../../assets/user-modal-back.svg";
import Button from "../../components/Button/Button";
import ClearIcon from "../../components/icons/ClearIcon";
import ToggleLeftIcon from "../../components/icons/ToggleLeftIcon";
import { useUpdateEmployeeStatusMutation } from "../../redux/features/api/employees";
import theme from "../../theme/light/theme";

interface DeactivateModalProps {
  open: boolean;
  toggle: () => void;
}

const BlurBackground = styled.div(() => ({
  display: "flex",
  position: "absolute",
  zIndex: -1,
}));

const ModalHeader = styled.div(() => ({
  paddingTop: 24,
  paddingRight: 24,
  paddingLeft: 24,
  paddingBottom: 20,
  display: "flex",
}));

const ModalBody = styled.div(() => ({
  paddingRight: 24,
  paddingLeft: 24,
  paddingBottom: 32,
  display: "flex",
  flexDirection: "column",
  gap: 20,
}));

const ModalFooter = styled.div(() => ({
  paddingRight: 24,
  paddingBottom: 24,
  paddingLeft: 24,
  position: "absolute",
  bottom: 0,
  width: "100%",
  display: "flex",
  justifyContent: "center",
  gap: 16,
  backgroundColor: "#ffffff",
}));

const SquareBackground = styled.div(() => ({
  display: "flex",
  position: "absolute",
  zIndex: -1,
}));

const DeactivateIconWrapper = styled.div(({ theme }) => ({
  position: "relative",
  height: 40,
  width: 40,
  background: "#ffffff",
  borderRadius: 20,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const TitleContainer = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: 8,
}));

const Title = styled.div(({ theme }) => ({
  ...theme.salesleg.typography.heading.sm.semiBold,
  color: theme.salesleg.palette.neutral[900],
}));

const SubTitle = styled.div(({ theme }) => ({
  ...theme.salesleg.typography.paragraph.sm.regular,
  color: theme.salesleg.palette.neutral[400],
}));

const ClearIconWrapper = styled.div(() => ({
  position: "absolute",
  right: 24,
  top: 24,
  cursor: "pointer",
}));

const DeactivateModal: FC<DeactivateModalProps> = ({ open, toggle }) => {
  const { userId } = useParams();
  const [updateEmployeeStatusMutation] = useUpdateEmployeeStatusMutation();

  const handleDeactivateUser = () => {
    if (userId) {
      updateEmployeeStatusMutation({ id: userId, status: "deactivated" });
      toggle();
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={toggle}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={{
            position: "absolute",
            width: 400,
            height: 280,
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: 3,
            background: "#ffffff",
            border: `3px solid #ffffff`,
            zIndex: 20,
          }}
        >
          <BlurBackground>
            <BackgroundBlurSvg />
          </BlurBackground>
          <SquareBackground>
            <SquareSvg />
          </SquareBackground>
          <ModalHeader>
            <ClearIconWrapper onClick={toggle}>
              <ClearIcon size={16} color={theme.palette.neutral[300]} />
            </ClearIconWrapper>
            <DeactivateIconWrapper>
              <ToggleLeftIcon size={24} color={theme.palette.neutral[600]} />
            </DeactivateIconWrapper>
          </ModalHeader>
          <ModalBody>
            <TitleContainer>
              <Title>Deactivate User</Title>
              <SubTitle>Are you sure you want to deactivate user?</SubTitle>
            </TitleContainer>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary-gray"
              label="Cancel"
              size="lg"
              onClick={toggle}
              fullWidth
            />
            <Button
              color="primary"
              label="Yes, Deactivate"
              size="lg"
              onClick={handleDeactivateUser}
              fullWidth
            />
          </ModalFooter>
        </div>
      </Modal>
    </>
  );
};

export default DeactivateModal;
