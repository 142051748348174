import styled from "@emotion/styled";
import {
  DistributionQueueRecord,
  ExtendedEmployee,
  WithMetadata,
} from "@hshrimal/salesleg";
import { useParams } from "react-router-dom";
import { ProgressIndicator } from "../../components/ProgressIndicator";
import { useSelectedCity } from "../../hooks/useSelectedCity";
import { useGetEmployeesQuery } from "../../redux/features/api/employees";
import { useGetProjectsQuery } from "../../redux/features/api/projects";
import { StyledFC } from "../../types/styledFC";
import { defined } from "../../utils/assert";
import Header from "./Header";
import UserCard from "./UserCard";

const RootContainer = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
}));

const UserCardsGrid = styled.div(({ theme }) => ({
  backgroundColor: theme.salesleg.palette.neutral[25],
  flexGrow: 1,
  display: "flex",
  flexWrap: "wrap",
  alignContent: "flex-start",
  padding: 32,
  gap: 24,
}));

const ViewProject: StyledFC = ({ style }) => {
  const { projectId } = useParams();

  if (!projectId) {
    throw new Error("Project ID is missing");
  }

  const selectedCity = useSelectedCity();
  const { data: projects, isFetching: isFetchingProjects } =
    useGetProjectsQuery({ city: selectedCity });
  const { data: users, isFetching: isFetchingEmployees } = useGetEmployeesQuery(
    {}
  );

  const project = projects?.find((project) => project.id === projectId);
  if (!isFetchingProjects && !project) {
    throw new Error("Project not found");
    // Show a message stating that this project no longer exists
  }

  let extendedDistQueueRecords: {
    user: WithMetadata<ExtendedEmployee>;
    weight: number;
  }[] = [];
  if (project && users) {
    extendedDistQueueRecords = getExtendedUsers(
      project?.distributionQueue || [],
      users || []
    );
  }

  return (
    <>
      {project && (
        <RootContainer style={style}>
          <Header project={project} />
          <UserCardsGrid>
            {extendedDistQueueRecords.map((record) => {
              return (
                <UserCard
                  key={record.user.id}
                  user={record.user}
                  weight={record.weight}
                />
              );
            })}
          </UserCardsGrid>
        </RootContainer>
      )}
      <ProgressIndicator open={isFetchingProjects || isFetchingEmployees} />
    </>
  );
};

function getExtendedUsers(
  distributionQueueRecords: DistributionQueueRecord[],
  users: WithMetadata<ExtendedEmployee>[]
): { user: WithMetadata<ExtendedEmployee>; weight: number }[] {
  const userLookupMap: Map<string, WithMetadata<ExtendedEmployee>> = new Map(
    users.map((user) => [user.id, user])
  );

  const result: { user: WithMetadata<ExtendedEmployee>; weight: number }[] = [];
  distributionQueueRecords.forEach((record) => {
    if (userLookupMap.has(record.salesExecutive.id))
      result.push({
        user: defined(userLookupMap.get(record.salesExecutive.id)),
        weight: record.weight,
      });
  });

  return result;
}

export default ViewProject;
