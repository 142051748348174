import { ReactNode, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../redux/hooks";
import { initialize as initializeAuth } from "../redux/features/auth";
// import ProgressBar from "../components/progress-bar/ProgressBar";
// import { PhoneLogin } from "../sections/PhoneLogin";
import { developersApi } from "../redux/features/api/developers";
import { employeesApi } from "../redux/features/api/employees";
import { leadsApi } from "../redux/features/api/leads";
import { leadsQualityApi } from "../redux/features/api/leadsQuality";
import { projectsApi } from "../redux/features/api/projects";
import { summaryApi } from "../redux/features/api/summary";
import { siteVisitsApi } from "../redux/features/api/siteVisits";
import { LoginPage } from "../pages/Login";

type AuthGuardProps = {
  children: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const {
    isInitialized,
    // loginPending,
    loginAsPending,
    // logoutPending,
    isAuthenticated,
    user,
  } = useAppSelector((state) => state.auth);

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(initializeAuth());
  }, [dispatch]);

  // Clear RTK API cache on logout (user === null)
  // or when login-as API is called (loginAsPending === true)
  useEffect(() => {
    if (!user || loginAsPending) {
      dispatch(summaryApi.util.resetApiState());
      dispatch(leadsApi.util.resetApiState());
      dispatch(leadsQualityApi.util.resetApiState());
      dispatch(projectsApi.util.resetApiState());
      dispatch(developersApi.util.resetApiState());
      dispatch(employeesApi.util.resetApiState());
      dispatch(siteVisitsApi.util.resetApiState());
    }
  }, [user, loginAsPending, dispatch]);

  return (
    <>
      {/* {!isInitialized && <ProgressBar text={"Checking login session"} />} */}
      {isInitialized && !isAuthenticated && <LoginPage />}
      {isAuthenticated && children}
      {/* {loginPending && <ProgressBar text={"Logging in"} />} */}
      {/* {logoutPending && <ProgressBar text={"Logging out"} />} */}
    </>
  );
}
