import styled from "@emotion/styled";
import { StyledFC } from "../../types/styledFC";

interface SectionHeaderProps {
  title: string;
}

const Container = styled.div(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: 12,
}));

const Title = styled.span(({ theme }) => ({
  ...theme.salesleg.typography.caption.lg.medium,
  letterSpacing: 1,
  color: theme.salesleg.palette.neutral[400],
  textTransform: "uppercase",
}));

const Line = styled.div(({ theme }) => ({
  flexGrow: 1,
  border: `1px solid ${theme.salesleg.palette.neutral[100]}`,
}));

const SectionHeader: StyledFC<SectionHeaderProps> = ({ title }) => {
  return (
    <Container>
      <Title>{title}</Title>
      <Line />
    </Container>
  );
};

export default SectionHeader;
