import { Controller, useFormContext } from "react-hook-form";
import Avatar from "../../components/Avatar";
import DropDownV2 from "../../components/DropDownV2/DropDownV2";
import { ProgressIndicator } from "../../components/ProgressIndicator";
import { useGetEmployeesQuery } from "../../redux/features/api/employees";
import { CreateTeamModalForm } from "./CreateTeamModal";

const OwnerDropdown = () => {
  const { data: employees, isFetching } = useGetEmployeesQuery({
    status: "active",
    role: ["sales_head"],
  });

  const options = employees?.map((employee) => ({
    value: employee.id,
    label: employee.name,
  }));

  const form = useFormContext<CreateTeamModalForm>();

  return (
    <>
      <Controller
        name="ownerId"
        control={form.control}
        render={({ field }) => {
          const { value: selectedOwnerId } = field;
          return (
            <DropDownV2
              items={options || []}
              value={selectedOwnerId}
              width={420}
              enableSearch={true}
              searchPlaceholder="Search Owner"
              height={44}
              onChange={(option) => {
                form.setValue("ownerId", option as string);
              }}
              menuItemVariant="avatar"
              avatarComponent={Avatar}
            />
          );
        }}
      />
      <ProgressIndicator open={isFetching} />
    </>
  );
};

export default OwnerDropdown;
