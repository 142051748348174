import { FC } from "react";
import { ReactComponent as XCircleSvgIcon } from "./../../../assets/icons/x-circle.svg";
import { IconProps } from "../types";

interface XCircleIconProps extends IconProps {
  onClick?: () => void;
}

const XCircleIcon: FC<XCircleIconProps> = ({ size, color, onClick }) => {
  return (
    <XCircleSvgIcon
      width={size}
      height={size}
      onClick={onClick}
      color={color}
    />
  );
};

export default XCircleIcon;
