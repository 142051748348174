export const FilterUrlParams = {
  DEVELOPER: "developer_id",
  PROJECT_TYPE: "project_type",
  City: "city",
  PROJECT: "project_id",
  ASSIGNEE: "assignee_id",
  SALES_EXECUTIVE: "executive_id",
  STAGE: "stage",
  TEAM: "team_id",
  LEAD_SOURCE: "source",
  MANAGER: "manager_id",
  VISIT_STATUS: "visit_status",
  CREATION_DATE: "created_date",
  PROSPECT_DATE: "prospect_date",
  VISIT_PLANNED_DATE: "visit_planned_date",
  VISIT_DONE_DATE: "visit_done_date",
  // This is an overloaded date meant to be used in site visits page
  // This is largely based on the idea that scheduled and completed date
  // of a visit should always be same (even if visit didn't happen, it will
  // be marked as canceled on the scheduled date itself)
  VISIT_DATE: "visit_date",
  OWNER: "owner_id",
  FOLLOWUP: "followup",
  GROUP_BY: "group_by",
  ROLE: "role",
};
