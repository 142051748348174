import styled from "@emotion/styled";
import { CSSProperties, FC, ReactNode } from "react";
import { IconProps } from "../icons/types";
import TabContextProvider from "./TabContextProvider";
import TabHeader from "./TabHeader";

const RootContainer = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
}));

const TabContent = styled.div<{ active: boolean }>(({ theme, active }) => ({
  display: active ? "flex" : "none",
  borderTop: `1px solid ${theme.salesleg.palette.neutral[100]}`,
  position: "relative",
  top: -1,
  backgroundColor: theme.salesleg.palette.neutral[25],
  flexGrow: 1,
  width: "100%",
}));

const TabContainer = styled.div(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "0px 24px 0px 0px",
}));

export type Variant = "default" | "grid";

export interface TabParams {
  id: string;
  label: string;
  icon?: React.FC<IconProps>;
  children: ReactNode;
  route?: string;
  quickAddButton?: ReactNode | undefined;
}

interface TabsProps {
  tabs: TabParams[];
  activeTab: string;
  handleTabClick: (tab: TabParams) => void;
  variant?: Variant;
  tabHeaderStyle?: CSSProperties | undefined;
}

const Tabs: FC<TabsProps> = ({
  tabs,
  activeTab,
  handleTabClick,
  tabHeaderStyle,
  variant = "default",
}) => {
  const activeTabItem = tabs.find((tab) => tab.id === activeTab);

  return (
    <TabContextProvider>
      <RootContainer>
        <TabContainer>
          <TabHeader
            activeTab={activeTab}
            handleTabClick={handleTabClick}
            variant={variant}
            tabs={tabs}
            tabHeaderStyle={tabHeaderStyle}
          />
          {activeTabItem?.quickAddButton}
        </TabContainer>
        {tabs.map((tab) => {
          return (
            <TabContent key={tab.id} active={activeTab === tab.id}>
              {activeTab === tab.id && tab.children}
            </TabContent>
          );
        })}
      </RootContainer>
    </TabContextProvider>
  );
};

export default Tabs;
