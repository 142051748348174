import styled from "@emotion/styled";
import { FollowUpSummary, Role } from "@hshrimal/salesleg";
import { useContext, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { ProgressIndicator } from "../../components/ProgressIndicator";
import Table from "../../components/Table";
import { useGetFollowUpReportQuery } from "../../redux/features/api/leadsQuality";
import theme from "../../theme/light/theme";
import AssignedCell from "./AssignedCell";
import FollowUpTotalCell from "./FollowUpTotalCell";
import NewCell from "./NewCell";
import PastDueCell from "./PastDueCell";
import TodayCell from "./TodayCell";
import TomorrowCell from "./TomorrowCell";
import UpcomingCell from "./UpcomingCell";
import { DownloadContext } from "./context/DownloadContext";
import { useLeadsQualityReportRequest } from "./hooks/useLeadsQualityReportRequest";

const RootContainer = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
}));
const TableContainer = styled.div(() => ({
  paddingLeft: 32,
  paddingTop: 12,
  paddingBottom: 12,
  paddingRight: 16,
  backgroundColor: theme.palette.neutral[25],
  overflowY: "auto",
  flexGrow: 1,
  flexBasis: 0,
}));

const FollowupContent = () => {
  const { employeeId } = useParams();
  const leadsQualityReportRequest = useLeadsQualityReportRequest(
    "manager",
    employeeId
  );
  const { data: followupReport, isLoading } = useGetFollowUpReportQuery(
    leadsQualityReportRequest
  );
  const columns = [
    { name: "Assigned To", width: 170 },
    { name: "New", width: 170 },
    { name: "Past Due", width: 170 },
    { name: "Today", width: 170 },
    { name: "Tomorrow", width: 170 },
    { name: "Upcoming", width: 170 },
    { name: "Total", width: 170 },
  ];

  const totalRow: FollowUpSummary & { id: string } = useMemo(() => {
    const total: FollowUpSummary & { id: string } = {
      id: "total",
      employee: {
        id: "",
        name: "",
        role: "sales_lead" as Role,
        level: 0,
        status: "active",
        isRoot: false,
      },
      new: 0,
      noFollowUp: 0,
      pastDue: 0,
      today: 0,
      tomorrow: 0,
      upcoming: 0,
      total: 0,
    };
    if (followupReport) {
      total.employee.id = followupReport[0].employee.id;
      followupReport.forEach((row) => {
        total.total += row.total;
        total.new += row.new;
        total.noFollowUp += row.noFollowUp;
        total.pastDue += row.pastDue;
        total.today += row.today;
        total.tomorrow += row.tomorrow;
        total.upcoming += row.upcoming;
      });
    }
    return total;
  }, [followupReport]);

  const downloadCtx = useContext(DownloadContext);

  useEffect(() => {
    if (followupReport !== undefined) {
      downloadCtx?.setFollowUpSummaryRows(followupReport);
    }
  }, [followupReport, downloadCtx]);

  useEffect(() => {
    downloadCtx?.setTotalFollowUpSummaryRow(totalRow);
  }, [totalRow, downloadCtx]);

  return (
    <>
      <RootContainer>
        <TableContainer>
          <Table
            columnGroups={[
              { span: 1, width: 200 },
              { span: 1, width: 140 },
              { span: 1, width: 140 },
              { span: 1, width: 140 },
              { span: 1, width: 140 },
              { span: 1, width: 140 },
              { span: 1, width: 140 },
            ]}
            columns={columns}
            rows={followupReport || []}
            totalRow={totalRow}
            renderTableRowCells={(row, index, isTotalRow) => [
              <AssignedCell
                name={row.employee.name}
                role={row.employee?.role}
                id={row.employee.id}
                disableDrillDown={
                  row.employee.isRoot || row.employee.role === "sales_associate"
                }
              />,
              <NewCell
                newNumber={row.new}
                employee={row.employee}
                isRoot={row.employee.isRoot}
              />,
              <PastDueCell
                pastDue={row.pastDue}
                employee={row.employee}
                isRoot={row.employee.isRoot}
              />,
              <TodayCell
                today={row.today}
                employee={row.employee}
                isRoot={row.employee.isRoot}
              />,
              <TomorrowCell
                tomorrow={row.tomorrow}
                employee={row.employee}
                isRoot={row.employee.isRoot}
              />,
              <UpcomingCell
                upcomming={row.upcoming}
                employee={row.employee}
                isRoot={row.employee.isRoot}
              />,
              <FollowUpTotalCell
                total={row.total}
                employee={row.employee}
                isRoot={row.employee.isRoot}
              />,
            ]}
          />
        </TableContainer>
      </RootContainer>
      <ProgressIndicator open={isLoading} />
    </>
  );
};

export default FollowupContent;
