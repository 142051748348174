import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import {
  ExtendedEmployee,
  TeamReference,
  WithMetadata,
} from "@hshrimal/salesleg";
import { ChangeEvent, FC, useState } from "react";
import { useFormContext } from "react-hook-form";
import { ProjectWithExtendedDistributionQueue } from "..";
import InputField from "../../../components/InputField";
import VerticalDivider from "../../../components/VerticalDivider";
import ClearIcon from "../../../components/icons/ClearIcon";
import { useGetEmployeesQuery } from "../../../redux/features/api/employees";
import EmptyUsersList from "../EmptyUsersList";
import SearchPreference from "../SearchPreference";
import UserCard from "../UserCard";
import SelectUserByTeam from "./SearchByTeam";

const Container = styled.div(({ theme }) => ({
  width: 456,
  borderRight: `1px solid ${theme.salesleg.palette.neutral[100]}`,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: 20,
  paddingTop: 24,
  paddingBottom: 24,
}));

const UserContainer = styled.div(() => ({
  flexBasis: 0,
  flexGrow: 1,
  width: 456,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  overflowY: "auto",
}));

const UserGroup = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: 16,
}));

const AddUsersText = styled.div(({ theme }) => ({
  ...theme.salesleg.typography.heading.md.medium,
  paddingLeft: 32,
  flexDirection: "row",
  alignSelf: "flex-start",
}));

const SearchContainer = styled.div(({ theme }) => ({
  ...theme.salesleg.typography.paragraph.sm.regular,
  width: 392,
  height: 40,
  border: `1px solid ${theme.salesleg.palette.neutral[100]}`,
  boxShadow: theme.salesleg.shadow.xs,
  borderRadius: 6,
  display: "flex",
  alignItems: "center",
}));

const IconContainer = styled.div(() => ({
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
}));

const DetailContainer = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "28px",
  flexGrow: 1,
}));

const AddUsers: FC = () => {
  const [filteredUsers, setFilteredUsers] = useState<
    WithMetadata<ExtendedEmployee>[]
  >([]);
  const [searchText, setSearchText] = useState<string | undefined>("");
  const [selectedSearchKey, setSelectedSearchKey] = useState<string>("1");

  const { getValues, setValue } =
    useFormContext<ProjectWithExtendedDistributionQueue>();
  const theme = useTheme();

  const { data: users } = useGetEmployeesQuery({
    role: ["sales_head", "sales_lead", "sales_associate"],
  });

  if (!users) return <></>;

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const filterText = value.trim();
    setSearchText(value);
    if (value.trim().length === 0) {
      // If Text box is blank setting the array blank
      setFilteredUsers([]);
    } else {
      let newFiltredOptions = [];

      if (selectedSearchKey === "2") {
        // If it is search by team
        newFiltredOptions = users.filter((user) => {
          const teams = user.teams.map((team) =>
            team.name.toLowerCase().includes(filterText.toLowerCase())
          );
          return teams.some((isTeamExist) => isTeamExist === true);
        });
      } else {
        // If it is search by user
        newFiltredOptions = users.filter((user) =>
          user.name.toLocaleLowerCase().includes(filterText.toLocaleLowerCase())
        );
      }
      setFilteredUsers(newFiltredOptions);
    }
  };

  const onAddUsers = (addUsers: WithMetadata<ExtendedEmployee>[]) => {
    const { distributionQueue, users: selectedUsers } = getValues();
    const newDistributionQueue = addUsers.map((user) => {
      return {
        salesExecutive: {
          id: user.id,
        },
        weight: 1,
      };
    });
    setValue("distributionQueue", [
      ...distributionQueue,
      ...newDistributionQueue,
    ]);
    setValue("users", [...selectedUsers, ...addUsers]);
  };

  const onRemoveUsers = (removeUsers: WithMetadata<ExtendedEmployee>[]) => {
    const { distributionQueue, users: selectedUsers } = getValues();
    const removeUserIds = removeUsers.map((user) => user.id);

    const updatedQueue = distributionQueue.filter(
      (queueMember) => !removeUserIds.includes(queueMember.salesExecutive.id)
    );
    setValue("distributionQueue", updatedQueue);
    const updatedUsers = selectedUsers.filter(
      (user) => !removeUserIds.includes(user.id)
    );
    setValue("users", updatedUsers);
  };

  const handleClear = () => {
    setSearchText("");
    setFilteredUsers([]);
  };

  const searchBy = (key: string) => {
    setSearchText("");
    setFilteredUsers([]);
    setSelectedSearchKey(key);
  };

  const selectedUserIds = getValues().distributionQueue.map(
    (user) => user.salesExecutive.id
  );

  const activeOrSelectedFilteredUsers = filteredUsers.filter(
    (user) => user.status === "active" || selectedUserIds.includes(user.id)
  );

  function getTeamsForUsers(
    users: WithMetadata<ExtendedEmployee>[]
  ): TeamReference[] {
    const uniqueTeams: Map<string, TeamReference> = new Map<
      string,
      TeamReference
    >();

    for (let i = 0; i < users.length; i++) {
      const user = users[i];
      const userTeams = user.teams;
      for (let j = 0; j < userTeams.length; j++) {
        const userTeam = userTeams[j];
        if (!uniqueTeams.has(userTeam.id)) {
          uniqueTeams.set(userTeam.id, userTeam);
        }
      }
    }

    return Array.from(uniqueTeams.values());
  }

  const teams = getTeamsForUsers(activeOrSelectedFilteredUsers);
  const filteredTeams = searchText
    ? teams.filter((team) => team.name.toLowerCase().includes(searchText))
    : [];

  return (
    <Container>
      <AddUsersText>Add Sales Executives</AddUsersText>
      <DetailContainer>
        <SearchContainer>
          <SearchPreference
            searchBy={searchBy}
            selectedSearchKey={selectedSearchKey}
          />
          <VerticalDivider height="100%" />
          <InputField
            endIcon={
              searchText !== undefined && searchText.length > 0 ? (
                <IconContainer onClick={handleClear}>
                  <ClearIcon
                    size={16}
                    color={theme.salesleg.palette.neutral[400]}
                  />
                </IconContainer>
              ) : (
                <></>
              )
            }
            placeholder={
              selectedSearchKey === "1"
                ? "Type user name here"
                : "Type team name here"
            }
            onChange={onSearch}
            height={40}
            width="100%"
            variant="plain"
            value={searchText}
          />
        </SearchContainer>

        {activeOrSelectedFilteredUsers !== undefined &&
          activeOrSelectedFilteredUsers.length > 0 && (
            <UserContainer>
              {selectedSearchKey === "1" && (
                <UserGroup>
                  {activeOrSelectedFilteredUsers?.map((user: any) => (
                    <UserCard
                      key={user.id}
                      user={user}
                      onAddUser={onAddUsers}
                      onRemoveUser={onRemoveUsers}
                      isSelected={selectedUserIds.includes(user.id)}
                    />
                  ))}
                </UserGroup>
              )}

              {selectedSearchKey === "2" && (
                <div>
                  <SelectUserByTeam
                    onAddUsers={onAddUsers}
                    onRemoveUsers={onRemoveUsers}
                    users={activeOrSelectedFilteredUsers}
                    teams={filteredTeams}
                  />
                </div>
              )}
            </UserContainer>
          )}
        {(activeOrSelectedFilteredUsers === undefined ||
          activeOrSelectedFilteredUsers.length === 0) && (
          <div
            style={{
              flexGrow: 1,
              height: 0,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <EmptyUsersList />
          </div>
        )}
      </DetailContainer>
    </Container>
  );
};

export default AddUsers;
