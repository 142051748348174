import { useTheme } from "@emotion/react";
import { StyledFC } from "../../types/styledFC";

interface UserContactCellProps {
  contact: { phone: string; email?: string | undefined };
}

const UserContactCell: StyledFC<UserContactCellProps> = ({ contact }) => {
  const theme = useTheme();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 8,
        ...theme.salesleg.typography.body.sm.regular,
      }}
    >
      <div style={{ display: "flex", gap: 4 }}>
        <span style={{ color: theme.salesleg.palette.neutral[400] }}>
          {contact.phone}
        </span>
      </div>
      {contact.email && (
        <div style={{ display: "flex", gap: 4 }}>
          <span style={{ color: theme.salesleg.palette.neutral[400] }}>
            {contact.email}
          </span>
        </div>
      )}
    </div>
  );
};

export default UserContactCell;
