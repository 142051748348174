import { Role } from "@hshrimal/salesleg";
import { Controller, useFormContext } from "react-hook-form";
import { CreateUserFormValues } from ".";
import DropDownV2 from "../../components/DropDownV2/DropDownV2";
import { useLoggedInViewer } from "../../hooks/getLoggedInViewer";
import { StyledFC } from "../../types/styledFC";

const RolesAllowedForOpsRoles = [
  { value: "sales_lead", label: "Sales Manager" },
  { value: "sales_associate", label: "Sales Associate" },
];

const RolesAllowedForITAdmin = [
  ...[
    { value: "marketing", label: "Marketing" },
    { value: "ops", label: "Ops" },
    { value: "sales_head", label: "Sales Head" },
  ],
  ...RolesAllowedForOpsRoles,
];

const RoleDropdown: StyledFC = () => {
  const form = useFormContext<CreateUserFormValues>();
  const viewer = useLoggedInViewer();
  const roles =
    viewer.role === "it" ? RolesAllowedForITAdmin : RolesAllowedForOpsRoles;
  return (
    <Controller
      name="role"
      render={({ field }) => {
        const { value: selectedRole } = field;
        const selectedRoleOption = roles?.find(
          (option) => option.value === selectedRole
        );
        return (
          <DropDownV2
            label="Role"
            width={312}
            height={44}
            items={roles}
            value={selectedRoleOption?.value}
            onChange={(option) => {
              form.setValue("role", option as Role);
            }}
          />
        );
      }}
    />
  );
};

export default RoleDropdown;
