import { useNavigate } from "react-router-dom";
import { createRelativeUrlWithSearchParams } from "../utils/urlHelper";

export function useNavigateWithTeam() {
  const reactRouterDomNavigate = useNavigate();

  function navigate(path: string) {
    const url = new URL(document.URL);
    const searchParams = new URLSearchParams(url.search);
    const teamId = searchParams.get("team_id");
    const branch = searchParams.get("branch");
    let modifiedPath = path;
    if (teamId || branch) {
      modifiedPath = createRelativeUrlWithSearchParams(path, {
        team_id: teamId,
        branch: branch,
      });
    }
    reactRouterDomNavigate(modifiedPath);
  }

  return navigate;
}
