import { createContext, useContext } from "react";
import { defined } from "../utils/assert";

interface TeamContextState {
  selectedTeamId: string | undefined;
  setSelectedTeamId: (value: string | undefined) => void;
}

export const TeamContext = createContext<TeamContextState | undefined>(
  undefined
);

export function useTeamSelector() {
  const context = defined(useContext(TeamContext));
  return context;
}
