import styled from "@emotion/styled";
import { LeadStage } from "@hshrimal/salesleg";
import { FC } from "react";
import { ClientStatusDisplayMap } from "../../utils/clientStatusDisplayHelper";
import Stage from "./Stage";

interface ClientStagePipelineProps {
  activeStage: LeadStage;
}

const stages: LeadStage[] = [
  "new",
  "contacted",
  "interested",
  "visit_planned",
  "visit_done",
  "negotiation",
  "booking",
];

const RootContainer = styled.div(() => ({
  display: "flex",
}));

const ClientStagePipeline: FC<ClientStagePipelineProps> = ({ activeStage }) => {
  const activeStageIndex = stages.findIndex((stage) => stage === activeStage);

  return (
    <RootContainer>
      {stages.map((stage, index) => {
        const isFirstStage = index === 0;
        const isLastStage = index === stages.length - 1;
        const isCompleted = index <= activeStageIndex;
        if (isCompleted) {
          return (
            activeStage === "dump" || (
              <Stage
                key={index}
                isFirstStage={isFirstStage}
                isLastStage={isLastStage}
                isActive={isCompleted}
                label={ClientStatusDisplayMap.get(stage) || ""}
              />
            )
          );
        }
        return null;
      })}

      {stages.map((stage, index) => {
        const isFirstStage = index === 0;
        const isLastStage = index === stages.length - 1;
        const isCompleted = index <= activeStageIndex;
        if (!isCompleted) {
          return (
            <Stage
              key={index}
              isFirstStage={isFirstStage}
              isLastStage={isLastStage}
              isActive={isCompleted}
              label={ClientStatusDisplayMap.get(stage) || ""}
            />
          );
        }
        return null;
      })}
    </RootContainer>
  );
};

export default ClientStagePipeline;
