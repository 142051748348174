import styled from "@emotion/styled";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { ProjectWithExtendedDistributionQueue } from "..";
import { useUrlQueryParam } from "../../../hooks/useUrlQueryParam";
import EmptySelectedUser from "../EmptySelectedUser";
import SelectedUserCard from "../SelectedUserCard";

const Container = styled.div<{ nav?: string | undefined }>(
  ({ theme, nav }) => ({
    display: "flex",
    flexDirection: "column",
    gap: 28,
    paddingTop: 24,
    paddingLeft: 24,
    backgroundColor: theme.salesleg.palette.neutral[25],
    flexGrow: 1,
  })
);

const HeadText = styled.div(({ theme }) => ({
  ...theme.salesleg.typography.heading.md.medium,
}));

const UserDetailContainer = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: 12,
  flexGrow: 1,
}));

const HeaderContainer = styled.div<{ nav?: string | undefined }>(({ nav }) => ({
  display: "flex",
  gap: nav === "expanded" ? 345 : 570,
}));

const SubtitleText = styled.div(({ theme }) => ({
  ...theme.salesleg.typography.caption.sm.medium,
  color: theme.salesleg.palette.neutral[400],
  textTransform: "uppercase",
}));

const SelectedUserGroup = styled.div({
  display: "flex",
  gap: 16,
  flexDirection: "column",
  flex: "1 0 0px",
  overflowY: "auto",
  paddingBottom: 24,
});

const SelectedUsers: FC = () => {
  const { getValues } = useFormContext<ProjectWithExtendedDistributionQueue>();
  const [nav] = useUrlQueryParam("nav");

  const { distributionQueue, users: selectedUsers } = getValues();

  if (
    getValues().distributionQueue === undefined ||
    getValues().distributionQueue.length === 0
  ) {
    return <EmptySelectedUser />;
  }

  return (
    <Container nav={nav}>
      <HeadText>Selected Users</HeadText>
      <UserDetailContainer>
        <HeaderContainer nav={nav}>
          <SubtitleText>User</SubtitleText>
          <SubtitleText>Lead Distribution Weight</SubtitleText>
        </HeaderContainer>
        <SelectedUserGroup>
          {distributionQueue.map((queueMember, index) => (
            <SelectedUserCard
              key={queueMember.salesExecutive.id}
              user={selectedUsers[index]}
              weight={queueMember.weight}
            />
          ))}
        </SelectedUserGroup>
      </UserDetailContainer>
    </Container>
  );
};

export default SelectedUsers;
