import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { ProjectType, ProjectTypes } from "@hshrimal/salesleg";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { ProjectWithExtendedDistributionQueue } from "..";
import Combobox from "../../../components/Combobox";
import BuildingIcon from "../../../components/icons/BuildingIcon";
import HouseIcon from "../../../components/icons/HouseIcon";
import { IconProps } from "../../../components/icons/types";

interface IProjectType {
  key: ProjectType;
  name: string;
  icon: FC<IconProps>;
}

const projectType: IProjectType[] = [
  {
    key: ProjectTypes[0],
    name: "Residential",
    icon: HouseIcon,
  },
  {
    key: ProjectTypes[1],
    name: "Commercial",
    icon: BuildingIcon,
  },
];

const OptionContainer = styled.div(({ theme }) => ({
  ...theme.salesleg.typography.paragraph.sm.medium,
  height: 40,
  width: 200,
  alignItems: "center",
  display: "flex",
  gap: 13,
}));

const SelectedContainer = styled.div(({ theme }) => ({
  ...theme.salesleg.typography.body.sm.regular,
  height: 16,
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
}));

const SelectProjectType = () => {
  const theme = useTheme();
  const { setValue, getValues } =
    useFormContext<ProjectWithExtendedDistributionQueue>();

  const propertyChange = (data: IProjectType) => {
    setValue("type", data.key);
  };

  const { type } = getValues();

  return (
    <Combobox
      options={projectType}
      renderOption={(data) => {
        const Icon = data.icon;
        return (
          <OptionContainer>
            <Icon size={14} color={theme.salesleg.palette.neutral[700]} />
            {data.name}
          </OptionContainer>
        );
      }}
      onSelectionChange={propertyChange}
      selectedOptionRender={(data) => {
        const Icon = data.icon;
        return (
          <SelectedContainer>
            <div style={{ marginRight: 4 }}>
              <Icon size={14} color={theme.salesleg.palette.neutral[400]} />
            </div>
            <div>{data.name}</div>
          </SelectedContainer>
        );
      }}
      initialValuePlaceholder={
        <SelectedContainer>
          <HouseIcon size={14} color={theme.salesleg.palette.neutral[400]} />
          <span style={{ marginLeft: 4 }}> Select Proptery Type </span>
        </SelectedContainer>
      }
      selectedKey={type}
    />
  );
};

export default SelectProjectType;
