import styled from "@emotion/styled";
import CheckBox from "../../components/CheckBox";
import { StyledFC } from "../../types/styledFC";

interface ClientNameCellProps {
  name: string;
  onClick: () => void;
  isSelected: boolean;
  onSelectionChange: (isSelected: boolean) => void;
}
const Container = styled.div(() => ({
  display: "flex",
  alignItems: "center",
  gap: 12,
}));

const Name = styled.div(({ theme }) => ({
  ...theme.salesleg.typography.paragraph.sm.medium,
  color: theme.salesleg.palette.neutral[700],
  textDecorationLine: "underline",
  textDecorationStyle: "dashed",
  cursor: "pointer",
}));

const ClientNameCell: StyledFC<ClientNameCellProps> = ({
  name,
  onClick,
  isSelected,
  onSelectionChange,
}) => {
  return (
    <Container>
      <CheckBox checked={isSelected} onChange={onSelectionChange} size="sm" />
      <Name onClick={onClick}>{name}</Name>
    </Container>
  );
};

export default ClientNameCell;
