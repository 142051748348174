import { ReportFilter } from "@hshrimal/salesleg";
import { useBranchSelector } from "../../../context/branchSelector";
import { useTeamSelector } from "../../../context/teamContext";
import { useLoggedInViewer } from "../../../hooks/getLoggedInViewer";

export function useReportFilter(employeeId?: string): ReportFilter {
  const viewer = useLoggedInViewer();
  const { selectedTeamId } = useTeamSelector();
  const { selectedBranch } = useBranchSelector();

  if (employeeId) {
    return { type: "manager", managerId: employeeId };
  }

  switch (viewer.role) {
    case "business_head":
      return { type: "city", city: selectedBranch };
    case "marketing":
    case "ops":
      return { type: "team", teamId: selectedTeamId };
    case "sales_head":
      return { type: "manager", managerId: viewer.id };
    case "sales_lead":
      return { type: "manager", managerId: viewer.id };
    default:
      // FIX THIS: Change type:"owner". It works functionally for now but does not read correctly
      return { type: "manager", managerId: viewer.id };
  }
}
