import { Controller, useFormContext } from "react-hook-form";
import { CreateUserFormValues } from ".";
import DropDownV2 from "../../components/DropDownV2/DropDownV2";
import { useLoggedInViewer } from "../../hooks/getLoggedInViewer";
import { useGetTeamsQuery } from "../../redux/features/api/teams";
import { StyledFC } from "../../types/styledFC";

const TeamDropdown: StyledFC = () => {
  const viewer = useLoggedInViewer();
  const { data: allTeams } = useGetTeamsQuery(undefined, {
    skip: viewer.role !== "it",
  });
  const teams = viewer.role === "it" ? allTeams || [] : viewer.teams;
  const teamOptions = teams.map((team) => ({
    value: team.id,
    label: team.name,
  }));
  const form = useFormContext<CreateUserFormValues>();

  return (
    <Controller
      name="teamIds"
      control={form.control}
      render={({ field }) => {
        const { value: selectedTeamIds } = field;

        const value =
          selectedTeamIds.length > 0 ? selectedTeamIds[0] : undefined;

        return (
          <DropDownV2
            items={teamOptions}
            value={value}
            onChange={(option) => {
              form.setValue("teamIds", [option] as string[]);
            }}
            label="Team"
            width={312}
            height={44}
          />
        );
      }}
    />
  );
};

export default TeamDropdown;
