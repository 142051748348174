import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { AuthStateType } from "../../auth/types";

const initialState: AuthStateType = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
  loginAsUser: null,
  loginPending: false,
  loginFailed: false,
  loginError: null,
  loginAsPending: false,
  loginAsFailed: false,
  loginAsError: null,
  logoutPending: false,
};

export const initialize = createAsyncThunk("auth/initialize", async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SALESLEG_API_BASE_URL}/api/me`,
      {
        withCredentials: true,
      }
    );
    const { user, loginAsUser } = response.data;
    return { isInitialized: true, isAuthenticated: true, user, loginAsUser };
  } catch (error) {
    return { isInitialized: true, isAuthenticated: false };
  }
});

export const otpLogin = createAsyncThunk(
  "auth/login/otp",
  async (credentials: { phoneNumber: string; otp: string }) => {
    const response = await axios.post(
      `${process.env.REACT_APP_SALESLEG_API_BASE_URL}/api/login/otp`,
      {
        phoneNumber: credentials.phoneNumber,
        otp: credentials.otp,
      },
      {
        withCredentials: true,
      }
    );
    const { user } = response.data;
    return { user: user };
  }
);

export const loginAs = createAsyncThunk(
  "auth/loginas",
  async (credentials: { tenantId: string; userId: string }) => {
    const response = await axios.post(
      `${process.env.REACT_APP_SALESLEG_API_BASE_URL}/api/loginas`,
      credentials,
      { withCredentials: true }
    );
    const { user } = response.data;
    return { user };
  }
);

export const logout = createAsyncThunk("auth/logout", async () => {
  await axios.post(
    `${process.env.REACT_APP_SALESLEG_API_BASE_URL}/api/logout`,
    {},
    { withCredentials: true }
  );
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(initialize.fulfilled, (state, action) => {
      state.isInitialized = true;
      state.isAuthenticated = action.payload.isAuthenticated;
      state.user = action.payload.user || null;
      state.loginAsUser = action.payload.loginAsUser || null;
    });
    builder.addCase(otpLogin.pending, (state) => {
      state.loginPending = true;
      state.loginFailed = false;
      state.loginError = null;
    });
    builder.addCase(otpLogin.rejected, (state, action) => {
      state.loginPending = false;
      state.loginFailed = true;
      state.loginError = action.error;
    });
    builder.addCase(otpLogin.fulfilled, (state, action) => {
      state.loginPending = false;
      state.isAuthenticated = true;
      state.user = action.payload.user;
    });
    builder.addCase(loginAs.pending, (state) => {
      state.loginAsPending = true;
      state.loginAsFailed = false;
      state.loginAsError = null;
    });
    builder.addCase(loginAs.rejected, (state, action) => {
      state.loginAsPending = false;
      state.loginAsFailed = true;
      state.loginAsError = action.error;
    });
    builder.addCase(loginAs.fulfilled, (state, action) => {
      state.loginAsPending = false;
      state.loginAsUser = action.payload.user;
    });
    builder.addCase(logout.pending, (state) => {
      state.logoutPending = true;
    });
    builder.addCase(logout.rejected, (state) => {
      state.logoutPending = false;
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.logoutPending = false;
      state.isAuthenticated = false;
      state.user = null;
      state.loginAsUser = null;
    });
  },
});

export default authSlice.reducer;
