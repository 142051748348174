import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

export function useUrlQueryParam<TParam extends string = string>(
  name: string,
  defaultValue?: TParam | undefined
): [TParam | undefined, (value: TParam | undefined) => void] {
  const [searchParams, setSearchParams] = useSearchParams();

  function get(): TParam | undefined {
    const value = searchParams.get(name);
    if (value === null) {
      return undefined;
    }

    return value as TParam;
  }

  function set(value: string | undefined) {
    setSearchParams((searchParams) => {
      if (value) {
        searchParams.set(name, value);
      } else {
        searchParams.delete(name);
      }

      return searchParams;
    });
  }
  const setMemoized = useCallback(set, [setSearchParams, name]);

  return [get() || defaultValue, setMemoized];
}

export function useMultiSelectUrlQueryParam<TParam extends string = string>(
  name: string,
  defaultValue?: TParam[] | undefined
): [TParam[], (value: TParam[]) => void] {
  const [searchParams, setSearchParams] = useSearchParams();

  function get(): TParam[] {
    const value = searchParams.getAll(name);
    if (value === null) {
      return [];
    }

    return value as TParam[];
  }

  function set(value: TParam[]) {
    setSearchParams((searchParams) => {
      searchParams.delete(name);
      value.forEach((item) => searchParams.append(name, item));
      return searchParams;
    });
  }
  const setMemoized = useCallback(set, [setSearchParams, name]);

  const value: TParam[] = get() || defaultValue || [];
  return [value, setMemoized];
}
