import { FC, useRef, useState } from "react";
import Avatar from "../../components/Avatar";
import IconButton from "../../components/Button/IconButton";
import { Menu } from "../../components/Menu";
import { MenuItem } from "../../components/Menu/MenuItem";
import { ProgressIndicator } from "../../components/ProgressIndicator";
import UserSwitchIcon from "../../components/icons/UserSwitchIcon";
import { useGetEmployeesQuery } from "../../redux/features/api/employees";
import { useTransferLeadsMutation } from "../../redux/features/api/leads";

interface ChangeAssigneeButtonProps {
  assignedTo: {
    id?: string | undefined;
  };
  leadId: string;
}

const ChangeAssigneeButton: FC<ChangeAssigneeButtonProps> = ({
  assignedTo,
  leadId,
}) => {
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen((open) => !open);
  };
  const [transferLeads] = useTransferLeadsMutation();

  const onAssigneeChange = (assigneeId: string) => {
    transferLeads({ leadIds: [leadId], ownerId: assigneeId });
  };

  const { data: employees, isFetching: isFetchingEmployees } =
    useGetEmployeesQuery({
      role: ["sales_head", "sales_lead", "sales_associate"],
      status: "active",
    });

  return (
    <>
      <IconButton
        color={`${open ? "primary" : "secondary-gray"}`}
        size="sm"
        icon={UserSwitchIcon}
        onClick={() => {
          toggle();
        }}
        ref={buttonRef}
      />
      <Menu
        open={open}
        onClose={() => setOpen(false)}
        placement="bottom"
        enableSearch
        searchPlaceholder="Search Employee"
        anchorEl={buttonRef.current || undefined}
        value={assignedTo.id}
        onChange={(assignedTo) => onAssigneeChange(assignedTo as string)}
      >
        {employees?.map((employee) => {
          return (
            <MenuItem
              key={employee.id}
              label={employee.name}
              variant="avatar"
              avatarComponent={Avatar}
              selected={employee.id === assignedTo.id}
              value={employee.id}
            />
          );
        })}
      </Menu>
      <ProgressIndicator open={isFetchingEmployees} />
    </>
  );
};

export default ChangeAssigneeButton;
