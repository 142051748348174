import { debounce } from "lodash";
import { useCallback } from "react";

export function useDebouncedCallback<T extends (...args: any) => any>(
  callback: T,
  wait: number = 500
) {
  // Whenever Eslint cannot see body of the callback passed to `React.useCallback`,
  // it cannot determine dependencies of the callback and it warns us about the same.
  // Generally, it's  a best practice to define the callback passed to ESLint inline,
  // but that unfortunately cannot be done when the callback is generated by an external
  // library like `debounce`. Therefore, disabling the warning
  //
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedCallback = useCallback(debounce(callback, wait), [callback]);

  return debouncedCallback;
}
