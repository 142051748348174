import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import AuthGuard from "./auth/AuthGuard";
import Layout from "./layout";
import { store } from "./redux/store";

function App() {
  console.log(`API Base URL: ${process.env.REACT_APP_SALESLEG_API_BASE_URL}`);
  return (
    <ReduxProvider store={store}>
      <AuthGuard>
        <BrowserRouter>
          <Layout />
        </BrowserRouter>
      </AuthGuard>
    </ReduxProvider>
  );
}

export default App;
