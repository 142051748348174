import { useTheme } from "@emotion/react";
import Avatar from "../../components/Avatar";
import { StyledFC } from "../../types/styledFC";

interface UserNameCellProps {
  name: string;
  onClick: () => void;
}

const UserNameCell: StyledFC<UserNameCellProps> = ({ name, onClick }) => {
  const theme = useTheme();
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: 12,
      }}
    >
      <Avatar size={40} />
      <div
        onClick={onClick}
        style={{
          ...theme.salesleg.typography.paragraph.sm.medium,
          color: theme.salesleg.palette.neutral[700],
          textDecorationLine: "underline",
          textDecorationStyle: "dashed",
          cursor: "pointer",
        }}
      >
        {name}
      </div>
    </div>
  );
};

export default UserNameCell;
