import { useTheme } from "@emotion/react";
import { StyledFC } from "../../types/styledFC";

interface UserAliasCellProps {
  alias?: string | undefined;
}

const UserAliasCell: StyledFC<UserAliasCellProps> = ({ alias }) => {
  const theme = useTheme();
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <div
        style={{
          ...theme.salesleg.typography.paragraph.sm.medium,
          color: theme.salesleg.palette.neutral[400],
        }}
      >
        {alias}
      </div>
    </div>
  );
};

export default UserAliasCell;
