import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { EmployeeFilter } from "@hshrimal/salesleg";
import Button from "../../components/Button/Button";
import Tabs, { TabParams } from "../../components/Tabs";
import ExportIcon from "../../components/icons/ExportIcon";
import PlusIcon from "../../components/icons/PlusIcon";
import UserCircleIcon from "../../components/icons/UserCircleIcon";
import UsersThreeIcon from "../../components/icons/UsersThreeIcon";
import { useLoggedInViewer } from "../../hooks/getLoggedInViewer";
import { useNavigateWithTeam } from "../../hooks/useNavigationWithTeam";
import { useUrlQueryParam } from "../../hooks/useUrlQueryParam";
import { useGetEmployeesQuery } from "../../redux/features/api/employees";
import { StyledFC } from "../../types/styledFC";
import { useOrgFilter } from "../ViewClients/useOrgFilter";
import CreateTeamButton from "./CreateTeamButton";
import DeactiveUserTabContent from "./DeactiveUserTabContent";
import TeamTabContent from "./TeamTabContent";
import UserTabContent from "./UserTabContent";
import { downloadEmployees } from "./utils/export";

const RootContainer = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
}));

const ButtonGroup = styled.div(() => ({
  display: "flex",
  gap: 16,
}));

const ViewUsers: StyledFC = ({ style }) => {
  const [activeTab] = useUrlQueryParam<string>("tab", "1");
  const theme = useTheme();
  const viewer = useLoggedInViewer();
  const navigate = useNavigateWithTeam();

  const tabs: TabParams[] = [
    {
      id: "1",
      label: "Active Users",
      children: <UserTabContent id="1" />,
      icon: UserCircleIcon,
    },
    {
      id: "3",
      label: "Inactive Users",
      children: <DeactiveUserTabContent id="3" />,
      icon: UserCircleIcon,
    },
  ];

  if (viewer.role === "it") {
    tabs.push({
      id: "2",
      label: "Teams",
      children: <TeamTabContent id="2" />,
      icon: UsersThreeIcon,
    });
  }

  const orgFilter = useOrgFilter();
  const employeeFilter: EmployeeFilter = {
    ...orgFilter,
    status: "active",
    role: ["sales_head", "sales_lead", "sales_associate", "marketing", "ops"],
  };
  const { data: employees } = useGetEmployeesQuery(employeeFilter);

  return (
    <RootContainer>
      <div
        style={{
          flex: "0 0 auto",
          height: 84,
          backgroundColor: "#ffffff",
          paddingLeft: 32,
          paddingRight: 32,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            ...theme.salesleg.typography.heading.md.semiBold,
            color: theme.salesleg.palette.neutral[600],
          }}
        >
          User Management
        </div>

        {activeTab === "1" && (
          <ButtonGroup>
            <Button
              size="sm"
              color="secondary-gray"
              onClick={() => {
                downloadEmployees(employees || []);
              }}
              label="Export Users"
              leadingIcon={ExportIcon}
            />
            <Button
              size="sm"
              color="primary"
              label="Create User"
              leadingIcon={PlusIcon}
              onClick={() => navigate("/users/create")}
            />
          </ButtonGroup>
        )}

        {activeTab === "2" && <CreateTeamButton />}
      </div>
      <Tabs
        tabs={tabs}
        activeTab={activeTab || "1"}
        variant="grid"
        handleTabClick={(tab: TabParams) => navigate("/users?tab=" + tab.id)}
        tabHeaderStyle={{
          paddingLeft: 32,
          paddingRight: 32,
        }}
      />
    </RootContainer>
  );
};

export default ViewUsers;
