import { Filter } from "../../components/Filter";
import { MenuItem } from "../../components/Menu/MenuItem";
import MapPinIcon from "../../components/icons/MapPinIcon";
import { FilterUrlParams } from "../../constants/filters";

export function GroupBySelector() {
  return (
    <Filter
      icon={MapPinIcon}
      name="GroupBy"
      urlParamName={FilterUrlParams.GROUP_BY}
      disableClearButton={true}
    >
      <MenuItem value="project" variant="default" label="Project" />
      <MenuItem value="manager" variant="default" label="Sales Manager" />
    </Filter>
  );
}
