import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { FC, ReactNode } from "react";
import ClearIcon from "../icons/ClearIcon";

interface ModalHeaderProps {
  children: ReactNode;
  toggle: () => void;
}

const Container = styled.div(() => ({
  padding: "32px 24px 24px 24px",
  display: "flex",
}));

const CloseIcon = styled.div(() => ({
  position: "absolute",
  right: 24,
  top: 34,
  cursor: "pointer",
}));

const ModalHeader: FC<ModalHeaderProps> = ({ children, toggle }) => {
  const theme = useTheme();
  return (
    <Container>
      <CloseIcon onClick={toggle}>
        <ClearIcon size={16} color={theme.salesleg.palette.neutral[300]} />
      </CloseIcon>
      {children}
    </Container>
  );
};

export default ModalHeader;
