import { FilterUrlParams } from "../../constants/filters";
import { useGetEmployeesQuery } from "../../redux/features/api/employees";
import { Filter } from "../Filter";
import { MenuItem } from "../Menu/MenuItem";
import { ProgressIndicator } from "../ProgressIndicator";
import UserIcon from "../icons/UserIcon";

const OwnerFilter = () => {
  const { data: employees, isFetching } = useGetEmployeesQuery({
    status: "active",
  });

  return (
    <>
      <Filter
        icon={UserIcon}
        name="Owner"
        urlParamName={FilterUrlParams.OWNER}
        multiple={true}
        enableSearch
        searchPlaceholder="Search"
      >
        {employees?.map((employee) => (
          <MenuItem
            key={employee.id}
            variant="default"
            value={employee.id}
            label={employee.name}
          />
        )) || []}
      </Filter>
      <ProgressIndicator open={isFetching} />
    </>
  );
};

export default OwnerFilter;
