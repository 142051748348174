import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import {
  ExtendedEmployee,
  Project,
  TeamReference,
  WithMetadata,
} from "@hshrimal/salesleg";
import { FC, useState } from "react";
import { useFormContext } from "react-hook-form";
import CheckBox from "../../../../../components/CheckBox";
import DropdownCloseIcon from "../../../../../components/icons/DropdownCloseIcon";
import DropdownOpenIcon from "../../../../../components/icons/DropdownOpenIcon";
import UserCard from "../../../UserCard";

const UserGroup = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: 16,
  padding: "10px 0px",
}));

const Container = styled.div(() => ({
  display: "flex",
  paddingLeft: 16,
  alignItems: "center",
  gap: 16,
}));

const HeaderContainer = styled.div(() => ({
  display: "flex",
  alignItems: "center",
  gap: 16,
  cursor: "pointer",
}));

const HearderText = styled.div(({ theme }) => ({
  ...theme.salesleg.typography.caption.lg.medium,
  color: theme.salesleg.palette.neutral[300],
  textTransform: "uppercase",
  letterSpacing: 1,
  width: 308,
}));

interface TeamCollapseProps {
  onAddUsers: (users: WithMetadata<ExtendedEmployee>[]) => void;
  onRemoveUsers: (users: WithMetadata<ExtendedEmployee>[]) => void;
  filteredUsers: WithMetadata<ExtendedEmployee>[];
  team: TeamReference;
}
const TeamCollapse: FC<TeamCollapseProps> = ({
  onAddUsers,
  filteredUsers,
  team,
  onRemoveUsers,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();
  const { getValues } = useFormContext<Project>();

  const toggle = () => {
    setIsOpen((open) => !open);
  };

  const selectedUserIds = getValues().distributionQueue.map(
    (user) => user.salesExecutive.id
  );

  const filteredUser = filteredUsers.filter((user) => {
    const teamIds = user.teams.map((team) => team.id);
    return teamIds.includes(team.id);
  });

  const isAllSelected = filteredUser.every((user) =>
    selectedUserIds.includes(user.id)
  );

  const isPartiallySelected = filteredUser.some((user) =>
    selectedUserIds.includes(user.id)
  );

  const onSelectTeam = (
    users: WithMetadata<ExtendedEmployee>[],
    isChecked: boolean
  ) => {
    if (isChecked) {
      onAddUsers(users);
    } else {
      onRemoveUsers(users);
    }
  };

  return (
    <UserGroup>
      <Container>
        <CheckBox
          size="sm"
          checked={isAllSelected}
          onChange={() => onSelectTeam(filteredUser, !isAllSelected)}
          partialChecked={isPartiallySelected && isAllSelected === false}
        />
        <HeaderContainer onClick={toggle}>
          <HearderText>{team.name}</HearderText>
          {isOpen ? (
            <DropdownCloseIcon
              size={24}
              color={theme.salesleg.palette.neutral[300]}
            />
          ) : (
            <DropdownOpenIcon
              size={24}
              color={theme.salesleg.palette.neutral[300]}
            />
          )}
        </HeaderContainer>
      </Container>

      {isOpen &&
        filteredUser.map((user) => (
          <UserCard
            key={user.id}
            user={user}
            onAddUser={onAddUsers}
            onRemoveUser={onRemoveUsers}
            isSelected={
              isAllSelected ? isAllSelected : selectedUserIds.includes(user.id)
            }
            showTeam={false}
          />
        ))}
    </UserGroup>
  );
};

export default TeamCollapse;
