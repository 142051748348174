import capitalize from "capitalize";
import { FilterUrlParams } from "../../constants/filters";
import { Filter } from "../Filter";
import { MenuItem } from "../Menu/MenuItem";
import RadioButtonIcon from "../icons/RadioButtonIcon";

const SiteVisitStatusOptions = ["canceled", "pending", "completed"];

export const SiteVisitStatusFilter = () => {
  return (
    <Filter
      icon={RadioButtonIcon}
      name="Status"
      urlParamName={FilterUrlParams.VISIT_STATUS}
      multiple={true}
    >
      {SiteVisitStatusOptions.map((option) => (
        <MenuItem
          key={option}
          variant="default"
          value={option}
          label={capitalize(option)}
        />
      ))}
    </Filter>
  );
};
