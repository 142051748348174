import { EmployeeFilter } from "@hshrimal/salesleg";
import { FilterUrlParams } from "../../constants/filters";
import { useOrgFilter } from "../../pages/ViewClients/useOrgFilter";
import { useGetEmployeesQuery } from "../../redux/features/api/employees";
import { FilterMenu, FilterMenuProps } from "../FilterMenu";
import { MenuItem } from "../Menu/MenuItem";

const ManagerMenu = ({
  open,
  onClose,
  anchorEl,
  placement,
  multiple,
}: Omit<FilterMenuProps, "children" | "urlParamName">) => {
  const orgFilter = useOrgFilter();
  const employeeFilter: EmployeeFilter = {
    ...orgFilter,
    role: ["sales_lead", "sales_head"],
    status: "active",
  };
  const { data: managers } = useGetEmployeesQuery(employeeFilter);

  return (
    <FilterMenu
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      placement={placement}
      multiple={multiple}
      urlParamName={FilterUrlParams.MANAGER}
    >
      {managers?.map((manager) => (
        <MenuItem
          key={manager.id}
          variant="default"
          value={manager.id}
          label={manager.name}
        />
      ))}
    </FilterMenu>
  );
};

export default ManagerMenu;
