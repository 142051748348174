import { EmployeeFilter, Role, deserializeEmployee } from "@hshrimal/salesleg";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { ErrorNotification } from "../../components/ErrorNotification";
import { ProgressIndicator } from "../../components/ProgressIndicator";
import { useNavigateWithTeam } from "../../hooks/useNavigationWithTeam";
import {
  useCreateEmployeeMutation,
  useGetEmployeesQuery,
  useUpdateEmployeeMutation,
} from "../../redux/features/api/employees";
import { StyledFC } from "../../types/styledFC";
import { useOrgFilter } from "../ViewClients/useOrgFilter";
import Content from "./Content";
import Header from "./Header";

export interface CreateUserFormValues {
  name: string;
  alias: string;
  phoneNumber: string;
  email: string;
  role: Role;
  title: string;
  teamIds: string[];
  managerId: string;
}

const CreateUser: StyledFC = ({ style }) => {
  const form = useForm<CreateUserFormValues>({
    defaultValues: {
      teamIds: [],
    },
  });
  const { userId } = useParams();
  const [
    createEmployee,
    {
      isLoading: isCreatingEmployee,
      error: createUserError,
      status: employeeCreationStatus,
      data: createEmployeeResponse,
    },
  ] = useCreateEmployeeMutation();

  const [
    updateEmployee,
    { isLoading: isUpdatingEmployee, status: employeeUpdationStatus },
  ] = useUpdateEmployeeMutation();

  const orgFilter = useOrgFilter();
  const employeeFilter: EmployeeFilter = {
    ...orgFilter,
  };
  const { data: users } = useGetEmployeesQuery(employeeFilter, {
    skip: userId === undefined,
  });

  const navigate = useNavigateWithTeam();

  useEffect(() => {
    if (
      !isCreatingEmployee &&
      employeeCreationStatus === "fulfilled" &&
      createEmployeeResponse
    ) {
      navigate(`/users/${createEmployeeResponse.id}`);
    }
  }, [
    employeeCreationStatus,
    createEmployeeResponse,
    isCreatingEmployee,
    navigate,
  ]);

  useEffect(() => {
    if (!isUpdatingEmployee && employeeUpdationStatus === "fulfilled") {
      navigate(`/users/${userId}`);
    }
  }, [employeeUpdationStatus, isUpdatingEmployee, navigate, userId]);

  useEffect(() => {
    const user = users?.find((user) => user.id === userId);
    if (user) {
      form.setValue("name", user.name);
      form.setValue("alias", user.alias || "");
      form.setValue("email", user.email || "");
      form.setValue("phoneNumber", user.phoneNumber.replace("+91", ""));
      form.setValue("role", user.role);
      form.setValue("title", user.title);
      form.setValue(
        "teamIds",
        user.teams.map((team) => team.id)
      );
      form.setValue("managerId", user.manager?.id || "");
    }
  }, [form, userId, users]);

  function onSubmit(formValues: CreateUserFormValues) {
    formValues.phoneNumber = `+91${formValues.phoneNumber}`;
    const employee = deserializeEmployee({
      ...formValues,
      department: "Sales",
    });

    if (userId) {
      updateEmployee({ id: userId, ...employee });
    } else {
      createEmployee(employee);
    }
  }

  return (
    <>
      <FormProvider {...form}>
        <div style={{ ...style, display: "flex", flexDirection: "column" }}>
          <Header onSubmit={onSubmit} />
          <Content />
        </div>
      </FormProvider>
      <ProgressIndicator open={isCreatingEmployee || isUpdatingEmployee} />
      {createUserError && (
        <ErrorNotification message={(createUserError as any).data.message} />
      )}
    </>
  );
};

export default CreateUser;
