import styled from "@emotion/styled";
import { LeadSource } from "@hshrimal/salesleg";
import HouseLineIcon from "../../components/icons/HouseLineIcon";
import theme from "../../theme/light/theme";
import { StyledFC } from "../../types/styledFC";
import { LeadSourceDisplayMap } from "../../utils/leadSourceDisplayHelper";

interface ProjectCellProps {
  developerName: string;
  projectName: string;
  leadSource: LeadSource;
}

const Container = styled.div(({ theme }) => ({
  ...theme.salesleg.typography.body.sm.regular,
  display: "flex",
  flexDirection: "column",
  gap: 8,
}));

const TitleContainer = styled.div(() => ({
  display: "flex",
  gap: 4,
}));

const Title = styled.div(({ theme }) => ({
  color: theme.salesleg.palette.neutral[700],
  whiteSpace: "nowrap",
}));

const SubTitle = styled.div(({ theme }) => ({
  color: theme.salesleg.palette.neutral[500],
  whiteSpace: "nowrap",
}));

const ProjectCell: StyledFC<ProjectCellProps> = ({
  developerName,
  projectName,
  leadSource,
}) => {
  return (
    <Container>
      <TitleContainer>
        <HouseLineIcon color={theme.palette.neutral[400]} size={16} />
        <Title>
          {developerName} {projectName}
        </Title>
      </TitleContainer>
      <SubTitle>{LeadSourceDisplayMap.get(leadSource)}</SubTitle>
    </Container>
  );
};

export default ProjectCell;
