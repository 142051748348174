import styled from "@emotion/styled";
import { useFormContext } from "react-hook-form";
import { CreateUserFormValues } from ".";
import { StyledFC } from "../../types/styledFC";
import ContactDetailsSection from "./ContactDetailsSection";
import EmploymentDetailsSection from "./EmploymentDetailsSection";
import SelectTeamSection from "./SelectTeamSection";

const Container = styled.div(({ theme }) => ({
  flexGrow: 1,
  height: 0,
  overflowY: "auto",
  backgroundColor: theme.salesleg.palette.neutral[25],
  padding: "24px 32px",
  display: "flex",
  flexDirection: "column",
  gap: 24,
}));

const Title = styled.span(({ theme }) => ({
  ...theme.salesleg.typography.heading.md.medium,
  color: theme.salesleg.palette.neutral[900],
}));

const Content: StyledFC = () => {
  const form = useFormContext<CreateUserFormValues>();

  const { role } = form.getValues();

  return (
    <Container>
      <Title>User Details</Title>
      <ContactDetailsSection />
      <EmploymentDetailsSection />

      {(role === "marketing" || role === "ops" || role === "sales_head") && (
        <SelectTeamSection />
      )}
    </Container>
  );
};

export default Content;
