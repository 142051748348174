import styled from "@emotion/styled";
import { EmployeeFilter } from "@hshrimal/salesleg";
import { FC, useContext, useEffect } from "react";
import { FilterPanel } from "../../components/FilterPanel";
import Pagination from "../../components/Pagination";
import { ProgressIndicator } from "../../components/ProgressIndicator";
import Table from "../../components/Table";
import {
  ITabContext,
  TabContext,
} from "../../components/Tabs/TabContextProvider";
import ManagerFilter from "../../components/filters/ManagerFilter";
import TeamFilter from "../../components/filters/TeamFilter";
import { FilterUrlParams } from "../../constants/filters";
import { useLoggedInViewer } from "../../hooks/getLoggedInViewer";
import { useNavigateWithTeam } from "../../hooks/useNavigationWithTeam";
import {
  useMultiSelectUrlQueryParam,
  useUrlQueryParam,
} from "../../hooks/useUrlQueryParam";
import { useGetEmployeesQuery } from "../../redux/features/api/employees";
import { useOrgFilter } from "../ViewClients/useOrgFilter";
import StatusCell from "./StatusCell";
import UserAliasCell from "./UserAliasCell";
import UserContactCell from "./UserContactCell";
import UserManagerCell from "./UserManagerCell";
import UserNameCell from "./UserNameCell";
import UserRoleCell from "./UserRoleCell";
import UserTeamNameCell from "./UserTeamNameCell";

interface DeactiveUserTabContentProps {
  id: string;
}

const RootContainer = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
}));

const TableContainer = styled.div(({ theme }) => ({
  backgroundColor: theme.salesleg.palette.neutral[25],
  flexGrow: 1,
  padding: 32,
  flexBasis: 0,
  overflowY: "auto",
}));

const DeactiveUserTabContent: FC<DeactiveUserTabContentProps> = ({ id }) => {
  const navigate = useNavigateWithTeam();
  const viewer = useLoggedInViewer();

  const orgFilter = useOrgFilter();
  const employeeFilter: EmployeeFilter = {
    ...orgFilter,
    status: "deactivated",
  };
  const { data: employees, isFetching: isFetchingEmployees } =
    useGetEmployeesQuery(employeeFilter);

  const { setBadgeValue: setValue } = useContext<ITabContext>(TabContext);

  const [searchText] = useUrlQueryParam("search");
  const [teamFilter] = useUrlQueryParam(FilterUrlParams.TEAM);
  const [managerFilter] = useMultiSelectUrlQueryParam(FilterUrlParams.MANAGER);
  const [page_size] = useUrlQueryParam("page_size", "10");
  const [page] = useUrlQueryParam("page", "1");

  useEffect(() => {
    if (employees) {
      setValue(id, employees.length.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employees]);

  let filteredEmployees = employees?.filter(
    (employee) =>
      (managerFilter.length === 0 ||
        (employee.manager !== undefined &&
          managerFilter.includes(employee.manager?.id))) &&
      (!teamFilter ||
        employee.teams.map((team) => team.id).includes(teamFilter)) &&
      (!searchText ||
        employee.name.toLowerCase().startsWith(searchText.toLocaleLowerCase()))
  );

  let currentPageUsers = employees;
  if (page_size && page) {
    const parsedPageSize = parseInt(page_size);
    const parsedPage = parseInt(page);
    const startRecordIndex = (parsedPage - 1) * parsedPageSize;
    const endRecordIndex = startRecordIndex + parsedPageSize;

    currentPageUsers = filteredEmployees?.slice(
      startRecordIndex,
      endRecordIndex
    );
  }

  const filters = [];
  if (viewer.role === "it") {
    filters.push({
      urlParamName: FilterUrlParams.TEAM,
      component: <TeamFilter key="team" />,
      permanent: true,
    });
  }
  filters.push({
    urlParamName: FilterUrlParams.MANAGER,
    component: <ManagerFilter key="manager" />,
    permanent: true,
  });

  return (
    <RootContainer>
      <FilterPanel masterFilterMenuItems={[]} directFilters={filters} />
      <TableContainer>
        <Table
          columnGroups={[
            { span: 1, width: 300 },
            { span: 1, width: 208 },
            { span: 1, width: 288.5 },
            { span: 1, width: 328.5 },
            { span: 1, width: 195 },
          ]}
          columns={[
            { name: "User" },
            { name: "Status" },
            { name: "Alias" },
            { name: "Role" },
            { name: "Team" },
            { name: "Manager" },
            { name: "Contact" },
          ]}
          rows={currentPageUsers || []}
          renderTableRowCells={(row) => [
            <UserNameCell
              name={row.name}
              onClick={() => navigate(`/users/${row.id}`)}
            />,
            <StatusCell status={row.status} />,
            <UserAliasCell alias={""} />, // CHECK: row.alias not found
            <UserRoleCell role={row.role} />,
            <UserTeamNameCell team={row.teams[0].name} />,
            <UserManagerCell manager={row.manager?.name} />,
            <UserContactCell
              contact={{ phone: row.phoneNumber, email: row.email }}
            />,
          ]}
        />
      </TableContainer>
      {filteredEmployees && (
        <Pagination numRecords={filteredEmployees.length} />
      )}
      <ProgressIndicator open={isFetchingEmployees} />
    </RootContainer>
  );
};

export default DeactiveUserTabContent;
