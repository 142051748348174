import { PaletteColor } from "../../types";

const color: PaletteColor = {
  25: "#F4F3FF",
  50: "#EBE8FF",
  100: "#D8D5FF",
  200: "#BBB3FF",
  300: "#9887FE",
  400: "#7857FB",
  500: "#7857FB",
  600: "#6735F3",
  700: "#5822DF",
  800: "#491CBB",
  900: "#230D68",
};

export default color;
