import {
  ExtendedProject,
  Project,
  ProjectFilter,
  WithMetadata,
  deserializeExtendedProjects,
} from "@hshrimal/salesleg";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const projectsApi = createApi({
  reducerPath: "projectsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_SALESLEG_API_BASE_URL}/api`,
    prepareHeaders: (headers) => headers.append("client", "web"),
    credentials: "include",
  }),
  tagTypes: ["projects"],
  endpoints: (builder) => ({
    getProjects: builder.query<WithMetadata<ExtendedProject>[], ProjectFilter>({
      query: (projectFilter) => ({
        method: "Post",
        url: `/projects/query`,
        body: projectFilter,
      }),
      transformResponse: (data) => deserializeExtendedProjects(data),
      providesTags: ["projects"],
    }),
    createProject: builder.mutation<{ id: string }, Project>({
      query: (input) => ({
        url: `/projects`,
        method: "POST",
        body: input,
      }),
      invalidatesTags: ["projects"],
    }),
    updateProject: builder.mutation<void, Project & { id: string }>({
      query: (input) => ({
        url: `/projects/${input.id}`,
        method: "PUT",
        body: input,
      }),
      invalidatesTags: ["projects"],
    }),
    addToProjectDistributionQueue: builder.mutation<
      void,
      {
        projectId: string;
        distributionQueueItem: { employeeId: string; weight: number };
      }
    >({
      query: (input) => ({
        url: `/projects/${input.projectId}/distributionqueue`,
        method: "POST",
        body: input.distributionQueueItem,
      }),
      invalidatesTags: ["projects"],
    }),
    deleteProjectDistributionQueueItem: builder.mutation<
      void,
      {
        projectId: string;
        employeeId: string;
      }
    >({
      query: (input) => ({
        url: `/projects/${input.projectId}/distributionqueue/${input.employeeId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["projects"],
    }),
  }),
});

export const {
  useGetProjectsQuery,
  useCreateProjectMutation,
  useUpdateProjectMutation,
  useAddToProjectDistributionQueueMutation,
  useDeleteProjectDistributionQueueItemMutation,
} = projectsApi;
