import styled from "@emotion/styled";
import { EmployeeFilter } from "@hshrimal/salesleg";
import { useParams } from "react-router-dom";
import { ProgressIndicator } from "../../components/ProgressIndicator";
import { useGetEmployeesQuery } from "../../redux/features/api/employees";
import { StyledFC } from "../../types/styledFC";
import { useOrgFilter } from "../ViewClients/useOrgFilter";
import Header from "./Header";
import { ProjectsCard } from "./ProjectsCard";
import UserDetails from "./UserDetails";

const ContentContainer = styled.div(({ theme }) => ({
  padding: "24px 32px",
  flexGrow: 1,
  backgroundColor: theme.salesleg.palette.neutral[25],
  display: "flex",
  gap: 24,
  overflowY: "auto",
}));

const ViewUser: StyledFC = ({ style }) => {
  const id = useParams();
  const userId = id.userId;

  const orgFilter = useOrgFilter();
  const employeeFilter: EmployeeFilter = {
    ...orgFilter,
  };
  const { data: users, isFetching } = useGetEmployeesQuery(employeeFilter);

  if (!users) return <></>;

  const user = users.find((user) => user.id === userId);
  if (!user) {
    throw new Error("User not found");
  }

  const manager = users.find((mapUser) => mapUser.id === user?.manager?.id);

  return (
    <>
      <div
        style={{
          ...style,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Header user={user} />
        <ContentContainer>
          <UserDetails user={user} manager={manager} />
          <ProjectsCard userId={user.id} />
        </ContentContainer>
      </div>
      {isFetching && <ProgressIndicator open={true} />}
    </>
  );
};

export default ViewUser;
