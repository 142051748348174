import { PaletteColor } from "../../types";

const color: PaletteColor = {
  25: "#FFF1F3",
  50: "#FFE4E8",
  100: "#FFCCD6",
  200: "#FEA3B4",
  300: "#FD6F8D",
  400: "#F63D68",
  500: "#E31B53",
  600: "#C01046",
  700: "#A11041",
  800: "#89123E",
  900: "#4D041D",
};

export default color;
