import { ThemeProvider as EmotionThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material/styles";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import theme from "./theme/light/theme";

const muiTheme = createTheme({ palette: {} });
const mergedTheme = { ...muiTheme, salesleg: theme };

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.Fragment>
    <EmotionThemeProvider theme={mergedTheme}>
      <App />
    </EmotionThemeProvider>
  </React.Fragment>
);
