import styled from "@emotion/styled";
import { Role } from "@hshrimal/salesleg";
import Tabs, { TabParams } from "../../components/Tabs";
import { FilterUrlParams } from "../../constants/filters";
import { useNavigateWithTeam } from "../../hooks/useNavigationWithTeam";
import { useUrlQueryParam } from "../../hooks/useUrlQueryParam";
import { getCurrentMonthQueryString } from "../../utils/dateHelper";
import ActiveLeadsContent from "./ActiveLeadsContent";
import AllLeadContent from "./AllLeadContent";
import FollowupContent from "./FollowupContent";
import Header from "./Header";
import ProductivityContent from "./ProductivityContent";
import DownloadProvider from "./context/DownloadProvider";

export interface Employee {
  id: string;
  name: string;
  role: Role;
}

const RootContainer = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
}));

const tabs: TabParams[] = [
  {
    id: "1",
    label: "All Leads",
    children: <AllLeadContent />,
  },
  {
    id: "2",
    label: "Active Leads",
    children: <ActiveLeadsContent />,
  },
  {
    id: "3",
    label: "Follow up",
    children: <FollowupContent />,
  },
  {
    id: "4",
    label: "Productivity",
    children: <ProductivityContent />,
  },
];

const AllLeads = () => {
  const [activeTab] = useUrlQueryParam<string>("tab", "1");
  const navigate = useNavigateWithTeam();

  const handleTabClick = (tab: TabParams) => {
    if (tab.id === "1") {
      const currentMonthDateRangeStr = getCurrentMonthQueryString();
      navigate(
        "/allLeads?tab=" +
          tab.id +
          `&${FilterUrlParams.CREATION_DATE}=${currentMonthDateRangeStr}&group_by=manager`
      );
    } else if (tab.id === "4") {
      const currentMonthDateRangeStr = getCurrentMonthQueryString();
      navigate(
        "/allLeads?tab=" +
          tab.id +
          `&${FilterUrlParams.CREATION_DATE}=${currentMonthDateRangeStr}`
      );
    } else {
      navigate("/allLeads?tab=" + tab.id);
    }
  };

  return (
    <RootContainer>
      <DownloadProvider>
        <Header activeTab={activeTab} />
        <Tabs
          tabs={tabs}
          activeTab={activeTab || "1"}
          variant="grid"
          handleTabClick={handleTabClick}
          tabHeaderStyle={{
            paddingLeft: 32,
            paddingRight: 32,
          }}
        />
      </DownloadProvider>
    </RootContainer>
  );
};

export default AllLeads;
