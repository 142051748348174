import styled from "@emotion/styled";
import { LeadSource } from "@hshrimal/salesleg";
import { FC } from "react";
import Tag from "../../components/Tag";
import { LeadSourceDisplayMap } from "../../utils/LeasesourceDisplayHelper";

const RootContainer = styled.div(() => ({
  display: "flex",
  gap: 20,
}));

const Field = styled.div(() => ({
  display: "flex",
  alignItems: "center",
  gap: 8,
}));

const FieldName = styled.div(({ theme }) => ({
  ...theme.salesleg.typography.paragraph.sm.medium,
  color: theme.salesleg.palette.neutral[400],
}));

const FieldValue = styled.div(({ theme }) => ({
  ...theme.salesleg.typography.body.sm.regular,
  color: theme.salesleg.palette.neutral[700],
}));

interface ProjectInfoProps {
  projectName: string;
  developerName: string;
  source: LeadSource;
}

const ProjectInfo: FC<ProjectInfoProps> = ({
  projectName,
  developerName,
  source,
}) => {
  return (
    <RootContainer>
      <Field>
        <FieldName>Project Name:</FieldName>
        <FieldValue>{projectName}</FieldValue>
      </Field>
      <Field>
        <FieldName>Developer:</FieldName>
        <FieldValue>{developerName}</FieldValue>
      </Field>
      <Field>
        <FieldName>Source</FieldName>
        <Tag label={LeadSourceDisplayMap.get(source)?.toUpperCase() || ""} />
      </Field>
    </RootContainer>
  );
};

export default ProjectInfo;
