import styled from "@emotion/styled";
import { FC, ReactNode } from "react";

interface ModalFooterProps {
  children: ReactNode;
}

const Container = styled.div(({ theme }) => ({
  padding: "14px 24px",
  position: "absolute",
  bottom: 0,
  width: "100%",
  display: "flex",
  justifyContent: "end",
  gap: 16,
  backgroundColor: "#ffffff",
  borderTop: `1px solid ${theme.salesleg.palette.neutral[100]}`,
}));

const ModalFooter: FC<ModalFooterProps> = ({ children }) => {
  return <Container>{children} </Container>;
};

export default ModalFooter;
