import { Role } from "@hshrimal/salesleg";
import { UserRoleDisplayMap } from "../utils/userRoleDisplayHelper";
import Badge, { BadgeColor } from "./Badge";

interface UserRoleBadgeProps {
  role: Role;
}

function getBadgeColor(role: Role): BadgeColor {
  switch (role) {
    case "admin":
      return "violet";
    case "sales_associate":
      return "rose";
    case "sales_lead":
      return "orange";
    case "sales_head":
      return "primary";
    case "ops":
      return "neutral";
    case "marketing":
      return "pink";
    default:
      throw new Error(`Unrecognized role value ${role}`);
  }
}

export function UserRoleBadge({ role }: UserRoleBadgeProps) {
  return (
    <Badge
      label={UserRoleDisplayMap.get(role) || ""}
      color={getBadgeColor(role)}
      size="sm"
    />
  );
}
