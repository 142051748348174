import React, { ReactElement, ReactNode, useRef, useState } from "react";
import { useMultiSelectUrlQueryParam } from "../hooks/useUrlQueryParam";
import { FilterButton } from "./FilterButton";
import { Menu } from "./Menu";
import { MenuItemProps } from "./Menu/MenuItem";
import AllFilter from "./filters/AllFilter";
import { IconComponent } from "./icons/types";

interface FilterProps {
  name: string;
  icon: IconComponent;
  urlParamName: string;
  children?: ReactNode | undefined;
  multiple?: boolean | false;
  enableSearch?: boolean | false;
  searchPlaceholder?: string | undefined;
  disableClearButton?: boolean | undefined;
}

export function Filter({
  name,
  icon,
  urlParamName,
  children,
  multiple,
  enableSearch,
  searchPlaceholder,
  disableClearButton,
}: FilterProps) {
  const [open, setOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] =
    useMultiSelectUrlQueryParam(urlParamName);
  const filterButtonRef = useRef<HTMLButtonElement | null>(null);

  const menuItemsValueToLabelMap = new Map<string, string>();
  const childrenArray = React.Children.toArray(children);
  childrenArray.forEach((child) => {
    const menuItemElement = child as ReactElement<MenuItemProps>;
    if (menuItemElement.props.value && menuItemElement.props.label) {
      menuItemsValueToLabelMap.set(
        menuItemElement.props.value,
        menuItemElement.props.label
      );
    }
  });

  const selectedOptionLabels: string[] = [];
  selectedOptions.forEach((option) => {
    const optionLabel = menuItemsValueToLabelMap.get(option);
    if (optionLabel) {
      selectedOptionLabels.push(optionLabel);
    }
  });

  function handleChange(value: string | string[] | undefined) {
    if (multiple) {
      const isAllSelected = childrenArray.length === value?.length;
      if (isAllSelected) {
        setSelectedOptions([]);
      } else {
        setSelectedOptions(value as string[]);
      }
    } else {
      const singleValue = value as string | undefined;
      if (singleValue === undefined) {
        setSelectedOptions([]);
      } else if (Array.isArray(singleValue)) {
        setSelectedOptions(singleValue);
      } else {
        setSelectedOptions([singleValue as string]);
      }
      setOpen(false);
    }
  }

  // TODO: this logic can be consolidated with line 55
  let isAllSelected = selectedOptions.length === childrenArray.length;

  if (selectedOptions.length === 0) {
    isAllSelected = true;
  }

  const handleSelectAllChange = () => {
    const newIsAllSelected = !isAllSelected;
    if (newIsAllSelected) {
      setSelectedOptions([]);
    }
  };

  const onCloseRemoveItem = (tag: string) => {
    let removeValueIndex = undefined;
    selectedOptions.forEach((option, index) => {
      const optionLabel = menuItemsValueToLabelMap.get(option);
      if (optionLabel === tag) {
        removeValueIndex = index;
      }
    });

    if (removeValueIndex !== undefined) {
      selectedOptions.splice(removeValueIndex, 1);
      handleChange(selectedOptions);
    }
  };

  const allFilterRemove = () => {
    setSelectedOptions([]);
  };

  return (
    <>
      <FilterButton
        size="sm"
        leadingIcon={icon}
        label={name}
        valueDisplayType={isAllSelected ? "text" : "tags"}
        valueTags={selectedOptionLabels}
        valueText="All"
        onClick={() => setOpen((open) => !open)}
        ref={filterButtonRef}
        onTagRemoveClick={onCloseRemoveItem}
        onFilterRemoveClick={allFilterRemove}
        disableClearButton={disableClearButton}
      />
      <Menu
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={filterButtonRef.current || undefined}
        placement="bottom"
        value={multiple ? selectedOptions : selectedOptions[0]}
        onChange={handleChange}
        multiple={multiple}
        enableSearch={enableSearch}
        searchPlaceholder={searchPlaceholder}
      >
        {multiple && (
          <AllFilter
            checked={isAllSelected}
            handleSelectAllChange={handleSelectAllChange}
          />
        )}
        {children}
      </Menu>
    </>
  );
}
