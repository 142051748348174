import { Role } from "@hshrimal/salesleg";
import { UserRoleBadge } from "../../components/UserRoleBadge";
import { StyledFC } from "../../types/styledFC";

interface UserRoleCellProps {
  role: Role;
}

const UserRoleCell: StyledFC<UserRoleCellProps> = ({ role }) => {
  return <UserRoleBadge role={role} />;
};

export default UserRoleCell;
