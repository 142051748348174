import styled from "@emotion/styled";
import { ExtendedEmployee, WithMetadata } from "@hshrimal/salesleg";
import { FC, useState } from "react";
import Avatar from "../../components/Avatar";
import Badge from "../../components/Badge";
import Button from "../../components/Button/Button";
import LinkButton from "../../components/Button/LinkButton";
import ArrowLeftIcon from "../../components/icons/ArrowLeftIcon";
import PencilSimpleIcon from "../../components/icons/PencilSimpleIcon";
import ToggleLeftIcon from "../../components/icons/ToggleLeftIcon";
import { useLoggedInViewer } from "../../hooks/getLoggedInViewer";
import { useNavigateWithTeam } from "../../hooks/useNavigationWithTeam";
import ActivateModal from "./ActivateModal";
import DeactivateModal from "./DeactivateModal";

const Container = styled.div(({ theme }) => ({
  flex: "0 0 auto",
  height: 120,
  padding: "24px 32px",
  backgroundColor: "#ffffff",
  border: `1px solid ${theme.salesleg.palette.neutral[100]}`,
  display: "flex",
  flexDirection: "column",
  gap: 8,
}));

const TitleAndActionContainer = styled.div({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const TitleContainer = styled.div({
  width: 385,
  height: 44,
  display: "flex",
  alignItems: "center",
  gap: 8,
});

const UserName = styled.div(({ theme }) => ({
  ...theme.salesleg.typography.heading.sm.medium,
  color: theme.salesleg.palette.neutral[900],
  paddingTop: 10,
  paddingBottom: 10,
}));

const AvatarContainer = styled.div(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: 8,
}));

const ActionContainer = styled.div({ display: "flex", gap: 12 });

interface HeaderProps {
  user: WithMetadata<ExtendedEmployee>;
}
const Header: FC<HeaderProps> = ({ user }) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigateWithTeam();
  const toggle = () => {
    setOpen(!open);
  };

  const viewer = useLoggedInViewer();
  return (
    <Container>
      <LinkButton
        size="sm"
        color="gray"
        label="Back"
        leadingIcon={ArrowLeftIcon}
        onClick={() => {
          navigate("/users");
        }}
      />
      <TitleAndActionContainer>
        <TitleContainer>
          <AvatarContainer>
            <Avatar size={32} isActive={user.status === "active"} />
            <UserName>{user?.name}</UserName>
            {user.status === "deactivated" ? (
              <Badge color="error" label="Inactive" size="sm" />
            ) : (
              <Badge color="success" label="Active" size="sm" />
            )}
          </AvatarContainer>
        </TitleContainer>
        {(viewer.role === "ops" || viewer.role === "it") && (
          <ActionContainer>
            {(user?.status === "active" || user?.status === undefined) && (
              <Button
                color="tertiary-gray"
                onClick={toggle}
                size="sm"
                label="Deactivate"
                leadingIcon={ToggleLeftIcon}
              />
            )}
            {user?.status === "deactivated" && (
              <Button
                color="tertiary-gray"
                onClick={toggle}
                size="sm"
                label="Activate"
                leadingIcon={ToggleLeftIcon}
              />
            )}
            {user?.status !== "deactivated" && (
              <DeactivateModal open={open} toggle={toggle} />
            )}
            {user?.status === "deactivated" && (
              <ActivateModal open={open} toggle={toggle} />
            )}
            <Button
              color="secondary"
              onClick={() => navigate(`/users/${user?.id}/update`)}
              size="sm"
              label="Edit User"
              leadingIcon={PencilSimpleIcon}
            />
          </ActionContainer>
        )}
      </TitleAndActionContainer>
    </Container>
  );
};

export default Header;
