import { Theme } from "../types";
import primaryColor from "./palette/primary";
import neutralColor from "./palette/neutral";
import errorColor from "./palette/error";
import successColor from "./palette/success";
import warningColor from "./palette/warning";
import roseColor from "./palette/rose";
import pinkColor from "./palette/pink";
import violetColor from "./palette/violet";
import limeColor from "./palette/lime";
import orangeColor from "./palette/orange";

const theme: Theme = {
  palette: {
    primary: primaryColor,
    neutral: neutralColor,
    error: errorColor,
    success: successColor,
    warning: warningColor,
    custom: {
      rose: roseColor,
      pink: pinkColor,
      violet: violetColor,
      lime: limeColor,
      orange: orangeColor,
    },
  },
  shadow: {
    xs: "0px 1px 2px rgba(16, 24, 40, 0.05)",
    sm: "0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)",
    md: "0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)",
    lg: "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
    xl: "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
    xxl: "0px 24px 48px -12px rgba(16, 24, 40, 0.18)",
    xxxl: "0px 32px 64px -12px rgba(16, 24, 40, 0.14)",
  },
  blur: {
    light: {
      sm: "blur(4px)",
      md: "blur(8px)",
      lg: "blur(12px)",
      xl: "blur(20px)",
    },
    dark: {
      sm: "blur(4px)",
      md: "blur(8px)",
      lg: "blur(12px)",
      xl: "blur(20px)",
    },
  },
  typography: {
    display: {
      sm: {
        regular: {
          fontFamily: "Switzer",
          fontWeight: 400,
          fontStyle: "normal",
          fontSize: "40px",
          lineHeight: "48px",
        },
        medium: {
          fontFamily: "Switzer",
          fontWeight: 500,
          fontStyle: "normal",
          fontSize: "40px",
          lineHeight: "48px",
        },
        semiBold: {
          fontFamily: "Switzer",
          fontWeight: 600,
          fontStyle: "normal",
          fontSize: "40px",
          lineHeight: "48px",
        },
        bold: {
          fontFamily: "Switzer",
          fontWeight: 700,
          fontStyle: "normal",
          fontSize: "40px",
          lineHeight: "48px",
        },
      },
      md: {
        regular: {
          fontFamily: "Switzer",
          fontWeight: 400,
          fontStyle: "normal",
          fontSize: "48px",
          lineHeight: "56px",
        },
        medium: {
          fontFamily: "Switzer",
          fontWeight: 500,
          fontStyle: "normal",
          fontSize: "48px",
          lineHeight: "56px",
        },
        semiBold: {
          fontFamily: "Switzer",
          fontWeight: 600,
          fontStyle: "normal",
          fontSize: "48px",
          lineHeight: "56px",
        },
        bold: {
          fontFamily: "Switzer",
          fontWeight: 700,
          fontStyle: "normal",
          fontSize: "48px",
          lineHeight: "56px",
        },
      },
      lg: {
        regular: {
          fontFamily: "Switzer",
          fontWeight: 400,
          fontStyle: "normal",
          fontSize: "56px",
          lineHeight: "64px",
        },
        medium: {
          fontFamily: "Switzer",
          fontWeight: 500,
          fontStyle: "normal",
          fontSize: "56px",
          lineHeight: "64px",
        },
        semiBold: {
          fontFamily: "Switzer",
          fontWeight: 600,
          fontStyle: "normal",
          fontSize: "56px",
          lineHeight: "64px",
        },
        bold: {
          fontFamily: "Switzer",
          fontWeight: 700,
          fontStyle: "normal",
          fontSize: "56px",
          lineHeight: "64px",
        },
      },
      xl: {
        regular: {
          fontFamily: "Switzer",
          fontWeight: 400,
          fontStyle: "normal",
          fontSize: "64px",
          lineHeight: "72px",
        },
        medium: {
          fontFamily: "Switzer",
          fontWeight: 500,
          fontStyle: "normal",
          fontSize: "64px",
          lineHeight: "72px",
        },
        semiBold: {
          fontFamily: "Switzer",
          fontWeight: 600,
          fontStyle: "normal",
          fontSize: "64px",
          lineHeight: "72px",
        },
        bold: {
          fontFamily: "Switzer",
          fontWeight: 700,
          fontStyle: "normal",
          fontSize: "64px",
          lineHeight: "72px",
        },
      },
    },
    heading: {
      sm: {
        regular: {
          fontFamily: "Switzer",
          fontWeight: 400,
          fontStyle: "normal",
          fontSize: "20px",
          lineHeight: "24px",
        },
        medium: {
          fontFamily: "Switzer",
          fontWeight: 500,
          fontStyle: "normal",
          fontSize: "20px",
          lineHeight: "24px",
        },
        semiBold: {
          fontFamily: "Switzer",
          fontWeight: 600,
          fontStyle: "normal",
          fontSize: "20px",
          lineHeight: "24px",
        },
        bold: {
          fontFamily: "Switzer",
          fontWeight: 700,
          fontStyle: "normal",
          fontSize: "20px",
          lineHeight: "24px",
        },
      },
      md: {
        regular: {
          fontFamily: "Switzer",
          fontWeight: 400,
          fontStyle: "normal",
          fontSize: "24px",
          lineHeight: "32px",
        },
        medium: {
          fontFamily: "Switzer",
          fontWeight: 500,
          fontStyle: "normal",
          fontSize: "24px",
          lineHeight: "32px",
        },
        semiBold: {
          fontFamily: "Switzer",
          fontWeight: 600,
          fontStyle: "normal",
          fontSize: "24px",
          lineHeight: "32px",
        },
        bold: {
          fontFamily: "Switzer",
          fontWeight: 700,
          fontStyle: "normal",
          fontSize: "24px",
          lineHeight: "32px",
        },
      },
      lg: {
        regular: {
          fontFamily: "Switzer",
          fontWeight: 400,
          fontStyle: "normal",
          fontSize: "32px",
          lineHeight: "40px",
        },
        medium: {
          fontFamily: "Switzer",
          fontWeight: 500,
          fontStyle: "normal",
          fontSize: "32px",
          lineHeight: "40px",
        },
        semiBold: {
          fontFamily: "Switzer",
          fontWeight: 600,
          fontStyle: "normal",
          fontSize: "32px",
          lineHeight: "40px",
        },
        bold: {
          fontFamily: "Switzer",
          fontWeight: 700,
          fontStyle: "normal",
          fontSize: "32px",
          lineHeight: "40px",
        },
      },
      xl: {
        regular: {
          fontFamily: "Switzer",
          fontWeight: 400,
          fontStyle: "normal",
          fontSize: "36px",
          lineHeight: "44px",
        },
        medium: {
          fontFamily: "Switzer",
          fontWeight: 500,
          fontStyle: "normal",
          fontSize: "36px",
          lineHeight: "44px",
        },
        semiBold: {
          fontFamily: "Switzer",
          fontWeight: 600,
          fontStyle: "normal",
          fontSize: "36px",
          lineHeight: "44px",
        },
        bold: {
          fontFamily: "Switzer",
          fontWeight: 700,
          fontStyle: "normal",
          fontSize: "36px",
          lineHeight: "44px",
        },
      },
    },
    body: {
      xs: {
        regular: {
          fontFamily: "Switzer",
          fontWeight: 400,
          fontStyle: "normal",
          fontSize: "12px",
          lineHeight: "16px",
        },
        medium: {
          fontFamily: "Switzer",
          fontWeight: 500,
          fontStyle: "normal",
          fontSize: "12px",
          lineHeight: "16px",
        },
        semiBold: {
          fontFamily: "Switzer",
          fontWeight: 600,
          fontStyle: "normal",
          fontSize: "12px",
          lineHeight: "16px",
        },
        bold: {
          fontFamily: "Switzer",
          fontWeight: 700,
          fontStyle: "normal",
          fontSize: "12px",
          lineHeight: "16px",
        },
      },
      sm: {
        regular: {
          fontFamily: "Switzer",
          fontWeight: 400,
          fontStyle: "normal",
          fontSize: "14px",
          lineHeight: "16px",
        },
        medium: {
          fontFamily: "Switzer",
          fontWeight: 500,
          fontStyle: "normal",
          fontSize: "14px",
          lineHeight: "16px",
        },
        semiBold: {
          fontFamily: "Switzer",
          fontWeight: 600,
          fontStyle: "normal",
          fontSize: "14px",
          lineHeight: "16px",
        },
        bold: {
          fontFamily: "Switzer",
          fontWeight: 700,
          fontStyle: "normal",
          fontSize: "14px",
          lineHeight: "16px",
        },
      },
      md: {
        regular: {
          fontFamily: "Switzer",
          fontWeight: 400,
          fontStyle: "normal",
          fontSize: "16px",
          lineHeight: "20px",
        },
        medium: {
          fontFamily: "Switzer",
          fontWeight: 500,
          fontStyle: "normal",
          fontSize: "16px",
          lineHeight: "20px",
        },
        semiBold: {
          fontFamily: "Switzer",
          fontWeight: 600,
          fontStyle: "normal",
          fontSize: "16px",
          lineHeight: "20px",
        },
        bold: {
          fontFamily: "Switzer",
          fontWeight: 700,
          fontStyle: "normal",
          fontSize: "16px",
          lineHeight: "20px",
        },
      },
      lg: {
        regular: {
          fontFamily: "Switzer",
          fontWeight: 400,
          fontStyle: "normal",
          fontSize: "18px",
          lineHeight: "22px",
        },
        medium: {
          fontFamily: "Switzer",
          fontWeight: 500,
          fontStyle: "normal",
          fontSize: "18px",
          lineHeight: "22px",
        },
        semiBold: {
          fontFamily: "Switzer",
          fontWeight: 600,
          fontStyle: "normal",
          fontSize: "18px",
          lineHeight: "22px",
        },
        bold: {
          fontFamily: "Switzer",
          fontWeight: 700,
          fontStyle: "normal",
          fontSize: "18px",
          lineHeight: "22px",
        },
      },
    },
    paragraph: {
      sm: {
        regular: {
          fontFamily: "Switzer",
          fontWeight: 400,
          fontStyle: "normal",
          fontSize: "14px",
          lineHeight: "20px",
        },
        medium: {
          fontFamily: "Switzer",
          fontWeight: 500,
          fontStyle: "normal",
          fontSize: "14px",
          lineHeight: "20px",
        },
      },
      md: {
        regular: {
          fontFamily: "Switzer",
          fontWeight: 400,
          fontStyle: "normal",
          fontSize: "16px",
          lineHeight: "24px",
        },
        medium: {
          fontFamily: "Switzer",
          fontWeight: 500,
          fontStyle: "normal",
          fontSize: "16px",
          lineHeight: "24px",
        },
      },
      lg: {
        regular: {
          fontFamily: "Switzer",
          fontWeight: 400,
          fontStyle: "normal",
          fontSize: "18px",
          lineHeight: "26px",
        },
        medium: {
          fontFamily: "Switzer",
          fontWeight: 500,
          fontStyle: "normal",
          fontSize: "18px",
          lineHeight: "26px",
        },
      },
    },
    caption: {
      sm: {
        medium: {
          fontFamily: "Switzer",
          fontWeight: 500,
          fontStyle: "normal",
          fontSize: "12px",
          lineHeight: "14px",
        },
        semiBold: {
          fontFamily: "Switzer",
          fontWeight: 600,
          fontStyle: "normal",
          fontSize: "12px",
          lineHeight: "14px",
        },
      },
      lg: {
        medium: {
          fontFamily: "Switzer",
          fontWeight: 500,
          fontStyle: "normal",
          fontSize: "14px",
          lineHeight: "16px",
        },
        semiBold: {
          fontFamily: "Switzer",
          fontWeight: 600,
          fontStyle: "normal",
          fontSize: "14px",
          lineHeight: "16px",
        },
      },
    },
  },
};

export default theme;
