export const downloadCsv = (data: string, fileName: string) => {
  // Create a blob with the data we want to download as a file
  const blob = new Blob([data], { type: "text/csv" });

  // Create an anchor element and dispatch a click event on it
  // to trigger a download
  const a = document.createElement("a");
  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);
  const clickEvt = new MouseEvent("click", {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  a.dispatchEvent(clickEvt);
  a.remove();
};

export function exportToCsv<TRow>(
  data: TRow[],
  getAttributes: (row: TRow) => string[]
): string[] {
  let csv = data.reduce((acc: string[], item: TRow) => {
    const attributes = getAttributes(item);
    acc.push(attributes.join(","));
    return acc;
  }, []);

  return csv;
}
