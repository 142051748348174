import dayjs from "dayjs";

export const getDateAndTime = (date: Date) => {
  const dateStr = dayjs(date).format("DD MMM YYYY");
  const timeStr = dayjs(date).format("h:mm A");
  return { dateStr, timeStr };
};

export const getLeadAgeInDays = (leadCreationTime: Date) => {
  const currentTime = dayjs();
  const creationTime = dayjs(leadCreationTime);
  return currentTime.diff(creationTime, "day");
};

export const getLeadAgeInHours = (leadCreationTime: Date) => {
  const currentTime = dayjs();
  const creationTime = dayjs(leadCreationTime);
  return currentTime.diff(creationTime, "hour");
};

export const getLeadAge = (leadCreationTime: Date) => {
  const day = getLeadAgeInDays(leadCreationTime);
  if (day === 0) {
    const hours = getLeadAgeInHours(leadCreationTime);
    if (hours === 1) {
      return `${hours} Hour`;
    }
    return `${hours} Hours`;
  }
  if (day === 1) {
    return `${day} Day`;
  }
  return `${day} Days`;
};

export const getCurrentMonthQueryString = () => {
  const now = dayjs();
  const startOfThisMonth = now.startOf("month");
  const endofThisMonth = now.endOf("month").startOf("day").add(1, "day");

  const startOfThisMonthStr = startOfThisMonth.toISOString();
  const endOfThisMonthStr = endofThisMonth.toISOString();
  const currentMonthDateRangeStr =
    startOfThisMonthStr + "_" + endOfThisMonthStr;
  return currentMonthDateRangeStr;
};
