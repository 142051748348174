import styled from "@emotion/styled";
import { ClickAwayListener, Modal, Popper } from "@mui/material";
import React, { ReactElement, ReactNode, useEffect, useState } from "react";
import theme from "../../theme/light/theme";
import { MenuItemProps } from "./MenuItem";
import { SearchInput } from "./SearchInput";

export type Placement = "bottom" | "right" | "top";

export type MenuType = "action" | "select";

export type ThemeColor = "light" | "dark";

interface ColorScheme {
  container: {
    backgroundColor: string;
    border: string;
  };
}

const lightTheme: ColorScheme = {
  container: {
    backgroundColor: "#ffffff",
    border: `1px solid ${theme.palette.neutral[50]}`,
  },
};
const darkTheme: ColorScheme = {
  container: {
    backgroundColor: theme.palette.neutral[900],
    border: `1px solid ${theme.palette.neutral[300]}`,
  },
};

const getColorScheme = (variant: ThemeColor) => {
  switch (variant) {
    case "light":
      return lightTheme;
    case "dark":
      return darkTheme;
  }
};

export interface MenuProps {
  open: boolean;
  onClose?: (clickAway: boolean) => void;
  anchorEl?: HTMLElement | undefined;
  placement?: Placement | undefined;
  multiple?: boolean | undefined;
  children: React.ReactNode;
  value?: string | string[] | undefined;
  onChange?: (value: string | string[] | undefined) => void;
  enableSearch?: boolean | undefined;
  searchPlaceholder?: string | undefined;
  quickAddComponent?: ReactNode | undefined;
  disableCloseOnSelection?: boolean | undefined;
  menuType?: MenuType | undefined;
  themeColor?: ThemeColor | undefined;
  height?: number | string | undefined;
  width?: number | string | undefined;
}

const RootContainer = styled.div<{
  placement: Placement;
  menuType: string;
  colorScheme: ColorScheme;
  height: number | string;
  width: string | number | undefined;
}>(({ placement, theme, menuType, colorScheme, height, width }) => ({
  boxShadow: theme.salesleg.shadow.xxl,
  borderTopLeftRadius: 8,
  borderTopRightRadius: 8,
  padding: menuType === "action" ? "0px 0px" : "8px 0px",
  ...(placement === "top" && { marginTop: 12, marginBottom: 12 }),
  ...(placement === "bottom" && { marginTop: 12, marginBottom: 12 }),
  ...(placement === "right" && { marginLeft: 12 }),
  display: "flex",
  flexDirection: "column",
  alignItems: "stretch",
  overflowY: "auto",
  maxHeight: height,
  width: width,
  ...colorScheme.container,
}));

const QuickAddComponentContainer = styled.div(({ theme }) => ({
  padding: "10px 14px",
  background: "#ffffff",
  borderBottomRightRadius: 8,
  borderBottomLeftRadius: 8,
  boxShadow: theme.salesleg.shadow.xxl,
}));

interface MenuContainerProps {
  onClickAway?: (() => void) | undefined;
  children: React.ReactElement;
}

function MenuContainer({ onClickAway, children }: MenuContainerProps) {
  if (onClickAway) {
    return (
      <ClickAwayListener onClickAway={onClickAway}>
        {children}
      </ClickAwayListener>
    );
  }

  return (
    <ClickAwayListener onClickAway={() => {}}>{children}</ClickAwayListener>
  );
}

export function Menu({
  open,
  onClose,
  anchorEl,
  placement = "bottom",
  children,
  multiple,
  value,
  onChange,
  enableSearch,
  searchPlaceholder,
  quickAddComponent,
  disableCloseOnSelection,
  width,
  height = 400,
  menuType = "select",
  themeColor = "light",
}: MenuProps) {
  const [visible, setVisible] = useState<boolean>(open);
  const [searchText, setSearchText] = useState<string | undefined>();
  const colorScheme = getColorScheme(themeColor);

  useEffect(() => {
    setVisible(open);
    if (open === false) {
      setSearchText("");
    }
  }, [open]);

  function handleItemClick(child: ReactElement<MenuItemProps>) {
    if (!onChange) {
      return;
    }

    const itemValue = child.props.value;
    let newValue;
    if (multiple) {
      const values = (value as string[]).slice();
      newValue = values.slice();
      const itemIndex = itemValue ? values.indexOf(itemValue) : -1;
      if (itemValue && itemIndex === -1) {
        newValue.push(itemValue);
      } else {
        newValue.splice(itemIndex, 1);
      }
    } else {
      newValue = itemValue !== value ? itemValue : undefined;
      if (!disableCloseOnSelection && onClose) {
        onClose(false);
      }
    }

    onChange(newValue);
  }

  const childrenArray = React.Children.toArray(children);
  const items = childrenArray.map((child) => {
    if (!React.isValidElement(child)) {
      return null;
    }

    const menuItemElement = child as ReactElement<MenuItemProps>;

    if (
      searchText &&
      !child.props.label?.toLowerCase().includes(searchText?.toLowerCase())
    ) {
      return <></>;
    }

    let selected = false;
    if (multiple) {
      if (!Array.isArray(value)) {
        throw new Error(
          "The `value` prop must be an array " +
            "when using the `Menu` component with `multiple`."
        );
      }
      const values = value as string[];
      if (
        menuItemElement.props.value &&
        values.findIndex((value) => value === menuItemElement.props.value) !==
          -1
      ) {
        selected = true;
      }
    } else {
      selected = menuItemElement.props.value === (value as string | undefined);
    }

    const handleMouseDown: React.MouseEventHandler<HTMLButtonElement> = (e) => {
      e.preventDefault();
    };
    return React.cloneElement(menuItemElement, {
      selected: selected,
      ...(!menuItemElement.props.onClick && {
        onClick: () =>
          menuItemElement.props.onClick || handleItemClick(menuItemElement),
      }),
      onMouseDown: handleMouseDown,
    });
  });

  return (
    <Modal open={visible} slotProps={{ backdrop: { invisible: true } }}>
      <>
        <MenuContainer
          onClickAway={() => {
            onClose && onClose(true);
          }}
        >
          <Popper
            open={visible}
            anchorEl={anchorEl}
            placement={`${placement}-start`}
            style={{ zIndex: 1400 }}
          >
            <RootContainer
              placement={placement}
              menuType={menuType}
              colorScheme={colorScheme}
              height={height}
              width={width}
            >
              {enableSearch && (
                <SearchInput
                  value={searchText}
                  onChange={setSearchText}
                  placeholder={searchPlaceholder}
                  style={{ marginLeft: 14, marginRight: 14 }}
                  autoFocus={true}
                />
              )}
              {items}
            </RootContainer>
            {quickAddComponent && (
              <QuickAddComponentContainer>
                {quickAddComponent}
              </QuickAddComponentContainer>
            )}
          </Popper>
        </MenuContainer>
      </>
    </Modal>
  );
}
