import styled from "@emotion/styled";
import { CompletedActivity, WithMetadata } from "@hshrimal/salesleg";
import { TimelineDot } from "@mui/lab";
import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from "@mui/lab/TimelineOppositeContent";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import { FC, ReactNode } from "react";
import ClientStageBadge from "../../components/ClientStatusBadge";
import CheckCircleIcon from "../../components/icons/CheckCircle";
import theme from "../../theme/light/theme";
import { defined } from "../../utils/assert";
import { getDateAndTime } from "../../utils/dateHelper";
import ActivityType from "./ActivityType";

export interface TabsProps {
  id: string;
  label: string;
  children: ReactNode;
}

const RootContainer = styled.div(({ theme }) => ({
  display: "flex",
  paddingTop: 24,
  paddingLeft: 24,
}));

const CheckIconStyle = styled.div(({ theme }) => ({
  borderRadius: 25,
  border: `8px solid ${theme.salesleg.palette.neutral[100]}`,
  alignItems: "center",
  display: "flex",
  backgroundColor: theme.salesleg.palette.neutral[100],
  justifyContent: "center",
}));

const DateText = styled.div(({ theme }) => ({
  ...theme.salesleg.typography.body.xs.regular,
  color: theme.salesleg.palette.neutral[600],
}));

const TimeText = styled.div(({ theme }) => ({
  ...theme.salesleg.typography.body.xs.regular,
  color: theme.salesleg.palette.neutral[400],
}));

const DateTimeContainer = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 2,
  width: 80,
}));

const ActivityDetail = styled.div(({ theme }) => ({
  ...theme.salesleg.typography.paragraph.sm.regular,
}));

interface ActivityTimelineProps {
  leadCreatedAt: Date;
  activities: WithMetadata<CompletedActivity>[];
}

const ActivityTimeline: FC<ActivityTimelineProps> = ({
  activities,
  leadCreatedAt,
}) => {
  const { dateStr: creationDateStr, timeStr: creationTimeStr } =
    getDateAndTime(leadCreatedAt);
  const activitiesCopy = [...activities];
  return (
    <RootContainer>
      <Timeline
        sx={{
          [`& .${timelineOppositeContentClasses.root}`]: {
            flex: 0,
            pt: "0px",
            pr: "12px",
            pl: "0px",
            whiteSpace: "nowrap",
            textAlign: "left",
          },
        }}
      >
        {activitiesCopy.reverse().map((activity, i) => {
          const { dateStr, timeStr } = getDateAndTime(
            activity.type === "site_visit" &&
              (activity.siteVisitStatus === "canceled" ||
                activity.siteVisitStatus === "rescheduled")
              ? defined(activity.scheduled_at)
              : activity.completed_at
          );
          return (
            <TimelineItem key={activity.id}>
              <TimelineOppositeContent>
                <DateTimeContainer>
                  <DateText>{dateStr}</DateText>
                  <TimeText>{timeStr}</TimeText>
                </DateTimeContainer>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <CheckIconStyle>
                  <CheckCircleIcon
                    color={theme.palette.neutral[600]}
                    size={16}
                  />
                </CheckIconStyle>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent
                sx={{
                  pl: "12px",
                  pt: "0px",
                }}
              >
                {(i === activitiesCopy.length - 1 ||
                  activity.result !== activitiesCopy[i + 1].result) && (
                  <ClientStageBadge stage={activity.result} />
                )}
                <ActivityDetail>{activity.details}</ActivityDetail>
                <ActivityType
                  type={activity.type}
                  siteVisitStatus={activity.siteVisitStatus}
                />
              </TimelineContent>
            </TimelineItem>
          );
        })}
        <TimelineItem key={"new"}>
          <TimelineOppositeContent>
            <DateTimeContainer>
              <DateText>{creationDateStr}</DateText>
              <TimeText>{creationTimeStr}</TimeText>
            </DateTimeContainer>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 25,
                px: "8px",
                py: "8px",
                border: `8px solid ${theme.palette.neutral[100]}`,
                backgroundColor: theme.palette.neutral[600],
                m: 0,
              }}
            />
          </TimelineSeparator>
          <TimelineContent
            sx={{
              pl: "12px",
              pt: "0px",
            }}
          >
            <ClientStageBadge stage="new" />
          </TimelineContent>
        </TimelineItem>
      </Timeline>
    </RootContainer>
  );
};

export default ActivityTimeline;
