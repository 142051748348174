import { LeadStage } from "@hshrimal/salesleg";
import ClientStageBadge from "../../components/ClientStatusBadge";
import { StyledFC } from "../../types/styledFC";

interface ClientStageCellProps {
  status: LeadStage;
}

const ClientStageCell: StyledFC<ClientStageCellProps> = ({ status }) => {
  return <ClientStageBadge stage={status} />;
};

export default ClientStageCell;
