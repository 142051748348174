import { FC } from "react";
import { ReactComponent as LogoBlackAndWhite } from "./../assets/logo.svg";
import { ReactComponent as LogoColored } from "./../assets/logo-colored.svg";

interface LogoProps {
  size: number;
  colored?: boolean | undefined;
}

const Logo: FC<LogoProps> = ({ size, colored }) => {
  return colored ? (
    <LogoColored />
  ) : (
    <LogoBlackAndWhite width={size} height={size} />
  );
};

export default Logo;
