import styled from "@emotion/styled";
import { ExtendedEmployee, WithMetadata } from "@hshrimal/salesleg";
import { FC } from "react";
import SectionHeader from "./SectionHeader";

const Container = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: 16,
}));

const FieldsContainer = styled.div(() => ({
  display: "flex",
  flexWrap: "wrap",
  alignContent: "flex-start",
  rowGap: 24,
  columnGap: 24,
}));

const ContactDetailContainer = styled.div(() => ({
  width: 312,
  height: 64,
}));

const Title = styled.div(({ theme }) => ({
  ...theme.salesleg.typography.paragraph.sm.medium,
  color: theme.salesleg.palette.neutral[700],
}));

const Value = styled.div(({ theme }) => ({
  ...theme.salesleg.typography.paragraph.md.regular,
  color: theme.salesleg.palette.neutral[500],
  paddingTop: 10,
  paddingRight: 14,
  paddingBottom: 10,
}));

interface ContactDetailsSectionProps {
  user: WithMetadata<ExtendedEmployee> | undefined;
}

const ContactDetailsSection: FC<ContactDetailsSectionProps> = ({ user }) => {
  return (
    <Container>
      <SectionHeader title="Contact Details" />
      <FieldsContainer>
        <ContactDetailContainer>
          <Title>Phone Number</Title>
          <Value>{user?.phoneNumber}</Value>
        </ContactDetailContainer>
        <ContactDetailContainer>
          <Title>Email Address</Title>
          <Value>{user?.email}</Value>
        </ContactDetailContainer>
      </FieldsContainer>
    </Container>
  );
};

export default ContactDetailsSection;
