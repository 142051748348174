import styled from "@emotion/styled";
import Button from "../../components/Button/Button";
import CheckSquareOffsetIcon from "../../components/icons/CheckSquareOffsetIcon";
import theme from "../../theme/light/theme";

const RootContainer = styled.div(({ theme }) => ({
  backgroundColor: theme.salesleg.palette.neutral[100],
  borderTop: `1px solid ${theme.salesleg.palette.neutral[300]}`,
  borderBottom: `1px solid ${theme.salesleg.palette.neutral[300]}`,
  display: "flex",
  alignItems: "center",
  paddingTop: 16,
  paddingBottom: 16,
  paddingLeft: 24,
  paddingRight: 32,
  justifyContent: "space-between",
}));

const IconContainer = styled.div(({ theme }) => ({
  width: 40,
  height: 40,
  borderRadius: 20,
  backgroundColor: theme.salesleg.palette.neutral[200],
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const FollowupContainer = styled.div(() => ({
  display: "flex",
  alignItems: "center",
  gap: 16,
}));

const FollowupTextContainer = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 4,
}));

const Title = styled.div(({ theme }) => ({
  ...theme.salesleg.typography.body.sm.regular,
  color: theme.salesleg.palette.neutral[700],
}));

const SubTitle = styled.div(({ theme }) => ({
  ...theme.salesleg.typography.body.xs.regular,
  color: theme.salesleg.palette.neutral[400],
}));

const ClientDetailFooter = () => {
  return (
    <RootContainer>
      <FollowupContainer>
        <IconContainer>
          <CheckSquareOffsetIcon color={theme.palette.neutral[600]} size={24} />
        </IconContainer>

        <FollowupTextContainer>
          <Title>Log Initial Followup</Title>
          <SubTitle>
            There are no followup logged. Log your initial followup to start
            client journey
          </SubTitle>
        </FollowupTextContainer>
      </FollowupContainer>
      <Button
        color="primary"
        onClick={() => {}}
        size="sm"
        label="Log Initial Followup"
      />
    </RootContainer>
  );
};

export default ClientDetailFooter;
