import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query/react";
import { developersApi } from "./features/api/developers";
import { employeesApi } from "./features/api/employees";
import { leadsApi } from "./features/api/leads";
import { leadsQualityApi } from "./features/api/leadsQuality";
import { projectsApi } from "./features/api/projects";
import { siteVisitsApi } from "./features/api/siteVisits";
import { summaryApi } from "./features/api/summary";
import { tenantsApi } from "./features/api/tenants";
import authReducer from "./features/auth";
import { teamsApi } from "./features/api/teams";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    [leadsApi.reducerPath]: leadsApi.reducer,
    [employeesApi.reducerPath]: employeesApi.reducer,
    [developersApi.reducerPath]: developersApi.reducer,
    [projectsApi.reducerPath]: projectsApi.reducer,
    [summaryApi.reducerPath]: summaryApi.reducer,
    [leadsQualityApi.reducerPath]: leadsQualityApi.reducer,
    [tenantsApi.reducerPath]: tenantsApi.reducer,
    [teamsApi.reducerPath]: teamsApi.reducer,
    [siteVisitsApi.reducerPath]: siteVisitsApi.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoreState: true,
        ignoreActions: true,
      },
    })
      .concat(leadsApi.middleware)
      .concat(employeesApi.middleware)
      .concat(developersApi.middleware)
      .concat(projectsApi.middleware)
      .concat(summaryApi.middleware)
      .concat(leadsQualityApi.middleware)
      .concat(tenantsApi.middleware)
      .concat(teamsApi.middleware)
      .concat(siteVisitsApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
