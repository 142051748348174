import {
  ExtendedLead,
  GetSiteVisitsRequest,
  WithMetadata,
} from "@hshrimal/salesleg";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const siteVisitsApi = createApi({
  reducerPath: "siteVisitsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_SALESLEG_API_BASE_URL}/api`,
    prepareHeaders: (headers) => headers.append("client", "web"),
    credentials: "include",
  }),
  tagTypes: ["site_visits"],
  endpoints: (builder) => ({
    getSiteVisits: builder.query<
      WithMetadata<ExtendedLead>[],
      GetSiteVisitsRequest
    >({
      query: (request) => ({
        method: "Post",
        url: "/visits",
        body: request,
      }),
      providesTags: ["site_visits"],
    }),
  }),
});

export const { useGetSiteVisitsQuery } = siteVisitsApi;
