import { StyledFC } from "../../types/styledFC";

export const BgBlurSvg: StyledFC = ({ style }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="207"
      height="211"
      viewBox="0 0 207 211"
      fill="none"
      style={style}
    >
      <g id="BG Blur" filter="url(#filter0_f_2535_203893)">
        <circle
          cx="42"
          cy="46"
          r="125"
          fill="url(#paint0_radial_2535_203893)"
          fillOpacity="0.3"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_2535_203893"
          x="-123"
          y="-119"
          width="330"
          height="330"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="20"
            result="effect1_foregroundBlur_2535_203893"
          />
        </filter>
        <radialGradient
          id="paint0_radial_2535_203893"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-6 -21) rotate(39.7376) scale(161.903)"
        >
          <stop stopColor="#0091FF" />
          <stop offset="1" stopColor="#0091FF" stopOpacity="0" />
        </radialGradient>
      </defs>
    </svg>
  );
};
