import { FilterUrlParams } from "../../constants/filters";
import { NextFollowUpDisplayMap } from "../../utils/nextFollowUpDisplayHelper";
import { FilterMenu, FilterMenuProps } from "../FilterMenu";
import { MenuItem } from "../Menu/MenuItem";

const nextFollowUps = ["past_due", "today", "tomorrow", "upcoming"];

const NextFollowUpMenu = ({
  open,
  onClose,
  anchorEl,
  placement,
}: Omit<FilterMenuProps, "children" | "urlParamName">) => {
  return (
    <FilterMenu
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      placement={placement}
      urlParamName={FilterUrlParams.FOLLOWUP}
      enableSearch={false}
    >
      {nextFollowUps.map((nextFollowUp) => (
        <MenuItem
          key={nextFollowUp}
          variant="default"
          value={nextFollowUp}
          label={NextFollowUpDisplayMap.get(nextFollowUp)}
        />
      ))}
    </FilterMenu>
  );
};

export default NextFollowUpMenu;
