import {
  ExtendedEmployee,
  Tenant,
  WithMetadata,
  deserializeExtendedEmployees,
  deserializeTenantsWithMetadata,
} from "@hshrimal/salesleg";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const tenantsApi = createApi({
  reducerPath: "tenantsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_SALESLEG_API_BASE_URL}/api`,
    prepareHeaders: (headers) => headers.append("client", "web"),
    credentials: "include",
  }),
  tagTypes: ["tenants", "tenant_employees"],
  endpoints: (builder) => ({
    getTenants: builder.query<WithMetadata<Tenant>[], void>({
      query: () => ({ url: "/tenants" }),
      transformResponse: (data) => deserializeTenantsWithMetadata(data),
      providesTags: ["tenants"],
    }),
    getTenantEmployees: builder.query<
      WithMetadata<ExtendedEmployee>[],
      { tenantId: string }
    >({
      query: (input) => ({ url: `/tenants/${input.tenantId}/employees` }),
      transformResponse: (data) => deserializeExtendedEmployees(data),
      providesTags: ["tenant_employees"],
    }),
  }),
});

export const { useGetTenantsQuery, useGetTenantEmployeesQuery } = tenantsApi;
