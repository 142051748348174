import { useMultiSelectUrlQueryParam } from "../hooks/useUrlQueryParam";
import { Menu, MenuProps } from "./Menu";

export type FilterMenuProps = Omit<MenuProps, "value" | "onChange"> & {
  urlParamName: string;
};

export function FilterMenu({
  open,
  onClose,
  anchorEl,
  placement,
  children,
  quickAddComponent,
  multiple,
  urlParamName,
  enableSearch = true,
}: FilterMenuProps) {
  const [selectedOptions, setSelectedOptions] =
    useMultiSelectUrlQueryParam(urlParamName);

  function handleChange(value: string | string[] | undefined) {
    if (value === undefined) {
      setSelectedOptions([]);
      return;
    }
    if (multiple) {
      setSelectedOptions(value as string[]);
    } else {
      setSelectedOptions([value as string]);
    }
  }

  return (
    <Menu
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      placement={placement}
      value={multiple ? selectedOptions : selectedOptions[0]}
      onChange={handleChange}
      multiple={multiple}
      enableSearch={enableSearch}
      searchPlaceholder="Search"
      quickAddComponent={quickAddComponent}
    >
      {children}
    </Menu>
  );
}
