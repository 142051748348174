import styled from "@emotion/styled";
import { FC } from "react";
import RightTriangle from "../../components/images/RightTriangle";
import theme from "../../theme/light/theme";

const Container = styled.div(() => ({
  display: "flex",
}));

const getBorderRadiusStyle = (
  left?: boolean | undefined,
  right?: boolean | undefined
) => {
  if (left) {
    return { borderRadius: "16px 0px 0px 16px" };
  } else if (right) {
    return {
      borderRadius: "0px 16px 16px 0px",
    };
  }
  return {};
};

const getBackGroundStyle = (
  isActive?: boolean | undefined,
  isDump?: boolean | undefined
) => {
  if (isDump) {
    return { background: "red" };
  }
  if (isActive) {
    return { background: theme.palette.primary[500] };
  }
  return { background: theme.palette.neutral[100] };
};

const PrimaryPipeline = styled.div<{
  isFirstStage?: boolean | undefined;
  isLastStage?: boolean | undefined;
  isActive?: boolean | undefined;
  isDump: boolean | undefined;
}>(({ theme, isFirstStage, isLastStage, isActive, isDump }) => ({
  ...theme.salesleg.typography.body.sm.regular,
  paddingTop: 8,
  paddingBottom: 8,
  paddingLeft: isFirstStage ? 32 : 44,
  paddingRight: isLastStage ? 32 : 12,
  color: isActive ? "#ffffff" : theme.salesleg.palette.neutral[500],
  height: 32,
  ...getBorderRadiusStyle(isFirstStage, isLastStage),
  ...getBackGroundStyle(isActive, isDump),
}));

// TODO: we need to resolve the absolute and relative position for the triangnle
const TriangleContainer = styled.div(() => ({
  position: "relative",
}));

const Triangle = styled.div(() => ({
  position: "absolute",
  zIndex: 10,
}));

const WhiteTriangleContainer = styled.div(() => ({
  position: "relative",
  left: -3,
}));

const WhiteTriangle = styled.div<{ isActive?: boolean | undefined }>(
  ({ isActive }) => ({
    position: "absolute",
    left: isActive ? 0 : 5,
    zIndex: 5,
  })
);

interface ClientPipelineProps {
  isFirstStage?: boolean | undefined;
  isLastStage?: boolean | undefined;
  isActive?: boolean | undefined;
  isDump?: boolean | undefined;
  label: string;
}

// TODO: we need to resolve the absolute and relative position for the triangnle

const Stage: FC<ClientPipelineProps> = ({
  isFirstStage,
  isLastStage,
  isActive,
  isDump,
  label,
}) => {
  return (
    <Container>
      {!isFirstStage && (
        <WhiteTriangleContainer>
          <WhiteTriangle>
            <RightTriangle color="#ffffff" />
          </WhiteTriangle>
        </WhiteTriangleContainer>
      )}

      <PrimaryPipeline
        isFirstStage={isFirstStage}
        isLastStage={isLastStage}
        isActive={isActive}
        isDump={isDump}
      >
        {label}
      </PrimaryPipeline>

      {!isLastStage && (
        <TriangleContainer>
          <Triangle>
            <RightTriangle
              color={
                isActive
                  ? theme.palette.primary[500]
                  : theme.palette.neutral[100]
              }
            />
          </Triangle>
        </TriangleContainer>
      )}
    </Container>
  );
};

export default Stage;
