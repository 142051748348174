import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { StyledFC } from "../../types/styledFC";
import CheckIcon from "../icons/CheckIcon";
import MinusIcon from "../icons/MinusIcon";

const Container = styled.div<{
  size: "sm" | "md";
  checked: boolean;
  partialChecked?: boolean | undefined;
  visible?: boolean | UnderlyingByteSource;
}>(({ size, checked, partialChecked, visible, theme }) => ({
  appearance: "none",
  height: size === "sm" ? 16 : 20,
  width: 16,
  background:
    checked || partialChecked ? theme.salesleg.palette.primary[25] : "#fffff",
  border: `1px solid ${
    checked || partialChecked
      ? theme.salesleg.palette.primary[600]
      : theme.salesleg.palette.neutral[100]
  }`,
  borderRadius: 4,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  ":hover": {
    background: theme.salesleg.palette.primary[25],
    border: `1px solid ${theme.salesleg.palette.primary[600]}`,
  },
  ":focus": {
    background: theme.salesleg.palette.primary[25],
    border: `1px solid ${theme.salesleg.palette.primary[600]}`,
    boxShadow: "0px 0px 0px 4px #EAFBFF",
  },
  visibility: visible ? "visible" : "hidden",
}));

export interface CheckBoxProps {
  size: "sm" | "md";
  checked: boolean;
  onChange: (checked: boolean) => void;
  partialChecked?: boolean | undefined;
  visible?: boolean | undefined;
}

const CheckBox: StyledFC<CheckBoxProps> = ({
  size,
  checked,
  onChange,
  style,
  partialChecked = false,
  visible = true,
}) => {
  const theme = useTheme();
  return (
    <Container
      size={size}
      checked={checked}
      partialChecked={partialChecked}
      visible={visible}
      onClick={() => {
        onChange(!checked);
      }}
      style={style}
    >
      {checked && (
        <CheckIcon
          size={size === "sm" ? 16 : 24}
          color={theme.salesleg.palette.primary[600]}
        />
      )}
      {checked === false && partialChecked === true && <MinusIcon size={16} />}
    </Container>
  );
};

export default CheckBox;
