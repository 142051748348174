import styled from "@emotion/styled";
import { City } from "@hshrimal/salesleg";
import { FC, memo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ProjectWithExtendedDistributionQueue } from "..";
import Button from "../../../components/Button/Button";
import EditorText from "../../../components/EditorText";
import SelectDeveloper from "../SelectDeveloper";
import SelectLocation from "../SelectLocation";
import SelectProjectType from "../SelectProjectType";

const HeaderContainer = styled.div(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

const PrimaryInputContainer = styled.div({ display: "flex", gap: 24 });

const ActionContainer = styled.div({ display: "flex", gap: 12 });

const SecondaryInput = styled.div(({ theme }) => ({
  marginTop: 10,
  display: "flex",
  alignItems: "center",
  gap: 25,
  color: theme.salesleg.palette.neutral[500],
}));

interface CreateProjectHeaderProps {
  onSubmit: (values: ProjectWithExtendedDistributionQueue) => void;
}

const CreateProjectHeader: FC<CreateProjectHeaderProps> = memo(
  ({ onSubmit }) => {
    const { control, setValue, handleSubmit, watch, getValues } =
      useFormContext<ProjectWithExtendedDistributionQueue>();

    const watchAllFields = watch();
    const isSubmitDisable =
      watchAllFields.developerId === undefined ||
      watchAllFields.name === "" ||
      watchAllFields.distributionQueue.length === 0 ||
      watchAllFields.distributionQueue.filter((item) => item.weight > 0)
        .length === 0 ||
      watchAllFields.city === undefined ||
      watchAllFields.type === undefined;

    const navigate = useNavigate();
    const handleCancel = () => {
      navigate(-1);
    };

    const onAddLocation = (selectedCity: string) => {
      setValue("city", selectedCity as City);
    };

    const { city } = getValues();

    return (
      <>
        <HeaderContainer>
          <PrimaryInputContainer>
            <Controller
              name="name"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <div>
                  <EditorText
                    placeholder="Enter Project Name"
                    disabled={false}
                    width={345}
                    {...field}
                  />
                </div>
              )}
            />
            <Controller
              name="developerId"
              control={control}
              render={({ field }) => {
                const { value } = field;
                return (
                  <div>
                    <SelectDeveloper
                      selectedDeveloperId={value}
                      onChange={(developerId) =>
                        setValue("developerId", developerId)
                      }
                    />
                  </div>
                );
              }}
            />
          </PrimaryInputContainer>
          <ActionContainer>
            <Button
              size="sm"
              color="secondary-gray"
              label="Cancel"
              onClick={handleCancel}
            />
            <Button
              size="sm"
              color="primary"
              label="Save Project"
              disabled={isSubmitDisable}
              onClick={handleSubmit(onSubmit)}
            />
          </ActionContainer>
        </HeaderContainer>
        <SecondaryInput>
          <SelectLocation
            onAddLocation={onAddLocation}
            selectedLocation={city}
          />
          <SelectProjectType />
        </SecondaryInput>
      </>
    );
  }
);

export default CreateProjectHeader;
