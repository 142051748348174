import styled from "@emotion/styled";
import { ExtendedEmployee, WithMetadata } from "@hshrimal/salesleg";
import { FC } from "react";
import ContactDetailsSection from "./ContactDetailsSection";
import EmploymentDetailsSection from "./EmploymentDetailsSection";
import OperationTeam from "./OperationTeam";

const Container = styled.div(({ theme }) => ({
  flexGrow: 1,
  height: 0,
  backgroundColor: theme.salesleg.palette.neutral[25],
  padding: "24px 32px",
  display: "flex",
  flexDirection: "column",
  gap: 32,
}));

const Title = styled.span(({ theme }) => ({
  ...theme.salesleg.typography.heading.md.medium,
  color: theme.salesleg.palette.neutral[900],
}));

interface UserDetailsProps {
  user: WithMetadata<ExtendedEmployee>;
  manager?: WithMetadata<ExtendedEmployee> | undefined;
}

const UserDetails: FC<UserDetailsProps> = ({ user, manager }) => {
  return (
    <Container>
      <Title>User Details</Title>
      <ContactDetailsSection user={user} />
      <EmploymentDetailsSection user={user} manager={manager} />
      <OperationTeam user={user} />
    </Container>
  );
};

export default UserDetails;
