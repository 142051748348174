import {
  FollowUpSummary,
  LeadsQualityByManagerV2,
  LeadsQualityByProjectV2,
  ProductivityReportByOwner,
  SummaryV2,
} from "@hshrimal/salesleg";
import { createContext } from "react";

interface DownloadContextProps {
  allLeadByManagerRows: LeadsQualityByManagerV2[];
  setAllLeadByManagerRows: (rows: LeadsQualityByManagerV2[]) => void;
  totalAllLeadByManagerRow:
    | (LeadsQualityByManagerV2 & { id: string })
    | undefined;
  setTotalAllLeadByManagerRow: (
    row: LeadsQualityByManagerV2 & { id: string }
  ) => void;

  allLeadByProjectRows: LeadsQualityByProjectV2[];
  setAllLeadByProjectRows: (rows: LeadsQualityByProjectV2[]) => void;
  totalAllLeadByProjectRow:
    | (LeadsQualityByProjectV2 & { id: string })
    | undefined;
  setTotalAllLeadByProjectRow: (
    row: LeadsQualityByProjectV2 & { id: string }
  ) => void;

  followUpSummaryRows: FollowUpSummary[];
  setFollowUpSummaryRows: (rows: FollowUpSummary[]) => void;
  totalFollowUpSummaryRow: (FollowUpSummary & { id: string }) | undefined;
  setTotalFollowUpSummaryRow: (row: FollowUpSummary & { id: string }) => void;

  productivityReportRows: ProductivityReportByOwner[];
  setProductivityReportRows: (rows: ProductivityReportByOwner[]) => void;
  totalProductivityReportRow:
    | (ProductivityReportByOwner & { id: string })
    | undefined;
  setTotalProductivityReportRow: (
    row: ProductivityReportByOwner & { id: string }
  ) => void;

  activeLeadRows: SummaryV2[];
  setActiveLeadRows: (rows: SummaryV2[]) => void;
  totalActiveLeadRow: (SummaryV2 & { id: string }) | undefined;
  setTotalActiveLeadRow: (row: SummaryV2 & { id: string }) => void;
}

export const DownloadContext = createContext<DownloadContextProps | undefined>(
  undefined
);
