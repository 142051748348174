import dayjs from "dayjs";
import { useUrlQueryParam } from "../../hooks/useUrlQueryParam";
import CalenderRange from "../Calendar/CalendarRange";
import { FilterMenuProps } from "../FilterMenu";

const DateRangeMenu = ({
  open,
  onClose,
  anchorEl,
  placement,
  urlParamName,
}: Omit<FilterMenuProps, "children">) => {
  const [createdAt, setCreatedAt] = useUrlQueryParam(urlParamName);

  let selectedDateRange: [Date, Date] | undefined = undefined;
  if (createdAt) {
    const [start, end] = createdAt?.split("-");
    const selectedStartDate = dayjs(start).toDate();

    const selectedEndDate = dayjs(end).toDate();
    selectedDateRange = [selectedStartDate, selectedEndDate];
  }

  const handleChange = (value: [Date, Date] | undefined) => {
    if (!value) {
      setCreatedAt(undefined);
      return;
    }

    const [startDate, endDate] = value;
    const startDateString = startDate.toISOString();
    const endDateString = endDate.toISOString();
    if (startDate === undefined && endDate === undefined) {
      setCreatedAt(undefined);
    } else {
      setCreatedAt(startDateString + "-" + endDateString);
    }
  };

  return (
    <>
      <CalenderRange
        onChange={handleChange}
        value={selectedDateRange}
        open={open}
        onClose={() => onClose && onClose(false)}
        anchorEl={anchorEl}
        placement={placement}
        monthsShown={2}
      />
    </>
  );
};
export default DateRangeMenu;
