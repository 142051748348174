import { LeadSources } from "@hshrimal/salesleg";
import { FilterUrlParams } from "../../constants/filters";
import { LeadSourceDisplayMap } from "../../utils/leadSourceDisplayHelper";
import { FilterMenu, FilterMenuProps } from "../FilterMenu";
import { MenuItem } from "../Menu/MenuItem";

const LeadSourceMenu = ({
  open,
  onClose,
  anchorEl,
  placement,
  multiple,
}: Omit<FilterMenuProps, "children" | "urlParamName">) => {
  return (
    <FilterMenu
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      placement={placement}
      multiple={multiple}
      urlParamName={FilterUrlParams.LEAD_SOURCE}
    >
      {LeadSources.map((lead) => (
        <MenuItem
          key={lead}
          variant="default"
          value={lead}
          label={LeadSourceDisplayMap.get(lead)}
        />
      )) || []}
    </FilterMenu>
  );
};

export default LeadSourceMenu;
