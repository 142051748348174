import { PaletteColor } from "../../types";

const color: PaletteColor = {
  25: "#FFFAF5",
  50: "#FFF6ED",
  100: "#FFEAD5",
  200: "#FDDCAB",
  300: "#FEB273",
  400: "#FD853A",
  500: "#FB6514",
  600: "#EC4A0A",
  700: "#C4320A",
  800: "#9C2A10",
  900: "#7E2410",
};

export default color;
