import styled from "@emotion/styled";
import { StyledFC } from "../../types/styledFC";

interface ClientNameCellProps {
  name: string;
  onClick: () => void;
}

const Name = styled.div(({ theme }) => ({
  ...theme.salesleg.typography.paragraph.sm.medium,
  color: theme.salesleg.palette.neutral[700],
  textDecorationLine: "underline",
  textDecorationStyle: "dashed",
  cursor: "pointer",
}));

const ClientNameCell: StyledFC<ClientNameCellProps> = ({ name, onClick }) => {
  return <Name onClick={onClick}>{name}</Name>;
};

export default ClientNameCell;
