import styled from "@emotion/styled";
import {
  GroupBy,
  LeadSource,
  LeadsQualityByManagerV2,
  LeadsQualityByProjectV2,
  Role,
} from "@hshrimal/salesleg";
import dayjs from "dayjs";
import { useContext, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { FilterPanel } from "../../components/FilterPanel";
import { ProgressIndicator } from "../../components/ProgressIndicator";
import Table from "../../components/Table";
import DateRangeFilter from "../../components/filters/DateRangeFilter";
import LeadSourceFilter from "../../components/filters/LeadSourceFilter";
import { FilterUrlParams } from "../../constants/filters";
import {
  useMultiSelectUrlQueryParam,
  useUrlQueryParam,
} from "../../hooks/useUrlQueryParam";
import {
  useGetLeadsQualityByProjectQuery,
  useGetLeadsQualityBySalesManagerQuery,
} from "../../redux/features/api/leadsQuality";
import theme from "../../theme/light/theme";
import AssignedCell from "./AssignedCell";
import BookingCell from "./BookingCell";
import ContactedCell from "./ContactedCell";
import DumpCell from "./DumpCell";
import { GroupBySelector } from "./GroupBySelector";
import InterestedCell from "./InterestedCell";
import NegotiationCell from "./NegotiationCell";
import NewCell from "./NewCell";
import ProjectCell from "./ProjectCell";
import TotalCell from "./TotalCell";
import VisitDoneCell from "./VisitDoneCell";
import VisitPlanedCell from "./VisitPlanedCell";
import { DownloadContext } from "./context/DownloadContext";
import { useLeadsQualityReportRequest } from "./hooks/useLeadsQualityReportRequest";

const RootContainer = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
}));
const TableContainer = styled.div(() => ({
  paddingLeft: 32,
  paddingTop: 12,
  paddingBottom: 12,
  paddingRight: 16,
  backgroundColor: theme.palette.neutral[25],
  overflowY: "auto",
  flexGrow: 1,
  flexBasis: 0,
}));

const getStartEndDate = (filterValue: string | undefined) => {
  const arrDate = filterValue?.split("_");
  const startDate = arrDate && dayjs(arrDate[0]).toDate();
  const endDate = arrDate && dayjs(arrDate[1]).toDate();
  return [startDate, endDate];
};

const AllLeadContent = () => {
  const { employeeId } = useParams();
  const [createdDate] = useUrlQueryParam(FilterUrlParams.CREATION_DATE);
  const [createdAtStart, createdAtEnd] = getStartEndDate(createdDate);
  const [leadSources] = useMultiSelectUrlQueryParam(
    FilterUrlParams.LEAD_SOURCE
  );

  const [groupBy] = useUrlQueryParam<GroupBy>(FilterUrlParams.GROUP_BY);
  const columns = [
    { name: groupBy === "manager" ? "Assigned To" : "Project", width: 170 },
    { name: "Total", width: 170 },
    { name: "New", width: 170 },
    { name: "Contacted", width: 170 },
    { name: "Interested", width: 170 },
    { name: "Visit Planned", width: 170 },
    { name: "Visit Done", width: 170 },
    { name: "Negotiation", width: 170 },
    { name: "Booking", width: 170 },
    { name: "Dump", width: 170 },
  ];

  const leadsQualityReportRequest = useLeadsQualityReportRequest(
    (groupBy as GroupBy) || "manager",
    employeeId,
    createdAtStart,
    createdAtEnd,
    leadSources as LeadSource[]
  );
  const {
    data: leadsFunnelReportBySalesManagerV2,
    isFetching: isFetchingLeadsFunnelReportBySalesManagerV2,
  } = useGetLeadsQualityBySalesManagerQuery(leadsQualityReportRequest, {
    skip: groupBy !== "manager",
  });

  const {
    data: leadsFunnelReportByProjectV2,
    isFetching: isFetchingLeadsFunnelReportByProjectV2,
  } = useGetLeadsQualityByProjectQuery(leadsQualityReportRequest, {
    skip: groupBy !== "project",
  });

  const totalRowByProjectV2: LeadsQualityByProjectV2 & { id: string } =
    useMemo(() => {
      const total: LeadsQualityByProjectV2 & { id: string } = {
        id: "total",
        project: {
          id: "",
          name: "",
        },
        total_leads: 0,
        new_leads: 0,
        contacted_leads: 0,
        interested_leads: 0,
        visit_planned_leads: 0,
        visit_done_leads: 0,
        negotiation_leads: 0,
        booking_leads: 0,
        dump_leads: 0,
      };
      if (
        leadsFunnelReportByProjectV2 &&
        leadsFunnelReportByProjectV2.length > 0
      ) {
        leadsFunnelReportByProjectV2.forEach((row) => {
          total.total_leads += row.total_leads;
          total.new_leads += row.new_leads;
          total.contacted_leads += row.contacted_leads;
          total.interested_leads += row.interested_leads;
          total.visit_planned_leads += row.visit_planned_leads;
          total.visit_done_leads += row.visit_done_leads;
          total.negotiation_leads += row.negotiation_leads;
          total.booking_leads += row.booking_leads;
          total.dump_leads += row.dump_leads;
        });
      }
      return total;
    }, [leadsFunnelReportByProjectV2]);

  const totalRowBySalesManagerV2: LeadsQualityByManagerV2 & { id: string } =
    useMemo(() => {
      const total: LeadsQualityByManagerV2 & { id: string } = {
        id: "total",
        employee: {
          id: "",
          name: "",
          role: "sales_lead" as Role,
          level: 0,
          status: "active",
          isRoot: false,
        },
        total_leads: 0,
        new_leads: 0,
        contacted_leads: 0,
        interested_leads: 0,
        visit_planned_leads: 0,
        visit_done_leads: 0,
        negotiation_leads: 0,
        booking_leads: 0,
        dump_leads: 0,
      };
      if (
        leadsFunnelReportBySalesManagerV2 &&
        leadsFunnelReportBySalesManagerV2.length > 0
      ) {
        total.employee.id = leadsFunnelReportBySalesManagerV2[0].employee.id;
        leadsFunnelReportBySalesManagerV2.forEach((row) => {
          total.total_leads += row.total_leads;
          total.new_leads += row.new_leads;
          total.contacted_leads += row.contacted_leads;
          total.interested_leads += row.interested_leads;
          total.visit_planned_leads += row.visit_planned_leads;
          total.visit_done_leads += row.visit_done_leads;
          total.negotiation_leads += row.negotiation_leads;
          total.booking_leads += row.booking_leads;
          total.dump_leads += row.dump_leads;
        });
      }
      return total;
    }, [leadsFunnelReportBySalesManagerV2]);

  const downloadCtx = useContext(DownloadContext);

  useEffect(() => {
    if (
      groupBy === "manager" &&
      leadsFunnelReportBySalesManagerV2 !== undefined
    ) {
      downloadCtx?.setAllLeadByManagerRows(leadsFunnelReportBySalesManagerV2);
    } else if (
      groupBy === "project" &&
      leadsFunnelReportByProjectV2 !== undefined
    ) {
      downloadCtx?.setAllLeadByProjectRows(leadsFunnelReportByProjectV2);
    }
  }, [
    leadsFunnelReportBySalesManagerV2,
    leadsFunnelReportByProjectV2,
    groupBy,
    downloadCtx,
  ]);

  useEffect(() => {
    if (groupBy === "manager") {
      downloadCtx?.setTotalAllLeadByManagerRow(totalRowBySalesManagerV2);
    } else if (groupBy === "project") {
      downloadCtx?.setTotalAllLeadByProjectRow(totalRowByProjectV2);
    }
  }, [totalRowBySalesManagerV2, totalRowByProjectV2, groupBy, downloadCtx]);

  return (
    <RootContainer>
      <FilterPanel
        searchable={false}
        masterFilterMenuItems={[]}
        directFilters={[
          {
            component: <GroupBySelector key="group_by" />,
            urlParamName: FilterUrlParams.GROUP_BY,
            permanent: true,
          },
          {
            component: (
              <DateRangeFilter
                key="created_at"
                label="Created At"
                urlParamName={FilterUrlParams.CREATION_DATE}
                isResetAllow={false}
              />
            ),
            urlParamName: FilterUrlParams.VISIT_DATE,
            permanent: true,
          },
          {
            component: <LeadSourceFilter key="lead source" />,
            urlParamName: FilterUrlParams.LEAD_SOURCE,
            permanent: true,
          },
        ]}
      />
      <TableContainer>
        {groupBy === "manager" && (
          <Table
            columnGroups={[
              { span: 1, width: 200 },
              { span: 1, width: 140 },
              { span: 1, width: 140 },
              { span: 1, width: 140 },
              { span: 1, width: 140 },
              { span: 1, width: 140 },
              { span: 1, width: 140 },
              { span: 1, width: 140 },
              { span: 1, width: 140 },
              { span: 1, width: 140 },
            ]}
            columns={columns}
            rows={leadsFunnelReportBySalesManagerV2 || []}
            totalRow={totalRowBySalesManagerV2}
            renderTableRowCells={(row, index, isTotalRow) => [
              <AssignedCell
                name={row.employee.name}
                id={row.employee.id}
                role={row.employee?.role}
                disableDrillDown={
                  row.employee.isRoot || row.employee.role === "sales_associate"
                }
              />,
              <TotalCell
                total={row.total_leads}
                employee={row.employee}
                isRoot={row.employee.isRoot}
              />,
              <NewCell
                newNumber={row.new_leads}
                employee={row.employee}
                isRoot={row.employee.isRoot}
              />,
              <ContactedCell
                contacted={row.contacted_leads}
                employee={row.employee}
                isRoot={row.employee.isRoot}
              />,
              <InterestedCell
                interested={row.interested_leads}
                employee={row.employee}
                isRoot={row.employee.isRoot}
              />,
              <VisitPlanedCell
                visitPlaned={row.visit_planned_leads}
                employee={row.employee}
                isRoot={row.employee.isRoot}
              />,
              <VisitDoneCell
                visitDone={row.visit_done_leads}
                employee={row.employee}
                isRoot={row.employee.isRoot}
              />,
              <NegotiationCell
                negotiation={row.negotiation_leads}
                employee={row.employee}
                isRoot={row.employee.isRoot}
              />,
              <BookingCell
                booking={row.booking_leads}
                employee={row.employee}
                isRoot={row.employee.isRoot}
              />,
              <DumpCell
                dump={row.dump_leads}
                employee={row.employee}
                isRoot={row.employee.isRoot}
              />,
            ]}
          />
        )}
        {groupBy === "project" && (
          <Table
            columnGroups={[
              { span: 1, width: 200 },
              { span: 1, width: 140 },
              { span: 1, width: 140 },
              { span: 1, width: 140 },
              { span: 1, width: 140 },
              { span: 1, width: 140 },
              { span: 1, width: 140 },
              { span: 1, width: 140 },
              { span: 1, width: 140 },
              { span: 1, width: 140 },
            ]}
            columns={columns}
            rows={leadsFunnelReportByProjectV2 || []}
            totalRow={totalRowByProjectV2}
            renderTableRowCells={(row, index, isTotalRow) => [
              <ProjectCell name={row.project.name} id={row.project.id} />,
              <TotalCell
                total={row.total_leads}
                projectId={!isTotalRow ? row.project.id : undefined}
              />,
              <NewCell
                newNumber={row.new_leads}
                projectId={!isTotalRow ? row.project.id : undefined}
              />,
              <ContactedCell
                contacted={row.contacted_leads}
                projectId={!isTotalRow ? row.project.id : undefined}
              />,
              <InterestedCell
                interested={row.interested_leads}
                projectId={!isTotalRow ? row.project.id : undefined}
              />,
              <VisitPlanedCell
                visitPlaned={row.visit_planned_leads}
                projectId={!isTotalRow ? row.project.id : undefined}
              />,
              <VisitDoneCell
                visitDone={row.visit_done_leads}
                projectId={!isTotalRow ? row.project.id : undefined}
              />,
              <NegotiationCell
                negotiation={row.negotiation_leads}
                projectId={!isTotalRow ? row.project.id : undefined}
              />,
              <BookingCell
                booking={row.booking_leads}
                projectId={!isTotalRow ? row.project.id : undefined}
              />,
              <DumpCell
                dump={row.dump_leads}
                projectId={!isTotalRow ? row.project.id : undefined}
              />,
            ]}
          />
        )}
      </TableContainer>
      <ProgressIndicator
        open={
          isFetchingLeadsFunnelReportBySalesManagerV2 ||
          isFetchingLeadsFunnelReportByProjectV2
        }
      />
    </RootContainer>
  );
};

export default AllLeadContent;
