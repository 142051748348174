import styled from "@emotion/styled";
import { FC } from "react";

const TabButton = styled.div<{ active: boolean }>(({ theme, active }) => ({
  ...theme.salesleg.typography.body.sm.medium,
  color: active
    ? theme.salesleg.palette.neutral[700]
    : theme.salesleg.palette.neutral[400],
  padding: "10px 20px",
  display: "flex",
  gap: 12,
  alignItems: "center",
  borderRadius: active ? "4px 4px 0px 0px" : "none",
  borderTop: active
    ? `1px solid ${theme.salesleg.palette.neutral[100]}`
    : "none",
  borderRight: active
    ? `1px solid ${theme.salesleg.palette.neutral[100]}`
    : "none",
  borderLeft: active
    ? `1px solid ${theme.salesleg.palette.neutral[100]}`
    : "none",
  background: active ? theme.salesleg.palette.neutral[25] : "none",
  cursor: "pointer",
}));

interface TabProps {
  active: boolean;
  onClick: (id: string) => void;
  label: string;
  id: string;
}

const Tab: FC<TabProps> = ({ active, onClick, id, label }) => {
  return (
    <TabButton active={active} onClick={() => onClick(id)}>
      {label}
    </TabButton>
  );
};

export default Tab;
