import { PaletteColor } from "../../types";

const color: PaletteColor = {
  25: "#FFFBED",
  50: "#FFF7D4",
  100: "#FFEAA8",
  200: "#FFDA71",
  300: "#FFBE38",
  400: "#FDA612",
  500: "#F78F08",
  600: "#C66A08",
  700: "#9D530F",
  800: "#7E4410",
  900: "#442106",
};

export default color;
