import { useAppSelector } from "../redux/hooks";

export function useLoggedInViewer() {
  const authContext = useAppSelector((state) => state.auth);
  if (!authContext.user) {
    throw new Error("No login session found");
  }

  return authContext.loginAsUser || authContext.user;
}

export function usePrincipleUser() {
  const authContext = useAppSelector((state) => state.auth);
  if (!authContext.user) {
    throw new Error("No login session found");
  }

  return authContext.user;
}
