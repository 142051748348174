import styled from "@emotion/styled";
import { Controller, useFormContext } from "react-hook-form";
import { CreateUserFormValues } from ".";
import TextInput from "../../components/TextInput";
import { StyledFC } from "../../types/styledFC";
import ManagerDropdown from "./ManagerDropDown";
import RoleDropdown from "./RoleDropdown";
import SectionHeader from "./SectionHeader";
import TeamDropdown from "./TeamDropdown";

const Container = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 16,
}));

const FieldsContainer = styled.div(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  alignContent: "flex-start",
  rowGap: 24,
  columnGap: 24,
}));

const EmploymentDetailsSection: StyledFC = () => {
  const { control, getValues, watch } = useFormContext<CreateUserFormValues>();
  const { role } = getValues();
  const { teamIds } = watch();
  return (
    <Container>
      <SectionHeader title="Employment Details" />
      <FieldsContainer>
        <Controller
          control={control}
          name="alias"
          render={({ field }) => <TextInput label="Employee Code" {...field} />}
        />
        <Controller
          control={control}
          name="title"
          render={({ field }) => <TextInput label="Designation" {...field} />}
        />
        <RoleDropdown />
        {(role === "sales_lead" || role === "sales_associate") && (
          <>
            <TeamDropdown />
            {teamIds && teamIds.length > 0 && (
              <ManagerDropdown selectedTeamId={teamIds[0]} />
            )}
          </>
        )}
      </FieldsContainer>
    </Container>
  );
};

export default EmploymentDetailsSection;
