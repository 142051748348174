import {
  FollowUpSummary,
  LeadsQualityByManagerV2,
  LeadsQualityByProjectV2,
  ProductivityReportByOwner,
  SummaryV2,
} from "@hshrimal/salesleg";
import { FC, ReactNode, useState } from "react";
import { DownloadContext } from "./DownloadContext";

interface DownloadProviderProps {
  children: ReactNode;
}

const DownloadProvider: FC<DownloadProviderProps> = ({ children }) => {
  const [allLeadByManagerRows, setAllLeadByManagerRows] = useState<
    LeadsQualityByManagerV2[]
  >([]);
  const [totalAllLeadByManagerRow, setTotalAllLeadByManagerRow] = useState<
    LeadsQualityByManagerV2 & { id: string }
  >();
  const [allLeadByProjectRows, setAllLeadByProjectRows] = useState<
    LeadsQualityByProjectV2[]
  >([]);

  const [totalAllLeadByProjectRow, setTotalAllLeadByProjectRow] = useState<
    LeadsQualityByProjectV2 & { id: string }
  >();

  const [followUpSummaryRows, setFollowUpSummaryRows] = useState<
    FollowUpSummary[]
  >([]);

  const [totalFollowUpSummaryRow, setTotalFollowUpSummaryRow] = useState<
    FollowUpSummary & { id: string }
  >();

  const [productivityReportRows, setProductivityReportRows] = useState<
    ProductivityReportByOwner[]
  >([]);

  const [totalProductivityReportRow, setTotalProductivityReportRow] = useState<
    ProductivityReportByOwner & { id: string }
  >();

  const [activeLeadRows, setActiveLeadRows] = useState<SummaryV2[]>([]);

  const [totalActiveLeadRow, setTotalActiveLeadRow] = useState<
    SummaryV2 & { id: string }
  >();

  return (
    <DownloadContext.Provider
      value={{
        allLeadByManagerRows,
        allLeadByProjectRows,
        followUpSummaryRows,
        productivityReportRows,
        activeLeadRows: activeLeadRows,
        totalAllLeadByManagerRow,
        totalAllLeadByProjectRow,
        totalFollowUpSummaryRow,
        totalProductivityReportRow,
        totalActiveLeadRow: totalActiveLeadRow,
        setAllLeadByManagerRows,
        setAllLeadByProjectRows,
        setFollowUpSummaryRows,
        setProductivityReportRows,
        setActiveLeadRows: setActiveLeadRows,
        setTotalAllLeadByManagerRow,
        setTotalAllLeadByProjectRow,
        setTotalFollowUpSummaryRow,
        setTotalProductivityReportRow,
        setTotalActiveLeadRow: setTotalActiveLeadRow,
      }}
    >
      {children}
    </DownloadContext.Provider>
  );
};

export default DownloadProvider;
