import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { Controller, useFormContext } from "react-hook-form";
import { CreateUserFormValues } from ".";
import Button from "../../components/Button/Button";
import LinkButton from "../../components/Button/LinkButton";
import EditorText from "../../components/EditorText";
import ArrowLeftIcon from "../../components/icons/ArrowLeftIcon";
import UserPlusIcon from "../../components/icons/UserPlusIcon";
import { StyledFC } from "../../types/styledFC";
import { useNavigateWithTeam } from "../../hooks/useNavigationWithTeam";

const Container = styled.div(({ theme }) => ({
  flex: "0 0 auto",
  height: 120,
  padding: "24px 32px",
  backgroundColor: "#ffffff",
  border: `1px solid ${theme.salesleg.palette.neutral[100]}`,
  display: "flex",
  flexDirection: "column",
  gap: 8,
}));

const TitleAndActionContainer = styled.div({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const TitleContainer = styled.div({
  height: 44,
  display: "flex",
  alignItems: "center",
  gap: 8,
});

const AvatarContainer = styled.div(({ theme }) => ({
  width: 32,
  height: 32,
  borderRadius: 16,
  backgroundColor: theme.salesleg.palette.primary[25],
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const ActionContainer = styled.div({ display: "flex", gap: 12 });

interface CreateUserHeaderProps {
  existingUserId?: string | undefined;
  onSubmit: (values: CreateUserFormValues) => void;
}

const Header: StyledFC<CreateUserHeaderProps> = ({
  existingUserId,
  onSubmit,
}) => {
  const navigate = useNavigateWithTeam();
  const theme = useTheme();
  const { control, handleSubmit } = useFormContext<CreateUserFormValues>();

  const { watch } = useFormContext<CreateUserFormValues>();

  const watchAllFields = watch();

  const isSubmitDisabled =
    watchAllFields.name === undefined ||
    watchAllFields.phoneNumber === undefined ||
    watchAllFields.role === undefined ||
    watchAllFields.title === undefined ||
    watchAllFields.name === "" ||
    watchAllFields.phoneNumber === "" ||
    watchAllFields.phoneNumber.length !== 10 ||
    watchAllFields.title === "" ||
    ((watchAllFields.role === "sales_lead" ||
      watchAllFields.role === "sales_associate") &&
      (watchAllFields.managerId === undefined ||
        watchAllFields.managerId === "")) ||
    ((watchAllFields.role === "sales_lead" ||
      watchAllFields.role === "sales_associate") &&
      watchAllFields.teamIds.length === 0);

  return (
    <>
      <Container>
        <LinkButton
          size="sm"
          color="gray"
          label="Back"
          leadingIcon={ArrowLeftIcon}
          onClick={() => {
            navigate("/users");
          }}
        />
        <TitleAndActionContainer>
          <TitleContainer>
            <AvatarContainer>
              <UserPlusIcon
                size={21.33}
                color={theme.salesleg.palette.primary[600]}
              />
            </AvatarContainer>
            <Controller
              control={control}
              name="name"
              render={({ field }) => (
                <EditorText
                  width={345}
                  placeholder="Name goes here"
                  {...field}
                />
              )}
            />
          </TitleContainer>
          <ActionContainer>
            <Button
              size="sm"
              color="secondary-gray"
              label="Cancel"
              onClick={() =>
                existingUserId
                  ? navigate(`/users/${existingUserId}`)
                  : navigate("/users")
              }
            />
            <Button
              size="sm"
              color="primary"
              label="Submit"
              disabled={isSubmitDisabled}
              onClick={handleSubmit(onSubmit)}
            />
          </ActionContainer>
        </TitleAndActionContainer>
      </Container>
    </>
  );
};

export default Header;
