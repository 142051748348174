import styled from "@emotion/styled";
import { FC } from "react";
import CloseIcon from "../../components/icons/CloseIcon";
import FileIcon from "../../components/icons/FileIcon";
import theme from "../../theme/light/theme";

const Card = styled.div(() => ({
  width: 420,
  padding: "20px 16px",
  display: "flex",
  gap: 16,
  borderRadius: 8,
  border: `1px solid ${theme.palette.neutral[100]}`,
  background: "#fffffff",
}));

const FileIconWrapper = styled.div(() => ({
  width: 32,
  height: 32,
  borderRadius: 16,
  padding: 8,
  backgroundColor: theme.palette.primary[25],
}));

const FileDetails = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: 4,
  flexGrow: 1,
}));

const FileTitle = styled.div(() => ({
  ...theme.typography.body.sm.regular,
  color: theme.palette.neutral[700],
}));

const FileSize = styled.div(() => ({
  ...theme.typography.body.xs.regular,
  color: theme.palette.neutral[400],
}));

const Container = styled.div(() => ({
  display: "flex",
  flexDirection: "row",
  flexGrow: 1,
  justifyContent: "space-between",
}));

const FileIconContianer = styled.div(() => ({
  display: "flex",
  justifyContent: "space-between",
}));

const IconWrapper = styled.div(() => ({
  cursor: "pointer",
}));

interface FileUploadingCardProps {
  fileName: string;
  fileSize: string;
  onClose?: () => void;
}
const FileUploadingCard: FC<FileUploadingCardProps> = ({
  fileName,
  fileSize,
  onClose,
}) => {
  return (
    <Card>
      <FileIconWrapper>
        <FileIcon color={theme.palette.primary[600]} size={16} />
      </FileIconWrapper>
      <Container>
        <FileDetails>
          <FileIconContianer>
            <FileTitle>{fileName}</FileTitle>
            <IconWrapper onClick={onClose}>
              <CloseIcon color={theme.palette.neutral[300]} size={20} />
            </IconWrapper>
          </FileIconContianer>
          <FileSize>{fileSize}</FileSize>
          {/* <FileInfoContainer>
            <BorderLinearProgress
              variant="determinate"
              value={50}
              style={{ width: "100%" }}
            />
            <Typography variant="body2" color={theme.palette.neutral[700]}>
              30%
            </Typography>
          </FileInfoContainer> */}
        </FileDetails>
      </Container>
    </Card>
  );
};

export default FileUploadingCard;
