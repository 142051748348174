import styled from "@emotion/styled";
import { logout } from "../../redux/features/auth";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { StyledFC } from "../../types/styledFC";
import Avatar from "../Avatar";
import LinkButton from "../Button/LinkButton";

const Container = styled.div(() => ({
  width: 248,
  height: 32,
  display: "flex",
  gap: 10,
}));

const Name = styled.span(({ theme }) => ({
  ...theme.salesleg.typography.body.sm.semiBold,
  color: "#FFFFFF",
}));

const UserProfileMenuItem: StyledFC = () => {
  const { user } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  return (
    <Container>
      <Avatar size={32} />

      <div style={{ display: "flex", flexDirection: "column", gap: 4 }}>
        <Name>{user?.name}</Name>
        <LinkButton
          size="sm"
          color="white"
          label="Logout"
          onClick={() => {
            dispatch(logout());
          }}
        />
      </div>
    </Container>
  );
};

export default UserProfileMenuItem;
