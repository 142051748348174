import styled from "@emotion/styled";
import dayjs from "dayjs";
import { useEffect } from "react";
import { ReactDatePickerCustomHeaderProps } from "react-datepicker";
import DrawerCloseIcon from "../../components/icons/DrawerCloseIcon";
import DrawerOpenIcon from "../../components/icons/DrawerOpenIcon";
import theme from "../../theme/light/theme";
import IconButton from "../Button/IconButton";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const IconContainer = styled.div(() => ({
  backgroundColor: "#ffffff",
  display: "flex",
  alignItems: "center",
  gap: 24,
  justifyContent: "space-between",
  marginBottom: 10,
}));

interface HeaderProps extends ReactDatePickerCustomHeaderProps {
  startDate: Date | undefined;
}

const Header = ({
  decreaseMonth,
  increaseMonth,
  monthDate,
  startDate,
  changeMonth,
  changeYear,
}: HeaderProps) => {
  const month = dayjs(monthDate).month();
  const year = dayjs(monthDate).year();

  useEffect(() => {
    if (startDate) {
      const month = dayjs(startDate).month();
      const year = dayjs(startDate).year();
      changeMonth(month);
      changeYear(year);
    }
  }, [startDate, changeMonth, changeYear]);
  return (
    <IconContainer>
      <IconButton
        color="tertiary-gray"
        icon={DrawerCloseIcon}
        onClick={decreaseMonth}
        size="md"
      />
      <div
        style={{ ...theme.typography.paragraph.sm.medium }}
      >{`${months[month]} ${year}`}</div>
      <IconButton
        color="tertiary-gray"
        icon={DrawerOpenIcon}
        onClick={increaseMonth}
        size="md"
      />
    </IconContainer>
  );
};

export default Header;
