import styled from "@emotion/styled";
import { FC } from "react";
import { Employee } from ".";
import { FilterUrlParams } from "../../constants/filters";
import {
  useMultiSelectUrlQueryParam,
  useUrlQueryParam,
} from "../../hooks/useUrlQueryParam";
import { getLeadsFilterUrl } from "../../utils/urlHelper";
import { useNavigateWithTeam } from "../../hooks/useNavigationWithTeam";

interface TotalProps {
  total: number;
  employee?: Employee | undefined;
  isRoot?: boolean | undefined;
  projectId?: string | undefined;
}
const RootContainer = styled.div(() => ({
  cursor: "pointer",
}));

const TotalCell: FC<TotalProps> = ({ total, employee, projectId, isRoot }) => {
  const navigate = useNavigateWithTeam();
  const [createdDate] = useUrlQueryParam(FilterUrlParams.CREATION_DATE);
  const [leadSource] = useMultiSelectUrlQueryParam(FilterUrlParams.LEAD_SOURCE);

  const handleClick = () => {
    const queryString = getLeadsFilterUrl(
      createdDate,
      leadSource,
      projectId,
      employee,
      undefined,
      undefined,
      isRoot
    );
    navigate(`/clients?${queryString}`);
  };
  return <RootContainer onClick={handleClick}>{total}</RootContainer>;
};

export default TotalCell;
