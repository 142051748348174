import styled from "@emotion/styled";
import dayjs from "dayjs";
import { FC, useEffect, useState } from "react";
import DatePicker, { CalendarContainer } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from "../../components/Button/Button";
import IconButton from "../../components/Button/IconButton";
import ClearIcon from "../../components/icons/ClearIcon";

import { Popper } from "@mui/material";
import isoWeek from "dayjs/plugin/isoWeek";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import theme from "../../theme/light/theme";
import Header from "./Header";
import "./index.css";

dayjs.extend(isoWeek);
dayjs.extend(quarterOfYear);

const now = dayjs();
const startOfToday = now.startOf("day");
const startOfYesterday = now.startOf("day").subtract(1, "day");
const startOfThisWeek = now.startOf("isoWeek");
const endOfThisWeek = now.endOf("isoWeek").startOf("day");
const startOfPrevWeek = startOfThisWeek.subtract(1, "week");
const endOfPrevWeek = endOfThisWeek.subtract(1, "week");
const startOfThisMonth = now.startOf("month");
const endofThisMonth = now.endOf("month").startOf("day");
const endOfLastMonth = now.startOf("month").subtract(1).startOf("day");
const startOfLastMonth = endOfLastMonth.startOf("month");
const startOfThisYear = now.startOf("year");
const endOfThisYear = now.endOf("year");
const endOfLastYear = now.startOf("year").subtract(1).endOf("year");
const startOfLastYear = endOfLastYear.startOf("year");

interface HeaderContainerProps {
  className?: string | undefined;
  children: any;
}

export type Placement = "bottom" | "right" | "top";

const RootContainer = styled.div<{ placement: string }>(({ placement }) => ({
  ...(placement === "bottom" && { marginTop: 12 }),
  ...(placement === "right" && { marginLeft: 12 }),
}));

const HeaderIcon = styled.div(() => ({
  paddingTop: 8,
  paddingLeft: 24,
  paddingRight: 24,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

const ButtonContainer = styled.div(() => ({
  padding: "16px 24px",
  display: "flex",
  gap: 16,
  alignItems: "center",
  justifyContent: "end",
}));

const SideBar = styled.div(() => ({
  padding: "20px 16px",
  borderRight: `1px solid ${theme.palette.neutral[100]}`,
}));

const Title = styled.div(() => ({
  ...theme.typography.caption.sm.medium,
  color: theme.palette.neutral[400],
}));

const DateValue = styled.div(() => ({
  ...theme.typography.paragraph.md.regular,
  color: theme.palette.neutral[900],
  borderBottom: `1px solid ${theme.palette.neutral[900]}`,
}));

const Container = styled.div(() => ({
  display: "flex",
}));

const DateHeader = styled.div(() => ({
  display: "flex",
  gap: 24,
}));

interface CalendarProps {
  value: [Date, Date] | undefined;
  onChange(value: [Date, Date] | undefined): void;
  open: boolean;
  onClose?: () => void;
  anchorEl?: HTMLElement | undefined;
  placement?: Placement | undefined;
  monthsShown?: number | undefined;
  isResetAllow?: boolean | undefined;
}
const DATE_DISPLAY_FORMAT = "DD MMM YYYY";
const CalenderRange: FC<CalendarProps> = ({
  value,
  onChange,
  open,
  anchorEl,
  onClose,
  placement = "bottom",
  monthsShown,
  isResetAllow = true,
}) => {
  const [startDate, setStartDate] = useState<Date | undefined>(
    value ? value[0] : undefined
  );
  const [endDate, setEndDate] = useState<Date | undefined>(
    value ? dayjs(value[1]).subtract(1, "day").toDate() : undefined
  );

  useEffect(() => {
    setStartDate(value ? value[0] : undefined);
    setEndDate(value ? dayjs(value[1]).subtract(1, "day").toDate() : undefined);
  }, [value]);

  const onCloseHandler = onClose ? onClose : () => {};

  const handleApply = () => {
    onChange(
      startDate && endDate
        ? [startDate, dayjs(endDate).add(1, "day").toDate()]
        : undefined
    );
    onCloseHandler();
  };

  const HeaderContainer = ({ className, children }: HeaderContainerProps) => {
    let startDateStr = "";
    if (startDate) {
      startDateStr = dayjs(startDate).format(DATE_DISPLAY_FORMAT);
    }
    let endDateStr = "";
    if (endDate) {
      endDateStr = dayjs(endDate).format(DATE_DISPLAY_FORMAT);
    }
    return (
      <RootContainer placement={placement}>
        <CalendarContainer className={className}>
          <Container>
            <SideBar>
              <Button
                color="tertiary-gray"
                onClick={() => {
                  setStartDate(startOfToday.toDate());
                  setEndDate(startOfToday.toDate());
                }}
                size="sm"
                label="Today"
              />
              <Button
                color="tertiary-gray"
                onClick={() => {
                  setStartDate(startOfYesterday.toDate());
                  setEndDate(startOfYesterday.toDate());
                }}
                size="sm"
                label="Yesterday"
              />
              <Button
                color="tertiary-gray"
                onClick={() => {
                  setStartDate(startOfThisWeek.toDate());
                  setEndDate(endOfThisWeek.toDate());
                }}
                size="sm"
                label="This Week"
              />
              <Button
                color="tertiary-gray"
                onClick={() => {
                  setStartDate(startOfPrevWeek.toDate());
                  setEndDate(endOfPrevWeek.toDate());
                }}
                size="sm"
                label="Last Week"
              />
              <Button
                color="tertiary-gray"
                onClick={() => {
                  setStartDate(startOfThisMonth.toDate());
                  setEndDate(endofThisMonth.toDate());
                }}
                size="sm"
                label="This Month"
              />
              <Button
                color="tertiary-gray"
                onClick={() => {
                  setStartDate(startOfLastMonth.toDate());
                  setEndDate(endOfLastMonth.toDate());
                }}
                size="sm"
                label="Last Month"
              />
              <Button
                color="tertiary-gray"
                onClick={() => {
                  setStartDate(startOfThisYear.toDate());
                  setEndDate(endOfThisYear.toDate());
                }}
                size="sm"
                label="This Year"
              />
              <Button
                color="tertiary-gray"
                onClick={() => {
                  setStartDate(startOfLastYear.toDate());
                  setEndDate(endOfLastYear.toDate());
                }}
                size="sm"
                label="Last Year"
              />
              {isResetAllow && (
                <Button
                  color="tertiary-gray"
                  onClick={() => {
                    setStartDate(undefined);
                    setEndDate(undefined);
                  }}
                  size="sm"
                  label="All Time"
                />
              )}
            </SideBar>

            <div className="calendar-picker">
              <HeaderIcon>
                <DateHeader>
                  <div>
                    <Title>FROM</Title>
                    <DateValue>{startDateStr}</DateValue>
                  </div>
                  <div>
                    <Title>TO</Title>
                    <DateValue>{endDateStr}</DateValue>
                  </div>
                </DateHeader>
                <IconButton
                  color="tertiary-gray"
                  icon={ClearIcon}
                  onClick={onCloseHandler}
                  size="md"
                />
              </HeaderIcon>
              {children}
            </div>
          </Container>
        </CalendarContainer>
      </RootContainer>
    );
  };

  const handleChange = (date: Date | [Date | null, Date | null]) => {
    if (Array.isArray(date)) {
      setStartDate(date[0] || undefined);
      setEndDate(date[1] || undefined);
    } else {
      setStartDate(date);
    }
  };

  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      placement={`${placement}-start`}
      style={{ zIndex: 1400 }}
    >
      <DatePicker
        onChange={handleChange}
        calendarContainer={HeaderContainer}
        renderCustomHeader={(props) => (
          <Header {...props} startDate={startDate} />
        )}
        shouldCloseOnSelect={false}
        inline
        startDate={startDate}
        endDate={endDate}
        selectsRange={true}
        onClickOutside={onClose}
        monthsShown={monthsShown}
        fixedHeight
      >
        <ButtonContainer>
          <Button
            color="primary"
            onClick={handleApply}
            size="sm"
            label="Apply"
          />
          <Button
            color="secondary-gray"
            onClick={onCloseHandler}
            size="sm"
            label="Cancel"
          />
        </ButtonContainer>
      </DatePicker>
    </Popper>
  );
};

export default CalenderRange;
