// EX: 1 (101, 1, 10, 2)  => <= 1,2,3, ... ,9,10,11 =>
// EX: 2 (101, 4, 10, 2)  => <= 2,3,4, ... ,9,10,11 =>
// EX: 3 (101, 8, 10, 2)  => <= 6,7,8, ... ,9,10,11 =>
// EX: 4 (101, 1, 25, 2)  => <= 1,2,3,4,5 =>
// EX: 5 (101, 1, 50, 2)  => <= 1,2,3 =>
// EX: 6 (101, 1, 100, 2) => <= 1,2 =>

const getShortcutPageNumbers = (
  numRecords: number,
  page: number,
  pageSize: number,
  PAGE_BLOCK_DISPLAY: number = 2
) => {
  // it is calculating the last page based on the page size

  const lastPage = Math.ceil(numRecords / pageSize);

  let leftPageStart = 1;

  if (page - PAGE_BLOCK_DISPLAY >= 1) {
    leftPageStart = page - PAGE_BLOCK_DISPLAY;
  }
  let leftPageEnd = leftPageStart + PAGE_BLOCK_DISPLAY;

  let rightPageStart = lastPage - PAGE_BLOCK_DISPLAY;
  let rightPageEnd = rightPageStart + PAGE_BLOCK_DISPLAY;

  const leftPages = [];
  const rightPages = [];

  if (leftPageEnd >= rightPageStart) {
    let singlePageStart = leftPageStart;
    if (rightPageStart > PAGE_BLOCK_DISPLAY) {
      singlePageStart = rightPageStart - PAGE_BLOCK_DISPLAY;
    }

    for (let i = singlePageStart; i <= rightPageEnd; i++) {
      leftPages.push(i);
    }
  } else {
    for (let i = leftPageStart; i <= leftPageEnd; i++) {
      leftPages.push(i);
    }

    for (let i = rightPageStart; i <= rightPageEnd; i++) {
      rightPages.push(i);
    }
  }

  return { leftPages, rightPages, lastPage };
};

export default getShortcutPageNumbers;
