import { FC, ReactElement } from "react";
import { useUrlQueryParam } from "../hooks/useUrlQueryParam";

interface FilterChipProps {
  urlParamName: string;
  component: ReactElement;
  permanent: boolean;
}

const FilterChip: FC<FilterChipProps> = ({
  component,
  permanent,
  urlParamName,
}) => {
  const [urlParam] = useUrlQueryParam(urlParamName);

  if (permanent || urlParam !== undefined) {
    return component;
  }

  return null;
};

export default FilterChip;
