import { PaletteColor } from "../../types";

const color: PaletteColor = {
  25: "#FEF1FA",
  50: "#FFE4F7",
  100: "#FFC9F0",
  200: "#FF9DE3",
  300: "#FF61CD",
  400: "#FF32B5",
  500: "#F31295",
  600: "#D40276",
  700: "#AF0561",
  800: "#910A53",
  900: "#5A002E",
};

export default color;
