import styled from "@emotion/styled";
import { ExtendedEmployee, WithMetadata } from "@hshrimal/salesleg";
import { FC, useState } from "react";
import { useFormContext } from "react-hook-form";
import { ProjectWithExtendedDistributionQueue } from "..";
import Avatar from "../../../components/Avatar";
import Badge from "../../../components/Badge";
import Button from "../../../components/Button/Button";
import CheckBox from "../../../components/CheckBox";
import MinusCircleIcon from "../../../components/icons/MinusCircleIcon";
import PlusCircleIcon from "../../../components/icons/PlusCircleIcon";
import TrashIcon from "../../../components/icons/TrashIcon";
import { useUrlQueryParam } from "../../../hooks/useUrlQueryParam";

interface UserInfoProps {
  user: WithMetadata<ExtendedEmployee>;
  weight: number;
}

const Card = styled.div<{ selected: boolean; nav?: string | undefined }>(
  ({ selected, theme, nav }) => ({
    width: nav === "expanded" ? 648 : 872,
    height: 76,
    padding: 16,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: selected ? theme.salesleg.palette.primary[25] : "#ffffff",
    border: selected
      ? `1px solid ${theme.salesleg.palette.primary[500]}`
      : `1px solid ${theme.salesleg.palette.neutral[50]}`,
    boxShadow: theme.salesleg.shadow.xs,
    borderRadius: 6,
  })
);

const Container = styled.div(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: 24,
}));

const UserContainer = styled.div(({ theme }) => ({
  display: "flex",
  gap: 10,
  alignItems: "center",
}));

const UserTitleContainer = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 4,
}));

const UserTitle = styled.div(({ theme }) => ({
  ...theme.salesleg.typography.body.sm.medium,
}));

const UserSubTitle = styled.div(({ theme }) => ({
  ...theme.salesleg.typography.body.xs.regular,
  color: theme.salesleg.palette.neutral[400],
}));

const ButtonContainer = styled.div(({ theme }) => ({
  display: "flex",
  gap: 8,
  alignItems: "center",
}));

const Text = styled.div(({ theme }) => ({
  ...theme.salesleg.typography.paragraph.md.regular,
  width: 80,
  height: 44,
  border: `1px solid ${theme.salesleg.palette.neutral[100]}`,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  boxShadow: theme.salesleg.shadow.xs,
  borderRadius: 6,
  background: "#FFFFFF",
  color: theme.salesleg.palette.neutral[500],
}));

const Weights = styled.div(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: 24,
}));

const SelectedUserCard: FC<UserInfoProps> = ({ user, weight }) => {
  const [selected, setSelected] = useState(false);
  const [nav] = useUrlQueryParam("nav");

  const { getValues, setValue } =
    useFormContext<ProjectWithExtendedDistributionQueue>();

  const onDeleteUser = (userId: string) => {
    const { distributionQueue, users: selectedUsers } = getValues();
    const updatedQueue = distributionQueue.filter(
      (queueMember) => queueMember.salesExecutive.id !== userId
    );
    setValue("distributionQueue", updatedQueue);

    const updatedUsers = selectedUsers.filter((user) => user.id !== userId);
    setValue("users", updatedUsers);
  };

  const onChangeUserWeight = (userId: string, weight: number) => {
    const distributionQueue = getValues().distributionQueue;
    const updatedQueue = distributionQueue.map((queueMember) => {
      if (queueMember.salesExecutive.id === userId) {
        queueMember.weight = weight;
      }
      return queueMember;
    });
    setValue("distributionQueue", updatedQueue);
  };

  const onIncrement = () => {
    onChangeUserWeight(user.id, weight + 1);
  };

  const onDecrement = () => {
    if (weight > 0) {
      onChangeUserWeight(user.id, weight - 1);
    }
  };

  return (
    <Card selected={selected} nav={nav}>
      <Container>
        <CheckBox
          size="sm"
          checked={selected}
          onChange={(checked) => setSelected(checked)}
        />
        <UserContainer>
          <Avatar size={32} />
          <UserTitleContainer>
            <UserTitle>{user.name}</UserTitle>
            <UserSubTitle>{user.alias}</UserSubTitle>
          </UserTitleContainer>
        </UserContainer>
      </Container>

      <Weights>
        <Badge color="neutral" size="md" label={user.teams[0]?.name} />
        <ButtonContainer>
          <Button
            leadingIcon={MinusCircleIcon}
            color="secondary-gray"
            size="sm"
            style={{ height: 44, padding: 12 }}
            onClick={onDecrement}
            disabled={weight === 0}
          />
          <Text>{`${weight}x`}</Text>
          <Button
            leadingIcon={PlusCircleIcon}
            color="secondary-gray"
            size="sm"
            style={{ height: 44, padding: 12 }}
            onClick={onIncrement}
          />
        </ButtonContainer>
        <Button
          leadingIcon={TrashIcon}
          color="secondary-gray"
          size="sm"
          style={{ height: 44, width: 44, border: "none", boxShadow: "none" }}
          onClick={() => onDeleteUser(user.id)}
        />
      </Weights>
    </Card>
  );
};

export default SelectedUserCard;
