import styled from "@emotion/styled";
import { Modal } from "@mui/material";
import { ChangeEvent, FC, useRef, useState } from "react";
import { ReactComponent as Empty } from "../../assets/Empty.svg";
import Button from "../../components/Button/Button";
import ModalHeader from "../../components/Modal/ModalHeader";
import { ProgressIndicator } from "../../components/ProgressIndicator";
import CloudArrowUpIcon from "../../components/icons/CloudArrowUpIcon";
import ModelBlurBG from "../../components/icons/ModelBlurBG";
import ModelSqureBG from "../../components/icons/ModelBlurBGSqure";
import { useUploadLeadAttachmentMutation } from "../../redux/features/api/leads";
import theme from "../../theme/light/theme";
import FileUploadingCard from "./FileUploadingCard";

interface UploadFilesModalProps {
  onClose: () => void;
  open: boolean;
  leadId: string;
}

const RootContainer = styled.div(() => ({
  position: "absolute",
  width: 468,
  left: "50%",
  top: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: 3,
  background: "#ffffff",
  border: `3px solid #ffffff`,
  zIndex: 20,
}));

const BackgroundSqure = styled.div(() => ({
  display: "flex",
  position: "absolute",
  zIndex: -1,
}));

const ModalFooter = styled.div(() => ({
  display: "flex",
  justifyContent: "center",
  gap: 16,
  backgroundColor: "#ffffff",
  padding: "0px 24px 24px 24px",
}));

const TitleContainer = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: 8,
}));

const Title = styled.div(({ theme }) => ({
  ...theme.salesleg.typography.heading.sm.semiBold,
  color: theme.salesleg.palette.neutral[900],
}));

const SubTitle = styled.div(({ theme }) => ({
  ...theme.salesleg.typography.paragraph.sm.regular,
  color: theme.salesleg.palette.neutral[400],
}));

const BlurBackground = styled.div(() => ({
  display: "flex",
  position: "absolute",
  zIndex: -1,
}));

const Background = styled.div(({ theme }) => ({
  position: "relative",
  height: 44,
  width: 44,
  background: "#ffffff",
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  boxShadow: theme.salesleg.shadow.sm,
}));

const UploadFileContainer = styled.div(() => ({
  padding: "20px 24px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: 16,
  borderRadius: 12,
  border: `1px solid ${theme.palette.neutral[100]}`,
  background: "#FFF",
}));

const TextContainer = styled.div(() => ({
  display: "flex",
  gap: 8,
  flexDirection: "column",
  alignItems: "center",
}));

const Link = styled.div(() => ({
  ...theme.typography.body.sm.medium,
  color: theme.palette.primary[700],
  cursor: "pointer",
}));

const Label = styled.div(() => ({
  ...theme.typography.body.sm.regular,
  color: theme.palette.neutral[400],
}));

const BodyContainer = styled.div(() => ({
  padding: "0px 24px 24px 24px",
  gap: 20,
  display: "flex",
  flexDirection: "column",
}));

const CardContainer = styled.div(() => ({
  display: "flex",
  gap: 12,
  flexDirection: "column",
}));

const UploadFilesModal: FC<UploadFilesModalProps> = ({
  onClose,
  open,
  leadId,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [uploadFile, setUploadFile] = useState<null | File>(null);

  const [uploadAttachment, { isLoading: isUploading }] =
    useUploadLeadAttachmentMutation();

  const handleClick = () => {
    inputRef?.current?.click();
  };

  const handleClose = () => {
    setUploadFile(null);
    onClose();
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target;
    let fileObj = null;
    if (input.files && input.files?.length) {
      fileObj = input.files[0];
    }

    if (!fileObj) {
      return;
    }

    setUploadFile(fileObj);
  };
  const onSubmit = () => {
    if (uploadFile) {
      uploadAttachment({
        file: uploadFile,
        name: uploadFile.name,
        leadId,
      });
      handleClose();
    }
  };

  return (
    <>
      <ProgressIndicator open={isUploading} />
      <Modal open={open} onClose={() => handleClose()}>
        <RootContainer>
          <BlurBackground>
            <ModelBlurBG color={theme.palette.primary[500]} />
          </BlurBackground>
          <BackgroundSqure>
            <ModelSqureBG color={theme.palette.primary[500]} />
          </BackgroundSqure>
          <ModalHeader toggle={() => handleClose()}>
            <Background>
              <CloudArrowUpIcon size={24} color={theme.palette.primary[600]} />
            </Background>
          </ModalHeader>
          <div style={{ display: "flex", flexDirection: "column", gap: 32 }}>
            <BodyContainer>
              <TitleContainer>
                <Title>Upload Files</Title>
                <SubTitle>Upload and attach files to this project.</SubTitle>
              </TitleContainer>
              <UploadFileContainer>
                <Empty />
                <TextContainer>
                  <input
                    style={{ display: "none" }}
                    ref={inputRef}
                    type="file"
                    onChange={handleFileChange}
                    accept=".pdf"
                  />
                  <Link onClick={handleClick}>Click to upload</Link>
                  <Label>XLS, PDF, JPG or PNG (max. 10 MB)</Label>
                </TextContainer>
              </UploadFileContainer>
              <CardContainer>
                {uploadFile && (
                  <FileUploadingCard
                    fileName={uploadFile.name}
                    fileSize={`${(uploadFile.size / (1024 * 1024)).toFixed(
                      2
                    )} MB`}
                    onClose={() => setUploadFile(null)}
                  />
                )}
              </CardContainer>
            </BodyContainer>
            <ModalFooter>
              <Button
                color="secondary-gray"
                label="Cancel"
                size="lg"
                onClick={() => handleClose()}
                fullWidth
              />
              <Button
                color="primary"
                label="Upload Files"
                size="lg"
                onClick={() => onSubmit()}
                fullWidth
                disabled={uploadFile === null}
              />
            </ModalFooter>
          </div>
        </RootContainer>
      </Modal>
    </>
  );
};

export default UploadFilesModal;
