import styled from "@emotion/styled";
import { ReactComponent as OppsTroopSvg } from "./../../../assets/opps-troop.svg";

const EmptyUsersListContainer = styled.div(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
}));

const TroopsText = styled.div(({ theme }) => ({
  ...theme.salesleg.typography.heading.lg.medium,
  color: theme.salesleg.palette.neutral[900],
}));

const OppsText = styled.div(({ theme }) => ({
  ...theme.salesleg.typography.paragraph.md.regular,
  color: theme.salesleg.palette.neutral[500],
  textAlign: "center",
}));

const TextContainer = styled.div(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  gap: 8,
}));

const EmptyUsersList = () => {
  return (
    <EmptyUsersListContainer>
      <OppsTroopSvg height={120} width={120} />
      <TextContainer>
        <TroopsText>Where is your troop?</TroopsText>
        <OppsText>Search by user or team to add users</OppsText>
      </TextContainer>
    </EmptyUsersListContainer>
  );
};

export default EmptyUsersList;
