import { useTheme } from "@emotion/react";
import { FC } from "react";

interface VerticalDividerProps {
  height?: number | string | undefined;
  width?: number | string | undefined;
}

const VerticalDivider: FC<VerticalDividerProps> = ({ width = 1, height }) => {
  const theme = useTheme();
  return (
    <div
      style={{
        width: width,
        height: height,
        border: `1px solid ${theme.salesleg.palette.neutral[100]}`,
      }}
    />
  );
};

export default VerticalDivider;
