import styled from "@emotion/styled";
import { FC } from "react";
import Combobox from "../../../components/Combobox";

export interface ISearchType {
  key: string;
  searchType: string;
}

const searchType: ISearchType[] = [
  {
    key: "1",
    searchType: "Search by user",
  },
  {
    key: "2",
    searchType: "Search by team",
  },
];

const OptionContainer = styled.div(({ theme }) => ({
  ...theme.salesleg.typography.paragraph.sm.medium,
  height: 40,
  width: 200,
  alignItems: "center",
  display: "flex",
}));

interface SearchPreferenceProps {
  searchBy: (key: string) => void;
  selectedSearchKey: string;
}

const SearchPreference: FC<SearchPreferenceProps> = ({
  searchBy,
  selectedSearchKey,
}) => {
  const onSearchTypeChange = (searchType: ISearchType) => {
    searchBy(searchType.key);
  };

  return (
    <Combobox
      options={searchType}
      height={40}
      width={154}
      renderOption={(data) => {
        return <OptionContainer>{data.searchType}</OptionContainer>;
      }}
      onSelectionChange={onSearchTypeChange}
      selectedOptionRender={(data) => {
        return <div>{data.searchType}</div>;
      }}
      initialValuePlaceholder={<>Search by Users</>}
      selectedKey={selectedSearchKey}
    />
  );
};

export default SearchPreference;
