import { EmployeeFilter } from "@hshrimal/salesleg";
import { Controller, useFormContext } from "react-hook-form";
import { CreateUserFormValues } from ".";
import Avatar from "../../components/Avatar";
import DropDownV2 from "../../components/DropDownV2/DropDownV2";
import { ProgressIndicator } from "../../components/ProgressIndicator";
import { useGetEmployeesQuery } from "../../redux/features/api/employees";
import { StyledFC } from "../../types/styledFC";
import { useOrgFilter } from "../ViewClients/useOrgFilter";

interface ManagerDropdownProps {
  selectedTeamId: string;
}

const ManagerDropdown: StyledFC<ManagerDropdownProps> = ({
  selectedTeamId,
}) => {
  const { control, setValue } = useFormContext<CreateUserFormValues>();

  const orgFilter = useOrgFilter();
  const employeeFilter: EmployeeFilter = {
    ...orgFilter,
    role: ["sales_lead", "sales_head"],
    status: "active",
  };
  const { data: employees, isFetching: isFetchingEmployees } =
    useGetEmployeesQuery(employeeFilter);
  const managerOptions = employees?.map((emp) => ({
    value: emp.id,
    label: emp.name,
  }));

  return (
    <>
      <Controller
        name="managerId"
        control={control}
        render={({ field }) => {
          const { value: selectedManagerId } = field;
          return (
            <DropDownV2
              items={managerOptions || []}
              label="Manager"
              width={312}
              height={44}
              onChange={(option) => {
                setValue("managerId", option as string);
              }}
              value={selectedManagerId}
              menuItemVariant="avatar"
              avatarComponent={Avatar}
            />
          );
        }}
      />

      <ProgressIndicator open={isFetchingEmployees} />
    </>
  );
};

export default ManagerDropdown;
