import styled from "@emotion/styled";
import { ExtendedLead, WithMetadata } from "@hshrimal/salesleg";
import { FC } from "react";
import ActivityTimeline from "./ActivityTimeline";
import ClientDetailFooter from "./ClientDetailFooter";
import ScheduledFooter from "./ScheduledFooter";

const RootContainer = styled.div(() => ({
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
}));

const TimeLineContainer = styled.div(() => ({
  flexBasis: 0,
  flexGrow: 1,
  overflowY: "auto",
}));

interface ActivityTimelineTabProps {
  lead: WithMetadata<ExtendedLead>;
}

const ActivityTimelineTab: FC<ActivityTimelineTabProps> = ({ lead }) => {
  return (
    <RootContainer>
      <TimeLineContainer>
        <ActivityTimeline
          leadCreatedAt={lead.created_at}
          activities={lead.activities.completed}
        />
      </TimeLineContainer>
      {lead.activities.scheduled.length === 0 &&
        lead.stage !== "dump" &&
        lead.stage !== "booking" && <ClientDetailFooter />}
      {lead.activities.scheduled.length > 0 && <ScheduledFooter lead={lead} />}
    </RootContainer>
  );
};

export default ActivityTimelineTab;
