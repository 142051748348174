import { FC, useRef, useState } from "react";
import Avatar from "../../components/Avatar";
import Button from "../../components/Button/Button";
import { Menu } from "../../components/Menu";
import { MenuItem } from "../../components/Menu/MenuItem";
import { ProgressIndicator } from "../../components/ProgressIndicator";
import UserSwitchIcon from "../../components/icons/UserSwitchIcon";
import { useGetEmployeesQuery } from "../../redux/features/api/employees";

interface MultiChangeAssigneeButtonProps {
  onAssigneeChange: (assigneeId: string) => void;
}

const MultiChangeAssigneeButton: FC<MultiChangeAssigneeButtonProps> = ({
  onAssigneeChange,
}) => {
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen((open) => !open);
  };

  const { data: employees, isFetching: isFetchingEmployees } =
    useGetEmployeesQuery({
      role: ["sales_head", "sales_lead", "sales_associate"],
      status: "active",
    });

  return (
    <>
      <Button
        color="primary"
        onClick={() => {
          toggle();
        }}
        size="sm"
        leadingIcon={UserSwitchIcon}
        label="Change Assignee"
        ref={buttonRef}
      />
      <Menu
        open={open}
        onClose={() => setOpen(false)}
        placement="top"
        enableSearch
        searchPlaceholder="Search Users"
        anchorEl={buttonRef.current || undefined}
        onChange={(assignedToId) => onAssigneeChange(assignedToId as string)}
      >
        {employees?.map((employee) => {
          return (
            <MenuItem
              key={employee.id}
              label={employee.name}
              variant="avatar"
              avatarComponent={Avatar}
              value={employee.id}
            />
          );
        })}
      </Menu>
      <ProgressIndicator open={isFetchingEmployees} />
    </>
  );
};

export default MultiChangeAssigneeButton;
