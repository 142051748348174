import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { ReactNode, useState } from "react";
import DropdownOpenIcon from "../icons/DropdownOpenIcon";

const ComboboxContainer = styled.div<{
  height?: number | string | undefined;
  width?: number | string | undefined;
}>(({ theme, width, height }) => ({
  ...theme.salesleg.typography.body.sm.regular,
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  justifyContent: "center",
  width: width,
  height: height,
}));

const OptionContainer = styled.div(({ theme }) => ({
  border: `1px solid ${theme.salesleg.palette.neutral[50]}`,
  boxShadow: theme.salesleg.shadow.xxl,
  position: "absolute",
  overflow: "hidden",
  borderRadius: 8,
  zIndex: 1,
}));

const ComboboxOption = styled.div<{ isActive?: boolean }>(
  ({ theme, isActive }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: 14,
    paddingRight: 14,
    background: isActive ? theme.salesleg.palette.primary[25] : "#ffffff",
    cursor: "pointer",
  })
);

interface IComboboxOption {
  key: string | number;
}

interface ComboboxProps<T> {
  options: T[];
  onSelectionChange: (data: T) => void;
  renderOption: (data: T) => ReactNode;
  selectedOptionRender: (data: T) => ReactNode;
  quickAddComponent?: ReactNode | undefined;
  initialValuePlaceholder: ReactNode;
  selectedKey?: string | undefined;
  height?: number | string | undefined;
  width?: number | string | undefined;
}

const Combobox = <T extends IComboboxOption>({
  options,
  onSelectionChange,
  renderOption,
  quickAddComponent,
  initialValuePlaceholder,
  selectedKey,
  selectedOptionRender,
  height,
  width,
}: ComboboxProps<T>) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  const toggle = () => {
    setOpen((open) => !open);
  };

  const onSelect = (option: T) => {
    onSelectionChange(option);
    toggle();
  };

  const selectedIndex = options.findIndex((e) => e.key === selectedKey);

  return (
    <div>
      <ComboboxContainer onClick={toggle} height={height} width={width}>
        {selectedIndex !== -1
          ? selectedOptionRender(options[selectedIndex])
          : initialValuePlaceholder}
        <DropdownOpenIcon
          size={12.5}
          style={{ marginLeft: 4 }}
          color={theme.salesleg.palette.neutral[400]}
        />
      </ComboboxContainer>

      {open && (
        <OptionContainer>
          {options.map((option) => {
            return (
              <ComboboxOption
                isActive={option.key === selectedKey}
                key={option.key}
                onClick={() => onSelect(option)}
              >
                {renderOption(option)}
              </ComboboxOption>
            );
          })}
          {quickAddComponent && (
            <ComboboxOption>{quickAddComponent}</ComboboxOption>
          )}
        </OptionContainer>
      )}
    </div>
  );
};

export default Combobox;
