import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { StyledFC } from "../../types/styledFC";
import InputField from "../InputField";
import SearchIcon from "../icons/SearchIcon";

const SelectOption = styled.div<{ isActive?: boolean }>(
  ({ theme, isActive }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "4px 14px",
    background: isActive ? theme.salesleg.palette.primary[25] : "#ffffff",
    cursor: "pointer",
  })
);

interface SearchInputProps {
  value?: string | undefined;
  onChange: (value: string | undefined) => void;
  placeholder?: string | undefined;
  autoFocus?: boolean | undefined;
}

export const SearchInput: StyledFC<SearchInputProps> = ({
  value,
  onChange,
  placeholder,
  autoFocus,
}: SearchInputProps) => {
  const theme = useTheme();
  return (
    <SelectOption>
      <InputField
        placeholder={placeholder}
        width="auto"
        height={32}
        startIcon={
          <SearchIcon size={20} color={theme.salesleg.palette.neutral[400]} />
        }
        value={value}
        onChange={(e) => onChange(e.target.value)}
        autoFocus={autoFocus}
      />
    </SelectOption>
  );
};
