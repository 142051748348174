import styled from "@emotion/styled";
import { ChangeEvent, useRef, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { CreateUserFormValues } from ".";
import InputField from "../../components/InputField";
import { Menu } from "../../components/Menu";
import { MenuItem } from "../../components/Menu/MenuItem";
import { ProgressIndicator } from "../../components/ProgressIndicator";
import SearchIcon from "../../components/icons/SearchIcon";
import { useGetTeamsQuery } from "../../redux/features/api/teams";
import theme from "../../theme/light/theme";
import SectionHeader from "./SectionHeader";
import TeamCard from "./TeamCard";

const RootContaner = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: 12,
}));

const SearchBar = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: 6,
}));

const CardContainer = styled.div(() => ({
  display: "flex",
  gap: 24,
  flexWrap: "wrap",
}));

const HeaderContainer = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: 16,
}));

const SelectTeamSection = () => {
  const searchInput = useRef(null);
  const { data: teams, isLoading: isTeamLoading } = useGetTeamsQuery();
  const [open, setOpen] = useState<boolean>(false);
  const [searchText, setSearchText] = useState("");

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const userInput = event.target.value;
    setSearchText(userInput);
    setOpen(true);
  };
  const filteredTeams = teams?.filter((team) =>
    team.name.toLowerCase().includes(searchText.toLowerCase())
  );
  const form = useFormContext<CreateUserFormValues>();

  const { teamIds } = form.getValues();
  const selectedTeams = teams?.filter((team) => teamIds.includes(team.id));

  const onRemoveTeam = (teamIdParam: string) => {
    const newTeamIds = teamIds.filter((teamId) => teamId !== teamIdParam);
    form.setValue("teamIds", newTeamIds);
  };

  return (
    <RootContaner>
      <HeaderContainer>
        <SectionHeader title="Select Operational Teams" />
        <SearchBar>
          <InputField
            startIcon={
              <SearchIcon size={24} color={theme.palette.neutral[400]} />
            }
            height={44}
            width={534}
            placeholder="Search Team"
            ref={searchInput}
            value={searchText}
            label="Select Teams"
            onChange={(event) => handleInputChange(event)}
            onFocus={() => setOpen(true)}
            onBlur={() => setOpen(false)}
          />
        </SearchBar>

        <Controller
          name="teamIds"
          control={form.control}
          render={({ field }) => {
            const { value: selectedTeamIds } = field;

            return (
              <Menu
                open={open}
                anchorEl={searchInput.current || undefined}
                value={selectedTeamIds || []}
                placement="bottom"
                onChange={(selectedIds: string | string[] | undefined) => {
                  if (selectedIds === undefined) {
                    form.setValue("teamIds", []);
                  } else {
                    form.setValue("teamIds", selectedIds as string[]);
                  }
                }}
                multiple
              >
                {filteredTeams?.map((team) => (
                  <MenuItem
                    key={team.id}
                    label={team.name}
                    variant="default"
                    value={team.id}
                  />
                ))}
              </Menu>
            );
          }}
        />
      </HeaderContainer>
      <CardContainer>
        {selectedTeams?.map((team) => {
          return <TeamCard key={team.id} team={team} onRemove={onRemoveTeam} />;
        })}
      </CardContainer>
      <ProgressIndicator open={isTeamLoading} />
    </RootContaner>
  );
};

export default SelectTeamSection;
