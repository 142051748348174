import styled from "@emotion/styled";

interface TableColumn {
  name: string;
  width?: number | string | undefined;
}

interface TableColumnGroup {
  span?: number | undefined;
  width?: number | string | undefined;
}

interface TableProps<TRow> {
  columnGroups: TableColumnGroup[];
  columns: TableColumn[];
  rows: TRow[];
  totalRow?: TRow | undefined;
  renderTableRowCells: (
    row: TRow,
    index: number,
    isTotalRow: boolean
  ) => React.ReactNode[];
}

const Container = styled.table(({ theme }) => ({
  border: `1px solid ${theme.salesleg.palette.neutral[100]}`,
  borderRadius: 8,
  flexGrow: 1,
  padding: 0,
  borderSpacing: 0,
  tableLayout: "fixed",
  "tr:first-of-type": {
    "th:first-of-type": {
      borderTopLeftRadius: 7,
    },
    "th:last-child": {
      borderTopRightRadius: 7,
    },
  },
  "tr:last-child": {
    "th:first-of-type": {
      borderBottomLeftRadius: 7,
      borderWidth: 0,
    },
    "th:last-child": {
      borderBottomRightRadius: 7,
      borderWidth: 0,
    },
    "td:first-of-type": {
      borderBottomLeftRadius: 7,
    },
    "td:last-child": {
      borderBottomRightRadius: 7,
    },
    th: {
      borderWidth: 0,
    },
    td: {
      borderWidth: 0,
    },
  },
}));

const TableRow = styled.tr(({ theme }) => ({
  backgroundColor: "#ffffff",
  borderBottom: `1px solid ${theme.salesleg.palette.neutral[100]}`,
}));

const ColumnHeader = styled.th(({ theme }) => ({
  height: 44,
  padding: "15px 24px",
  backgroundColor: theme.salesleg.palette.neutral[50],
  borderBottom: `1px solid ${theme.salesleg.palette.neutral[100]}`,
  ...theme.salesleg.typography.caption.sm.medium,
  letterSpacing: 1,
  textAlign: "left",
  textTransform: "uppercase",
  color: theme.salesleg.palette.neutral[400],
}));

const TableCell = styled.td(({ theme }) => ({
  borderBottom: `1px solid ${theme.salesleg.palette.neutral[100]}`,
  height: 64,
  paddingLeft: 24,
  paddingRight: 24,
  ...theme.salesleg.typography.body.sm.regular,
  color: theme.salesleg.palette.neutral[700],
}));

const TotalRowTableCell = styled.td(({ theme }) => ({
  borderBottom: `1px solid ${theme.salesleg.palette.neutral[100]}`,
  height: 64,
  paddingLeft: 24,
  paddingRight: 24,
  ...theme.salesleg.typography.body.sm.bold,
  color: theme.salesleg.palette.neutral[400],
  backgroundColor: theme.salesleg.palette.neutral[50],
}));

function Table<TRow>({
  columnGroups,
  columns,
  rows,
  totalRow,
  renderTableRowCells,
}: TableProps<TRow>) {
  return (
    <Container>
      <colgroup>
        {columnGroups.map((colGroup, index) => (
          <col span={colGroup.span} width={colGroup.width} key={index} />
        ))}
      </colgroup>
      <thead>
        <TableRow>
          {columns.map((column, index) => (
            <ColumnHeader key={index} scope="col">
              {column.name}
            </ColumnHeader>
          ))}
        </TableRow>
      </thead>
      <tbody>
        {rows.map((row, index) => {
          const cells = renderTableRowCells(row, index, false);
          return (
            <TableRow key={index}>
              {cells.map((cell, index) => (
                <TableCell key={index}>{cell}</TableCell>
              ))}
            </TableRow>
          );
        })}
        {rows.length > 0 && totalRow && (
          <TableRow key={rows.length}>
            {renderTableRowCells(totalRow, rows.length, true).map(
              (cell, index) => (
                <TotalRowTableCell key={index}>
                  {index > 0 ? cell : <></>}
                </TotalRowTableCell>
              )
            )}
          </TableRow>
        )}
      </tbody>
    </Container>
  );
}

export default Table;
