import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { KeyboardEvent, useState } from "react";
import InputField from "../../../components/InputField";
import { ProgressIndicator } from "../../../components/ProgressIndicator";
import PlusIcon from "../../../components/icons/PlusIcon";
import XCircleIcon from "../../../components/icons/XCircleIcon";
import { useCreateDeveloperMutation } from "../../../redux/features/api/developers";
import { StyledFC } from "../../../types/styledFC";

const Container = styled.div(() => ({
  display: "flex",
  alignItems: "center",
  gap: 8,
  cursor: "pointer",
  width: 320,
  height: 36,
  animation: "fadeIn 5s",
}));

const LableText = styled.div(({ theme }) => ({
  ...theme.salesleg.typography.paragraph.sm.regular,
  color: theme.salesleg.palette.primary[600],
}));

const AddDeveloperButton: StyledFC<{}> = ({ style }) => {
  const [open, setOpen] = useState(true);
  const [developer, setDeveloper] = useState("");
  const [error, setError] = useState("");
  const theme = useTheme();

  const [createDeveloper, { isLoading: isCreatingDeveloper }] =
    useCreateDeveloperMutation();
  const toggle = () => {
    setOpen((open) => !open);
  };

  const onChange = (developer: string) => {
    setDeveloper(developer);
  };

  const onKeyUp = (keyBoardEvent: KeyboardEvent<HTMLInputElement>) => {
    if (keyBoardEvent.key === "Enter") {
      if (developer.trim() === "") {
        setError("Developer name is required");
      } else {
        createDeveloper({ name: developer });
        setDeveloper("");
        setError("");
      }
    }
  };

  return (
    <>
      {open && (
        <Container onClick={toggle} style={style}>
          <PlusIcon size={20} color={theme.salesleg.palette.primary[600]} />
          <LableText>Add Developers</LableText>
        </Container>
      )}
      {!open && (
        <>
          <InputField
            placeholder="Create New Developer"
            value={developer}
            width={292}
            height={36}
            endIcon={
              <XCircleIcon
                onClick={toggle}
                size={16}
                color={theme.salesleg.palette.neutral[400]}
              />
            }
            onChange={(e) => onChange(e.target.value)}
            onKeyUp={onKeyUp}
            error={error}
          />
          <ProgressIndicator open={isCreatingDeveloper} />
          {/* FIX THIS: Currently not showing inside of the developer dropw down menu in a weird way*/}
          {/* {createDeveloperError && (
            <ErrorNotification
              message={(createDeveloperError as any).data.message}
            />
          )} */}
        </>
      )}
    </>
  );
};

export default AddDeveloperButton;
