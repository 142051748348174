import { LeadsQualityByManagerV2 } from "@hshrimal/salesleg";
import { downloadCsv } from "../../../utils/csvUtils";

function toCSV(leadsQuality: LeadsQualityByManagerV2) {
  const leadAttributes = [
    leadsQuality.employee.name,
    leadsQuality.total_leads,
    leadsQuality.new_leads,
    leadsQuality.contacted_leads,
    leadsQuality.interested_leads,
    leadsQuality.visit_planned_leads,
    leadsQuality.visit_done_leads,
    leadsQuality.negotiation_leads,
    leadsQuality.booking_leads,
    leadsQuality.dump_leads,
  ];
  return leadAttributes.join(",");
}

export function downloadAllLeadsManager(
  leadsQuality: LeadsQualityByManagerV2[]
) {
  const columnNames = [
    "MANAGER,TOTAL,NEW,CONTACTED,INTERESTED,VISIT PLANNED,VISIT DONE,NEGOTIATION",
  ];
  const headerRow = columnNames.join(",");
  const dataRows = leadsQuality.map((leads) => toCSV(leads));
  const csv = [headerRow, ...dataRows].join("\n");
  downloadCsv(csv, `All_Leads_Manager`);
}
