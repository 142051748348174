import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import {
  DateRange,
  GetSiteVisitsRequest,
  SiteVisit,
  deserializeSiteVisit,
} from "@hshrimal/salesleg";
import dayjs from "dayjs";
import { FilterPanel } from "../../components/FilterPanel";
import Pagination from "../../components/Pagination";
import { ProgressIndicator } from "../../components/ProgressIndicator";
import Divider from "../../components/SideBar/Divider";
import Table from "../../components/Table";
import LeadSourceMenu from "../../components/filterMenus/LeadSourceMenu";
import ManagerMenu from "../../components/filterMenus/ManagerMenu";
import ProjectMenu from "../../components/filterMenus/ProjectMenu";
import SalesExecutiveMenu from "../../components/filterMenus/SalesExecutiveMenu";
import DateRangeFilter from "../../components/filters/DateRangeFilter";
import LeadSourceFilter from "../../components/filters/LeadSourceFilter";
import ManagerFilter from "../../components/filters/ManagerFilter";
import ProjectFilter from "../../components/filters/ProjectFilter";
import SalesExecutiveFilter from "../../components/filters/SalesExecutiveFilter";
import { SiteVisitStatusFilter } from "../../components/filters/SiteVisitStatusFilter";
import CalendarIcon from "../../components/icons/CalenderIcon";
import SquaresFourIcon from "../../components/icons/SquaresFourIcon";
import UserIcon from "../../components/icons/UserIcon";
import { FilterUrlParams } from "../../constants/filters";
import { useNavigateWithTeam } from "../../hooks/useNavigationWithTeam";
import {
  useMultiSelectUrlQueryParam,
  useUrlQueryParam,
} from "../../hooks/useUrlQueryParam";
import { useGetSiteVisitsQuery } from "../../redux/features/api/siteVisits";
import { StyledFC } from "../../types/styledFC";
import ProjectCell from "../ViewClients/ProjectCell";
import { useOrgFilter } from "../ViewClients/useOrgFilter";
import AssignedToCell from "./AssigneeCell";
import ClientNameCell from "./ClientNameCell";
import ClientPhoneNumberCell from "./ClientPhoneNumberCell";
import { FeedbackCell } from "./FeebackCell";
import Header from "./Header";
import VisitDateCell from "./VisitDateCell";
import VisitStatusCell from "./VisitStatusCell";

const RootContainer = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
}));

const TableContainer = styled.div(({ theme }) => ({
  paddingLeft: 32,
  paddingTop: 12,
  paddingBottom: 12,
  paddingRight: 16,
  backgroundColor: theme.salesleg.palette.neutral[25],
  overflowY: "scroll",
  flexGrow: 1,
  height: 0,
}));

const ErrorMessageContainer = styled.span(() => ({
  flexGrow: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const ErrorMessage = styled.span(({ theme }) => ({
  ...theme.salesleg.typography.body.lg.regular,
  color: theme.salesleg.palette.error[700],
}));

const getStartEndDate = (filterValue: string | undefined) => {
  const arrDate = filterValue?.split("_");
  const start_date = arrDate && dayjs(arrDate[0]).toDate();
  const end_date = arrDate && dayjs(arrDate[1]).toDate();
  return [start_date, end_date];
};

const CompletedSiteVisit: StyledFC = ({ style }) => {
  const [visitDateFilter] = useUrlQueryParam(FilterUrlParams.VISIT_DATE);
  const [visit_start_date, visit_end_date] = getStartEndDate(visitDateFilter);
  const [visitStatusFiter] = useMultiSelectUrlQueryParam(
    FilterUrlParams.VISIT_STATUS
  );
  const [searchText] = useUrlQueryParam("search");
  const [projectFilter] = useMultiSelectUrlQueryParam(FilterUrlParams.PROJECT);
  const [executiveFilter] = useUrlQueryParam(FilterUrlParams.SALES_EXECUTIVE);
  const [managerFilter] = useUrlQueryParam(FilterUrlParams.MANAGER);
  const [leadSourceFilter] = useMultiSelectUrlQueryParam(
    FilterUrlParams.LEAD_SOURCE
  );
  const [page_size] = useUrlQueryParam("page_size", "10");
  const [page] = useUrlQueryParam("page", "1");

  function matchVisitStatus(visitStatusFilter: string[], siteVisit: SiteVisit) {
    if (visitStatusFilter.length === 0) {
      return true;
    }

    if (siteVisit.status === "completed") {
      return visitStatusFilter.includes("completed");
    }

    if (siteVisit.status === "canceled" || siteVisit.status === "rescheduled") {
      return visitStatusFilter.includes("canceled");
    }

    // Visit status is "scheduled"
    return visitStatusFilter.includes("pending");
  }

  if (!visit_start_date || !visit_end_date) {
    throw new Error("Visit date range filter must be defined");
  }

  const orgFilter = useOrgFilter(managerFilter, executiveFilter);
  const visitDateRange: DateRange = {
    start: visit_start_date,
    end: visit_end_date,
  };
  const getSiteVisitsQuery: GetSiteVisitsRequest = {
    orgFilter,
    visitDate: visitDateRange,
  };

  const { data: siteVisitsData, isFetching } = useGetSiteVisitsQuery(
    getSiteVisitsQuery,
    {
      skip: visit_start_date === undefined || visit_end_date === undefined,
    }
  );
  const siteVisits = siteVisitsData?.map((item) => deserializeSiteVisit(item));

  let filteredSiteVisits = siteVisits?.filter(
    (siteVisit) =>
      matchVisitStatus(visitStatusFiter, siteVisit) &&
      (projectFilter.length === 0 ||
        projectFilter.includes(siteVisit.lead.project.id)) &&
      (!searchText ||
        siteVisit.lead.client.name
          .toLowerCase()
          .includes(searchText.toLocaleLowerCase())) &&
      (leadSourceFilter.length === 0 ||
        leadSourceFilter.includes(siteVisit.lead.source))
  );

  let currentPageSiteVisit = filteredSiteVisits;
  if (page_size && page) {
    const parsedPageSize = parseInt(page_size);
    const parsedPage = parseInt(page);
    const startRecordIndex = (parsedPage - 1) * parsedPageSize;
    const endRecordIndex = startRecordIndex + parsedPageSize;

    currentPageSiteVisit = filteredSiteVisits?.slice(
      startRecordIndex,
      endRecordIndex
    );
  }

  const columns = [
    { name: "Client Name", width: 52 },
    { name: "Client Phone Number", width: 52 },
    { name: "Visit Date", width: 96 },
    { name: "Visit Status", width: 96 },
    { name: "Executive", width: 250 },
    { name: "Team Leader", width: 250 },
    { name: "Project", width: 96 },
    { name: "Feedback", width: 170 },
  ];

  let isError = visitDateFilter === undefined;

  const theme = useTheme();
  const navigate = useNavigateWithTeam();
  return (
    <>
      {filteredSiteVisits && (
        <RootContainer style={style}>
          <Header
            visits={filteredSiteVisits}
            count={filteredSiteVisits.length}
          />
          <Divider color={theme.salesleg.palette.neutral[100]} width="100%" />
          <FilterPanel
            masterFilterMenuItems={[
              {
                name: "Project",
                icon: SquaresFourIcon,
                subMenuComponent: ProjectMenu,
              },
              {
                name: "Executive",
                icon: UserIcon,
                subMenuComponent: SalesExecutiveMenu,
              },
              {
                name: "Manager",
                icon: UserIcon,
                subMenuComponent: ManagerMenu,
              },
              {
                name: "Lead Source",
                icon: CalendarIcon,
                subMenuComponent: LeadSourceMenu,
              },
            ]}
            directFilters={[
              {
                component: <SiteVisitStatusFilter key="visitStatus" />,
                urlParamName: FilterUrlParams.VISIT_STATUS,
                permanent: true,
              },
              {
                component: (
                  <DateRangeFilter
                    key="visitDate"
                    label="Visit Date"
                    urlParamName={FilterUrlParams.VISIT_DATE}
                  />
                ),
                urlParamName: FilterUrlParams.VISIT_DATE,
                permanent: true,
              },
              {
                component: <ProjectFilter key="project" />,
                permanent: false,
                urlParamName: FilterUrlParams.PROJECT,
              },
              {
                component: <SalesExecutiveFilter key="executive" />,
                urlParamName: FilterUrlParams.SALES_EXECUTIVE,
                permanent: false,
              },
              {
                component: <ManagerFilter key="manager" />,
                urlParamName: FilterUrlParams.MANAGER,
                permanent: true,
              },
              {
                urlParamName: FilterUrlParams.LEAD_SOURCE,
                component: <LeadSourceFilter key="lead_source" />,
                permanent: false,
              },
            ]}
          />
          {isError && (
            <ErrorMessageContainer>
              <ErrorMessage>
                Set visit date filter to avoid requesting large amounts of data
              </ErrorMessage>
            </ErrorMessageContainer>
          )}
          {!isError && (
            <>
              <TableContainer>
                <Table
                  columnGroups={[
                    { span: 1 },
                    { span: 1 },
                    { span: 1 },
                    { span: 1 },
                    { span: 1 },
                  ]}
                  columns={columns}
                  rows={currentPageSiteVisit || []}
                  renderTableRowCells={(row) => [
                    <ClientNameCell
                      name={row.lead.client.name}
                      onClick={() => navigate(`/clients/${row.lead.id}`)}
                    />,
                    <ClientPhoneNumberCell
                      phoneNumber={row.lead.client.phone}
                    />,
                    <VisitDateCell date={row.date} />,
                    <VisitStatusCell status={row.status} />,
                    <AssignedToCell name={row.assignedTo?.name} />,
                    <AssignedToCell name={row.teamLeader?.name} />,
                    <ProjectCell
                      developerName={row.lead.developer.name}
                      projectName={row.lead.project.name}
                      leadSource={row.lead.source}
                    />,
                    <FeedbackCell feedback={row.feedback} />,
                  ]}
                />
              </TableContainer>

              <Pagination numRecords={filteredSiteVisits?.length || 0} />
            </>
          )}
        </RootContainer>
      )}
      <ProgressIndicator open={isFetching} />
    </>
  );
};

export default CompletedSiteVisit;
