import styled from "@emotion/styled";
import dayjs from "dayjs";
import theme from "../../theme/light/theme";
import { StyledFC } from "../../types/styledFC";

const VisitDateStyle = styled.div(() => ({
  color: theme.palette.neutral[400],
}));

interface AssigneeCellProps {
  date?: Date | undefined;
}
const VisitDateCell: StyledFC<AssigneeCellProps> = ({ date }) => {
  if (!date) {
    return <></>;
  }

  return <VisitDateStyle>{dayjs(date).format("DD MMM YYYY")}</VisitDateStyle>;
};

export default VisitDateCell;
