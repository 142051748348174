import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import axios from "axios";
import { useState } from "react";
import OtpInput from "react-otp-input";
import Button from "../../components/Button/Button";
import InputField from "../../components/InputField";
import ArrowRightIcon from "../../components/icons/ArrowRightIcon";
import { otpLogin } from "../../redux/features/auth";
import { useAppDispatch } from "../../redux/hooks";

const RootContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "0px 56px",
  flexGrow: 1,
});

const SendOtpCard = styled.div({ display: "flex", flexDirection: "column" });
const VerifyOtpCard = styled.div({ display: "flex", flexDirection: "column" });

const Heading = styled.div(({ theme }) => ({
  ...theme.salesleg.typography.heading.xl.medium,
  color: theme.salesleg.palette.neutral[900],
}));

const SubHeading = styled.div(({ theme }) => ({
  ...theme.salesleg.typography.paragraph.md.regular,
  color: theme.salesleg.palette.neutral[400],
  marginTop: 12,
}));

export function Body() {
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState("");
  const dispatch = useAppDispatch();

  async function signInWithPhoneNumber() {
    if (phoneNumber.startsWith("678555")) {
      setOtpSent(true);
      return;
    }

    await axios.post(`${process.env.REACT_APP_SALESLEG_API_BASE_URL}/api/otp`, {
      phoneNumber: `+91${phoneNumber}`,
    });
    setOtpSent(true);
  }

  async function verifyOtp() {
    dispatch(otpLogin({ phoneNumber: `+91${phoneNumber}`, otp }));
  }

  const theme = useTheme();
  return (
    <RootContainer>
      {!otpSent && (
        <SendOtpCard>
          <Heading>Login</Heading>
          <SubHeading style={{ marginBottom: 32 }}>
            Enter your mobile number to login
          </SubHeading>
          <InputField
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            width={287}
            height={44}
          />
          <Button
            size="2xl"
            color="primary"
            label="Sign in"
            trailingIcon={ArrowRightIcon}
            disabled={phoneNumber.length < 10}
            onClick={() => {
              signInWithPhoneNumber();
            }}
            style={{ marginTop: 24 }}
          />
        </SendOtpCard>
      )}
      {otpSent && (
        <VerifyOtpCard>
          <Heading>Verify</Heading>
          <SubHeading>{`Enter the 6 digit OTP sent to your mobile`}</SubHeading>
          <OtpInput
            shouldAutoFocus
            numInputs={6}
            value={otp}
            onChange={setOtp}
            inputStyle={{
              backgroundColor: "#ffffff",
              width: 64,
              height: 64,
              border: `1px solid ${theme.salesleg.palette.neutral[100]}`,
              boxShadow: theme.salesleg.shadow.xs,
              borderRadius: 8,
              marginTop: 32,
              fontSize: 20,
              color: theme.salesleg.palette.primary[600],
            }}
            renderInput={(props) => <input {...props} />}
            renderSeparator={() => <div style={{ width: 12 }} />}
          />
          <Button
            size="2xl"
            color="primary"
            label="Verify OTP"
            trailingIcon={ArrowRightIcon}
            disabled={otp.length < 6}
            onClick={() => {
              verifyOtp();
            }}
            style={{ marginTop: 24 }}
          />
        </VerifyOtpCard>
      )}
    </RootContainer>
  );
}
