import { PaletteColor } from "../../types";

const color: PaletteColor = {
  25: "#EEFFF3",
  50: "#D7FFE6",
  100: "#B2FFCE",
  200: "#76FFAA",
  300: "#33F57D",
  400: "#09E15C",
  500: "#00B947",
  600: "#05903B",
  700: "#0A7133",
  800: "#0A5D2C",
  900: "#003416",
};

export default color;
