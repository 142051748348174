import styled from "@emotion/styled";
import { ExtendedEmployee, WithMetadata } from "@hshrimal/salesleg";
import { FC } from "react";
import Avatar from "../../../components/Avatar";
import Badge from "../../../components/Badge";
import CheckBox from "../../../components/CheckBox";

const Card = styled.div<{ selected: boolean }>(({ selected, theme }) => ({
  width: 392,
  height: 66,
  paddingLeft: 16,
  paddingRight: 16,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: selected ? theme.salesleg.palette.primary[25] : "#ffffff",
  border: selected
    ? `1px solid ${theme.salesleg.palette.primary[500]}`
    : `1px solid ${theme.salesleg.palette.neutral[50]}`,
  boxShadow: theme.salesleg.shadow.xs,
  borderRadius: 6,
}));

const Container = styled.div(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: 16,
}));

const UserContainer = styled.div(({ theme }) => ({
  display: "flex",
  gap: 12,
  alignItems: "center",
}));

const UserTitleContainer = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 4,
}));

const UserTitle = styled.div(({ theme }) => ({
  ...theme.salesleg.typography.body.sm.medium,
}));

const UserSubTitle = styled.div(({ theme }) => ({
  ...theme.salesleg.typography.body.xs.regular,
  color: theme.salesleg.palette.neutral[400],
}));

interface UserCardProps {
  user: WithMetadata<ExtendedEmployee>;
  onAddUser: (users: WithMetadata<ExtendedEmployee>[]) => void;
  isSelected: boolean;
  onRemoveUser: (users: WithMetadata<ExtendedEmployee>[]) => void;
  showTeam?: boolean | undefined;
}

const UserCard: FC<UserCardProps> = ({
  user,
  onAddUser,
  isSelected,
  onRemoveUser,
  showTeam = true,
}) => {
  const onSelect = (isSelected: boolean) => {
    if (isSelected) {
      onAddUser([user]);
    } else {
      onRemoveUser([user]);
    }
  };
  return (
    <Card selected={isSelected}>
      <Container>
        <CheckBox size="sm" checked={isSelected} onChange={onSelect} />
        <UserContainer>
          <Avatar size={32} />
          <UserTitleContainer>
            <UserTitle>{user.name}</UserTitle>
            <UserSubTitle>{user.alias}</UserSubTitle>
          </UserTitleContainer>
        </UserContainer>
      </Container>
      {showTeam && (
        <Badge color="neutral" size="md" label={user.teams[0]?.name} />
      )}
    </Card>
  );
};

export default UserCard;
