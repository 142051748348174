import { SKU } from "@hshrimal/salesleg";

export const SKUDisplayMap = new Map<SKU, string>([
  ["1bhk", "1 BHK"],
  ["2bhk", "2 BHK"],
  ["3bhk", "3 BHK"],
  ["4bhk", "4 BHK"],
  ["row_house", "Row House"],
  ["villa", "Villa"],
  ["plot", "Plot"],
]);
