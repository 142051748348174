import { FilterUrlParams } from "../../constants/filters";
import { Filter } from "../Filter";
import { MenuItem } from "../Menu/MenuItem";
import MapPinIcon from "../icons/MapPinIcon";

export function CityFilter() {
  return (
    <Filter
      icon={MapPinIcon}
      name="City"
      urlParamName={FilterUrlParams.City}
      multiple={true}
    >
      <MenuItem value="bengaluru" variant="default" label="Bengaluru" />
      <MenuItem value="mumbai" variant="default" label="Mumbai" />
      <MenuItem value="pune" variant="default" label="Pune" />
    </Filter>
  );
}
