import { EmployeeFilter } from "@hshrimal/salesleg";
import { FilterUrlParams } from "../../constants/filters";
import { useOrgFilter } from "../../pages/ViewClients/useOrgFilter";
import { useGetEmployeesQuery } from "../../redux/features/api/employees";
import { Filter } from "../Filter";
import { MenuItem } from "../Menu/MenuItem";
import { ProgressIndicator } from "../ProgressIndicator";
import UserIcon from "../icons/UserIcon";

const SalesExecutiveFilter = () => {
  const orgFilter = useOrgFilter();
  const employeeFilter: EmployeeFilter = {
    ...orgFilter,
    role: ["sales_head", "sales_lead", "sales_associate"],
    status: "active",
  };
  const { data: executives, isFetching } = useGetEmployeesQuery(employeeFilter);

  return (
    <>
      <Filter
        icon={UserIcon}
        name="Assignee"
        urlParamName={FilterUrlParams.ASSIGNEE}
        multiple={false}
        enableSearch
        searchPlaceholder="Search"
      >
        {executives?.map((executive) => (
          <MenuItem
            key={executive.id}
            variant="default"
            value={executive.id}
            label={executive.name}
          />
        )) || []}
      </Filter>
      <ProgressIndicator open={isFetching} />
    </>
  );
};

export default SalesExecutiveFilter;
