import { StyledFC } from "../../types/styledFC";

interface DividerProps {
  width: number | string;
  color: string;
}

const Divider: StyledFC<DividerProps> = ({ width, color }) => {
  return (
    <div
      style={{
        width: width,
        height: 2,
        border: `1px solid ${color}`,
      }}
    />
  );
};

export default Divider;
