import styled from "@emotion/styled";
import { ChangeEvent, forwardRef } from "react";

const Input = styled.input<{
  width: number | string;
}>(({ theme, width }) => ({
  ...theme.salesleg.typography.heading.sm.medium,
  paddingLeft: 14,
  paddingRight: 14,
  display: "flex",
  alignItems: "center",
  background: "#FFFFFF",
  border: `1px solid ${theme.salesleg.palette.neutral[100]}`,
  boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
  borderRadius: 8,
  width: width,
  height: 44,

  "::placeholder": {
    color: theme.salesleg.palette.neutral[200],
  },

  ":focus": {
    border: `1px solid ${theme.salesleg.palette.primary[100]}`,
    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #EAFBFF",
  },

  ":focus-visible": {
    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
    outline: "none",
  },

  ":disabled": {
    color: theme.salesleg.palette.neutral[400],
    background: "#F5F7F8",
    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
  },
}));

interface EditorTextProps {
  placeholder?: string | undefined;
  disabled?: boolean;
  value?: string | number;
  width: string | number;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void | undefined;
  onBlur?: () => void;
}

export type Ref = HTMLInputElement;

const EditorText = forwardRef<Ref, EditorTextProps>(
  ({ placeholder, disabled, value, width, onChange, onBlur }, ref) => {
    return (
      <Input
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        width={width}
        onBlur={onBlur}
        onChange={onChange}
        ref={ref}
      />
    );
  }
);

export default EditorText;
