import { FilterUrlParams } from "../../constants/filters";
import { useGetDevelopersQuery } from "../../redux/features/api/developers";
import { Filter } from "../Filter";
import { MenuItem } from "../Menu/MenuItem";
import BuildingsIcon from "../icons/BuildingsIcon";

export function DeveloperFilter() {
  const { data: developers } = useGetDevelopersQuery();

  if (!developers) {
    return <></>;
  }

  return (
    <Filter
      icon={BuildingsIcon}
      name="Developer"
      urlParamName={FilterUrlParams.DEVELOPER}
      multiple={true}
      enableSearch
      searchPlaceholder="Search Developer"
    >
      {developers?.map((developer) => (
        <MenuItem
          key={developer.id}
          variant="default"
          value={developer.id}
          label={developer.name}
        />
      )) || []}
    </Filter>
  );
}
