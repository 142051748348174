import styled from "@emotion/styled";
import theme from "../../theme/light/theme";
import { StyledFC } from "../../types/styledFC";

const FeedbackText = styled.span(() => ({
  color: theme.palette.neutral[400],
}));

interface FeedbackCellProps {
  feedback?: string | undefined;
}

export const FeedbackCell: StyledFC<FeedbackCellProps> = ({ feedback }) => {
  if (!feedback) {
    return <></>;
  }

  return <FeedbackText>{feedback}</FeedbackText>;
};
