import styled from "@emotion/styled";
import { yupResolver } from "@hookform/resolvers/yup";
import { FC } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import Button from "../../components/Button/Button";
import ErrorText from "../../components/ErrorText";
import InputField from "../../components/InputField";
import InputLabel from "../../components/InputLabel";
import Modal from "../../components/Modal";
import ModalBody from "../../components/Modal/ModalBody";
import ModalFooter from "../../components/Modal/ModalFooter";
import ModalHeader from "../../components/Modal/ModalHeader";
import ModelBlurBG from "../../components/icons/ModelBlurBG";
import ModelSqureBG from "../../components/icons/ModelBlurBGSqure";
import UsersThreeIcon from "../../components/icons/UsersThreeIcon";
import theme from "../../theme/light/theme";
import SelectCity from "../CreateProject/SelectLocation/SelectCity";
import OwnerDropdown from "./OwnerDropdown";

interface CreateTeamModalProps {
  open: boolean;
  toggle: () => void;
  onSubmit: (values: CreateTeamModalForm) => void;
}

const Background = styled.div(({ theme }) => ({
  position: "relative",
  height: 44,
  width: 44,
  background: "#ffffff",
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  boxShadow: theme.salesleg.shadow.sm,
}));

const BlurBackground = styled.div(() => ({
  display: "flex",
  position: "absolute",
  zIndex: -1,
}));

const CustomFieldGroup = styled.div(() => ({
  display: "flex",
  gap: 6,
  flexDirection: "column",
}));

const BackgroundSqure = styled.div(() => ({
  display: "flex",
  position: "absolute",
  zIndex: -1,
}));

export interface CreateTeamModalForm {
  name: string;
  ownerId: string;
  city: string;
}

const schema = yup.object({
  name: yup.string().required("Team name is required"),
  ownerId: yup.string().required("Owner is required"),
  city: yup.string().required("City is required"),
});

const CreateTeamModal: FC<CreateTeamModalProps> = ({
  open,
  toggle,
  onSubmit,
}) => {
  const resolver = yupResolver(schema);

  const methods = useForm<CreateTeamModalForm>({ resolver });

  const {
    handleSubmit,
    reset,
    register,
    setValue,
    formState: { errors },
    control,
  } = methods;

  const handleCancel = () => {
    reset();
  };

  const onFormSubmit = (values: CreateTeamModalForm) => {
    onSubmit(values);
    toggle();
    reset();
  };

  return (
    <FormProvider {...methods}>
      <Modal isOpen={open}>
        <BlurBackground>
          <ModelBlurBG color={theme.palette.primary[500]} />
        </BlurBackground>
        <BackgroundSqure>
          <ModelSqureBG color={theme.palette.primary[500]} />
        </BackgroundSqure>
        <ModalHeader toggle={toggle}>
          <Background>
            <UsersThreeIcon size={24} color={theme.palette.primary[600]} />
          </Background>
        </ModalHeader>
        <ModalBody>
          <InputField
            required={true}
            label="Team Name"
            placeholder="Enter Team Name"
            width={420}
            height={44}
            error={errors.name?.message}
            {...register("name")}
          />

          <CustomFieldGroup>
            <InputLabel label="Owner" required={true} />
            <OwnerDropdown />
            <ErrorText error={errors.ownerId?.message} />
          </CustomFieldGroup>

          <Controller
            name="city"
            control={control}
            render={({ field }) => {
              const { value } = field;
              return (
                <CustomFieldGroup>
                  <InputLabel label="city" required={true} />
                  <SelectCity
                    selectedCityKey={value}
                    onChange={(city) => {
                      setValue("city", city);
                    }}
                  />
                  <ErrorText error={errors.city?.message} />
                </CustomFieldGroup>
              );
            }}
          />
        </ModalBody>

        <ModalFooter>
          <Button
            style={{
              borderRadius: 6,
              height: 44,
              width: 122,
              boxShadow: theme.shadow.xs,
              justifyContent: "center",
            }}
            color="secondary-gray"
            onClick={handleCancel}
            size="sm"
            label="Reset"
          />
          <Button
            style={{ height: 44, width: 122, justifyContent: "center" }}
            color="primary"
            onClick={handleSubmit(onFormSubmit)}
            size="sm"
            label="Add Team"
          />
        </ModalFooter>
      </Modal>
    </FormProvider>
  );
};

export default CreateTeamModal;
