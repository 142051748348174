import {
  ExtendedTeam,
  Team,
  WithMetadata,
  deserializeTeamsWithMetadata,
} from "@hshrimal/salesleg";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const teamsApi = createApi({
  reducerPath: "teamsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_SALESLEG_API_BASE_URL}/api`,
    prepareHeaders: (headers) => headers.append("client", "web"),
    credentials: "include",
  }),
  tagTypes: ["teams"],
  endpoints: (builder) => ({
    getTeams: builder.query<WithMetadata<ExtendedTeam>[], void>({
      query: () => ({ url: "/teams" }),
      transformResponse: (data) => deserializeTeamsWithMetadata(data),
      providesTags: ["teams"],
    }),
    createTeam: builder.mutation<{ id: string }, Team>({
      query: (input) => ({
        url: `/teams`,
        method: "POST",
        body: input,
      }),
      invalidatesTags: ["teams"],
    }),
  }),
});

export const { useGetTeamsQuery, useCreateTeamMutation } = teamsApi;
