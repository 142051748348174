import styled from "@emotion/styled";
import { ExtendedLead, WithMetadata } from "@hshrimal/salesleg";
import { FC } from "react";
import Tag from "../../components/Tag";
import CurrencyInrIcon from "../../components/icons/CurrencyInrIcon";
import HouseLineIcon from "../../components/icons/HouseLineIcon";
import theme from "../../theme/light/theme";
import { ConstructionStatusDisplayMap } from "../../utils/constructionStatusDisplayHelper";

const RootContainer = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: "24px 32px",
  width: 344,
  gap: 20,
  borderRight: `1px solid ${theme.salesleg.palette.neutral[100]}`,
}));

const HeaderLabel = styled.div(({ theme }) => ({
  ...theme.salesleg.typography.heading.md.medium,
  color: theme.salesleg.palette.neutral[900],
}));

const RowContainer = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
}));

const Label = styled.div(({ theme }) => ({
  ...theme.salesleg.typography.paragraph.sm.medium,
  color: theme.salesleg.palette.neutral[700],
}));

const ColumnContainer = styled.div(() => ({
  display: "flex",
  gap: 8,
  alignItems: "center",
}));

const Text = styled.div(({ theme }) => ({
  ...theme.salesleg.typography.paragraph.md.regular,
  color: theme.salesleg.palette.neutral[500],
  paddingTop: "10px",
  paddingBottom: "10px",
  paddingRight: "14px",
}));

const TagContainer = styled.div(() => ({
  display: "flex",
  gap: 6,
  paddingTop: "10px",
  paddingBottom: "10px",
  paddingRight: "14px",
}));

interface ClientSideBarProps {
  lead: WithMetadata<ExtendedLead>;
}

// TODO: we need to change project type dynamically

const ClientSideBar: FC<ClientSideBarProps> = ({ lead }) => {
  const constructionStatus = lead.requirement?.constructionStatus;

  return (
    <RootContainer>
      <HeaderLabel>Requirements</HeaderLabel>
      <RowContainer>
        <Label>Project Type</Label>
        <ColumnContainer>
          <HouseLineIcon color={theme.palette.neutral[400]} size={24} />
          <Text>Residential</Text>
        </ColumnContainer>
      </RowContainer>
      <RowContainer>
        <Label>Budget</Label>
        <ColumnContainer>
          <CurrencyInrIcon color={theme.palette.neutral[400]} size={24} />
          <Text>{lead.requirement?.budget}</Text>
        </ColumnContainer>
      </RowContainer>
      <RowContainer>
        <Label>Construction Status</Label>
        <Text>
          {constructionStatus
            ? ConstructionStatusDisplayMap.get(constructionStatus)
            : ""}
        </Text>
      </RowContainer>
      <RowContainer>
        <Label>Locality</Label>
        <TagContainer>
          {lead.requirement?.location &&
            Array(lead.requirement?.location).map((location) => {
              return <Tag label={location} key={location} />;
            })}
        </TagContainer>
      </RowContainer>
    </RootContainer>
  );
};

export default ClientSideBar;
