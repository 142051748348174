import { FC, useRef, useState } from "react";
import IconButton from "../../components/Button/IconButton";
import { Menu } from "../../components/Menu";
import { MenuItem } from "../../components/Menu/MenuItem";
import DoubleCheckIcon from "../../components/icons/DoubleCheckIcon";
import ThreeDotIcon from "../../components/icons/ThreeDotIcon";

interface AllActionsMenuButtonProps {
  onMarkAsTestClient: () => void;
  onMarkAsDuplicateClient: () => void;
}

const AllActionsMenuButton: FC<AllActionsMenuButtonProps> = ({
  onMarkAsTestClient,
  onMarkAsDuplicateClient,
}) => {
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen((open) => !open);
  };

  return (
    <>
      <IconButton
        color="tertiary-gray"
        icon={ThreeDotIcon}
        size="sm"
        ref={buttonRef}
        onClick={() => {
          toggle();
        }}
      />
      <Menu
        open={open}
        onClose={() => setOpen(false)}
        placement="bottom"
        anchorEl={buttonRef.current || undefined}
      >
        <MenuItem
          value="markAsTestClient"
          variant="icon"
          label="Mark as Test Client"
          iconComponent={DoubleCheckIcon}
          onClick={() => {
            setOpen(false);
            onMarkAsTestClient();
          }}
        />
        {/* <MenuItem
          value="markAsDuplicateClient"
          variant="icon"
          label="Mark as Duplicate Client"
          iconComponent={ListCheckIcon}
          onClick={onMarkAsDuplicateClient}
        /> */}
      </Menu>
    </>
  );
};

export default AllActionsMenuButton;
