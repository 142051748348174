import styled from "@emotion/styled";
import { ExtendedTeam, WithMetadata } from "@hshrimal/salesleg";
import { FC, useState } from "react";
import Avatar from "../../components/Avatar";
import PencilSimpleIcon from "../../components/icons/PencilSimpleIcon";
import theme from "../../theme/light/theme";

const Content = styled.div(() => ({
  display: "flex",
  gap: 12,
  flexDirection: "column",
}));

const UserDetail = styled.div(() => ({
  width: 228,
  display: "flex",
  flexDirection: "column",
  gap: 4,
}));

const TeamName = styled.p(() => ({
  color: theme.palette.neutral[800],
  ...theme.typography.body.sm.regular,
}));

const Location = styled.div(() => ({
  color: theme.palette.neutral[400],
  ...theme.typography.body.sm.regular,
  textTransform: "capitalize",
}));

const AvatarContainer = styled.div(() => ({
  display: "flex",
  gap: 8,
  alignItems: "center",
}));

const Name = styled.div(() => ({
  color: theme.palette.neutral[500],
  ...theme.typography.body.sm.regular,
}));

const RootContainer = styled.div(() => ({
  width: 312,
  padding: "20px 12px 20px 20px",
  display: "flex",
  borderRadius: 6,
  boxshadow: theme.shadow.xs,
  border: `1px solid ${theme.palette.neutral[100]}`,
  background: "#ffffff",

  ":hover": {
    boxShadow: theme.shadow.lg,
  },

  cursor: "pointer",
}));

const Container = styled.div(() => ({
  display: "flex",
  gap: 16,
  alignItems: "center",
  justifyContent: "space-between",
}));

const IconWrapper = styled.div(() => ({
  padding: "6px 8px",
  cursor: "pointer",
}));

interface TeamCardProps {
  team: WithMetadata<ExtendedTeam>;
  onEdit: (teamId: string) => void;
}

const TeamCard: FC<TeamCardProps> = ({ team, onEdit }) => {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <RootContainer
      onMouseOver={() => setIsHovering(true)}
      onMouseOut={() => setIsHovering(false)}
    >
      <Content>
        <Container>
          <UserDetail>
            <TeamName>{team.name}</TeamName>
            <Location>{team.city}</Location>
          </UserDetail>
          {isHovering && (
            <IconWrapper onClick={() => onEdit(team.id)}>
              <PencilSimpleIcon size={20} color={theme.palette.neutral[300]} />
            </IconWrapper>
          )}
          {/* <IconWrapper onClick={() => onEdit(team.id)}>
            <PencilSimpleIcon size={20} color={theme.palette.neutral[300]} />
          </IconWrapper> */}
        </Container>
        <AvatarContainer>
          <Avatar size={24} />
          <Name>{team.owner.name}</Name>
        </AvatarContainer>
      </Content>
    </RootContainer>
  );
};

export default TeamCard;
