import styled from "@emotion/styled";
import { StyledFC } from "../types/styledFC";
import ErrorText from "./ErrorText";
import InputLabel from "./InputLabel";
import VerticalDivider from "./VerticalDivider";

interface TextInputProps {
  label: string;
  value: string;
  onChange: (value: string) => void;
  maxLength?: number | undefined;
  type?: string | undefined;
  prefix?: string | undefined;
  error?: string | undefined;
  width?: string | number | undefined;
  required?: boolean | undefined;
}

const Container = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: 6,
}));

const Input = styled.input(({ theme }) => ({
  width: "100%",
  ...theme.salesleg.typography.paragraph.md.regular,
  color: theme.salesleg.palette.neutral[500],
  padding: "10px 14px",
}));

const Prefix = styled.div(({ theme }) => ({
  ...theme.salesleg.typography.paragraph.md.regular,
  backgroundColor: "#ffffff",
  borderRadius: 6,
  color: theme.salesleg.palette.neutral[500],
  padding: "10px 12px",
}));

const InputContainer = styled.div<{ width?: string | number | undefined }>(
  ({ theme, width }) => ({
    display: "flex",
    backgroundColor: "#ffffff",
    border: `1px solid ${theme.salesleg.palette.neutral[100]}`,
    boxShadow: theme.salesleg.shadow.xs,
    borderRadius: 6,
    height: 44,
    width: width || 320,
  })
);

const TextInput: StyledFC<TextInputProps> = ({
  label,
  value,
  onChange,
  maxLength,
  type,
  prefix,
  error,
  width,
  required = false,
}) => {
  return (
    <Container>
      {label && <InputLabel label={label} required={required} />}
      <InputContainer width={width}>
        {prefix && (
          <>
            <Prefix>{prefix}</Prefix>
            <VerticalDivider height={42} />
          </>
        )}
        <Input
          type={type}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          maxLength={maxLength}
        />
      </InputContainer>
      {error && <ErrorText error={error} />}
    </Container>
  );
};

export default TextInput;
