import { FilterUrlParams } from "../../constants/filters";
import { useSelectedCity } from "../../hooks/useSelectedCity";
import { useGetProjectsQuery } from "../../redux/features/api/projects";
import { FilterMenu, FilterMenuProps } from "../FilterMenu";
import { MenuItem } from "../Menu/MenuItem";
import { ProgressIndicator } from "../ProgressIndicator";

const ProjectMenu = ({
  open,
  onClose,
  anchorEl,
  placement,
  multiple,
}: Omit<FilterMenuProps, "children" | "urlParamName">) => {
  const selectedCity = useSelectedCity();
  const { data: projects, isFetching: isFetchingProjects } =
    useGetProjectsQuery({ city: selectedCity });
  return (
    <>
      <FilterMenu
        open={open}
        onClose={onClose}
        anchorEl={anchorEl}
        placement={placement}
        multiple={multiple}
        urlParamName={FilterUrlParams.PROJECT}
      >
        {projects?.map((project) => (
          <MenuItem
            key={project.id}
            variant="default"
            value={project.id}
            label={`${project.developer.name} ${project.name}`}
          />
        ))}
      </FilterMenu>
      <ProgressIndicator open={isFetchingProjects} />
    </>
  );
};

export default ProjectMenu;
