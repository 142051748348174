import { FilterUrlParams } from "../../constants/filters";
import { useLoggedInViewer } from "../../hooks/getLoggedInViewer";
import { useGetTeamsQuery } from "../../redux/features/api/teams";
import { Filter } from "../Filter";
import { MenuItem } from "../Menu/MenuItem";
import UserIcon from "../icons/UserIcon";

const TeamFilter = () => {
  const viewer = useLoggedInViewer();
  const { data: allTeams } = useGetTeamsQuery(undefined, {
    skip: viewer.role !== "it",
  });
  const teams = viewer.role === "it" ? allTeams : viewer.teams;
  return (
    <>
      <Filter
        icon={UserIcon}
        name="Team"
        urlParamName={FilterUrlParams.TEAM}
        multiple={false}
        enableSearch
        searchPlaceholder="Search Team"
      >
        {teams?.map((team) => (
          <MenuItem
            key={team.id}
            variant="default"
            value={team.id}
            label={team.name}
          />
        )) || []}
      </Filter>
    </>
  );
};

export default TeamFilter;
