import styled from "@emotion/styled";
import { City } from "@hshrimal/salesleg";
import dayjs from "dayjs";
import { Navigate, Route, Routes } from "react-router-dom";
import SideBar from "../components/SideBar";
import { FilterUrlParams } from "../constants/filters";
import { BranchContext } from "../context/branchSelector";
import { TeamContext } from "../context/teamContext";
import { useLoggedInViewer } from "../hooks/getLoggedInViewer";
import { useUrlQueryParam } from "../hooks/useUrlQueryParam";
import AllLeads from "../pages/AllLeads";
import ClientDetails from "../pages/ClientDetails";
import CreateProject from "../pages/CreateProject";
import CreateUser from "../pages/CreateUser";
import LoginAs from "../pages/LoginAs";
import CompletedSiteVisit from "../pages/SiteVisits";
import ViewClients from "../pages/ViewClients";
import ViewProject from "../pages/ViewProject";
import ViewProjects from "../pages/ViewProjects";
import ViewUser from "../pages/ViewUser";
import ViewUsers from "../pages/ViewUsers";

const Container = styled.div({
  display: "flex",
  flexDirection: "column",
  height: "100vh",
  overflow: "hidden",
});

const Layout = () => {
  const viewer = useLoggedInViewer();
  const [selectedTeamId, setSelectedTeamId] = useUrlQueryParam(
    "team_id",
    (viewer.role === "sales_head" ||
      viewer.role === "marketing" ||
      viewer.role === "ops") &&
      viewer.teams.length > 0
      ? viewer.teams[0].id
      : undefined
  );
  const [selectedBranch, setSelectedBranch] = useUrlQueryParam<City>(
    "branch",
    viewer.role === "business_head" ? "bengaluru" : undefined
  );

  const now = dayjs();
  const startOfThisMonth = now.startOf("month");
  const endofThisMonth = now.endOf("month").startOf("day").add(1, "day");

  const startOfThisMonthStr = startOfThisMonth.toISOString();
  const endOfThisMonthStr = endofThisMonth.toISOString();
  const currentMonthDateRangeStr =
    startOfThisMonthStr + "_" + endOfThisMonthStr;

  let landingPage;
  switch (viewer.role) {
    case "it":
      landingPage = `/users`;
      break;
    case "god":
      landingPage = "/loginas";
      break;
    case "business_head":
      landingPage = `/allleads?${FilterUrlParams.CREATION_DATE}=${currentMonthDateRangeStr}&group_by=manager`;
      break;
    default:
      landingPage = `/clients?${FilterUrlParams.CREATION_DATE}=${currentMonthDateRangeStr}`;
      break;
  }

  return (
    <BranchContext.Provider
      value={{
        selectedBranch,
        setSelectedBranch,
      }}
    >
      <TeamContext.Provider value={{ selectedTeamId, setSelectedTeamId }}>
        <Container>
          <SideBar />
          <Routes>
            <Route
              path="/"
              element={<Navigate to={landingPage} replace={true} />}
            />
            <Route
              path="/projects"
              element={<ViewProjects style={{ flexGrow: 1 }} />}
            />
            <Route
              path="/clients"
              element={<ViewClients style={{ flexGrow: 1 }} />}
            />
            <Route
              path="/clients/:clientId"
              element={<ClientDetails style={{ flexGrow: 1 }} />}
            />
            <Route
              path="/projects/:projectId"
              element={<ViewProject style={{ flexGrow: 1 }} />}
            />
            <Route
              path="/projects/:projectId/update"
              element={<CreateProject style={{ flexGrow: 1 }} />}
            />
            <Route
              path="/projects/create"
              element={<CreateProject style={{ flexGrow: 1 }} />}
            />
            <Route
              path="/users"
              element={<ViewUsers style={{ flexGrow: 1 }} />}
            />
            <Route
              path="/users/create"
              element={<CreateUser style={{ flexGrow: 1 }} />}
            />
            <Route
              path="/users/:userId"
              element={<ViewUser style={{ flexGrow: 1 }} />}
            />
            <Route
              path="/users/:userId/update"
              element={<CreateUser style={{ flexGrow: 1 }} />}
            />
            <Route
              path="/visits"
              element={<CompletedSiteVisit style={{ flexGrow: 1 }} />}
            />
            <Route path="/allleads" element={<AllLeads />} />
            <Route path="/allleads/:employeeId" element={<AllLeads />} />
            <Route path="/loginAs" element={<LoginAs />} />
          </Routes>
        </Container>
      </TeamContext.Provider>
    </BranchContext.Provider>
  );
};

export default Layout;
