import styled from "@emotion/styled";
import { ReactNode } from "react";
import { StyledFC } from "../../types/styledFC";

interface ModalProps {
  isOpen: boolean;
  children: ReactNode;
}

const Container = styled.div(({ theme }) => ({
  height: "100vh",
  width: 468,
  right: 0,
  top: 0,
  position: "fixed",
  background: "#ffffff",
  boxShadow: theme.salesleg.shadow.xxl,
  zIndex: 100,
}));

const Modal: StyledFC<ModalProps> = ({ isOpen, children, style }) => {
  return <>{isOpen && <Container style={style}>{children}</Container>}</>;
};

export default Modal;
