import styled from "@emotion/styled";
import { FC } from "react";
import { Employee } from ".";
import { FilterUrlParams } from "../../constants/filters";
import { useNavigateWithTeam } from "../../hooks/useNavigationWithTeam";
import {
  useMultiSelectUrlQueryParam,
  useUrlQueryParam,
} from "../../hooks/useUrlQueryParam";
import { getLeadsFilterUrl } from "../../utils/urlHelper";

interface BookingCellProps {
  booking: number;
  employee?: Employee | undefined;
  projectId?: string | undefined;
  isRoot?: boolean | undefined;
}

const RootContainer = styled.div(() => ({
  cursor: "pointer",
}));

const BookingCell: FC<BookingCellProps> = ({
  booking,
  employee,
  projectId,
  isRoot,
}) => {
  const navigate = useNavigateWithTeam();
  const [createdDate] = useUrlQueryParam(FilterUrlParams.CREATION_DATE);
  const [leadSource] = useMultiSelectUrlQueryParam(FilterUrlParams.LEAD_SOURCE);

  const handleClick = () => {
    const queryString = getLeadsFilterUrl(
      createdDate,
      leadSource,
      projectId,
      employee,
      "booking",
      undefined,
      isRoot
    );
    navigate(`/clients?${queryString}`);
  };
  return <RootContainer onClick={handleClick}>{booking}</RootContainer>;
};

export default BookingCell;
