import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { yupResolver } from "@hookform/resolvers/yup";
import { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { ReactComponent as MapSvg } from "../../../assets/map.svg";
import Button from "../../../components/Button/Button";
import ErrorText from "../../../components/ErrorText";
import InputField from "../../../components/InputField";
import InputLabel from "../../../components/InputLabel";
import Modal from "../../../components/Modal";
import ModalBody from "../../../components/Modal/ModalBody";
import ModalFooter from "../../../components/Modal/ModalFooter";
import ModalHeader from "../../../components/Modal/ModalHeader";
import MapPinIcon from "../../../components/icons/MapPinIcon";
import ModelBlurBG from "../../../components/icons/ModelBlurBG";
import ModelSqureBG from "../../../components/icons/ModelBlurBGSqure";
import SelectCity from "./SelectCity";

interface LocationModalProps {
  open: boolean;
  toggle: () => void;
  setCity: (city: string) => void;
}

// const TextInputFlex = styled.div(() => ({
//   display: "flex",
//   gap: 20,
// }));

const Background = styled.div(({ theme }) => ({
  position: "relative",
  height: 44,
  width: 44,
  background: "#ffffff",
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  boxShadow: theme.salesleg.shadow.sm,
}));

const BlurBackground = styled.div(() => ({
  display: "flex",
  position: "absolute",
  zIndex: -1,
}));

const CityField = styled.div(() => ({
  display: "flex",
  gap: 6,
  flexDirection: "column",
}));

const LocationBack = styled.div(() => ({
  display: "flex",
  position: "absolute",
  zIndex: -1,
}));

export interface ILocationModalForm {
  // pincode?: number | undefined;
  // plotNoName?: string | undefined;
  // areaName?: string | undefined;
  // streetNoName?: string | undefined;
  city: string;
  coordinates?: string | undefined;
}

const schema = yup.object({
  // pincode: yup.number().typeError("It must be a number"),
  city: yup.string().required("City is required"),
});

const LocationModal: FC<LocationModalProps> = ({ open, toggle, setCity }) => {
  const theme = useTheme();
  const resolver = yupResolver(schema);

  const {
    handleSubmit,
    reset,
    register,
    setValue,
    formState: { errors },
    control,
  } = useForm<ILocationModalForm>({ resolver });

  const handleCancel = () => {
    reset();
  };

  const onSubmit = (values: ILocationModalForm) => {
    setCity(values.city);
    toggle();
  };

  return (
    <Modal isOpen={open}>
      <BlurBackground>
        <ModelBlurBG color={theme.salesleg.palette.custom.orange[500]} />
      </BlurBackground>
      <LocationBack>
        <ModelSqureBG color={theme.salesleg.palette.custom.orange[500]} />
      </LocationBack>
      <ModalHeader toggle={toggle}>
        <Background>
          <MapPinIcon
            size={24}
            color={theme.salesleg.palette.custom.orange[600]}
          />
        </Background>
      </ModalHeader>
      <ModalBody>
        {/* <InputField
          label="Pincode"
          placeholder="560006"
          width={420}
          height={44}
          type="number"
          error={errors.pincode?.message}
          {...register("pincode")}
        />

        <InputField
          label="Plot No / Name"
          placeholder="237"
          width={420}
          height={44}
          {...register("plotNoName")}
        />

        <TextInputFlex>
          <InputField
            label="Area Name"
            placeholder="Shivaji Nagar"
            width="100%"
            height={44}
            {...register("areaName")}
          />

          <InputField
            label="Street No / Name"
            placeholder="Sector 27"
            width="100%"
            height={44}
            {...register("streetNoName")}
          />
        </TextInputFlex> */}

        <Controller
          name="city"
          control={control}
          render={({ field }) => {
            const { value } = field;
            return (
              <CityField>
                <InputLabel label="city" required={true} />
                <SelectCity
                  selectedCityKey={value}
                  onChange={(city) => {
                    setValue("city", city);
                  }}
                />
                <ErrorText error={errors.city?.message} />
              </CityField>
            );
          }}
        ></Controller>

        <InputField
          placeholder="Enter geo coordinates"
          height={44}
          width={420}
          {...register("coordinates")}
        />

        <MapSvg style={{ marginTop: 4 }} />
      </ModalBody>

      <ModalFooter>
        <Button
          style={{
            borderRadius: 6,
            height: 44,
            width: 122,
            boxShadow: theme.salesleg.shadow.xs,
            justifyContent: "center",
          }}
          color="secondary-gray"
          onClick={handleCancel}
          size="sm"
          label="Reset"
        />
        <Button
          style={{ height: 44, width: 122, justifyContent: "center" }}
          color="primary"
          onClick={handleSubmit(onSubmit)}
          size="sm"
          label="Add Location"
        />
      </ModalFooter>
    </Modal>
  );
};

export default LocationModal;
