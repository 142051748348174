import { LeadFilter, LeadSource, LeadStage } from "@hshrimal/salesleg";
import dayjs from "dayjs";

export function useLeadFilter(
  stages: LeadStage[],
  sources: LeadSource[],
  projectIds: string[],
  createdAtStart?: Date | undefined,
  createdAtEnd?: Date | undefined,
  prospectDateStart?: Date | undefined,
  prospectDateEnd?: Date | undefined,
  visitDateStart?: Date | undefined,
  visitDateEnd?: Date | undefined,
  nextFollowUpFilter?: string | undefined
): LeadFilter {
  const filter: LeadFilter = {};

  filter.stages = stages;
  filter.sources = sources;
  filter.projectIds = projectIds;

  if (createdAtStart && createdAtEnd) {
    filter.creationDate = { start: createdAtStart, end: createdAtEnd };
  }

  if (prospectDateStart && prospectDateEnd) {
    filter.prospectDate = { start: prospectDateStart, end: prospectDateEnd };
  }

  if (visitDateStart && visitDateEnd) {
    filter.visitDate = { start: visitDateStart, end: visitDateEnd };
  }

  if (nextFollowUpFilter) {
    const nextFollowupDateRangeFilter = getDateRange(nextFollowUpFilter);
    filter.nextFollowupDate = {
      start: nextFollowupDateRangeFilter[0],
      end: nextFollowupDateRangeFilter[1],
    };
  }

  return filter;
}

function getDateRange(nextFollowupFilter: string): (Date | undefined)[] {
  const todayStart = dayjs().startOf("day");
  const todayEnd = todayStart.add(1, "day");
  const pastDueEnd = todayStart;
  const tomorrowStart = todayEnd;
  const tomorrowEnd = tomorrowStart.add(1, "day");

  if (nextFollowupFilter === "today") {
    return [todayStart.toDate(), todayEnd.toDate()];
  }

  if (nextFollowupFilter === "past_due") {
    return [undefined, pastDueEnd.toDate()];
  }

  if (nextFollowupFilter === "tomorrow") {
    return [tomorrowStart.toDate(), tomorrowEnd.toDate()];
  }

  if (nextFollowupFilter === "upcoming") {
    return [tomorrowEnd.toDate(), undefined];
  }

  throw new Error("invalid next follow up filter value");
}
