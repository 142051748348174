import { FC } from "react";
import { ReactComponent as MinusSvgIcon } from "./../../../assets/icons/minus.svg";

interface MinusIconProps {
  size: number;
}

const MinusIcon: FC<MinusIconProps> = ({ size }) => {
  return <MinusSvgIcon width={size} height={size} />;
};

export default MinusIcon;
