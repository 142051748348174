import styled from "@emotion/styled";
import {
  ActivityType as SLActivityType,
  SiteVisitStatus,
} from "@hshrimal/salesleg";
import { FC } from "react";
import BuildingIcon from "../../components/icons/BuildingIcon";
import PhoneCallIcon from "../../components/icons/PhoneCallIcon";
import theme from "../../theme/light/theme";
import { activityTypeDisplayMap } from "../../utils/activityTypeDisplayHelper";

const Container = styled.div(() => ({
  display: "flex",
  gap: 4,
}));

const Text = styled.div(({ theme }) => ({
  ...theme.salesleg.typography.body.sm.regular,
  color: theme.salesleg.palette.neutral[600],
}));

const getIcon = (type: SLActivityType) => {
  switch (type) {
    case "call":
      return <PhoneCallIcon color={theme.palette.neutral[300]} size={16} />;
    case "site_visit":
      return <BuildingIcon color={theme.palette.neutral[300]} size={16} />;
  }
};

interface ActivityTypeProps {
  type: SLActivityType;
  siteVisitStatus?: SiteVisitStatus | undefined;
}
const ActivityType: FC<ActivityTypeProps> = ({ type, siteVisitStatus }) => {
  return (
    <Container>
      {getIcon(type)}
      <div style={{ display: "flex", gap: 8 }}>
        <Text>{activityTypeDisplayMap.get(type) || ""}</Text>
        {type === "site_visit" &&
          siteVisitStatus &&
          siteVisitStatus !== "completed" && (
            <Text style={{ fontStyle: "italic", textTransform: "capitalize" }}>
              {`(${siteVisitStatus})`}
            </Text>
          )}
      </div>
    </Container>
  );
};

export default ActivityType;
