import { FC, useRef, useState } from "react";
import IconButton from "../../components/Button/IconButton";
import { Menu } from "../../components/Menu";
import { MenuItem } from "../../components/Menu/MenuItem";
import { ProgressIndicator } from "../../components/ProgressIndicator";
import BuildingIcon from "../../components/icons/BuildingIcon";
import HouseLineIcon from "../../components/icons/HouseLineIcon";
import SwapIcon from "../../components/icons/SwapIcon";
import { useGetProjectsQuery } from "../../redux/features/api/projects";
import { useSelectedCity } from "../../hooks/useSelectedCity";

interface ChangeProjectButtonProps {
  projectId: string;
}

const ChangeProjectButton: FC<ChangeProjectButtonProps> = ({ projectId }) => {
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState(projectId);

  const toggle = () => {
    setOpen((open) => !open);
  };

  const selectedCity = useSelectedCity();
  const { data: projects, isFetching: isFetchingProjects } =
    useGetProjectsQuery({ city: selectedCity });

  if (!projects) {
    return <></>;
  }

  return (
    <>
      <IconButton
        color={`${open ? "primary" : "secondary-gray"}`}
        size="sm"
        icon={SwapIcon}
        onClick={() => {
          toggle();
        }}
        ref={buttonRef}
      />
      <Menu
        open={open}
        onClose={() => setOpen(false)}
        placement="bottom"
        enableSearch
        searchPlaceholder="Search Project"
        anchorEl={buttonRef.current || undefined}
        value={selectedProjectId}
        onChange={(projectId) => setSelectedProjectId(projectId as string)}
      >
        {projects.map((project) => {
          return (
            <MenuItem
              key={project.id}
              value={project.id}
              label={project.name}
              variant="icon"
              iconComponent={
                project.type === "residential" ? HouseLineIcon : BuildingIcon
              }
              selected={project.id === projectId}
            />
          );
        })}
      </Menu>
      <ProgressIndicator open={isFetchingProjects} />
    </>
  );
};

export default ChangeProjectButton;
