import styled from "@emotion/styled";
import { Popper } from "@mui/material";
import { FC } from "react";
import LinkButton from "../../components/Button/LinkButton";
import CheckBox from "../../components/CheckBox";
import { useLoggedInViewer } from "../../hooks/getLoggedInViewer";
import theme from "../../theme/light/theme";
import AllActionsMenuButton from "./ClientButton";
import MultiChangeAssigneeButton from "./MultiChangeAssigneeButton";

const Text = styled.div(({ theme }) => ({
  ...theme.salesleg.typography.body.sm.regular,
  color: theme.salesleg.palette.neutral[400],
}));

const ButtonContainer = styled.div(({ theme }) => ({
  display: "flex",
  gap: 16,
}));

const CheckBoxContainer = styled.div(() => ({
  display: "flex",
  gap: 12,
  alignItems: "center",
}));

interface MultiSelectBarProps {
  open: boolean;
  selectedCount: number;
  onDeSelect: () => void;
  onSelectAll: () => void;
  isSelectAll: boolean;
  onProjectChange: (projectId: string) => void;
  onAssigneeChange: (assigneeId: string) => void;
  onMarkAsTestClient: () => void;
  onMarkAsDuplicateClient: () => void;
}

const MultiSelectBar: FC<MultiSelectBarProps> = ({
  open,
  selectedCount,
  onDeSelect,
  onSelectAll,
  isSelectAll,
  onProjectChange,
  onAssigneeChange,
  onMarkAsTestClient,
  onMarkAsDuplicateClient,
}) => {
  const viewer = useLoggedInViewer();
  return (
    <>
      <Popper
        open={open}
        style={{
          position: "fixed",
          bottom: 40,
          left: "50%",
          backgroundColor: "white",
          width: 900,
          padding: "10px 20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          boxShadow: theme.shadow.xl,
          borderRadius: 8,
          border: `1px solid ${theme.palette.neutral[50]}`,
          transform: "translate(-50%, 0)",
          top: "unset",
        }}
      >
        <CheckBoxContainer>
          <CheckBox
            partialChecked={!isSelectAll}
            checked={isSelectAll}
            onChange={() => {
              onDeSelect();
            }}
            size="sm"
          />
          <Text>{selectedCount} Selected</Text>
          <LinkButton
            color="gray"
            size="sm"
            onClick={onSelectAll}
            label="Select All"
          />
        </CheckBoxContainer>
        <ButtonContainer>
          {/* <MultiChangeProjectButton onProjectChange={onProjectChange} /> */}
          <MultiChangeAssigneeButton onAssigneeChange={onAssigneeChange} />
          {(viewer.role === "ops" ||
            viewer.role === "marketing" ||
            viewer.role === "sales_head") && (
            <AllActionsMenuButton
              onMarkAsTestClient={onMarkAsTestClient}
              onMarkAsDuplicateClient={onMarkAsDuplicateClient}
            />
          )}
        </ButtonContainer>
      </Popper>
    </>
  );
};

export default MultiSelectBar;
