import { City } from "@hshrimal/salesleg";
import { FC } from "react";
import DropDownV2 from "../../../components/DropDownV2/DropDownV2";

export interface ICity {
  value: City | "";
  label: string;
}

const cities: ICity[] = [
  {
    value: "",
    label: "Select City",
  },
  {
    value: "bengaluru",
    label: "Bengaluru",
  },
  {
    value: "mumbai",
    label: "Mumbai",
  },
  {
    value: "pune",
    label: "Pune",
  },
  { value: "noida", label: "Noida" },
  { value: "gurgaon", label: "Gurgaon" },
];

interface SelectCityProps {
  onChange: (city: string) => void;
  selectedCityKey: string;
}

const SelectCity: FC<SelectCityProps> = ({ onChange, selectedCityKey }) => {
  const onOptionChange = (value: string | string[] | undefined) => {
    onChange(value as string);
  };

  return (
    <div>
      <DropDownV2
        width={420}
        height={44}
        items={cities}
        enableSearch={false}
        onChange={(value) => onOptionChange(value)}
        value={selectedCityKey}
      />
    </div>
  );
};

export default SelectCity;
