import styled from "@emotion/styled";
import { SiteVisit } from "@hshrimal/salesleg";
import { FC } from "react";
import Badge from "../../components/Badge";
import Button from "../../components/Button/Button";
import ExportIcon from "../../components/icons/ExportIcon";
import { downloadSiteVisits } from "./utils/export";

const ClientsHeader = styled.div(() => ({
  backgroundColor: "#ffffff",
  padding: "24px 32px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const ClientTextGroup = styled.div(() => ({
  display: "flex",
  alignItems: "center",
  gap: 8,
}));

const ClientText = styled.div(({ theme }) => ({
  ...theme.salesleg.typography.heading.md.semiBold,
  color: theme.salesleg.palette.neutral[900],
}));

const ButtonGroup = styled.div(() => ({
  display: "flex",
  gap: 16,
}));
interface HeaderProps {
  visits: SiteVisit[];
  count: number;
}

const Header: FC<HeaderProps> = ({ visits, count }) => {
  return (
    <ClientsHeader>
      <ClientTextGroup>
        <ClientText>Site Visits</ClientText>
        <Badge size="sm" color="primary" label={count.toString()} />
      </ClientTextGroup>
      <ButtonGroup>
        <Button
          size="sm"
          color="secondary-gray"
          onClick={() => {
            downloadSiteVisits(visits);
          }}
          label="Download CSV"
          leadingIcon={ExportIcon}
        />
      </ButtonGroup>
    </ClientsHeader>
  );
};

export default Header;
