import styled from "@emotion/styled";
import { useLocation } from "react-router-dom";
import { StyledFC } from "../../types/styledFC";
import { IconComponent } from "../icons/types";
import MenuItem from "./MenuItem";

const Container = styled.div(() => ({
  display: "flex",
  flexDirection: "row",
  gap: 20,
}));

interface MenuProps {
  items: {
    icon: IconComponent;
    label: string;
    route: string;
  }[];
}

const Menu: StyledFC<MenuProps> = ({ items }) => {
  const location = useLocation();
  return (
    <Container>
      {items.map((item, index) => {
        return (
          <MenuItem
            key={index}
            isActive={item.route.startsWith(location.pathname)}
            icon={item.icon}
            label={item.label}
            route={item.route}
          ></MenuItem>
        );
      })}
    </Container>
  );
};
export default Menu;
