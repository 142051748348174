import styled from "@emotion/styled";
import { GetLeadsRequest, LeadSource, LeadStage } from "@hshrimal/salesleg";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { FilterPanel } from "../../components/FilterPanel";
import Pagination from "../../components/Pagination";
import { ProgressIndicator } from "../../components/ProgressIndicator";
import Divider from "../../components/SideBar/Divider";
import Table from "../../components/Table";
import AssigneeMenu from "../../components/filterMenus/AssigneeMenu";
import CreatedAtMenu from "../../components/filterMenus/CreatedAtMenu";
import LeadSourceMenu from "../../components/filterMenus/LeadSourceMenu";
import ManagerMenu from "../../components/filterMenus/ManagerMenu";
import NextFollowUpMenu from "../../components/filterMenus/NextFollowUpMenu";
import ProjectMenu from "../../components/filterMenus/ProjectMenu";
import StageMenu from "../../components/filterMenus/StageMenu";
import AssigneeFilter from "../../components/filters/AssigneeFilter";
import DateRangeFilter from "../../components/filters/DateRangeFilter";
import LeadSourceFilter from "../../components/filters/LeadSourceFilter";
import ManagerFilter from "../../components/filters/ManagerFilter";
import NextFollowUpFilter from "../../components/filters/NextFollowUpFilter";
import ProjectFilter from "../../components/filters/ProjectFilter";
import StageFilter from "../../components/filters/StageFilter";
import CalendarIcon from "../../components/icons/CalenderIcon";
import RadioButtonIcon from "../../components/icons/RadioButtonIcon";
import SquaresFourIcon from "../../components/icons/SquaresFourIcon";
import UserIcon from "../../components/icons/UserIcon";
import { FilterUrlParams } from "../../constants/filters";
import { useNavBarState } from "../../hooks/useNavBarState";
import { useNavigateWithTeam } from "../../hooks/useNavigationWithTeam";
import {
  useMultiSelectUrlQueryParam,
  useUrlQueryParam,
} from "../../hooks/useUrlQueryParam";
import {
  useGetLeadsQuery,
  useMarkLeadsAsTestMutation,
  useTransferLeadsMutation,
} from "../../redux/features/api/leads";
import theme from "../../theme/light/theme";
import { StyledFC } from "../../types/styledFC";
import { defined } from "../../utils/assert";
import AssigneeCell from "./AssigneeCell";
import ChangeAssigneeButton from "./ChangeAssigneeButton";
import ChangeProjectButton from "./ChangeProjectButton";
import ClientNameCell from "./ClientNameCell";
import ClientStageCell from "./ClientStageCell";
import ContactDetailsCell from "./ContactDetailsCell";
import Header from "./Header";
import LeadDateCell from "./LeadDateCell";
import MultiSelectBar from "./MultiSelectBar";
import ProjectCell from "./ProjectCell";
import { useLeadFilter } from "./useLeadFilter";
import { useOrgFilter } from "./useOrgFilter";

const getStartEndDate = (filterValue: string | undefined) => {
  const arrDate = filterValue?.split("_");
  const start_date = arrDate && dayjs(arrDate[0]).toDate();
  const end_date = arrDate && dayjs(arrDate[1]).toDate();
  return [start_date, end_date];
};

const RootContainer = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
}));

const ActionButtonGroup = styled.div(() => ({
  display: "flex",
  gap: 4,
}));

const TableContainer = styled.div(() => ({
  paddingLeft: 32,
  paddingTop: 12,
  paddingBottom: 12,
  paddingRight: 16,
  backgroundColor: theme.palette.neutral[25],
  overflowY: "scroll",
  flexGrow: 1,
  height: 0,
}));

const ViewClients: StyledFC = ({ style }) => {
  const navigate = useNavigateWithTeam();
  const [selectedLeadIds, setSelectedLeadIds] = useState<string[]>([]);

  const [projectFilter] = useMultiSelectUrlQueryParam(FilterUrlParams.PROJECT);
  const [assigneeFilter] = useUrlQueryParam(FilterUrlParams.ASSIGNEE);
  const [stageFilter] = useMultiSelectUrlQueryParam(FilterUrlParams.STAGE);
  const [searchText] = useUrlQueryParam("search");
  const [page_size] = useUrlQueryParam("page_size", "10");
  const [page] = useUrlQueryParam("page", "1");
  const [leadSourceFilter] = useMultiSelectUrlQueryParam(
    FilterUrlParams.LEAD_SOURCE
  );

  const [managerFilter] = useUrlQueryParam(FilterUrlParams.MANAGER);
  const [createdAtFilter] = useUrlQueryParam(FilterUrlParams.CREATION_DATE);
  const [prospectFilter] = useUrlQueryParam(FilterUrlParams.PROSPECT_DATE);
  const [visitDateFilter] = useUrlQueryParam(FilterUrlParams.VISIT_DATE);
  const [nextFollowUpFilter] = useUrlQueryParam(FilterUrlParams.FOLLOWUP);

  const [created_start_date, created_end_date] =
    getStartEndDate(createdAtFilter);

  const [prospect_start_date, prospect_end_date] =
    getStartEndDate(prospectFilter);

  const [visit_date_start, visit_date_end] = getStartEndDate(visitDateFilter);

  const leadFilter = useLeadFilter(
    stageFilter as LeadStage[],
    leadSourceFilter as LeadSource[],
    projectFilter,
    created_start_date,
    created_end_date,
    prospect_start_date,
    prospect_end_date,
    visit_date_start,
    visit_date_end,
    nextFollowUpFilter
  );
  const orgFilter = useOrgFilter(managerFilter, assigneeFilter);

  const parsedPageSize = parseInt(defined(page_size));
  const parsedPage = parseInt(defined(page));
  const request: GetLeadsRequest = {
    orgFilter: orgFilter,
    leadFilter: leadFilter,
    search: searchText,
    paginationOptions: { pageNum: parsedPage, pageSize: parsedPageSize },
  };
  const { data: paginatedLeads, isLoading: isLoadingLeads } =
    useGetLeadsQuery(request);

  useEffect(() => {
    setSelectedLeadIds([]);
  }, [paginatedLeads]);

  const leads = paginatedLeads?.leads;
  const totalLeads = paginatedLeads?.totalCount || 0;

  const [navBarState] = useNavBarState();

  const handleRowSelection = (rowId: string, isSelected: boolean) => {
    if (isSelected) {
      setSelectedLeadIds((prevSelectedRows) => [...prevSelectedRows, rowId]);
    } else {
      setSelectedLeadIds((prevSelectedRows) =>
        prevSelectedRows.filter((id) => id !== rowId)
      );
    }
  };

  const onDeSelect = () => {
    setSelectedLeadIds([]);
  };

  const onSelectAll = () => {
    const leadIds = leads?.map((lead) => lead.id);
    if (leadIds) {
      setSelectedLeadIds(leadIds);
    }
  };

  const onProjectChange = (projectId: string) => {
    console.log(projectId);
  };

  const [transferLeads, { isLoading: isTransferringLeads }] =
    useTransferLeadsMutation();

  const [markLeadsAsTest, { isLoading: markLeadsAsTestInProgress }] =
    useMarkLeadsAsTestMutation();

  const onAssigneeChange = (assigneeId: string) => {
    setSelectedLeadIds([]);
    transferLeads({ leadIds: selectedLeadIds, ownerId: assigneeId });
  };

  const onMarkAsTestClient = () => {
    setSelectedLeadIds([]);
    markLeadsAsTest(selectedLeadIds);
  };

  const onMarkAsDuplicateClient = () => {
    console.log("onMarkAsDuplicateClient");
  };

  const isSelectAll = leads?.length === selectedLeadIds.length;

  const columns = [
    { name: "Client Name", width: 250 },
    ...(navBarState === "collapsed"
      ? [{ name: "Contact Details", width: 147 }]
      : []),
    { name: "Lead Date", width: 96 },
    { name: "Stage", width: 96 },
    { name: "Assignee", width: 52 },
    { name: "Team Leader", width: 52 },
    { name: "Project", width: 170 },
  ];

  if (selectedLeadIds.length === 0) {
    columns.push({ name: "Actions", width: 100 });
  }

  return (
    <>
      {leads && (
        <RootContainer style={style}>
          <Header leads={leads} count={totalLeads} />
          <Divider color={theme.palette.neutral[100]} width="100%" />
          <FilterPanel
            masterFilterMenuItems={[
              {
                name: "Project",
                icon: SquaresFourIcon,
                subMenuComponent: ProjectMenu,
              },
              {
                name: "Assignee",
                icon: UserIcon,
                subMenuComponent: AssigneeMenu,
              },
              {
                name: "Stage",
                icon: RadioButtonIcon,
                subMenuComponent: StageMenu,
              },
              {
                name: "Next Followup",
                icon: RadioButtonIcon,
                subMenuComponent: NextFollowUpMenu,
              },
              {
                name: "Created At",
                icon: CalendarIcon,
                subMenuComponent: CreatedAtMenu,
              },
              {
                name: "Lead Source",
                icon: CalendarIcon,
                subMenuComponent: LeadSourceMenu,
              },
              {
                name: "Manager",
                icon: UserIcon,
                subMenuComponent: ManagerMenu,
              },
            ]}
            directFilters={[
              {
                urlParamName: FilterUrlParams.STAGE,
                component: <StageFilter key="stage" />,
                permanent: true,
              },
              {
                urlParamName: FilterUrlParams.FOLLOWUP,
                component: (
                  <NextFollowUpFilter key={FilterUrlParams.FOLLOWUP} />
                ),
                permanent: true,
              },
              {
                urlParamName: FilterUrlParams.CREATION_DATE,
                component: (
                  <DateRangeFilter
                    key="created_at"
                    label="Created At"
                    urlParamName={FilterUrlParams.CREATION_DATE}
                  />
                ),
                permanent: true,
              },
              {
                urlParamName: FilterUrlParams.PROSPECT_DATE,
                component: (
                  <DateRangeFilter
                    key="prospect_date"
                    label="Prospect Date"
                    urlParamName={FilterUrlParams.PROSPECT_DATE}
                  />
                ),
                permanent: true,
              },
              {
                urlParamName: FilterUrlParams.VISIT_DATE,
                component: (
                  <DateRangeFilter
                    key="visit_date"
                    label="Visit Date"
                    urlParamName={FilterUrlParams.VISIT_DATE}
                  />
                ),
                permanent: true,
              },
              {
                urlParamName: FilterUrlParams.PROJECT,
                component: <ProjectFilter key="project" />,
                permanent: false,
              },
              {
                urlParamName: FilterUrlParams.ASSIGNEE,
                component: <AssigneeFilter key="assignee" />,
                permanent: false,
              },
              {
                urlParamName: FilterUrlParams.LEAD_SOURCE,
                component: <LeadSourceFilter key="lead_source" />,
                permanent: false,
              },
              {
                urlParamName: FilterUrlParams.MANAGER,
                component: <ManagerFilter key="manager" />,
                permanent: false,
              },
            ]}
          />

          <>
            <TableContainer>
              <Table
                columnGroups={[
                  { span: 1, width: 240 },
                  ...(navBarState === "collapsed"
                    ? [{ span: 1, width: 160 }]
                    : []),
                  { span: 1, width: 144 },
                  { span: 1, width: 160 },
                  { span: 1, width: 240 },
                  { span: 3 },
                ]}
                columns={columns}
                rows={leads || []}
                renderTableRowCells={(row) => [
                  <ClientNameCell
                    name={row.client.name}
                    onClick={() => navigate(`/clients/${row.id}`)}
                    isSelected={selectedLeadIds.includes(row.id)}
                    onSelectionChange={(isSelected) =>
                      handleRowSelection(row.id, isSelected)
                    }
                  />,
                  ...(navBarState === "collapsed"
                    ? [<ContactDetailsCell contact={row.client} />]
                    : []),
                  <LeadDateCell date={row.created_at} />,
                  <ClientStageCell status={row.stage} />,
                  <AssigneeCell assignedToName={row.assignedTo?.name} />,
                  <AssigneeCell assignedToName={row.teamLeader.name} />,
                  <ProjectCell
                    developerName={row.developer.name}
                    projectName={row.project.name}
                    leadSource={row.source}
                  />,
                  selectedLeadIds.length === 0 && (
                    <ActionButtonGroup>
                      <ChangeProjectButton projectId={row.project.id} />
                      <ChangeAssigneeButton
                        assignedTo={{ id: row.assignedTo?.id }}
                        leadId={row.id}
                      />
                    </ActionButtonGroup>
                  ),
                ]}
              />
              <MultiSelectBar
                open={selectedLeadIds.length > 0}
                selectedCount={selectedLeadIds.length}
                onDeSelect={onDeSelect}
                onSelectAll={onSelectAll}
                isSelectAll={isSelectAll}
                onAssigneeChange={onAssigneeChange}
                onProjectChange={onProjectChange}
                onMarkAsTestClient={onMarkAsTestClient}
                onMarkAsDuplicateClient={onMarkAsDuplicateClient}
              />
            </TableContainer>
            {totalLeads > 0 && <Pagination numRecords={totalLeads} />}
          </>
        </RootContainer>
      )}
      <ProgressIndicator
        open={
          isLoadingLeads || isTransferringLeads || markLeadsAsTestInProgress
        }
      />
    </>
  );
};

export default ViewClients;
