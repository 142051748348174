import { ReactComponent as DropdownCloseSvgIcon } from "./../../assets/icons/dropdown-close.svg";
import { StyledFC } from "../../types/styledFC";

interface DropdownCloseIconProps {
  size: number;
  color: string;
}

const DropdownCloseIcon: StyledFC<DropdownCloseIconProps> = ({
  size,
  color,
  style,
}) => {
  return (
    <DropdownCloseSvgIcon
      width={size}
      height={size}
      color={color}
      style={style}
    />
  );
};

export default DropdownCloseIcon;
