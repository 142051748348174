import {
  Client,
  ExtendedLead,
  GetLeadsRequest,
  GetLeadsResponse,
  LeadStage,
  Requirement,
  WithMetadata,
  deserializeExtendedLead,
  deserializeGetLeadsResponse,
} from "@hshrimal/salesleg";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export interface CreateLeadInput extends Client {
  source: string;
  projectId: string;
}

export interface LeadAttachmentInput {
  file: File;
  name: string;
  leadId: string;
}

export const leadsApi = createApi({
  reducerPath: "leadsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_SALESLEG_API_BASE_URL}/api`,
    prepareHeaders: (headers) => headers.append("client", "web"),
    credentials: "include",
  }),
  tagTypes: ["leads"],
  endpoints: (builder) => ({
    getLeads: builder.query<GetLeadsResponse, GetLeadsRequest>({
      query: (req) => ({
        method: "Post",
        url: "/leads/query",
        body: req,
      }),
      transformResponse: (data) => deserializeGetLeadsResponse(data),
      providesTags: ["leads"],
    }),
    getLead: builder.query<WithMetadata<ExtendedLead>, { leadId: string }>({
      query: (query) => {
        let url = `/leads/${query.leadId}`;
        return { url };
      },
      transformResponse: (data) => deserializeExtendedLead(data),
      providesTags: (_result, _error, arg) => [
        { type: "leads", id: arg.leadId },
      ],
    }),
    updateLeadStage: builder.mutation<
      void,
      { leadId: string; stage: LeadStage }
    >({
      query: (input) => ({
        url: `/leads/${input.leadId}/stage`,
        method: "PUT",
        body: input.stage,
      }),
      invalidatesTags: (_result, _error, arg) => [
        "leads",
        { type: "leads", id: arg.leadId },
      ],
    }),
    updateLeadRequirement: builder.mutation<
      void,
      { leadId: string; requirement: Requirement }
    >({
      query: (input) => ({
        url: `/leads/${input.leadId}/requirement`,
        method: "PUT",
        body: input.requirement,
      }),
      invalidatesTags: (_result, _error, arg) => [
        "leads",
        { type: "leads", id: arg.leadId },
      ],
    }),
    transferLeads: builder.mutation<
      void,
      { leadIds: string[]; ownerId: string }
    >({
      query: (input) => ({
        url: `/leads`,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          leadIds: input.leadIds,
          ownerId: input.ownerId,
        }),
      }),
      invalidatesTags: (_result, _error, arg) => [
        "leads",
        ...arg.leadIds.map((leadId) => ({
          type: "leads" as "leads",
          id: leadId,
        })),
      ],
    }),
    createLead: builder.mutation<void, CreateLeadInput>({
      query: (input) => ({
        url: `/projects/${input.projectId}/leads?source=${input.source}`,
        method: "POST",
        body: {
          name: input.name,
          email: input.email,
          phone: input.phone,
        },
        invalidatesTags: ["leads"],
      }),
    }),
    uploadLeadAttachment: builder.mutation<void, LeadAttachmentInput>({
      query: (input) => {
        const formData = new FormData();
        formData.append("file", input.file);
        formData.append("name", input.name);
        return {
          url: `/leads/${input.leadId}/attachments`,
          method: "POST",
          body: formData,
          formData: true,
        };
      },
      invalidatesTags: (_result, _error, arg) => {
        return [{ type: "leads", id: arg.leadId }];
      },
    }),
    markLeadsAsTest: builder.mutation<void, string[]>({
      query: (input) => ({
        url: "/leads/markAsTest",
        method: "POST",
        body: { leadIds: input },
      }),
      invalidatesTags: (_result, _error, arg) => [
        "leads",
        ...arg.map((leadId) => ({
          type: "leads" as "leads",
          id: leadId,
        })),
      ],
    }),
  }),
});

export const {
  useGetLeadsQuery,
  useGetLeadQuery,
  useUpdateLeadStageMutation,
  useUpdateLeadRequirementMutation,
  useTransferLeadsMutation,
  useCreateLeadMutation,
  useUploadLeadAttachmentMutation,
  useMarkLeadsAsTestMutation,
} = leadsApi;
