import styled from "@emotion/styled";
import { StyledFC } from "../../types/styledFC";

const Assignee = styled.div(({ theme }) => ({
  ...theme.salesleg.typography.paragraph.md.medium,
  color: theme.salesleg.palette.neutral[400],
  whiteSpace: "nowrap",
}));

interface AssigneeCellProps {
  assignedToName?: string | undefined;
}
const AssigneeCell: StyledFC<AssigneeCellProps> = ({ assignedToName }) => {
  if (!assignedToName) {
    return <></>;
  }

  return <Assignee>{assignedToName}</Assignee>;
};

export default AssigneeCell;
