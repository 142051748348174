import { StyledFC } from "../../types/styledFC";

export const MaskGroupSvg: StyledFC = ({ style }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="274"
    height="166"
    viewBox="0 0 274 166"
    fill="none"
    style={style}
  >
    <mask
      id="mask0_2535_203894"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="-122"
      y="-134"
      width="396"
      height="300"
    >
      <rect
        x="-122"
        y="-134"
        width="396"
        height="300"
        fill="url(#paint0_radial_2535_203894)"
      />
    </mask>
    <g mask="url(#mask0_2535_203894)">
      <path
        d="M-102.5 -134L-102.5 166M-82.5 -134L-82.5 166M-62.5 -134L-62.5 166M-42.5 -134L-42.5 166M-22.5 -134L-22.5 166M-2.49998 -134L-2.5 166M17.5 -134L17.5 166M37.5 -134L37.5 166M57.5 -134L57.5 166M77.5 -134L77.5 166M97.5 -134L97.5 166M117.5 -134L117.5 166M137.5 -134V166M157.5 -134V166M177.5 -134V166M197.5 -134V166M217.5 -134V166M238 -134L-122 -134M238 -115.25L-122 -115.25M238 -96.5L-122 -96.5M238 -77.75L-122 -77.75M238 -59L-122 -59M238 -40.25L-122 -40.25M238 -21.5L-122 -21.5M238 -2.74997L-122 -2.75M238 16L-122 16M238 34.75L-122 34.75M238 53.5L-122 53.5M238 72.25L-122 72.25M238 91L-122 91M238 109.75H-122M238 128.5H-122M238 147.25H-122M238 166H-122"
        stroke="url(#paint1_radial_2535_203894)"
      />
    </g>
    <defs>
      <radialGradient
        id="paint0_radial_2535_203894"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(76 16) rotate(90) scale(108.824 143.647)"
      >
        <stop stopColor="#0077FF" stopOpacity="0.6" />
        <stop offset="1" stopColor="#0077FF" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint1_radial_2535_203894"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(58 16) rotate(90) scale(87.5 105)"
      >
        <stop stopColor="#0091FF" stopOpacity="0.39" />
        <stop offset="1" stopColor="#0091FF" stopOpacity="0" />
      </radialGradient>
    </defs>
  </svg>
);
