import { Role } from "@hshrimal/salesleg";

export const UserRoleDisplayMap = new Map<Role, string>([
  ["sales_head", "Sales Head"],
  ["sales_lead", "Sales Manager"],
  ["sales_associate", "Sales Associate"],
  ["ops", "Ops"],
  ["marketing", "Marketing"],
  ["admin", "Admin"],
  ["god", "God"],
]);
