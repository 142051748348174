import styled from "@emotion/styled";
import { FC } from "react";

interface ErrorTextProps {
  error?: string | undefined;
}

const ErrorStyled = styled.span(({ theme }) => ({
  color: theme.salesleg.palette.error[500],
  ...theme.salesleg.typography.paragraph.sm.medium,
}));
const ErrorText: FC<ErrorTextProps> = ({ error }) => {
  return <ErrorStyled>{error}</ErrorStyled>;
};

export default ErrorText;
