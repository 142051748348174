import { FC, ReactNode, createContext, useState } from "react";

export interface ITabContext {
  setBadgeValue: (key: string, value: string) => void;
  counts: Record<string, string>;
}

export const TabContext = createContext<ITabContext>({
  setBadgeValue: () => {},
  counts: {},
});

interface TabContextProviderProps {
  children: ReactNode;
}

const TabContextProvider: FC<TabContextProviderProps> = ({ children }) => {
  const [badgeCounts, setBadgeCounts] = useState({});

  const setBadgeValue = (key: string, value: string) => {
    setBadgeCounts((preBadgeCount) => ({
      ...preBadgeCount,
      [key]: value,
    }));
  };

  return (
    <TabContext.Provider value={{ setBadgeValue, counts: badgeCounts }}>
      {children}
    </TabContext.Provider>
  );
};

export default TabContextProvider;
