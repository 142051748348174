import styled from "@emotion/styled";
import { ProductivityReportByOwner, Role } from "@hshrimal/salesleg";
import dayjs from "dayjs";
import { useContext, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { FilterPanel } from "../../components/FilterPanel";
import { ProgressIndicator } from "../../components/ProgressIndicator";
import Table from "../../components/Table";
import DateRangeFilter from "../../components/filters/DateRangeFilter";
import { FilterUrlParams } from "../../constants/filters";
import { useUrlQueryParam } from "../../hooks/useUrlQueryParam";
import { useGetProductivityReportQuery } from "../../redux/features/api/leadsQuality";
import theme from "../../theme/light/theme";
import { defined } from "../../utils/assert";
import AssignedCell from "./AssignedCell";
import ProductivityCell from "./ProductivityCell";
import { DownloadContext } from "./context/DownloadContext";
import { useProductivityReportRequest } from "./hooks/useProductivityReportRequest";

const RootContainer = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
}));

const TableContainer = styled.div(() => ({
  paddingLeft: 32,
  paddingTop: 12,
  paddingBottom: 12,
  paddingRight: 16,
  backgroundColor: theme.palette.neutral[25],
  overflowY: "auto",
  flexGrow: 1,
  flexBasis: 0,
}));

const getStartEndDate = (filterValue: string | undefined) => {
  const arrDate = filterValue?.split("_");
  const startDate = arrDate && dayjs(arrDate[0]).toDate();
  const endDate = arrDate && dayjs(arrDate[1]).toDate();
  return [startDate, endDate];
};

const ProductivityContent = () => {
  const { employeeId } = useParams();
  const [createdDate] = useUrlQueryParam(FilterUrlParams.CREATION_DATE);
  const [activityDateStart, activityDateEnd] = getStartEndDate(createdDate);

  const activityDateRange = {
    start: defined(activityDateStart),
    end: defined(activityDateEnd),
  };
  const productivityReportRequest = useProductivityReportRequest(
    activityDateRange,
    employeeId
  );
  const { data: productivityReport, isLoading } = useGetProductivityReportQuery(
    productivityReportRequest
  );
  const totalRow: ProductivityReportByOwner & { id: string } = useMemo(() => {
    const total: ProductivityReportByOwner & { id: string } = {
      id: "total",
      employee: {
        id: "",
        name: "",
        role: "sales_lead" as Role,
        level: 0,
        status: "active",
        isRoot: false,
      },
      digitalLeads: {
        facebook: 0,
        google: 0,
      },
      prospectsGenerated: { total: 0, google: 0, facebook: 0, cold_calling: 0 },
      siteVisitsPlanned: { total: 0, google: 0, facebook: 0, cold_calling: 0 },
      siteVisitsDone: { total: 0, google: 0, facebook: 0, cold_calling: 0 },
      revisitsDone: { total: 0, google: 0, facebook: 0, cold_calling: 0 },
      bookings: { total: 0, google: 0, facebook: 0, cold_calling: 0 },
    };
    if (productivityReport) {
      productivityReport.forEach((row) => {
        total.prospectsGenerated.total += row.prospectsGenerated.total;
        total.siteVisitsPlanned.total += row.siteVisitsPlanned.total;
        total.siteVisitsDone.total += row.siteVisitsDone.total;
        total.revisitsDone.total += row.revisitsDone.total;
        total.bookings.total += row.bookings.total;
      });
    }
    return total;
  }, [productivityReport]);

  const columns = [
    { name: "Assigned To", width: 170 },
    { name: "Prospects", width: 170 },
    { name: "Visits Planned", width: 170 },
    { name: "Visits Done", width: 170 },
    { name: "Re-visits Done", width: 170 },
    { name: "Bookings", width: 170 },
  ];

  const downloadCtx = useContext(DownloadContext);

  useEffect(() => {
    if (productivityReport !== undefined) {
      downloadCtx?.setProductivityReportRows(productivityReport);
    }
  }, [productivityReport, downloadCtx]);

  useEffect(() => {
    downloadCtx?.setTotalProductivityReportRow(totalRow);
  }, [totalRow, downloadCtx]);

  return (
    <>
      <RootContainer>
        <FilterPanel
          searchable={false}
          masterFilterMenuItems={[]}
          directFilters={[
            {
              component: (
                <DateRangeFilter
                  key="created_at"
                  label="Created At"
                  urlParamName={FilterUrlParams.CREATION_DATE}
                  isResetAllow={false}
                />
              ),
              urlParamName: FilterUrlParams.VISIT_DATE,
              permanent: true,
            },
          ]}
        />
        <TableContainer>
          <Table
            columnGroups={[
              { span: 1, width: 200 },
              { span: 1, width: 140 },
              { span: 1, width: 140 },
              { span: 1, width: 140 },
              { span: 1, width: 140 },
              { span: 1, width: 140 },
            ]}
            columns={columns}
            rows={productivityReport || []}
            totalRow={totalRow}
            renderTableRowCells={(row, index, isTotalRow) => [
              <AssignedCell
                name={row.employee.name}
                role={row.employee.role}
                id={row.employee.id}
                disableDrillDown={
                  row.employee.isRoot || row.employee.role === "sales_associate"
                }
              />,
              <ProductivityCell count={row.prospectsGenerated.total} employee={row.employee} isRoot={row.employee.isRoot} />,
              <ProductivityCell count={row.siteVisitsPlanned.total} employee={row.employee} isRoot={row.employee.isRoot} />,
              <ProductivityCell count={row.siteVisitsDone.total} employee={row.employee} isRoot={row.employee.isRoot} />,
              <ProductivityCell count={row.revisitsDone.total} employee={row.employee} isRoot={row.employee.isRoot} />,
              <ProductivityCell count={row.bookings.total} employee={row.employee} isRoot={row.employee.isRoot} />,
            ]}
          />
        </TableContainer>
      </RootContainer>
      <ProgressIndicator open={isLoading} />
    </>
  );
};

export default ProductivityContent;
