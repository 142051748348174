import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { FC, useRef, useState } from "react";
import { useUrlQueryParam } from "../../hooks/useUrlQueryParam";
import CalenderRange from "../Calendar/CalendarRange";
import { FilterButton } from "../FilterButton";
import CalendarIcon from "../icons/CalenderIcon";
dayjs.extend(customParseFormat);

interface CreatedAtFilterProps {
  label: string;
  urlParamName: string;
  isResetAllow?: boolean | undefined;
}
const DateRangeFilter: FC<CreatedAtFilterProps> = ({
  label,
  urlParamName,
  isResetAllow = true,
}) => {
  const [createdAt, setCreatedAt] = useUrlQueryParam(urlParamName);
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLButtonElement | null>(null);

  const handleClose = () => {
    setOpen(false);
  };

  const toggle = () => {
    setOpen((open) => !open);
  };

  let selectedDateRange: [Date, Date] | undefined = undefined;
  if (createdAt) {
    const [start, end] = createdAt?.split("_");
    const selectedStartDate = dayjs(start).toDate();

    const selectedEndDate = dayjs(end).toDate();
    selectedDateRange = [selectedStartDate, selectedEndDate];
  }

  const handleChange = (value: [Date, Date] | undefined) => {
    if (!value) {
      setCreatedAt(undefined);
      return;
    }

    const [startDate, endDate] = value;
    const startDateString = startDate.toISOString();
    const endDateString = endDate.toISOString();
    if (startDate === undefined && endDate === undefined) {
      setCreatedAt(undefined);
    } else {
      setCreatedAt(startDateString + "_" + endDateString);
    }
  };

  return (
    <>
      <FilterButton
        leadingIcon={CalendarIcon}
        size="sm"
        valueText={
          selectedDateRange
            ? `${dayjs(selectedDateRange[0]).format("DD MMM YYYY")} - ${dayjs(
                selectedDateRange[1]
              )
                .subtract(1, "day")
                .format("DD MMM YYYY")}`
            : "All"
        }
        onClick={toggle}
        label={label}
        ref={ref}
      />
      <CalenderRange
        onChange={handleChange}
        value={selectedDateRange}
        open={open}
        onClose={handleClose}
        anchorEl={ref.current || undefined}
        placement="bottom"
        monthsShown={2}
        isResetAllow={isResetAllow}
      />
    </>
  );
};

export default DateRangeFilter;
