import styled from "@emotion/styled";
import { CSSProperties, FC, ForwardedRef, forwardRef } from "react";
import { MergedTheme } from "../../types/emotion";
import { IconProps } from "../icons/types";

type ButtonColor =
  | "primary"
  | "secondary"
  | "secondary-gray"
  | "tertiary-gray"
  | "error"
  | "light-gray";

type ButtonSize = "sm" | "lg" | "2xl";

interface ButtonProps {
  color: ButtonColor;
  size: ButtonSize;
  leadingIcon?: FC<IconProps> | undefined;
  trailingIcon?: FC<IconProps> | undefined;
  label?: string | undefined;
  onClick: () => void;
  disabled?: boolean | undefined;
  style?: CSSProperties | undefined;
  fullWidth?: boolean | undefined;
}

function getDefaultColorStyle(
  theme: MergedTheme,
  color: ButtonColor,
  error?: boolean | undefined
) {
  switch (color) {
    case "primary":
      return {
        backgroundColor: theme.salesleg.palette.primary[600],
        color: "#ffffff",
        boxShadow: theme.salesleg.shadow.xs,
        ":hover": {
          backgroundColor: theme.salesleg.palette.primary[700],
        },
        ":disabled": {
          backgroundColor: theme.salesleg.palette.primary[50],
        },
        ":focused": {
          boxShadow:
            "0px 0px 0px 4px #EBF1F8, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);",
        },
      };

    case "secondary":
      return {
        backgroundColor: theme.salesleg.palette.primary[50],
        color: theme.salesleg.palette.primary[900],
        boxShadow: theme.salesleg.shadow.xs,
        ":hover": {
          backgroundColor: theme.salesleg.palette.primary[100],
          color: theme.salesleg.palette.primary[900],
        },
        ":disabled": {
          backgroundColor: theme.salesleg.palette.primary[25],
          color: theme.salesleg.palette.neutral[100],
        },
        ":focused": {
          boxShadow:
            "0px 0px 0px 4px #EAFBFF, 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
        },
      };

    case "secondary-gray":
      return {
        backgroundColor: "#ffffff",
        border: `1px solid ${theme.salesleg.palette.neutral[100]}`,
        color: theme.salesleg.palette.neutral[700],
        boxShadow: theme.salesleg.shadow.xs,
        ":hover": {
          backgroundColor: theme.salesleg.palette.neutral[25],
          color: theme.salesleg.palette.neutral[800],
        },
        ":disabled": {
          border: `1px solid ${theme.salesleg.palette.neutral[50]}`,
          boxShadow: theme.salesleg.shadow.xs,
          color: theme.salesleg.palette.neutral[100],
        },
      };
    case "tertiary-gray":
      return {
        backgroundColor: "inherit",
        color: theme.salesleg.palette.neutral[500],
        ":hover": {
          backgroundColor: theme.salesleg.palette.neutral[25],
          color: theme.salesleg.palette.neutral[600],
        },
        ":disabled": {
          color: theme.salesleg.palette.neutral[100],
        },
      };

    case "error":
      return {
        backgroundColor: theme.salesleg.palette.error[50],
        color: theme.salesleg.palette.error[700],
        ":hover": {
          backgroundColor: theme.salesleg.palette.error[100],
          color: theme.salesleg.palette.error[800],
        },
        ":disabled": {
          backgroundColor: theme.salesleg.palette.error[25],
          color: theme.salesleg.palette.error[200],
        },
        ":focus-visible": {
          boxShadow:
            "0px 0px 0px 4px #FFC2C2, 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
        },
      };
    case "light-gray":
      return {
        backgroundColor: theme.salesleg.palette.neutral[50],
        color: theme.salesleg.palette.neutral[500],
        boxShadow: theme.salesleg.shadow.xs,
        ":hover": {
          backgroundColor: theme.salesleg.palette.neutral[25],
          color: theme.salesleg.palette.neutral[800],
        },
        ":disabled": {
          border: `1px solid ${theme.salesleg.palette.neutral[50]}`,
          boxShadow: theme.salesleg.shadow.xs,
          color: theme.salesleg.palette.neutral[100],
        },
      };
  }
}

function getSizeStyle(size: ButtonSize) {
  switch (size) {
    case "sm":
      return {
        height: 36,
        padding: "8px 14px",
        borderRadius: 4,
        gap: 8,
        svg: {
          height: 20,
          width: 20,
        },
      };
    case "lg":
      return {
        height: 44,
        padding: "10px 18px",
        borderRadius: 4,
        gap: 8,
        svg: {
          height: 20,
          width: 20,
        },
      };
    case "2xl":
      return {
        height: 56,
        padding: "16px 28px",
        borderRadius: 8,
        gap: 12,
        svg: {
          height: 24,
          width: 24,
        },
      };
  }
}

const getButtonWidth = (fullWidth?: boolean | undefined) => {
  if (fullWidth) {
    return { width: "100%" };
  }
  return {};
};

export const RootContainer = styled.button<{
  color: ButtonColor;
  size: ButtonSize;
  fullWidth?: boolean | undefined;
}>(({ color, size, disabled, theme, fullWidth }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  ...getDefaultColorStyle(theme, color, disabled),
  ...getSizeStyle(size),
  ...getButtonWidth(fullWidth),
  cursor: "pointer",
}));

const Label = styled.span<{ size: ButtonSize }>(({ size, theme }) => {
  switch (size) {
    case "sm":
      return {
        ...theme.salesleg.typography.paragraph.sm.medium,
        whiteSpace: "nowrap",
      };
    default:
      return {
        ...theme.salesleg.typography.paragraph.lg.medium,
        whiteSpace: "nowrap",
      };
  }
});

export type Ref = ForwardedRef<HTMLButtonElement>;

const Button = (
  {
    size,
    color,
    leadingIcon,
    trailingIcon,
    label,
    onClick,
    style,
    disabled,
    fullWidth,
  }: ButtonProps,
  ref: Ref
) => {
  const LeadingIcon = leadingIcon;
  const TrailingIcon = trailingIcon;
  return (
    <RootContainer
      color={color}
      size={size}
      onClick={onClick}
      style={style}
      disabled={disabled}
      fullWidth={fullWidth}
      ref={ref}
    >
      {LeadingIcon && <LeadingIcon color="inherit" />}
      {label && <Label size={size}>{label}</Label>}
      {TrailingIcon && <TrailingIcon color="inherit" />}
    </RootContainer>
  );
};

export default forwardRef(Button);
