import styled from "@emotion/styled";
import { useState } from "react";
import { useParams } from "react-router";

import LinkButton from "../../components/Button/LinkButton";
import { ProgressIndicator } from "../../components/ProgressIndicator";
import Tabs, { TabParams } from "../../components/Tabs";
import PaperclipIcon from "../../components/icons/PaperclipIcon";
import { useGetLeadQuery } from "../../redux/features/api/leads";
import { StyledFC } from "../../types/styledFC";
import { defined } from "../../utils/assert";
import ActivityTimelineTab from "./ActivityTimelineTab";
import AttachmentContent from "./AttachmentContent";
import ClientSideBar from "./ClientSideBar";
import Header from "./Header";
import UploadFilesModal from "./UploadFilesModal";

const RootContainer = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
}));

const BodyContainer = styled.div(() => ({
  display: "flex",
  flexGrow: 1,
}));

const ClientDetails: StyledFC = ({ style }) => {
  const [activeTab, setActiveTab] = useState("1");

  const handleTabClick = (tab: TabParams) => {
    setActiveTab(tab.id);
  };

  const { clientId } = useParams();
  const { data: lead, isFetching } = useGetLeadQuery({
    leadId: defined(clientId),
  });

  const [open, setOpen] = useState(false);

  return (
    <RootContainer style={style}>
      {lead && (
        <>
          <Header lead={lead} />
          <BodyContainer>
            <ClientSideBar lead={lead} />

            <Tabs
              tabs={[
                {
                  id: "1",
                  label: `Timeline`,
                  children: <ActivityTimelineTab lead={lead} />,
                },
                {
                  id: "2",
                  label: `Attachments`,
                  children: (
                    <AttachmentContent attachments={lead.attachments} />
                  ),
                  quickAddButton: (
                    <LinkButton
                      color="white"
                      size="sm"
                      label="Attach Files"
                      leadingIcon={PaperclipIcon}
                      onClick={() => {
                        setOpen(true);
                      }}
                    />
                  ),
                },
              ]}
              activeTab={activeTab}
              handleTabClick={handleTabClick}
              tabHeaderStyle={{
                paddingTop: 24,
                paddingLeft: 24,
                paddingRight: 24,
              }}
            />
          </BodyContainer>

          <UploadFilesModal
            onClose={() => setOpen(false)}
            open={open}
            leadId={lead.id}
          />
        </>
      )}
      <ProgressIndicator open={isFetching} />
    </RootContainer>
  );
};

export default ClientDetails;
