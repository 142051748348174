import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import * as React from "react";
import theme from "../../theme/light/theme";
import { AvatarProps } from "../Avatar";
import CheckIcon from "../icons/CheckIcon";
import { IconProps } from "../icons/types";

export type Variant = "default" | "icon" | "avatar" | "dot" | "search";
type ThemeColor = "light" | "dark";

interface ColorScheme {
  container: {
    backgroundColor: string;
    color: string;
  };
  selected: {
    backgroundColor: string;
    color: string;
  };
  hover: {
    bacbackgroundColor: string;
    color: string;
  };
  icon: {
    color: string;
  };
}

const lightTheme: ColorScheme = {
  container: {
    backgroundColor: "#ffffff",
    color: theme.palette.neutral[900],
  },
  selected: {
    backgroundColor: theme.palette.primary[25],
    color: theme.palette.neutral[900],
  },
  hover: {
    bacbackgroundColor: "#ffffff",
    color: theme.palette.neutral[700],
  },
  icon: {
    color: theme.palette.primary[600],
  },
};

const darkTheme: ColorScheme = {
  container: {
    backgroundColor: theme.palette.neutral[900],
    color: theme.palette.neutral[300],
  },
  selected: {
    backgroundColor: theme.palette.neutral[900],
    color: theme.palette.neutral[100],
  },
  hover: {
    bacbackgroundColor: theme.palette.neutral[900],
    color: theme.palette.neutral[200],
  },
  icon: {
    color: theme.palette.neutral[100],
  },
};

const getColorScheme = (themeColor: ThemeColor) => {
  switch (themeColor) {
    case "light":
      return lightTheme;
    case "dark":
      return darkTheme;
  }
};

export interface MenuItemProps {
  value?: string | undefined;
  label: string | undefined;
  variant?: Variant | undefined;
  iconComponent?: React.FC<IconProps> | undefined;
  avatarComponent?: React.FC<AvatarProps> | undefined;
  selected?: boolean | undefined;
  disableCheckMark?: boolean | undefined;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  onMouseDown?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  themeColor?: ThemeColor | undefined;
}

const RootContainer = styled.button<{
  selected?: boolean | undefined;
  variant: Variant;
  colorScheme: ColorScheme;
}>(({ selected, theme, variant, colorScheme }) => ({
  padding: "10px 14px",
  display: "flex",
  alignItems: "center",
  gap: 8,
  ":hover": {
    ...colorScheme.hover,
  },
  ...(selected ? colorScheme.selected : colorScheme.container),
  cursor: "pointer",
}));

const Label = styled.span<{
  colorScheme: ColorScheme;
  selected?: boolean | undefined;
}>(({ theme, colorScheme, selected }) => ({
  flexGrow: 1,
  ...theme.salesleg.typography.paragraph.md.medium,
  ...(selected ? colorScheme.selected : colorScheme.container),
  ":hover": {
    ...colorScheme.hover,
  },
  textAlign: "left",
}));

// This ensure that width of the menu does not change with selection.
// This basically locks width of the menu according to the child item
// with longest label
const CheckMarkPlaceholder = styled.div({ width: 16, height: 16 });

export const MenuItem = React.forwardRef<HTMLButtonElement, MenuItemProps>(
  (
    {
      variant = "default",
      label,
      iconComponent,
      avatarComponent,
      selected,
      disableCheckMark,
      onClick,
      onMouseDown,
      themeColor = "light",
    },
    ref
  ) => {
    const IconComponent = iconComponent;
    const AvatarComponent = avatarComponent;
    const theme = useTheme();

    const colorScheme = getColorScheme(themeColor);

    return (
      <RootContainer
        selected={selected}
        onClick={onClick}
        onMouseDown={onMouseDown}
        ref={ref}
        variant={variant}
        colorScheme={colorScheme}
      >
        {variant === "icon" && IconComponent && (
          <IconComponent
            size={20}
            color={theme.salesleg.palette.neutral[400]}
          />
        )}
        {variant === "avatar" && AvatarComponent && (
          <AvatarComponent size={24} />
        )}
        <Label colorScheme={colorScheme} selected={selected}>
          {label}
        </Label>
        {selected && !disableCheckMark && (
          <CheckIcon size={20} color={colorScheme.icon.color} />
        )}
        {!selected && !disableCheckMark && <CheckMarkPlaceholder />}
      </RootContainer>
    );
  }
);
