import styled from "@emotion/styled";
import theme from "../../theme/light/theme";
import { StyledFC } from "../../types/styledFC";

interface AssignedToCellProps {
  name?: string | undefined;
}

const Name = styled.div(() => ({
  color: theme.palette.neutral[400],
}));

const AssignedToCell: StyledFC<AssignedToCellProps> = ({ name }) => {
  return <Name>{name}</Name>;
};

export default AssignedToCell;
